/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, makeStyles, StylesProvider } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import styled from "styled-components";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonUtils, { getAccessToken, getRefreshToken } from "./utils/commonUtils";
import config from "./config";
import Notification from "./components/Notification/Notification";
import {
	closeNotification,
	hideJobVerificationModal,
	hidePocDocUploadModal,
	toggleHrNumberVerificationModal,
} from "./actions";
import Clevertap from "./utils/Clevertap";
import { clientTokenExchange, getAuthToken } from "./utils/apiClients";
import Modal from "./components/Modal/Modal";
import JobHrNumVerificationModal from "./views/JobsPage/JobHrNumVerificationModal";
import EditJobWarningModal from "./views/PostJobFormNew/EditWarningModal";
import JobVerificationModal from "./views/JobsPage/JobVerificationModal";
import PocDocUploadModal from "./views/JobsPage/PocDocUploadModal";
import { lazyLoader } from "./utils/helpers";
import NotFoundPage from "./views/NotFoundPage";
import { remoteConfig, fetchAndActivate } from "./firebase";
import { LOGIN_VIA_OTP } from "./utils/constants";

const OrgEmailVerificationV2 = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "OrgEmailVerificationV2" */
		"./views/CompanyVerificationPage/components/JobVerification/OrgEmailVerification/index.js"
	)
);

const ClickToVerifyEmail = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "ClickToVerifyEmail" */
		"./views/CompanyVerificationPage/components/JobVerification/OrgEmailVerification/ClickToVerifyEmail.jsx"
	)
);

const Main = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "Mainjs" */
		"./views/Main/Main"
	)
);

const Login = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "Login" */
		"./views/Login/LoginV2"
	)
);

const SEMLandingPage = lazyLoader(() => import("./views/SEMLanding/SEMLandingPage"));

const Enterprise = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "Enterprise" */
		"./enterpriseGrading/Enterprise"
	)
);

const Onboarding = lazyLoader(() =>
	import(
		/* webpackPrefetch: true */
		/* webpackChunkName: "Onboarding" */
		"./views/OnboardingNew/index"
	)
);

const theme = createTheme({
	palette: {
		primary: {
			main: "#4d3951",
		},
		secondary: {
			main: "#2bb793",
			contrastText: "#fff",
		},
		textColor: {
			main: "#ffffff",
		},
	},
});

const useStyles = makeStyles(muiTheme => ({
	backdrop: {
		zIndex: muiTheme.zIndex.modal + 1,
		color: "#fff",
	},
}));

const App = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const notification = useSelector(state => state.notification);
	const [
		showHrNumberVerificationModal,
		showJobVerificationModal,
		editWarningModal,
		showPocDocUploadModal,
	] = useSelector(
		({
			modal: { hrNumber, jobVerificationModal, editWarning, pocDocUploadModal },
		}) => [hrNumber, jobVerificationModal, editWarning, pocDocUploadModal]
	);
	const loader = useSelector(state => state.loader.show);
	const [isBranchLoaded, setBranchLoaded] = useState(false);

	useEffect(() => {
		const tagManagerArgs = {
			gtmId: config.google.gtmId,
		};
		TagManager.initialize(tagManagerArgs);
		Clevertap.initialize(config.cleverTapAccountId);
		let userData = JSON.parse(CommonUtils.getItemFromLocalStorage("__user__"));
		const timerId = setTimeout(() => {
			setBranchLoaded(true);
		}, 3000);

		try {
			window.branch.init(config.branchSdkKey, async (err, data) => {
				// custom branch event as per sidharth request.
				window.branch.logEvent("Web Login", data?.data_parsed, error =>
					console.error(error)
				);
				if (data && data.data_parsed) {
					let authToken = null;
					let refreshToken = null;

					const token = data?.data_parsed?.token;
					const workspaceId = data?.data_parsed?.workspace_id;
					if (token) {
						// fetch actual token from api. and display loader
						const resp = await getAuthToken({ token });
						if (resp?.status === 200) {
							authToken =
								resp?.data?.tokens?.access || resp?.data?.auth_token; // TODO: resp?.data?.auth_token to removed post release one week
							refreshToken = resp?.data?.tokens?.refresh;
							userData = JSON.stringify(resp?.data?.user_data);
						}
					} else {
						authToken =
							data?.data_parsed?.tokens?.access ||
							data?.data_parsed?.auth_token;
						refreshToken = data?.data_parsed?.tokens?.refresh;
						userData = JSON.stringify(data.data_parsed?.user_data);
					}
					if (authToken) {
						CommonUtils.setItemInLocalStorage("__token__", authToken);

						CommonUtils.setItemInLocalStorage("__user__", userData);
						!CommonUtils.isLatestVersion() &&
							CommonUtils.updateReleaseVersion();
					}
					if (refreshToken) {
						CommonUtils.setItemInLocalStorage("__rtk__", refreshToken);
					}
					if (workspaceId) {
						CommonUtils.setItemInLocalStorage("__workspaceId__", workspaceId);
						CommonUtils.setItemInLocalStorage(LOGIN_VIA_OTP, 1);
					}
				}
				clearTimeout(timerId);
				setBranchLoaded(true);
			});
		} catch (error) {
			setBranchLoaded(true);
			throw new Error(error);
		}
	}, []);

	useEffect(() => {
		try {
			fetchAndActivate(remoteConfig);
		} catch (err) {
			console.error(err);
		}
	}, [remoteConfig]);

	const onJobVerificationModalClose = () => {
		dispatch(hideJobVerificationModal());
	};

	const onPocDocUploadModalClose = () => {
		dispatch(hidePocDocUploadModal());
	};

	useEffect(() => {
		// Check on mount for exchange
		const refreshToken = getRefreshToken();
		const accessToken = getAccessToken();
		if (!refreshToken && accessToken) {
			clientTokenExchange({
				grant_type: "client_credentials",
				client_secret: accessToken,
			})
				.then(resp => {
					if (resp?.status === 200 && resp?.data?.success) {
						CommonUtils.setItemInLocalStorage(
							"__token__",
							resp?.data?.access_token
						);
						CommonUtils.setItemInLocalStorage(
							"__rtk__",
							resp?.data?.refresh_token
						);
					}
				})
				.catch(err => console.error(err));
		}
	}, []);

	const getLoginURL = data => {
		// Remove JobId if present, when user gets loggedOut
		if (data.location.pathname.includes("/jobs")) {
			return {
				pathname: "/login",
			};
		}

		return {
			pathname: `/login${data.location.pathname}`,
			search: data?.location?.search,
		};
	};

	if (isBranchLoaded) {
		const token = CommonUtils.getItemFromLocalStorage("__token__");
		return (
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					<AppContainer>
						<Switch>
							<Route
								path={[
									"/post-a-free-job",
									"/bengaluru-post-a-free-job",
									"/hire-post-a-free-job",
								]}
								name="SEMLanding"
								component={SEMLandingPage}
							/>
							<Route
								path={[
									"/login",
									"/fos",
									"/apnahire-east-and-central-india",
									"/apnahire-west-india",
									"/apnahire-north-india",
									"/apnahire-south-india",
								]}
								name="Login"
								component={Login}
							/>
							<Route
								path="/verify/email"
								name="Verify Email"
								component={ClickToVerifyEmail}
							/>
							<Route
								path="/verification/email"
								name="Email Verification"
								component={OrgEmailVerificationV2}
							/>
							<Route
								path="/enterprise"
								name="Enterprise"
								component={Enterprise}
							/>
							<Route
								path="/onboarding"
								name="onboarding"
								component={Onboarding}
								exact
							/>
							<Route
								path={[
									"/post-job",
									"/profile",
									"/company-page",
									"/jobs/:id",
									"/jobs",
									"/new-user",
									"/jobs/:id/:userId",
									"/manage-coins",
									"/buy-coins",
									"/download-applications",
									"/reports",
									"/reports-dashboard",
									"/company-and-members",
									"/internal-user",
									"/dashboard",
									"/verification/:jobId",
									"/database",
									"/",
									"/accounts/:userId",
								]}
								exact
								name="Main"
								render={data => {
									return token ? (
										<Main history={data.history} />
									) : (
										<Redirect to={getLoginURL(data)} />
									);
								}}
							/>
							<Route path="*" component={NotFoundPage} />
						</Switch>
						<Notification
							open={notification.open}
							type={notification.type || "info"}
							message={notification.message}
							duration={notification.duration}
							onClose={() => dispatch(closeNotification())}
						/>
						<Backdrop open={loader} className={classes.backdrop}>
							<AnimatedLogo />
						</Backdrop>
						<Modal
							open={showHrNumberVerificationModal?.open}
							handleClose={() => {
								dispatch(toggleHrNumberVerificationModal());
							}}
						>
							<JobHrNumVerificationModal />
						</Modal>
						<Modal
							open={showJobVerificationModal.show}
							handleClose={onJobVerificationModalClose}
							maxWidth={640}
							borderRadius={2}
							paddingSpacing={[3]}
							disableBackdropClick
						>
							<JobVerificationModal
								showUpdateEmailOption={
									showJobVerificationModal.showUpdateEmailOption
								}
								handleClose={onJobVerificationModalClose}
							/>
						</Modal>
						<Modal
							open={showPocDocUploadModal}
							handleClose={onPocDocUploadModalClose}
							borderRadius={2}
							maxWidth={450}
							paddingSpacing={[3]}
							disableBackdropClick
						>
							<PocDocUploadModal handleClose={onPocDocUploadModalClose} />
						</Modal>
						<Modal
							open={editWarningModal?.open}
							maxWidth={648}
							handleClose={() => {}}
						>
							<EditJobWarningModal />
						</Modal>
						<ToastContainer />
					</AppContainer>
				</ThemeProvider>
			</StylesProvider>
		);
	}
	return (
		<Backdrop open className={classes.backdrop}>
			<AnimatedLogo />
		</Backdrop>
	);
};

// Styles
const AnimatedLogo = styled.img.attrs(
	({ src = "/apna-logo.svg", alt = "logo", width = 80 }) => ({
		src,
		alt,
		width,
	})
)`
	animation: pulse 1.3s infinite;
	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;

const AppContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100vw;
	background-color: #f8f8f8;

	// CSV DOWNLOAD TOAST STYLE CUSTOMIZATIONS
	.toast {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		max-width: 344px;
	}
	.toast-success {
		background: #021330 !important;
		color: #ffffff;
		margin: 10px auto;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
	}
	.toast-error {
		background: #fc3c13 !important;
		margin: 10px auto;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.12);
		border-radius: 4px;
	}
	.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
	.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
	.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
	.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
		background-color: #fff;
	}
`;

export default withRouter(App);
