import styled, { css } from "styled-components";

const NO_OF_COLS = 12;

const SCREEN_SIZE_CONFIG = [
	{
		type: "sm",
		offset: "Sm",
		screenSize: 576,
	},
	{
		type: "md",
		offset: "Md",
		screenSize: 768,
	},
	{
		type: "lg",
		offset: "Lg",
		screenSize: 992,
	},
	{
		type: "xl",
		offset: "Xl",
		screenSize: 1200,
	},
	{
		type: "xxl",
		offset: "Xxl",
		screenSize: 1400,
	},
];

export const Container = styled.div`
	width: 100%;
`;

export const Row = styled.div`
	--bs-gutter-x: ${({ gutterX = 0 }) => gutterX}rem;
	--bs-gutter-y: ${({ guttery = 0 }) => guttery}rem;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
	justify-content: ${({ jc = "flex-start" }) => jc};

	& > * {
		flex-shrink: 0;
		width: 100%;
		max-width: 100%;
		padding-right: calc(var(--bs-gutter-x) * 0.5);
		padding-left: calc(var(--bs-gutter-x) * 0.5);
		margin-top: var(--bs-gutter-y);
	}
`;

export const Col = styled.div`
	flex: 0 0 auto;
	width: ${({ width }) =>
		!width || width === "auto" ? "auto" : `${(width * 100) / NO_OF_COLS}%`};
	${({ offset }) =>
		offset &&
		css`
			margin-left: ${(offset * 100) / NO_OF_COLS}%;
		`}

	${props =>
		css`
			${SCREEN_SIZE_CONFIG.map(({ type, offset, screenSize }) =>
				props[type]
					? `@media (min-width: ${screenSize}px) {
				width: ${props[type] === "auto" ? "auto" : `${(props[type] * 100) / NO_OF_COLS}%`};
				${props[offset] && `margin-left: ${(props[offset] * 100) / NO_OF_COLS}%;`}
			}`
					: ""
			).join("")}
		`};
`;

export const Flex = styled.div`
	display: flex;
	justify-content: ${({ jc = "flex-start" }) => jc};
	align-items: ${({ align = "start" }) => align};
	flex-direction: ${({ direction = "row" }) => direction};
	gap: ${({ gap = "0px" }) => gap};
	width: ${({ width = "auto" }) => width};
	background: ${({ bg = "none" }) => bg};
	padding: ${({ padding = "0" }) => padding};
	flex-wrap: ${({ wrap = "nowrap" }) => wrap};
`;

export default {};
