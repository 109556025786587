export default {
	LOGOUT: "/api/employer/v1/logout/",
	LOGOUT_V2: "/auth/v2/logout",
	LOGIN_V2: "/apna-auth/employer/login/", // TRAI changes
	LOGIN_V3: "/apna-auth/v2/employer/login/",
	OTP: "/api/v1/employer-info/get-otp/",
	OTP_V2: "/api/employer/v1/send-otp/",
	OTP_V3: "/api/userprofile/v1/otp/", // TRAI changes
	SQL_QUERY: "/api/v1/sql-query/",
	JOBS: "/api/employer/v1/jobs/",
	JOB_DATA: "/api/employer/v1/job/",
	JOB_DATA_V3: "/api/employer/v3/job/",
	BANNED_USER: "/api/employer/v1/is_banned/",
	JOBS_V2: "/api/employer/v2/jobs/",
	JOBS_V3: "/api/employer/v3/jobs/",
	JOBS_V4: "/api/employer/v4/jobs/",
	JOBS_V5: "/api/employer/v5/jobs/",
	JOBS_V6: "/api/employer/v6/jobs/",
	JOBS_V7: "/api/employer/v7/jobs/",
	JOBS_V8: "/api/employer/v8/jobs/",
	ENTERPRISE_V1_JOBS: "/api/employer/v1/enterprise/jobs/",
	JOB_DATA_V2: "/api/employer/v2/jobs/",
	BANNED_USER_V2: "/api/employer/v2/is_banned/",
	NEW_JOB_CATEGORIES: "/api/v1/job-catg/",
	EDUCATION_LEVELS: "/api/v1/education-levels/",
	ORGANIZATION_NAME: "/api/v1/orgs/",
	VERIFIED_ORGANISATION: "/api/employer/v1/organization-search",
	ACCEPT_TERMS: "/api/employer/v1/terms/accept/",
	INTERNAL_USER_JOBS_LIST: "/api/internal/v1/jobs/",
	INTERNAL_USER_JOBS_LIST_V2: "/api/internal/v2/jobs/",
	INTERNAL_USER_JOBS_LIST_V4: "/api/internal/v4/jobs/",
	EMPLOYER_JOBS_LIST_V2: "/api/employer/v2/jobs/",
	USER_JOBS_LIST: "/api/employer/v3/jobs/",
	CITIES_LIST: "/api/location/v1/cities/",
	ASSESSMENT: "/api/assessments/v1/",
	JOB_POST_COUNT: "/api/internal/v1/jobs_count/",
	USER_PERSONA: "/api/employer/v2/persona/",
	USERS: "/api/employer/v1/users/",
	INTERNAL_USERS: "/api/internal/v1/users/",
	INTERNAL_USERS_V2: "/api/internal/v2/users/",
	EMPLOYER_TYPE: "/api/internal/v1/set-type/",
	INTERNAL_EMPLOYEE_COUNT: "/api/internal/v1/set-headcount/",
	INTERNAL_DB_EMPLOYEE_COUNT: "/api/internal/v1/set-rec-org-headcount/%RECORG_ID%",
	GST_DETAILS: "/api/internal/v2/gst-details/",
	EMPLOYER_INVITE_TEXT: "/api/employer/v1/invite-text/",
	SEND_EMPLOYER_INVITE: "/api/employer/v1/invite-employers/",
	QC_JOB_LIST: "/api/assessments/v1/jobs-by-questions/",
	NEW_QC_JOB_LIST: "/api/internal/v1/jobs_qc_view/",
	JOB_APPLICATIONS: "/api/employer/v1/applications/",
	GET_AUTH_TOKEN: "/api/employer/v2/auth_token/",
	INTERNAL_TAGS: "/api/internal/v1/job_tags/",
	ATS_FUNNEL_STEPS: "/api/employer/v1/ats-funnel/",
	UPDATE_JOB_ATS_TEMPLATE: "/api/employer/v1/ats-templates/",
	LANGUAGES_BY_CITY: "/api/location/v1/city-languages/",
	UPDATE_EMPLOYER_ECC_ACCESS: "/api/employer/v1/jobs/",
	EMPLOYER_HR_NUMBER_LIST: "/api/employer/v1/employer_hr_number_list/",
	EMPLOYER_HR_NUMBER_STATUS: "/api/employer/v1/employer_hr_number_status/",
	INTERNAL_VERIFY_PHONE_NUMBER: "/api/internal/v1/verify_phone_number/",
	SEND_PHONE_NUMBER_EVENT: "/api/employer/v1/update_application_status/",
	ECC_PREFERENCE_CONFIG: "/api/employer/v1/employer_preference/templates",
	EMPLOYER_JOBS_LOCATION: "/api/employer/v1/jobs_by_location/",
	JOB_ROLES_SUGGESTIONS: "/suggester/api/v4/suggestions",
	CREDIT_EXPIRY_OPTIONS: "/api/internal/v1/get-credits-delete-reason/",
	INITIATE_NUMBER_MASK_CALL_MOBILE: "/number-mask/api/v1/call/initiate",
	INITIATE_NUMBER_MASK_CALL_DESKTOP: "/number-mask/api/v1/call/trigger-call",
	NUMBER_MASK_CALL_STATUS: "/number-mask/api/v1/call/",
	GET_CANDIDATE_RESUME: "/api/employer/v1/resume/",
	ORGANIZATION_DOMAINS: "/api/employer/v1/organization-domains/",
	EMAIL_VERIFICATION_STATUS: "api/employer/v1/employer_hr_email_status/",
	COMPANY_PREFILL: "/api/employer/v1/organization-prefill/",
	SALARY_SUGGESTION_URL: "/api/employer/v1/salary-suggestion",
	BAN_DETAILS: "/api/employer/v1/ban-details/",
	REACTIVATION_REQUEST: "/api/employer/v1/reactivation-request/",
	ACCEPT_REACTIVATION_CONSENT: "/api/employer/v1/consent-accepted/",
	RECRUITERS_LIST: "/api/employer/v1/creator-recruiters/",
	USER_PROFILE: "/api/employer/v2/profile/",
	USER_WORKSPACE: "/api/employer/v1/workspace/",
	INTERNAL_USER_WORKSPACE: "/api/internal/v1/workspace/",
	TNC_AGREEMENT: "/api/employer/v1/check_agreement/",
	SIMILAR_JOBS: "/api/employer/v1/similar_jobs/",
	ACCOUNT_DETAILS: "/apna-account/v1/account/",
	VALIDATE_USER: "/api/userprofile/v1/user/validate-number/",
	PAYMENT_PENDING: "/api/employer/v1/jobs/premium-payment/info",
	AUTO_RENEWAL_CHANGE: "/auto-renew/change/",
	TRANSACTIONS: "/apna-account/v1/coin/transactions/",
	INTERNAL_TRANSACTIONS: "/apna-account/v1/internal/coin/transactions/",
	CHECK_COUPON_URL: "/apna-coupon/api/v1/redeem-coupon/",
	INIT_TRANSFER: "/apna-account/v1/coin/init-transfer/",
	VERIFY_TRANSFER: "/apna-account/v1/coin/verify-transfer/",
	DEBIT_COINS_UPDATE_JOB_STATUS: "/api/employer/v3/debit-and-post-job/",
	PACKAGES: "/product/packages",
	PACKAGES_PURCHASE_DETAILS: "/order/coin/purchase-details?num_coins=",
	SERVICES: "/product/v3/services-filtered",
	JOB_ACTIVATION_PLANS: "/product/get-plans/",
	SELECTED_SERVICE: "/product/service/",
	GENERATE_PAYMENT_ORDER: "/order/order",
	APNA_PRODUCTS_URL: "/product/product",
	ACC_OTP: "/apna-account/v1/coin/transfer/resend-otp/",
	STATES_URL: "/api/publicapis/v1/city-state-list/",
	CITIES_URL: "/api/publicapis/v1/city-state-list/",
	REPORT_RESUME_URL: "/api/employer/v1/report_resume/",
	UPDATE_TNC_AGREEMENT: "/api/employer/v1/sign_agreement/",
	TRIGGER_KALEYRA_CALL: "/api/employer/v1/%JOB_ID%/trigger-call/%EMPLOYER_ID%",
	TRIGGER_DB_KALEYRA_CALL: "/api/employer/v1/%RECORG_ID%/db/trigger-call",
	ADD_CALL_FEEDBACK: "/api/internal/v1/jobs/%JOB_ID%/add_call_feedback",
	ADD_DB_CALL_FEEDBACK: "/api/internal/v1/db/%RECORG_ID%/add_call_feedback",
	GET_CALLING_DETAILS: "/api/employer/v1/c2c_call/",
	GET_CALLING_COUNT: "/api/internal/v1/jobs/%JOB_ID%/call-counts",
	GET_CALLING_COUNT_FOR_DB: "/apna-jvs/database/v1/access/%RECORG_ID%/call-counts",
	GET_EMPLOYER_FEEDBACK_OPTIONS: "/api/internal/v1/employer_feedback_process",
	ADD_EMPLOYER_FEEDBACK_COMMENTS: "/api/internal/v2/jobs/%JOB_ID%/comments/",
	GET_USER_COMPLAINT_HISTORY: "/api/internal/v1/jobs/%JOB_ID%/complaint_history/",
	GET_GST_DETAILS: "/invoice/api/v1/company-detail",
	POST_GST_DETAILS: "/invoice/api/v1/add-company-detail",
	POST_GST_IN_CHECKOUT: "/invoice/api/v1/add-gst-number",
	INVOICE_URL: "/invoice/api/v1/invoice/path/",
	ORDER_URL: "/order/order/",
	COMPANY_VERIFICATION_DETAILS: "/api/internal/v1/jobs/%JOB_ID%/verification_details/",
	PAID_PROMOTION_ELIGIBILITY_URL: "/api/employer/v1/paid-promotion-employer/check/",
	CHECK_MONETISED: "/api/employer/v1/check_monetised/",
	EXPIRY_FEEDBACK_STATUS: "/api/employer/v1/jobs/{jobId}/feedback/",
	GET_SUPPORT_CALL_JOBS_LIST: "/api/internal/v1/jobs_support_view/",
	GET_KALEYRA_CALL_HISTORY: "/api/internal/v1/%JOB_ID%/call-history",
	GET_KALEYRA_CALL_HISTORY_FOR_DB: "/api/internal/v1/%RECRUITER_ID%/user-call-history",
	TRIGGER_SUPPORT_CALL_REQUEST:
		"/api/employer/v1/%JOB_ID%/request_support/%EMPLOYER_ID%",
	DELETEJOB: "/api/employer/v5/jobs/",
	BULK_CSV_DOWNLOAD: "/api/employer/v1/csv-download-info",
	VERIFY_COMPANY_CARD_DETAILS: "/%CARD%/company-details",
	REPORT_MEMBER_AND_CONSULTANCY: "/api/employer/v1/admin/report-%REPORTED%/",
	ADD_TEAM_MEMBER: "/api/employer/v1/admin/add-member/",
	GET_ADMIN_MEMBERS_CONFIG: "/api/employer/v1/admin/config/",
	GET_ADMIN_TEAM_MEMBERS: "/api/employer/v1/admin/recruiters/?organization_id=",
	DUPLICATE_JOB_CHECK: "/api/employer/v2/job/check-duplicate",
	CHECK_FIRST_PURCHASE: "/order/check-first-purchase/",
	COIN_REFUND_DETAILS: "/apna-account/v1/coin/refunded/uf/",
	JOB_POST_MISSING_FIELDS: "/api/employer/v2/jobs/%JOB_ID%/missing_fields/",
	VALIDATE_RETRY_PAYMENT: "order/validate-order-retry-payment/",
	GST_COMPANY_DETAILS: "invoice/api/v1/gst/detail/",
	TITLE_CAT_OPTIONS: "api/employer/v1/title-cat-options",
	APNA_DB_ELIGIBILITY: "/cerebro/api/v1/eligible/",
	EMPLOYER_POST_JOB_PREFILL: "/api/employer/v4/prefill",
	ALLOWED_JOB_TITLES: "/api/employer/v2/%JOB_ID%/allowed-job-titles",
	CREATE_CUSTOM_PLAN: "/product/custom-plan",
	FRAUD_ANALYSIS_DATA: "/api/internal/v1/job/%JOB_ID%/tns-data/",
	VERIFY_USER: "/apna-verification/deviceinfo/",
	CHECK_FOR_MICRO_CITY_FLAG: "/api/employer/v1/micro-city-flag",
	LOGIN_TOKEN_EXCHANGE: "/auth/v2/exchange",
	ADDITIONAL_DOCUMENT: "/api/internal/additional-documents/%job_id%",
	ADDITIONAL_DOCUMENT_FOR_DB:
		"/api/internal/v1/rec-org-additional-documents/%RECRUITER_ID%/%ORG_ID%",
	SET_HEADCOUNT_VALUE: "/api/employer/v1/set-headcount/%JOB_ID%/",
	HEADCOUNT_MODAL_RENDERED: "/api/employer/v1/%JOB_ID%/headcount-modal-rendered",
	SUPPORT_REQUEST_DATA: "/api/internal/v1/%JOB_ID%/request_support/%USER_ID%",
	PENDING_ORDER_LIST: "/order/list/order",
	DS_MODEL_FRAUD_ANALYSIS_DATA: "/apna-jvs/tns-model/v2/%JOB_ID%/user-score",
	INDUSTRIES_LIST: "/apna-organisations/api/v1/industries/",
	DEGREE_LIST: "/api/edu/v1/course/?education_level=%EDU_LEVEL%&exclude_courses=true",
	DEPARTMENT_LIST_DATA: "/api/employer/v2/job-title-department",
	CATEGORY_DEPARTMENT_INFERENCE: "/api/employer/v1/cat-dep-inference",
	SKILL_RECOMMENDATION: "/api/employer/v1/skill/recommendation",
	SKILL_SEARCH_SUGGESTIONS: "/suggester/api/v2/suggestions",
	UPDATE_JOY_RIDE: "api/employer/v1/%ORG_ID%/update-joy-ride",
	GET_PROMOTIONAL_USER_COUPONS:
		"apna-coupon/api/v1/user/%USER_ID%/coupons-applicable?coupon_type=promotional",
	SEND_EMPLOYER_FEEDBACK: "/api/employer/v1/employer-feedback",
	AUTO_RENEW: "/api/employer/v1/jobs/auto-renew/",
	SUGGESTED_INDUSTRIES: "api/employer/v1/get-suggested-industries/%ORG_ID%",
	ALL_ELIGIBLE_USER_COUPONS:
		"apna-coupon/api/v1/user/%USER_ID%/coupons-applicable?coupon_type=standard,generic,promotional",
	GET_USER_ACCESS_TYPE: "/api/internal/v1/user-access-type/",
	IS_INTERNAL_SALES_AGENT: "/monetisation-pilots/api/v1/check-is-agent",
	UPDATE_TNS_INVESTIGATION_STATUS: "/api/internal/v1/job/%JOB_ID%/tns-investigation/",
	GET_CITY_AREA_DETAILS:
		"/api/location/v1/get-area-details?latitude=%LAT%&longitude=%LNG%",
	GET_USER_SAVED_ADDRESSES:
		"api/employer/v1/employer-saved-addresses/%USER_ID%/?location_type=%LOC_TYPE%&location=%LOCATION%",
	GET_SECTOR_INDUSTRY: "/apna-organisations/api/v1/sector-industries/",

	// /enterprise-profile/dashboard/
	ENTERPRISE_USER_PROFILE: "/enterprise-profile/dashboard/api/v1/users/profile",
	MEMBER_PROFILE_DATA:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/members/profile/",
	ORGANIZATION_INFO: "/apna-organisations/api/v2/organisation/%ORG_ID%/",
	// login
	SEND_ENTERPRISE_LOGIN_OTP: "/apna-auth/v1/enterprise/otp/",
	ENTERPRISE_REGULAR_LOGIN: "/apna-auth/v1/enterprise/login/",
	ENTERPRISE_FIRST_TIME_LOGIN: "/apna-auth/v2/enterprise/otp/login/",
	ENTERPRISE_FORGOT_PASSWORD: "/api/employer/v1/enterprise/forget-password/%EMAIL_ID%",
	ENTERPRISE_UPDATE_PASSWORD: "/api/employer/v1/enterprise/update-password",

	// manage-coins
	MANAGE_COINS_SUMMARY:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/coins/summary",
	VIEW_USAGE:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/coins/team-usage",
	CHANGE_LIMIT:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/coins/limit",
	ENTERPRISE_TRANSACTIONS:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/coins/transactions",
	ENTERPRISE_ORDER:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/order",
	// invite
	INVITE_MEMBER:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/members",
	EDIT_MEMBER_ACCESS:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/members/",
	TEAM_MEMBERS_LIST:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/members",
	INVITE_STATUS: "/api/employer/v1/enterprise/invite-status",

	// enterprise - jobsList
	ENTERPRISE_JOBS_LIST: "/api/employer/v1/enterprise/jobs/",

	// enterprise-reports
	JOB_REPORTS:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/reports/jobs",
	ENTERPRISE_BULK_CSV_DOWNLOAD: "/api/employer/v1/enterprise/csv-download-info/",
	ENTERPRISE_JOBS_APPLICATION: "/api/employer/v1/enterprise/applications/",

	// HR Collab
	HR_COLLABORATOR_DATA:
		"/enterprise-profile/dashboard/api/v1/workspaces/%ENT_USER_ID%/collaborators?status=1",

	// enterprise workspace
	ENTERPRISE_WORKSPACE:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/creator-workspace",
	POC_DOC_V2: "/apna-jvs/poc/v2/%JOB_ID%",
	POC_DOC_V1: " /apna-jvs/poc/v1/%JOB_ID%",
	POC_STATUS: "/apna-jvs/poc/v2/%JOB_ID%/status",

	// company profile
	GET_COMPANY_PROFILE: "/apna-jvs/company-profile/v1",
	POST_SUGGEST_REPORT_COMPANY_PROFILE:
		"/apna-organisations/api/v1/company-profile/report-suggest",
	POST_UPLOAD_LOGO: "/apna-organisations/api/v1/upload",
	// enterprise update migration
	ENTERPRISE_UPDATE_MIGRATION_NUDGES:
		"/api/employer/v1/update-nudge?type=job_migration",
	// database Integration api
	DATABASE_INTEGRATION: "/cerebro/api/v1/job-search/%jobId%",

	VERIFY_BUSINESS_EMAIL_ON_UR: "/apna-jvs/business-email/v1/%JOB_ID%/update-status",
	SELECTED_SERVICE_V2: "/product/v2/job-plan-details",

	// team structure
	TEAM_STRUCTURE:
		"/enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/members/%ENTERPRISE_MEMBER_ID%/details",
	STANDARD_TITLE_NORMALIZER: "suggester/api/v1/normalizer/JobTitle/",

	// Layout Version
	LAYOUT_VERSION: "/api/employer/v1/apnahire/new-layout/%USER_ID%",
	ENABLE_LAYOUT_VERSION: "/api/employer/v1/apnahire/enable-new-layout",

	// PPJ & Related title
	GET_NEIGHBOUR_NODE: "/apna-skillgraph/skill/neighbour-nodes",
	GET_USER_ELLIGIBILITY: "/api/employer/v1/job-posting-eligibility",

	// Internal dashboard accounts list for DB verification
	GET_ACCOUNTS_LIST: "/apna-jvs/database/v1/access-list",
	GET_ACCOUNT_DATA: "/apna-jvs/database/v1/access/%ACCOUNT_ID%",

	PICKUP_FOR_DB_VERIFICAION: "/apna-jvs/database/v1/access/%JOB_ID%/pickup",

	ADD_EMPLOYER_COMMENT_DB_DASHBOARD: "/apna-jvs/database/v1/access/%RECORG_ID%/comment",

	// Pricing Contract
	GET_PRICING_CONTRACT: "/product/pricing-contract/entity/",

	// Transfer Coins
	CHECK_TRANSFER_COINS:
		"apna-account/v1/check-transfer-coins-cohort/%ORG_ID%?external_id=",

	// Coins & Usage
	COINS_AND_USAGE:
		"enterprise-profile/dashboard/api/v1/workspaces/%WORKSPACE_ID%/coins/",
	// internal coins
	COINS_SUMMARY_INTERNAL:
		"/enterprise-profile/dashboard/api/v1/internal/workspaces/%WORKSPACE_ID%/coins/summary?user_id=",
	COIN_TRANSACTIONS_INTERNAL:
		"/enterprise-profile/dashboard/api/v1/internal/workspaces/%WORKSPACE_ID%/coins/transactions",

	// UAN Verification
	SEND_OTP: "/apna-jvs/company/api/v1/%JOB_ID%/company-access/send-otp",
	VERIFY_OTP: "/apna-jvs/company/api/v1/%JOB_ID%/company-access/verify-otp",

	CONTRACT_ORDER_DETAILS: "/product/pricing-contract/order/%ORDER_ID%/",

	GET_WORKSPACE_LIST: "/api/employer/v1/workspace-list",
	DROPOFF_MODAL_DETAILS: "/api/employer/v1/job-post-dropoff-modal",
	JOB_TITLE_NUDGE: "/api/employer/v1/fulfilment-nudges",
	DISCOUNT_COUPON_DETAILS:
		"/apna-coupon/api/v1/user/%USER_ID%/coupons-applicable?source=INDASH&page=1&coupon_type=first_purchase&discount_type=percent&sort_on=cart_constraints.min_value&sort_order=asc",
	REPORT_ACCOUNT_MANAGER_ISSUE: "/api/employer/v1/employer-report-issue",
	CONTACT_US: "/order/contact-us",
	// generate sign-up link
	WORKSPACE_DETAILS: "/api/v1/enterprise/workspace-details",
	SEND_VERIFICATION_LINK: "/api/v1/enterprise/send-verification-link",
	MERGE_SELECT_PLAN_EXPRESS_CHECKOUT_COHORT: "/order/cohort/merge-select-plan-cohort",
	UPDATE_REQUIREMENTS_FULFILMENT: "employer-retention-fulfilment/fulfilment/v1/nudges",
	UPDATE_FULFILMENT_REQUIREMENTS: "/api/employer/v1/job_edit/%JOB_ID%",
	BOOK_DEMO: "/v1/book-demo",
};
