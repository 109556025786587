import React from "react";

const PeoplesIcon = ({ fill = "#5E6C84" }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_755_8490)">
				<path
					d="M13.3335 9.16667C14.7168 9.16667 15.8252 8.05001 15.8252 6.66667C15.8252 5.28334 14.7168 4.16667 13.3335 4.16667C11.9502 4.16667 10.8335 5.28334 10.8335 6.66667C10.8335 8.05001 11.9502 9.16667 13.3335 9.16667ZM6.66683 9.16667C8.05016 9.16667 9.1585 8.05001 9.1585 6.66667C9.1585 5.28334 8.05016 4.16667 6.66683 4.16667C5.2835 4.16667 4.16683 5.28334 4.16683 6.66667C4.16683 8.05001 5.2835 9.16667 6.66683 9.16667ZM6.66683 10.8333C4.72516 10.8333 0.833496 11.8083 0.833496 13.75V15C0.833496 15.4583 1.2085 15.8333 1.66683 15.8333H11.6668C12.1252 15.8333 12.5002 15.4583 12.5002 15V13.75C12.5002 11.8083 8.6085 10.8333 6.66683 10.8333ZM13.3335 10.8333C13.0918 10.8333 12.8168 10.85 12.5252 10.875C12.5418 10.8833 12.5502 10.9 12.5585 10.9083C13.5085 11.6 14.1668 12.525 14.1668 13.75V15C14.1668 15.2917 14.1085 15.575 14.0168 15.8333H18.3335C18.7918 15.8333 19.1668 15.4583 19.1668 15V13.75C19.1668 11.8083 15.2752 10.8333 13.3335 10.8333Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_755_8490">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export const PeopleIcon = () => (
	<svg
		width="22"
		height="16"
		viewBox="0 0 22 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.6699 9.12988C17.0399 10.0599 17.9999 11.3199 17.9999 12.9999V15.9999H20.9999C21.5499 15.9999 21.9999 15.5499 21.9999 14.9999V12.9999C21.9999 10.8199 18.4299 9.52988 15.6699 9.12988Z"
			fill="#00857C"
		/>
		<path
			d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z"
			fill="#00857C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.9999 8C16.2099 8 17.9999 6.21 17.9999 4C17.9999 1.79 16.2099 0 13.9999 0C13.5299 0 13.0899 0.0999998 12.6699 0.24C13.4999 1.27 13.9999 2.58 13.9999 4C13.9999 5.42 13.4999 6.73 12.6699 7.76C13.0899 7.9 13.5299 8 13.9999 8Z"
			fill="#00857C"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 9C5.33 9 0 10.34 0 13V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V13C16 10.34 10.67 9 8 9Z"
			fill="#00857C"
		/>
	</svg>
);

export default PeoplesIcon;
