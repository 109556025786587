import { initializeApp } from "firebase/app";
import { getStorage, ref } from "firebase/storage";
import {
	getRemoteConfig,
	getValue,
	fetchAndActivate,
	fetchConfig,
} from "firebase/remote-config";
import { initializeAppCheck, getToken, ReCaptchaV3Provider } from "firebase/app-check";
import config from "../config";
import {
	REMOTE_CONFIG_FETCH_INTERVAL,
	REMOTE_CONFIG_FETCH_INTERVAL_DEV,
} from "../utils/constants";

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: config.firebase.apiKey,
	authDomain: config.firebase.authDomain,
	databaseURL: config.firebase.databaseURL,
	projectId: config.firebase.projectId,
	storageBucket: config.firebase.storageBucket,
	messagingSenderId: config.firebase.messagingSenderId,
	appId: config.firebase.appId,
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider(config.recaptchaKey),

	// Optional argument. If true, the SDK automatically refreshes App Check
	// tokens as needed.
	isTokenAutoRefreshEnabled: false,
});

const remoteConfig = getRemoteConfig(app);
if (remoteConfig?.settings?.minimumFetchIntervalMillis) {
	remoteConfig.settings.minimumFetchIntervalMillis =
		process.env.NODE_ENV !== "development"
			? REMOTE_CONFIG_FETCH_INTERVAL
			: REMOTE_CONFIG_FETCH_INTERVAL_DEV;
}

const storage = getStorage(app);
// export const fireBaseStorage = firebase.storage().ref();
export const fireBaseStorage = ref(storage);

export { app, storage as default };

export { remoteConfig, getValue, appCheck, getToken, fetchAndActivate, fetchConfig };
