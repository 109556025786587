import {
	UPDATE_JOB_APPLICATIONS_LOADER,
	UPDATE_JOB_APPLICATIONS_LIST,
	RESET_JOB_APPLICATIONS,
	UPDATE_CANDIDATE_APPLICATION,
} from "../constants/types";

const initialState = {
	loading: true,
	jobId: null,
	tab: "not_connected",
	page: 1,
	candidates: [],
};

export default (state = initialState, action) => {
	const { type, payload = {} } = action;
	switch (type) {
		case UPDATE_JOB_APPLICATIONS_LOADER:
			return {
				...state,
				loading: action.value,
			};
		case UPDATE_JOB_APPLICATIONS_LIST:
			return {
				...state,
				loading: false,
				tab: payload.tab,
				page: payload.page,
				jobId: payload.jobId,
				[payload.jobId]: { [payload.tab]: payload.candidates },
			};
		case UPDATE_CANDIDATE_APPLICATION:
			return {
				...state,
				[state.jobId]: { [state.tab]: payload.candidates },
			};
		case RESET_JOB_APPLICATIONS:
			if (payload.jobId && payload.tab)
				return {
					...state,
					[payload.jobId]: { [payload.tab]: [] },
				};
			return initialState;
		default:
			return state;
	}
};
