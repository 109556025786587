import React from "react";
import styled, { css } from "styled-components";
import ElipseLoader from "../../../../assets/svgs/ElipseLoader";

const Spinner = ({ children, disable = false, fillColor = "#2BB792" }) => {
	return (
		<Container disable={disable}>
			{children || <ElipseLoader fillColor={fillColor} />}
		</Container>
	);
};

const Container = styled.span`
	height: min-content;
	width: min-content;
	& > svg {
		@keyframes spinner {
			to {
				transform: rotate(360deg);
			}
		}
		animation: spinner 0.6s linear infinite;
	}

	${props =>
		props?.disable &&
		css`
			display: none;
		`}
`;

export default Spinner;
