export default {
	development: {
		env: "development",
		datadogEnv: "staging",
		baseUrl: "https://backend-stg.apna.co",
		employerBaseUrl: "https://employer-test.apna.co",
		enterpriseBaseUrl: "http://api.staging.infra.apna.co",
		websiteUrl: "https://apna-co.staging.infra.apna.co",
		accountsUrl: "https://api.staging.infra.apna.co",
		IMAGE_BASE_URL: "https://apna-staging.gumlet.io/",
		// firebase: {
		// 	apiKey: "AIzaSyCR-DCbkt3Ut2wVFDNQhx7Smf7KlNDJPjk",
		// 	authDomain: "apna-android-debug.firebaseapp.com",
		// 	databaseURL: "https://apna-android-debug.firebaseio.com",
		// 	projectId: "apna-android-debug",
		// 	storageBucket: "apna-android-debug.appspot.com",
		// 	messagingSenderId: "378198512148",
		// 	appId: "1:378198512148:web:c08022d6194c5246d80d01",
		// 	measurementId: "G-0DMM0K4F69",
		// },
		firebase: {
			apiKey: "AIzaSyD9rPLehpZZZNMK_9TkUIeGSnyN1Lzwb44",
			authDomain: "apnatime-fbc72.firebaseapp.com",
			databaseURL: "https://apnatime-fbc72.firebaseio.com",
			projectId: "apnatime-fbc72",
			storageBucket: "mumbai_apnatime_prod",
			messagingSenderId: "978462882991",
			appId: "1:978462882991:web:dd8e8f71975db46b4b685a",
			measurementId: "G-Z9W20H2DTD",
		},
		google: {
			placesApiKey: "AIzaSyBb1n1ev8NAyWdOjxcTAOqsV9XWQc0Y87s",
			analytics: "UA-155377963-1",
			mapsKey: "AIzaSyBoxQ6DGHWLm_skwqDfbmYFkQ60MA96Mhs",
			gtmId: "GTM-KN9MWFC",
		},
		mixpanelKey: "6be41536d5701ebe5974cad71e5d0ec4", // Android Staging2
		mixpanelProxyURL: "https://api-mixpanel-staging.infra.apna.co",
		logrocket: "66keko/employer-dashboard-stg",
		intercom: "d0yv8lrt",
		sentry: "https://9606d2cc1dac4238b899f747d3165c41@sentry.io/5188582",
		idleTimeout: 3600000, // 60 mins time in Milliseconds
		branchSdkKey: "key_test_cpPtPeH1YBRDuRUCQ17CXcijtEkVtGTU",
		// TODO: Check before merge, "TEST-967-8R9-WW6Z"
		cleverTapAccountId: "967-8R9-WW6Z",
		moengageAppId: "PTB9NWRFRFHNVWOJJ0QL0MML_DEBUG",
		debugLog: 1,
		ravenWSUrl: "wss://backend-stg.apna.co/raven/api/v4/websocket",
		ravenTeamId: "6aqs1gnh8t8z5k6jmofcicyher",
		unleashProxyUrl: "https://unleash-proxy.infra.apna.co/proxy",
		unleashClientKey: "unleashEVVZuaqq",
		kaleyraBaseUrl: "https://api-voice.kaleyra.com/v1/",
		kaleyraApiKey: "A9fae898e7df220428bd9ac1e5b190e70",
		razorPayKey: "rzp_test_ytGyigdFFm7njU",
		expiryModalCloseIconConfig: [],
		fingerprintId: "oJLjj46L9jcalm3prUDe",
		recaptchaKey: "6LdKUrYgAAAAABpiTAAoVKxIKPVATVMdNdgaO3de",
		oauthBaseUrl: "https://api.staging.infra.apna.co",
		privateBaseUrl: "https://api-private.staging.infra.apna.co",
		ppjFetchTime: 1,
		salesForceLeadFormId: "00D0p0000002h2j",
		salesForceLeadFormUrl:
			"https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
	},
	test: {
		env: "staging",
		datadogEnv: "staging",
		baseUrl: "https://backend-stg.apna.co",
		employerBaseUrl: "https://employer-test.apna.co",
		enterpriseBaseUrl: "https://api.staging.infra.apna.co",
		websiteUrl: "https://apna-co.staging.infra.apna.co",
		accountsUrl: "https://api.staging.infra.apna.co",
		IMAGE_BASE_URL: "https://apna-staging.gumlet.io/",
		firebase: {
			apiKey: "AIzaSyCR-DCbkt3Ut2wVFDNQhx7Smf7KlNDJPjk",
			authDomain: "apna-android-debug.firebaseapp.com",
			databaseURL: "https://apna-android-debug.firebaseio.com",
			projectId: "apna-android-debug",
			storageBucket: "apna-android-debug.appspot.com",
			messagingSenderId: "378198512148",
			appId: "1:378198512148:web:c08022d6194c5246d80d01",
			measurementId: "G-0DMM0K4F69",
		},
		google: {
			placesApiKey: "AIzaSyDWfrISMm1Y0wbSOy2WxXME5LAZnl5-4Tk",
			analytics: "UA-155377963-1",
			mapsKey: "AIzaSyBoxQ6DGHWLm_skwqDfbmYFkQ60MA96Mhs",
			gtmId: "GTM-KN9MWFC",
		},
		mixpanelKey: "6be41536d5701ebe5974cad71e5d0ec4", // Android Staging2
		mixpanelProxyURL: "https://api-mixpanel-staging.infra.apna.co",
		logrocket: "66keko/employer-dashboard-stg",
		intercom: "d0yv8lrt",
		sentry: "https://9606d2cc1dac4238b899f747d3165c41@sentry.io/5188582",
		idleTimeout: 600000, // 10 mins time in Milliseconds
		branchSdkKey: "key_test_cpPtPeH1YBRDuRUCQ17CXcijtEkVtGTU",
		// TODO: Check before merge "TEST-967-8R9-WW6Z"
		cleverTapAccountId: "967-8R9-WW6Z",
		moengageAppId: "PTB9NWRFRFHNVWOJJ0QL0MML_DEBUG",
		debugLog: 1,
		ravenWSUrl: "wss://backend-stg.apna.co/raven/api/v4/websocket",
		ravenTeamId: "6aqs1gnh8t8z5k6jmofcicyher",
		unleashProxyUrl: "https://unleash-proxy.infra.apna.co/proxy",
		unleashClientKey: "unleashEVVZuaqq",
		kaleyraBaseUrl: "https://api-voice.kaleyra.com/v1/",
		kaleyraApiKey: "A9fae898e7df220428bd9ac1e5b190e70",
		razorPayKey: "rzp_test_ytGyigdFFm7njU",
		expiryModalCloseIconConfig: [],
		fingerprintId: "oJLjj46L9jcalm3prUDe",
		recaptchaKey: "6LdKUrYgAAAAABpiTAAoVKxIKPVATVMdNdgaO3de",
		oauthBaseUrl: "https://api.staging.infra.apna.co",
		privateBaseUrl: "https://api-private.staging.infra.apna.co",
		ppjFetchTime: 1,
		salesForceLeadFormId: "00D0p0000002h2j",
		salesForceLeadFormUrl:
			"https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
	},
	production: {
		env: "production",
		datadogEnv: "production",
		baseUrl: "https://production.apna.co",
		employerBaseUrl: "https://employer.apna.co",
		enterpriseBaseUrl: "https://production.apna.co",
		websiteUrl: "https://www.apna.co",
		accountsUrl: "https://production.apna.co",
		IMAGE_BASE_URL: "https://cdn.apna.co/",
		firebase: {
			apiKey: "AIzaSyD9rPLehpZZZNMK_9TkUIeGSnyN1Lzwb44",
			authDomain: "apnatime-fbc72.firebaseapp.com",
			databaseURL: "https://apnatime-fbc72.firebaseio.com",
			projectId: "apnatime-fbc72",
			storageBucket: "mumbai_apnatime_prod",
			messagingSenderId: "978462882991",
			appId: "1:978462882991:web:dd8e8f71975db46b4b685a",
			measurementId: "G-Z9W20H2DTD",
		},
		google: {
			placesApiKey: "AIzaSyDWfrISMm1Y0wbSOy2WxXME5LAZnl5-4Tk",
			analytics: "UA-155377963-1",
			mapsKey: "AIzaSyBoxQ6DGHWLm_skwqDfbmYFkQ60MA96Mhs",
			gtmId: "GTM-5PZVNTV",
		},
		mixpanelKey: "b96e1914cc08adb5d3332330e947ca74", // Candidate Android App
		mixpanelProxyURL: "https://api-mixpanel.infra.apna.co",
		logrocket: "66keko/employer-dashboard-production",
		intercom: "y0p3z303",
		sentry: "https://9606d2cc1dac4238b899f747d3165c41@sentry.io/5188582",
		idleTimeout: 1800000, // 30 mins time in Milliseconds
		branchSdkKey: "key_live_daMARmQZ1yKByHRqT9TNIiiiwrkTAIJy",
		cleverTapAccountId: "867-8R9-WW6Z",
		moengageAppId: "PTB9NWRFRFHNVWOJJ0QL0MML",
		debugLog: 0,
		ravenWSUrl: "wss://production.apna.co/raven/api/v4/websocket",
		ravenTeamId: "61o6uacaajfmjd3fmyoisdukba",
		unleashProxyUrl: "https://unleash-proxy.production.infra.apna.co/proxy",
		unleashClientKey: "unleashEVVZuaqq",
		kaleyraBaseUrl: "https://api-voice.kaleyra.com/v1/",
		kaleyraApiKey: "",
		razorPayKey: "rzp_live_gJ6Rw1hGWfJEoq",
		expiryModalCloseIconConfig: [],
		fingerprintId: "oJLjj46L9jcalm3prUDe",
		recaptchaKey: "6Lev2_AgAAAAAGwQJNJu_RetSojrrRdq8-Z7hvWV",
		oauthBaseUrl: "https://api.production.infra.apna.co",
		privateBaseUrl: "https://production.apna.co",
		ppjFetchTime: 3,
		salesForceLeadFormId: "00D5j0000047tjQ",
		salesForceLeadFormUrl:
			"https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
	},
};
