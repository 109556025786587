/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable import/no-cycle */

import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import Bowser from "bowser";
import {
	TRUE_ECC_CALL_DETAIL,
	STALE_JOBS,
	TRUE_ECC_NUM_MASK_EDU,
	TECC_PREF_POPUP_SHOWN_FOR,
} from "../true-ecc/constants";

import {
	getReleaseVersion,
	MONTHS_SHORT_HAND,
	APPLICATION_STATUSES,
	AUTO_OPENED_FEEDBACK,
	CANDIDATES_PAGINATION_SIZE,
	VERIFICATION_STEPS,
	APPLICATION_LOCATION_VALUES_FOR_NON_WFH,
	APPLICATION_LOCATION_VALUES_FOR_WFH,
	PAY_TYPE,
	URL_PARAMS,
	SUPPORTED_BROWSERS,
	TAG_TYPES,
	DEVICE_IDENTIFIER,
	DEVICE_INFO,
	DEVICE_REQUEST_ID,
	SALES_LINK_EXPIRY_DAYS,
	ENABLE_NEW_LAYOUT,
	ELIGIBLE_FOR_NEW_LAYOUT,
	NEW_USER_MODAL_STATE,
	PPJ_WHITELISTED_USER,
	BULK_WHITELISTED_USER,
	SHOW_PREVIEW,
	DATABASE_ELIGIBILITY_ROUTE,
	USER_ACCOUNT_ID,
	LOGIN_VIA_OTP,
	WORKSPACE_TYPE_FOR_DATABASE,
	SET_JOB_UPDATE_STATUS,
} from "./constants";
import config from "../config";
import Mixpanel from "./Mixpanel";
import { openRespondChat } from "./respond";
import { logoutV2 } from "./apiClients";
import {
	BE_VALUES_FOR_LOCATION_TYPES,
	EXPERIENCE_LEVELS,
	JOB_LOCATION_TYPE,
	SAVE_JOB_POST,
	USER_TYPE,
} from "../views/PostJobFormNew/constants";
import * as Styles from "../components/Common/JobForm/JobFormStyles";
import InfoDescriptionIcon from "../assets/JobPostFormIcons/InfoDescriptionIcon";
import {
	SHARE_ACTIVE_JOB_POPPER,
	TAB_AWARENESS_FLOW_SHOWN,
} from "../true-ecc/constants/types";
import {
	ENTERPRISE_PROFILE_DATA,
	ENTERPRISE_USAGE_DATA,
	ENTERPRISE_USER_DATA,
	USER_ROLE,
} from "../enterpriseGrading/constants/constants";
import {
	BUY_COINS_PACKAGES,
	BUY_COINS_PRICING_PLANS,
} from "../views/JobsPage/selectPlan/constants";

export const checkForValidEmail = email => {
	const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegex.test(email);
};

export const checkForValidPhoneNumber = num => {
	const pattern = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
	return pattern.test(num);
};

export const checkForValidGstin = gstin => {
	const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
	return gstinRegex.test(gstin);
};
dayjs.extend(relativeTime);

export const checkIsEnterprise = () => window.location.pathname.includes("enterprise");

/* eslint-disable consistent-return */
const CommonUtils = {
	setItemInLocalStorage: (key, value) => {
		if (key) {
			localStorage.setItem(key, value);
		}
	},
	getItemFromLocalStorage: key => {
		let data = null;
		try {
			if (key) {
				data = localStorage.getItem(key);
			}
			return data;
		} catch (error) {
			return data;
		}
	},
	clearLocalStorage: () => {
		const localStorageItems = [
			"__user__",
			"__token__",
			"__rtk__",
			"__tokenRefreshAt__",
			"__version__",
			"__filters__",
			"__DASHBOARD_TYPE__",
			"ravenToken",
			"first_chat_loaded",
			AUTO_OPENED_FEEDBACK,
			TRUE_ECC_CALL_DETAIL,
			STALE_JOBS,
			TRUE_ECC_NUM_MASK_EDU,
			TECC_PREF_POPUP_SHOWN_FOR,
			TAB_AWARENESS_FLOW_SHOWN,
			DEVICE_IDENTIFIER,
			DEVICE_INFO,
			DEVICE_REQUEST_ID,
			SHARE_ACTIVE_JOB_POPPER,
			ENTERPRISE_PROFILE_DATA,
			ENTERPRISE_USER_DATA,
			"__useremail__",
			"__enterpriseworkspace__",
			"__workspaceId__",
			ENTERPRISE_USAGE_DATA,
			ENABLE_NEW_LAYOUT,
			ELIGIBLE_FOR_NEW_LAYOUT,
			NEW_USER_MODAL_STATE,
			PPJ_WHITELISTED_USER,
			BULK_WHITELISTED_USER,
			SHOW_PREVIEW,
			DATABASE_ELIGIBILITY_ROUTE,
			USER_ACCOUNT_ID,
			BUY_COINS_PACKAGES,
			BUY_COINS_PRICING_PLANS,
			ENTERPRISE_USAGE_DATA,
			LOGIN_VIA_OTP,
			WORKSPACE_TYPE_FOR_DATABASE,
		];
		localStorageItems.forEach(item => {
			localStorage.removeItem(item);
		});
	},
	clearLocalStorageForSwitchingWorkspace: () => {
		const localStorageItems = [
			AUTO_OPENED_FEEDBACK,
			TRUE_ECC_CALL_DETAIL,
			STALE_JOBS,
			TRUE_ECC_NUM_MASK_EDU,
			TECC_PREF_POPUP_SHOWN_FOR,
			TAB_AWARENESS_FLOW_SHOWN,
			DEVICE_IDENTIFIER,
			DEVICE_INFO,
			DEVICE_REQUEST_ID,
			SHARE_ACTIVE_JOB_POPPER,
			ENTERPRISE_PROFILE_DATA,
			ENTERPRISE_USER_DATA,
			ENTERPRISE_USAGE_DATA,
			ENABLE_NEW_LAYOUT,
			ELIGIBLE_FOR_NEW_LAYOUT,
			NEW_USER_MODAL_STATE,
			SHOW_PREVIEW,
			DATABASE_ELIGIBILITY_ROUTE,
			USER_ACCOUNT_ID,
			BUY_COINS_PACKAGES,
			BUY_COINS_PRICING_PLANS,
		];
		localStorageItems.forEach(item => {
			localStorage.removeItem(item);
		});
	},
	isLatestVersion: () => {
		const version = CommonUtils.getItemFromLocalStorage("__version__");
		return version && getReleaseVersion() === version;
	},
	updateReleaseVersion: () => {
		CommonUtils.setItemInLocalStorage("__version__", getReleaseVersion());
	},
	clearStorageAndRedirectToLogin: () => {
		CommonUtils.clearLocalStorage();
		if (!window.location.pathname.includes("/login")) {
			if (checkIsEnterprise()) {
				window.location.replace("/enterprise/login");
			} else {
				window.location.replace("/login");
			}
		}
	},

	logout: () => {
		if (CommonUtils.getItemFromLocalStorage("__token__")) {
			logoutV2()
				.then(() => {})
				.finally(() => {
					CommonUtils.clearStorageAndRedirectToLogin();
				});
		} else {
			CommonUtils.clearStorageAndRedirectToLogin();
		}
	},
	formatAMPM: date => {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours %= 12;
		hours = hours || 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? `0${minutes}` : minutes;
		const strTime = `${hours}:${minutes} ${ampm}`;
		return strTime;
	},
	convertTimestampToDate: (timestamp, formatted, reverse, includeTime) => {
		const currentDate = new Date(timestamp);
		const date = currentDate.getDate();
		const month = currentDate.getMonth();
		const year = currentDate.getFullYear();

		const padding = n => {
			return n < 10 ? `0${n}` : n;
		};
		return reverse
			? `${year}-${
					formatted ? MONTHS_SHORT_HAND[month] : padding(month + 1)
			  }-${padding(date)}${
					includeTime ? ` ${CommonUtils.formatAMPM(currentDate)}` : ""
			  }`
			: `${padding(date)}-${
					formatted ? MONTHS_SHORT_HAND[month] : padding(month + 1)
			  }-${year}${includeTime ? ` ${CommonUtils.formatAMPM(currentDate)}` : ""}`;
	},
	formatDateToString: (date, dateFormat = "DD-MMM-YYYY") => {
		const currentDate = new Date(date);
		return dayjs(currentDate).format(dateFormat);
	},
	dateDuration: (date, dateFormat = "MMM YYYY") => {
		if ((date?.ongoing && date?.start) || (!date?.end && date?.start)) {
			return `${CommonUtils.formatDateToString(date?.start, dateFormat)} - Present`;
		}
		if (date?.start && date?.end) {
			const start = CommonUtils.formatDateToString(date?.start, dateFormat);
			const end = CommonUtils.formatDateToString(date?.end, dateFormat);
			if (start === end) return "1 month";
			return `${start} - ${end}`;
		}
		if (!date?.start && date?.end) {
			const end = CommonUtils.formatDateToString(date?.end, dateFormat);
			return end;
		}

		return null;
	},
	timePeriod: (dateStart, dateEnd) => {
		const endDate = dateEnd !== null ? dateEnd : new Date();
		const months =
			endDate?.getMonth() -
			dateStart?.getMonth() +
			12 * (endDate?.getFullYear() - dateStart?.getFullYear());
		const year = Math.floor(months / 12);
		const month = months - year * 12;
		if ((months === 0 || months === 1) && year === 0) return "1 mo";
		if (months < 12 && year === 0) {
			return `${Math.abs(months)} mos`;
		}
		return month === 0
			? `${Math.abs(year)} yr`
			: `${Math.abs(year)} yr ${Math.abs(month)} mos`;
	},
	addDays: (days, oldDate) => {
		const date = oldDate ? new Date(oldDate) : new Date();
		date.setDate(date.getDate() + days);
		return date;
	},
	subtractDays: (days, oldDate) => {
		const date = oldDate ? new Date(oldDate) : new Date();
		date.setDate(date.getDate() - days);
		return date;
	},
	getKeyByValue: (object, value) => {
		return Object.keys(object).find(key => object[key] === value);
	},
	getAllKeysByValue: (object, value) => {
		const keys = [];
		Object.keys(object).forEach(item => {
			if (object[item] === value) {
				keys.push(item);
			}
		});
		return keys;
	},
	scrollToTop: id => {
		const element = document.getElementById(id);
		if (element) element.scrollTop = 0;
	},
	differenceInDays: (date, withZeroValue, csvDownload = false) => {
		const currentDateInISO = new Date().toISOString().split("T")[0];
		const currentDate = withZeroValue ? new Date(currentDateInISO) : new Date();
		const oldDate = new Date(date);
		const diffTime = withZeroValue
			? Math.abs(oldDate.getTime() - currentDate.getTime())
			: Math.abs(oldDate - currentDate);

		const diffDays = csvDownload
			? Math.floor(diffTime / (1000 * 60 * 60 * 24))
			: Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		return diffDays;
	},
	getUrlParameter: (search, name) => {
		const newName = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
		const regex = new RegExp(`[\\?&]${newName}=([^&#]*)`);
		const results = regex.exec(search);
		return results === null ? "" : decodeURIComponent(results[1]);
	},
	generateCSVDataForAppliedAndMatchedCandidates: (
		appliedOrMatchedCandidatesData,
		isAppliedCandidatesData
	) => {
		const appliedData = Object.assign([], appliedOrMatchedCandidatesData);
		const newAppliedData = [];
		appliedData.map(data => {
			if (isAppliedCandidatesData) {
				newAppliedData.push({
					Name: data.full_name,
					"Phone Number": data.username,
					Area: data.area,
					Company: data.company,
					"Current Job": data.job_type,
					Education: data.edu,
					Experience: data.experience_in_years || "Fresher",
					// Gender: "",
					Status: APPLICATION_STATUSES[data.status],
					Feedback: data.feedback,
					"Applied On": new Date(data.last_updated).toLocaleString("en-IN", {
						hour12: true,
					}),
				});
			} else {
				newAppliedData.push({
					Name: data.full_name,
					"Phone Number": data.username,
					Area: data.area,
					Company: data.company,
					"Current Job": data.job_type,
					Education: data.edu,
					Experience: data.experience_in_years || "Fresher",
					// Gender: "",
					// Status: data.status,
					// Feedback: data.feedback,
					"Last Active On": new Date(data.last_active_on).toLocaleString(
						"en-IN",
						{
							hour12: true,
						}
					),
				});
			}
		});

		return newAppliedData;
	},

	generateBulkCSVDataForAppliedCandidates: appliedCandidatesData => {
		const appliedData = Object.assign([], appliedCandidatesData);
		const newAppliedData = [];
		appliedData.map(data => {
			const wfhStatus = data?.job_wfh_status;
			const isWillingToRelocate =
				wfhStatus === false &&
				data.job_city &&
				data.city &&
				data.job_city !== data.city;
			newAppliedData.push({
				"Job Applied For": data.job_title,
				"Job Id": data.job_id,
				"Job city": data.job_city,
				"Job Area": data.job_area,
				"Recruiter Communication Preference":
					data.recruiter_communication_preference,
				"Candidate Name": data.full_name,
				"Priority Canidate": data?.is_priority_candidate ? "Priority" : "-",
				"Phone Number": data.phone_number,
				Gender: data.gender,
				"Applied On": new Date(data?.lead_created_at).toLocaleString("en-IN", {
					hour12: true,
				}),
				"Candidate city": data.city,
				"Candidate Area": data.area,
				"Willing to relocate": isWillingToRelocate
					? "Willing to relocate"
					: "No relocation needed",
				Education: data.edu,
				Degree: data.degree,
				Experience: data.experience_in_years || "Fresher",

				"Current Job role": data.company_title,
				"Recruiter Notes": data.employer_feedback,
				"Connection Status": `${
					data.connection_status === "connected" ? "Reviewed" : "Review Pending"
				}`,
				"Hiring Status": data.hiring_state,
				"Resume, Skills etc": `${window.location.origin}/jobs/${data.job_id}/${data.application_id}/`,
				Source: "Apna",
			});
		});

		return newAppliedData;
	},

	/**
	 * Working days from Monday to Saturday and Working hours are from 8AM to 9PM
	 * @returns {number} Returns "true" if current date and time falls under the specified Working days and Hours.
	 */
	isWorkingDaysAndHours: () => {
		const date = new Date(); // returns current time
		const hours = date.getHours(); // returns the hour (from 0 to 23)
		const day = date.getDay(); // returns the day of the week (from 0 to 6). Sunday is 0, Monday is 1, and so on.
		const openingDays = [1, 2, 3, 4, 5, 6]; // Containing days from Monday to Saturday
		return openingDays.includes(day) && hours >= 8 && hours <= 21;
	},
	RemoveItemFromLocalStorage: key => {
		if (key) {
			const data = localStorage.removeItem(key);
			return data;
		}
	},
	isCategoryHasTags: categoryId => {
		const JOB_TYPES_WITH_TAGS = [1, 11, 37, 30, 2, 9];
		return JOB_TYPES_WITH_TAGS.find(val => val === categoryId);
	},
	getMaskedMobNumb: num => {
		return ` ${num.substring(0, 3)}****${num.substring(num.length - 4, num.length)} `;
	},
	checkForValidEmail,
	checkForValidPhoneNumber,
	checkForValidText: text => {
		const pattern = /^[a-zA-Z\s]*$/;
		return pattern.test(text);
	},
	checkForValidJobDescription: text => {
		const pattern = /[a-zA-Z]/;
		return pattern.test(text);
	},
};

export function getUserDetails() {
	return JSON.parse(CommonUtils.getItemFromLocalStorage("__user__")) || {};
}
export const isDBVerificationRoute = () =>
	window?.location?.pathname?.includes("/verification/db_");

export const constructDBVerificationId = () => {
	const userDetails = getUserDetails();
	const userId = userDetails?.id;
	const orgId = userDetails?.organization?.id;
	return `db_${userId}_${orgId}`;
};

export const checkIsNewLayout = () =>
	Boolean(JSON.parse(CommonUtils.getItemFromLocalStorage(ENABLE_NEW_LAYOUT)));

export const checkEligiblityForNewLayout = () =>
	Boolean(JSON.parse(CommonUtils.getItemFromLocalStorage(ELIGIBLE_FOR_NEW_LAYOUT)));

export const parseQueryParams = params => {
	if (params?.includes("?")) {
		// remove ?
		const plainParams = params?.slice(1);
		const paramsArray = plainParams?.split("&");
		const result = paramsArray?.map(obj => {
			const keyVal = obj?.split("=");
			return { [keyVal?.[0]]: keyVal?.[1] };
		});
		return result;
	}
};

export const checkContainsCharacter = str => {
	const regex = /[a-zA-Z]/;
	return regex.test(str);
};

export const generateLocaleDate = stringifiedDate => {
	return dayjs(stringifiedDate).format("DD MMM YYYY");
};

export const generateLocaleTime = stringifiedDate => {
	return dayjs(stringifiedDate).format("h:mm:ss A");
};

export const getMissingFieldsForMixPanel = data => {
	const dataObj = { ...data };
	const arr = [];
	Object.entries(dataObj).forEach(([key, value]) => {
		if (!value) {
			arr.push(key);
		}
	});
	return arr;
};

export function decimalToRoman(number) {
	let roman = "";
	const romanNumList = {
		M: 1000,
		CM: 900,
		D: 500,
		CD: 400,
		C: 100,
		XC: 90,
		L: 50,
		XV: 40,
		X: 10,
		IX: 9,
		V: 5,
		IV: 4,
		I: 1,
	};
	let a;
	if (number < 1 || number > 3999) throw new Error("Enter a number between 1 and 3999");
	let tempNum = number;
	Object.keys(romanNumList).forEach(key => {
		a = Math.floor(tempNum / romanNumList[key]);
		if (a >= 0) {
			for (let i = 0; i < a; i += 1) {
				roman += key;
			}
		}
		tempNum %= romanNumList[key];
	});

	return roman;
}

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch(error => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error);
						return;
					}

					// Passing on "reject" is the important part
					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

export function debounce(func, timeout) {
	let timer;
	return (...args) => {
		const next = () => func(...args);
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(next, timeout > 0 ? timeout : 300);
	};
}

export const newIntercomMessage = msg => {
	openRespondChat();
	if (msg) {
		// window.Intercom("showNewMessage", msg);
	} else {
		// sessionStorage.removeItem("intercom.draft");
		// window.Intercom("showNewMessage");
	}
};

/**
 *
 * @param {Array<string>} acceptedDomains
 * @param {string} enteredEmail
 * @returns {boolean}
 */
export const isMatchingEmail = (acceptedDomains = [], enteredEmail = "") =>
	acceptedDomains.some(domain => enteredEmail.endsWith(`@${domain}`));

export const findMatchedData = (candidateData = [], jobRequirementData = []) => {
	let matchedPercentage = 0;
	const matchData = [];
	if (!jobRequirementData?.length) {
		return 1;
	}
	candidateData.forEach(candidateValue => {
		const dataFound = jobRequirementData.find(
			jobValue => jobValue?.id === candidateValue?.id
		);
		if (dataFound) {
			matchData.push(dataFound);
		}
	});
	matchedPercentage = matchData?.length / jobRequirementData?.length;
	return matchedPercentage;
};

export const getCandidateTrackingData = ({
	currentIndex = 0,
	currentPageNumber = 1,
	candidateData = {},
	noOfApplicants = 0,
}) => {
	const {
		assets = [],
		required_assets = [],
		skills = [],
		required_skills = [],
		last_applied_at,
		app_version = "",
		score = "",
		id = "",
		experience_in_years = "",
		exp = "",
		edu = "",
		last_active_on = "",
		username = "",
		full_name = "",
		show_number_clicked = "",
		company = "",
		current_salary = "",
		english = "",
		gender = "",
	} = candidateData;
	const candidatePosition =
		(currentPageNumber - 1) * CANDIDATES_PAGINATION_SIZE + (currentIndex + 1);
	const matchedSkills = findMatchedData(skills, required_skills);
	const matchedAssets = findMatchedData(assets, required_assets);
	let diffDays = 0;
	try {
		const todaysDate = new Date(dayjs().format("MM/DD/YYYY"));
		const lastAplliedDate = new Date(
			dayjs(last_applied_at || todaysDate).format("MM/DD/YYYY")
		);
		const diffTime = Math.abs(todaysDate - lastAplliedDate);
		diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	} catch (error) {
		console.log("error:", error);
	}
	return {
		"App Version": app_version,
		Position: candidatePosition,
		Score: score,
		"Number of skills required": required_skills?.length,
		"Number of assets required": required_assets?.length,
		"Job Skills required": required_skills,
		"Job assets required": required_assets,
		"Candidate Skills": skills,
		"Candidate Assets": assets,
		"Skills Matched Ratio": matchedSkills,
		"Assets Matched Ratio": matchedAssets,
		"Last Job Applied (days)": diffDays,
		"Candidate Experience In Years": experience_in_years,
		"Candidate Experience": exp,
		"Candidate Education": edu,
		"Candidate Last Active On": last_active_on,
		Number: username,
		"Candidate Name": full_name,
		"Candidate ID": id,
		"Show Number Clicked": show_number_clicked,
		"Candidate Company": company,
		"Candidate Current Salary": current_salary,
		"Applied Candidate Count": noOfApplicants,
		"Candidate English Requirements": english,
		"Candidate Gender": gender,
	};
};
// is redundant
export const getCandidateTrackingDataV2 = (jobData, candidateData) => {
	return {
		"App Version": "",
		Score: "",
		"Number of skills required": "",
		"Number of assets required": "",
		"Job Skills required": "",
		"Job assets required": "",
		"Candidate Skills": candidateData?.skills,
		"Candidate Assets": candidateData?.assets,
		"Skills Matched Ratio": "",
		"Assets Matched Ratio": "",
		"Last Job Applied (days)": "",
		"Candidate Experience In Years": candidateData?.totalExpInYears,
		"Candidate Experience": candidateData?.totalExpInYears,
		"Candidate Education": candidateData?.education?.level,
		"Candidate Last Active On": candidateData?.updatedOn,
		Number: "",
		"Candidate Name": candidateData?.fullName,
		"Candidate ID": candidateData,
		"Show Number Clicked": "",
		"Candidate Company":
			candidateData?.currentExperience?.organisationTitle ||
			candidateData?.previousExperience?.organisationTitle,
		"Candidate Current Salary": candidateData?.currentSalary,
		"Candidate English Requirements": "",
		"Candidate Gender": candidateData?.gender,
	};
};

export function getCurrentDateString(format = "YYYY-MM-DD") {
	const currentDate = new Date();
	return dayjs(currentDate).format(format);
}

export const getDocumentType = (documentPath = "") => {
	const splitTypes = documentPath.split(".");
	if (splitTypes.length === 1 || (splitTypes[0] === "" && splitTypes.length === 2)) {
		return "";
	}
	return splitTypes.pop();
};

/**
 *
 * @param {String[]} inp
 * @returns string
 */
export const makeString = inp => {
	if (!Array.isArray(inp) && !inp.length) return "";
	if (inp.length === 1) return inp[0];
	const last = inp.pop();
	return `${inp.join(", ")} and ${last}`;
};

/**
 * Use this function to download comma separated values as a CSV file.
 *
 * @param {string} filename
 * @param {string} rows
 */
export const downloadCsv = (filename, csvFile) => {
	const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, filename);
	} else {
		const link = document.createElement("a");
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.display = "none";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

/**
 * This function will convert and download a 2D array as a CSV file.
 * Warning: Intended to be used on frontend only.
 * Copied from stackoverflow and made a few changes according to our requirements.
 * Ref: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 *
 * @param {string} filename
 * @param {string[][]} rows
 */
export const exportToCsv = (filename, rows) => {
	const processRow = row => {
		let finalVal = "";
		for (let j = 0; j < row.length; j += 1) {
			let innerValue = row[j] === null ? "" : row[j].toString();
			if (row[j] instanceof Date) {
				innerValue = row[j].toLocaleString();
			}
			let result = innerValue.replace(/"/g, '""');
			if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
			if (j > 0) finalVal += ",";
			finalVal += result;
		}
		return `${finalVal}\n`;
	};

	let csvFile = "";
	for (let i = 0; i < rows.length; i += 1) {
		csvFile += processRow(rows[i]);
	}
	downloadCsv(filename, csvFile);
};

export const getCapitalizedWords = (stringToCapitalize = "") => {
	if (typeof stringToCapitalize === "string") {
		const wordsArray = stringToCapitalize?.split(" ");
		const refinedWords = [];
		wordsArray.forEach(word => {
			let trimmedWord = word;
			if (word?.trim) {
				trimmedWord = word.trim();
			}
			if (trimmedWord.length) {
				refinedWords.push(
					trimmedWord[0]?.toUpperCase() + trimmedWord?.substring(1)
				);
			}
		});
		return refinedWords.join(" ");
	}
	return "";
};

export const getPayJobTrackingData = ({ service = {}, bulkUserInput = {} }) => {
	if (Object.keys(bulkUserInput)?.length > 0) {
		return {
			"Service Id": "NA",
			"SKU Title": "Bulk",
			"Valid for Days": bulkUserInput?.valid_for_days || "NA",
			"SKU Description": "NA",
			Price: "NA",
			"Coins Required": bulkUserInput?.cost || "NA",
			"Target Leads": bulkUserInput?.target_leads || "NA",
		};
	}
	return {
		"Service Id": service?.variant_id || "NA",
		"SKU Title": service?.title,
		"Valid for Days": service?.valid_for_days || "NA",
		"SKU Description": service?.description || "NA",
		Price: service?.price || "NA",
		"Coins Required": service?.price?.selling_price || "NA",
		"Target Leads": service?.target_leads || "NA",
	};
};

export const getRazorPayOptions = ({ paymentDetail, handlePaymentSuccess, userData }) => {
	const options = {
		key: config.razorPayKey,
		amount: paymentDetail?.grandTotal,
		currency: "INR",
		name: "Apna Time",
		description: "Apna Time Transaction",
		order_id: paymentDetail?.razorPayOrderId,
		handler(response) {
			handlePaymentSuccess(response);
		},
		prefill: {
			name: userData?.full_name,
			email: userData?.email,
			contact: userData?.phone_number,
		},
		theme: {
			color: "#3399cc",
		},
		retry: {
			enabled: false,
		},
	};
	return options;
};

export const convertFromMiltaryTimeToAMPM = time => {
	return `${time / 100 > 12 ? time / 100 - 12 : time / 100}:${
		time % 100 > 9 ? time % 100 : `${time % 100}0`
	} ${time / 100 > 12 ? "PM" : "AM"}`;
};

export const checkIfOnlyNumerical = str => {
	const regExp = new RegExp("^\\d+$");
	const isValid = regExp.test(str);
	return isValid;
};

/**
 * To be used with axios in onUploadProgess.
 * @param {any} progressEvent
 * @returns {number}
 */
export const getUploadProgress = progressEvent => {
	const totalLength = progressEvent.lengthComputable
		? progressEvent.total
		: progressEvent.target.getResponseHeader("content-length") ||
		  progressEvent.target.getResponseHeader("x-decompressed-content-length");
	if (totalLength !== null) {
		return Math.round((progressEvent.loaded * 100) / totalLength);
	}
	return 0;
};

export const extractCompanyVerificationData = tempData => {
	return (
		tempData?.verification_steps?.find(el =>
			el.step?.includes(VERIFICATION_STEPS.COMPANY_VERIFICATION)
		) || {}
	);
};

export const sendMixpanelCompanyVerificationEventsEvents = ({
	eventName,
	tempData = {},
	jobData = {},
	isInternal = false,
	verificationDetails = {},
}) => {
	const isDBVerification = isDBVerificationRoute();
	const userDetails = getUserDetails();
	const temp = {
		"Job ID": isDBVerification ? constructDBVerificationId() : jobData?.id,
		"User ID": userDetails?.id,
		"Creator Org ID": isDBVerification
			? userDetails?.organization?.id
			: jobData?.creator_organization?.id,
		"Org ID": isDBVerification
			? userDetails?.organization?.id
			: jobData?.organization?.id,
		"Org Type":
			jobData?.organization?.domains?.length > 0
				? "Whitelisted Domain"
				: "Non Domain Whitelisted",
		"Hiring For": isDBVerification
			? "my company"
			: jobData?.organization?.id === jobData?.creator_organization?.id
			? "my company"
			: "my clients",
		"Creator Org Type": jobData?.creator_organization?.domains?.length
			? "Domain Whitelisted"
			: "Non Domain Whitelisted",
		"Page Type": isInternal ? "UR" : "EMPLOYER",
		"Is Reverification": Boolean(verificationDetails?.is_reverification),
		"Verification flow": isDBVerification ? "DB" : "JOB",
	};
	Mixpanel.track(eventName, { ...temp, ...tempData });
};

export const getMappedVerificationData = tempVerificationStatusData => {
	let structuredStepsData = {};
	Object.values(VERIFICATION_STEPS).forEach(val => {
		const stepData = tempVerificationStatusData?.verification_steps?.find(
			el => el?.step === val
		);
		if (stepData) structuredStepsData = { ...structuredStepsData, [val]: stepData };
	});
	return structuredStepsData;
};
export const numberFormater = (num, format = "en-In") => {
	const formater = new Intl.NumberFormat(format);
	return formater.format(parseInt(num, 10));
};

export const getPreviousExpList = candidateData => {
	const tempArray = candidateData?.previous_experience || [];

	return tempArray?.join(", ");
};
export const calculatePlansDiscountPercentage = item => {
	let discountPercentage = 0;
	try {
		const discountAmount = item?.price?.mrp - item?.price?.selling_price;
		discountPercentage = Math.round((discountAmount * 100) / item?.price?.mrp);
		return discountPercentage;
	} catch (err) {
		console.log(err);
	}
	return discountPercentage;
};
export const checkForPromotionalURL = queryParamsList => {
	const promotionalCouponCode = queryParamsList.get("cc");
	const promotionalCheckoutAmount = queryParamsList.get("cv");
	const promotionalURLTeamName = queryParamsList.get("tn");

	const isPromotionalURL =
		promotionalCouponCode && promotionalCheckoutAmount && promotionalURLTeamName;
	return isPromotionalURL;
};

export const isNegative = number => {
	return number.match(/^-\d+$/);
};

export const getAddressString = ({
	completeAddress = "",
	areaName = "",
	cityName = "",
}) => {
	return `${completeAddress ? `${completeAddress},` : ""} ${
		areaName ? `${areaName},` : ""
	} ${cityName}`;
};

export const checkIfSame = (string1 = "", string2 = "") => {
	return string1 === string2;
};

export const getEmployerUserProperties = () => {
	const userData = getUserDetails();
	const isInternal = userData?.is_internal_user;
	return {
		"User ID": userData?.id,
		"Mobile Number": userData?.phone_number,
		Company: userData?.organization?.name,
		"Recruiter ID": userData?.id,
		"Recruiter Name": userData?.full_name,
		"Recruiter Email": userData?.email,
		is_consultant: userData?.organization?.is_consultant,
		// eslint-disable-next-line no-nested-ternary
		"User type": isInternal
			? USER_TYPE.INTERNAL
			: userData?.employer_type === "new_employer"
			? USER_TYPE.NEW
			: USER_TYPE.EXISTING,
	};
};

export const getApplicationLocationOptions = (
	jobLocationCity = "",
	isEntireIndia = false
) => {
	const tempOptions = [
		{
			label: "Within 10 KM",
			value: APPLICATION_LOCATION_VALUES_FOR_NON_WFH.WITHIN_10KM,
		},
		{
			label: "Within 25 KM",
			value: APPLICATION_LOCATION_VALUES_FOR_NON_WFH.WITHIN_25KM,
		},
		{
			label: `Entire ${jobLocationCity || "city"}`,
			value: APPLICATION_LOCATION_VALUES_FOR_NON_WFH.ENTIRE_CITY,
		},
	];
	const anyWhereInIndiaOption = {
		label: "Anywhere in India",
		value: APPLICATION_LOCATION_VALUES_FOR_WFH.ENTIRE_INDIA,
	};
	if (isEntireIndia) {
		tempOptions.push(anyWhereInIndiaOption);
	}

	return tempOptions;
};

// TODO: Can be accessed directly
export const salaryTypeOptions = () => {
	const tempOptions = [
		{
			label: "Fixed only",
			value: PAY_TYPE.FIXED_ONLY,
		},
		{
			label: "Fixed + Incentive",
			value: PAY_TYPE.FIXED_AND_INCENTIVE,
		},
		{
			label: "Incentive only",
			value: PAY_TYPE.INCENTIVE_ONLY,
		},
	];
	return tempOptions;
};

export const getAreaDescription = data => {
	if (data?.address_representation) return data?.address_representation;
	switch (data?.job_location_type) {
		case BE_VALUES_FOR_LOCATION_TYPES.WORK_FROM_HOME:
			return data?.applicant_location ===
				APPLICATION_LOCATION_VALUES_FOR_WFH.ENTIRE_CITY
				? `All Areas in ${data?.address?.area?.city?.name}`
				: "Anywhere in India";
		case BE_VALUES_FOR_LOCATION_TYPES.FIELD_JOB:
			return `All Areas in ${data?.address?.area?.city?.name}`;
		default:
			return data?.address?.area?.name || "";
	}
};

export const getAreaDescriptionFromFormData = formOneData => {
	switch (formOneData?.jobLocationType) {
		case JOB_LOCATION_TYPE.WORK_FROM_HOME:
			return formOneData?.wfhJobApplicationLocation ===
				APPLICATION_LOCATION_VALUES_FOR_WFH.ENTIRE_CITY
				? `All Areas in ${formOneData?.jobLocationCity}`
				: "Anywhere in India";
		case JOB_LOCATION_TYPE.FIELD_JOB:
			return `All Areas in ${formOneData?.jobLocationCity}`;
		default:
			return formOneData?.jobLocationArea?.name
				? `${formOneData?.jobLocationArea?.name}, ${formOneData?.jobLocationCity}`
				: "";
	}
};

export const isHighlightRequired = (key, data = []) => {
	if (data?.includes(key)) return true;
	return false;
};

export const getExperienceType = (maxExp = 0, minExp = 0, isNewView = true) => {
	// Fresher - historically min 0 max 0, now min 0 max 1
	// Any - 0 to 31
	// Experienced - Min from input to 31
	let expType = "";
	if (!isNewView) {
		if (minExp === 0 && [0, 1].includes(maxExp)) {
			expType = EXPERIENCE_LEVELS.FRESHER;
		} else if (maxExp > 0) {
			expType = EXPERIENCE_LEVELS.EXPERIENCED;
		}
		return expType;
	}

	if (maxExp === 1) {
		expType = EXPERIENCE_LEVELS.FRESHER;
	} else if (maxExp > 1 && minExp === 0) {
		expType = EXPERIENCE_LEVELS.ANY;
	} else if (maxExp > 1) {
		expType = EXPERIENCE_LEVELS.EXPERIENCED;
	}
	return expType;
};

export const getCityDescription = data => {
	switch (data?.job_location_type) {
		case BE_VALUES_FOR_LOCATION_TYPES.WORK_FROM_HOME:
		case BE_VALUES_FOR_LOCATION_TYPES.FIELD_JOB:
			return data?.address_representation;
		default:
			return data?.address?.area?.city?.name || "";
	}
};

export const getSalaryDetails = (
	minSalary,
	maxSalary,
	incentives,
	showRupeeSymbol = false
) => {
	return `${showRupeeSymbol ? "₹" : ""}${minSalary?.toLocaleString("en-IN")} - ${
		showRupeeSymbol ? "₹" : ""
	}${maxSalary?.toLocaleString("en-IN")} per month ( Fixed
	${incentives !== null && incentives ? "+ incentives )" : ")"}`;
};

export const removeFreeJobsLandingUTM = () => {
	// utm_custom_2 to track the landing page
	// from banner or whatsapp
	const utmParams = localStorage.getItem(URL_PARAMS);
	if (utmParams) {
		const urlUTMParams = JSON.parse(localStorage.getItem(URL_PARAMS));
		if (urlUTMParams?.utm_custom_2) {
			delete urlUTMParams.utm_custom_2;

			localStorage.setItem(
				URL_PARAMS,
				JSON.stringify({
					...urlUTMParams,
				})
			);
		}
	}
};

export const detectIfBrowserSupported = () => {
	const browser = Bowser.getParser(window.navigator.userAgent);
	const isValidBrowser = browser.satisfies(SUPPORTED_BROWSERS);
	return isValidBrowser;
};

export const checkForHoliday = (startDate, endDate) => {
	const todayDate = new Date();

	if (todayDate > startDate && todayDate < endDate) {
		return true;
	}

	return false;
};

export const checkForHolidayBanner = () => {
	// (YYYY, MM, DD, Hr, Min, Sec)
	const startDate = new Date(2022, 9, 23, 21, 0, 0); // Oct 23rd 9PM
	const endDate = new Date(2022, 9, 27, 8, 0, 0); // Oct 27th 8AM

	return checkForHoliday(startDate, endDate);
};

export const checkForPreDiwali = () => {
	// (YYYY, MM, DD, Hr, Min, Sec)
	const startDate = new Date(2022, 9, 23, 21, 0, 0); // Oct 23rd 9PM
	const endDate = new Date(2022, 9, 25, 8, 0, 0); // Oct 25th 8AM

	return checkForHoliday(startDate, endDate);
};

export const deleteKeysWithEmptyValueFromObject = tempObj => {
	const keys = Object.keys(tempObj);
	const copyObj = tempObj;
	// eslint-disable-next-line no-restricted-syntax
	for (const key of keys) {
		if ([null, undefined, ""].includes(tempObj[key])) {
			delete copyObj[key];
		}
	}
	return copyObj;
};

export const getSalaryString = ({
	minSalary = 0,
	maxSalary = 0,
	salaryType = "",
	incentive = 0,
	showPerMonth = false,
	hideSymbol = false,
}) => {
	const symbol = hideSymbol ? "" : "₹ ";
	switch (salaryType) {
		case PAY_TYPE.FIXED_ONLY:
			return `${symbol}${numberFormater(minSalary)} - ${symbol}${numberFormater(
				maxSalary
			)}${showPerMonth ? " per month (Fixed only)" : ""}`;
		case PAY_TYPE.INCENTIVE_ONLY:
			return `${symbol}${numberFormater(incentive)} ${
				showPerMonth ? " per month (Incentive only)" : "*"
			}`;
		case PAY_TYPE.FIXED_AND_INCENTIVE:
			return `${symbol}${numberFormater(minSalary)} - ${symbol}${numberFormater(
				Number(incentive) + Number(maxSalary)
			)}${showPerMonth ? " per month (Fixed + incentives) " : " *"}`;
		default:
			return `${symbol}${numberFormater(minSalary)} - ${symbol}${numberFormater(
				maxSalary
			)}`;
	}
};

export const getSalaryPerkStringArray = ({
	salaryPerksOptions = [],
	selectedPerkIds = [],
}) => {
	return (
		selectedPerkIds
			?.filter?.(each => !!each)
			?.map(each => {
				const temp = salaryPerksOptions?.find(eachOpt => eachOpt?.id === each);
				return temp?.text;
			}) || []
	);
};

export const getSalaryFixedIncentiveString = (jobData = {}) => {
	let tempString = jobData?.salary_representation;

	if (jobData?.job_data?.salary_breakdown) {
		const salaryType = jobData?.job_data?.salary_breakdown?.salary_type;

		if (salaryType === PAY_TYPE.FIXED_AND_INCENTIVE) {
			tempString += ` ( Fixed:  ₹ ${jobData?.job_data?.salary_breakdown?.fixed?.min_salary?.toLocaleString(
				"en-IN"
			)} - ₹ ${jobData?.job_data?.salary_breakdown?.fixed?.max_salary?.toLocaleString(
				"en-IN"
			)}, Incentive: ₹ ${jobData?.job_data?.salary_breakdown?.incentive?.avg_incentive?.toLocaleString(
				"en-IN"
			)} ) `;
		}
		if (salaryType === PAY_TYPE.FIXED_ONLY) {
			tempString += ` ( Fixed: ₹ ${jobData?.job_data?.salary_breakdown?.fixed?.min_salary?.toLocaleString(
				"en-IN"
			)} - ₹ ${jobData?.job_data?.salary_breakdown?.fixed?.max_salary?.toLocaleString(
				"en-IN"
			)}, Incentive: 0) `;
		}
		if (salaryType === PAY_TYPE.INCENTIVE_ONLY) {
			tempString += ` ( Fixed: 0, Incentive: ₹ ${jobData?.job_data?.salary_breakdown?.incentive?.avg_incentive?.toLocaleString(
				"en-IN"
			)} ) `;
		}
	}

	return tempString;
};

export const getGenericPerks = (jobData = {}) => {
	let tempString = "NA";

	const tagList = jobData?.tag_list
		?.filter(each => each?.tag_type === TAG_TYPES.SALARY)
		?.map(each => each?.text);

	if (tagList?.length > 0) {
		tempString = tagList?.join(", ");
	}
	return tempString;
};

export const getNumberOfSalaryPerks = (jobData = {}) => {
	const tagList = jobData?.tag_list
		?.filter(each => each?.tag_type === TAG_TYPES.SALARY)
		?.map(each => each?.tag_text || each?.text);

	return tagList?.length + jobData?.job_data?.salary_breakdown?.other_perks ? 1 : 0;
};

export const checkIfNoDigitsAdded = str => {
	const regex = /[0-9]/;
	return !regex.test(str);
};

export const checkIfLessThanNDigit = (str, number) => {
	const temp = `${str}`; // to make sure its string
	return temp?.length < number;
};

export const callLogoutAndClearData = (error, apiConfig) => {
	if (!apiConfig?.url?.includes("/logout")) {
		CommonUtils.logout();
	}
	console.error("callLogoutAndClearData", error);
};

export const getAccessToken = () =>
	CommonUtils.getItemFromLocalStorage("__token__") || "";
export const getRefreshToken = () => CommonUtils.getItemFromLocalStorage("__rtk__") || "";

const checkAndGetLatestTokens = () => {
	const prevAccessToken = getAccessToken();
	const prevRefreshToken = getRefreshToken();
	const timeDiff =
		new Date() -
		new Date(CommonUtils.getItemFromLocalStorage("__tokenRefreshAt__") || null);
	if (timeDiff > 0 && timeDiff < 10000 && prevAccessToken && prevRefreshToken) {
		return {
			accessToken: prevAccessToken,
			refreshToken: prevRefreshToken,
		};
	}
	return {};
};

export const callRefreshAndSetTokens = async (
	apiConfig,
	apiRetry = 1,
	forceAll = false
) => {
	if (apiConfig?.url?.includes("/logout")) return {}; // To handle if logout API gives 401 error
	const checkForRefresh = checkAndGetLatestTokens();
	if (checkForRefresh?.accessToken) {
		return Promise.resolve(checkForRefresh);
	}
	try {
		// RT Not Exist, V1
		const prevRefreshToken = getRefreshToken();
		if (!prevRefreshToken) throw new Error("401 Logout");

		if (!window?.isRefreshingToken || forceAll) {
			return new Promise(resolve => {
				window.isRefreshingToken = true;

				// This will make sure, employer comes out of this isRefreshed Before Interval
				setTimeout(() => {
					window.isRefreshingToken = false;
					resolve({});
				}, 10000);

				// Refresh API Call
				const URL = `${config.oauthBaseUrl}/auth/v2/refresh-tokens`;
				const myHeaders = new Headers();
				myHeaders.append("Content-Type", "application/json");

				const raw = JSON.stringify({
					refresh_token: getRefreshToken(),
				});

				const requestOptions = {
					method: "POST",
					headers: myHeaders,
					body: raw,
				};
				const callCount =
					Number(localStorage.getItem("RefreshAPICallCount")) || 0;
				if (callCount > 3) {
					callLogoutAndClearData({}, apiConfig);
					window.isRefreshingToken = false;
					setTimeout(() => {
						localStorage.setItem("RefreshAPICallCount", 0);
					}, 120000);
					return Promise.resolve({});
				}
				fetch(URL, requestOptions)
					.then(resp => {
						localStorage.setItem("RefreshAPICallCount", callCount + 1);
						return resp?.json();
					})
					.then(respData => {
						const accessToken = respData?.access_token;
						const refreshToken = respData?.refresh_token;
						if (accessToken && refreshToken) {
							CommonUtils.setItemInLocalStorage("__token__", accessToken);
							CommonUtils.setItemInLocalStorage("__rtk__", refreshToken);
							CommonUtils.setItemInLocalStorage(
								"__tokenRefreshAt__",
								new Date().toISOString()
							);
							window.isRefreshingToken = false;
							resolve({
								accessToken,
								refreshToken,
							});
							return;
						}
						throw new Error(
							"callRefreshAndSetTokens Didn't get Access and Refresh token"
						);
					})
					.catch(error => {
						// If for any reason api throws error, we will retry it two time
						if (apiRetry >= 1 && apiRetry < 3) {
							return callRefreshAndSetTokens(apiConfig, apiRetry + 1, true);
						}
						const checkForRefreshTime = checkAndGetLatestTokens();
						if (checkForRefreshTime?.accessToken) {
							resolve(checkForRefreshTime);
							return;
						}

						callLogoutAndClearData(error, apiConfig);
						window.isRefreshingToken = false;
					});
			});
		}
		return new Promise(resolve => {
			const refreshInterval = setInterval(() => {
				if (!window.isRefreshingToken) {
					const checkForRefreshTime = checkAndGetLatestTokens();
					if (checkForRefreshTime?.accessToken) {
						clearInterval(refreshInterval);
						resolve(checkForRefreshTime);
					}
				}
			}, 100);
		});
	} catch (error) {
		const checkForRefreshTime = checkAndGetLatestTokens();
		if (checkForRefreshTime?.accessToken) {
			return Promise.resolve(checkForRefreshTime);
		}
		window.isRefreshingToken = false;
		callLogoutAndClearData(error, apiConfig);
	}
	return Promise.resolve({});
};

export const isObjectEmpty = obj => {
	return typeof obj === "object" && Object.keys(obj).length === 0;
};

export const showNudgeForWrongGender = (jobTitle = "", gender) => {
	const female_words = ["female", "females", "girl", "girls", "woman", "women"];
	const male_words = ["male", "males", "boy", "boys", "man", "men"];
	const sanitizedJobTitle = jobTitle
		?.replace(/[^a-zA-Z0-9 ]/g, "")
		?.trim()
		?.toLowerCase()
		?.split(" ");
	let showNudge = false;
	let matching_list = [];
	if (!gender) return false;
	if (gender === "female") matching_list = male_words;
	if (gender === "male") matching_list = female_words;
	if (gender === "both") matching_list = [...male_words, ...female_words];

	for (let i = 0; i < sanitizedJobTitle?.length; i++) {
		showNudge = matching_list.some(item => item === sanitizedJobTitle[i]);
		if (showNudge) break;
	}
	return showNudge;
};

export const isWebCamAvailable = async () => {
	return new Promise(resolve => {
		try {
			const md = navigator.mediaDevices;
			if (!md || !md.enumerateDevices) resolve(true);
			md.enumerateDevices().then(devices => {
				resolve(devices.some(device => device.kind === "videoinput"));
			});
		} catch (error) {
			console.error("Error getting mediaDevices");
			resolve(false);
		}
	});
};

export const createCookie = (key, value, minutes) => {
	let expires = "";
	if (minutes) {
		const date = new Date();
		date.setTime(date.getTime() + minutes * 60 * 1000);
		expires = `; expires=${date.toGMTString()}`;
	}
	document.cookie = `${key}=${value}${expires}; path=/; SameSite=Strict`;
};

export const getCookie = key => {
	const name = `${key}=`;
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};
export const deleteCookie = (key, value) => {
	const expires = `; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
	document.cookie = `${key}=${value}${expires}; path=/; SameSite=Strict`;
};

export const getSalesLinkExpiryDays = orderCreatedDate => {
	const todayDate = new Date();
	const daysLeftInExpiry = Math.ceil(
		SALES_LINK_EXPIRY_DAYS -
			Math.abs(todayDate - orderCreatedDate) / (1000 * 60 * 60 * 24)
	);
	return daysLeftInExpiry;
};
export const missingFieldsForMixpanel = data => {
	const fields = [];
	const entries = Object.entries(data);
	/* eslint-disable no-unused-expressions */
	entries?.map(([key, val]) => (val ? fields.push(key) : ""));
	return fields;
};

export const redirectFromVerificationToJobs = () => {
	const isDBVerification = isDBVerificationRoute();
	if (isDBVerification) {
		window.location.href = "/search";
	} else {
		window.location.href = window.location.href?.replace("verification", "jobs");
	}
};

export const isNewVerificationFlowEnable = (allowedLastDigits = []) => {
	const { id = "" } = getUserDetails();

	// Dummy
	return Boolean(
		allowedLastDigits.findIndex(e => Number(e) === Number(id) % 10) !== -1
	);
};

export const showErrorMessage = (errorType, mt = 4, mb = 0) => {
	return (
		<>
			{errorType && (
				<Styles.ErrorContainer className="scrollToError" mt={mt} mb={mb}>
					<InfoDescriptionIcon fillColor="#D93F4C" />
					<Styles.ErrorMessage>{errorType}</Styles.ErrorMessage>
				</Styles.ErrorContainer>
			)}
		</>
	);
};
export const getTimeLeftInCouponExpiry = ({ couponCreationTime, validityDays }) => {
	const todayDate = new Date();
	const creationTime = new Date(couponCreationTime);
	const daysLeft =
		validityDays - Math.abs(todayDate - creationTime) / (1000 * 60 * 60 * 24);

	if (daysLeft <= 1 && daysLeft >= 0) {
		const hoursLeft = Math.ceil(daysLeft * 24);
		if (hoursLeft > 1) {
			return `${hoursLeft} hrs`;
		}
		return `${hoursLeft} hr`;
	}
	if (daysLeft > 1) {
		return `${Math.ceil(daysLeft)} days`;
	}
	return false;
};

export const getHighlightedText = (string, inputText) => {
	let inputValue = inputText?.trim();
	inputValue = inputValue?.replace(/[|&;$%@"<>()+,]/g, "");
	const parts = string?.split(new RegExp(`(${inputValue})`, "gi"));
	return (
		<span>
			{parts?.map(part => (
				<span
					style={
						part?.toLowerCase() === inputValue?.toLowerCase()
							? { color: "#0074E8" }
							: {}
					}
				>
					{part}
				</span>
			))}
		</span>
	);
};

export const getSectorIndustryData = (
	sectorIndustryList = [],
	selectedSectors = [],
	selectedIndustry = []
) => {
	const selectedIndustryData = [];
	let selectedSectorData =
		selectedSectors?.map(eachId =>
			sectorIndustryList?.find(sec => sec?.id === eachId)
		) || [];
	// eslint-disable-next-line no-restricted-syntax
	for (const eachId of selectedIndustry) {
		// eslint-disable-next-line no-restricted-syntax
		for (const eachSector of sectorIndustryList) {
			const tempIndustry = eachSector?.industries?.find(ind => ind?.id === eachId);
			if (tempIndustry) {
				selectedIndustryData.push({
					...tempIndustry,
					sectorData: eachSector,
				});
				if (selectedSectors?.includes(eachSector?.id)) {
					selectedSectorData = selectedSectorData.filter(
						sec => sec?.id !== eachSector?.id
					);
				}
			}
		}
	}

	return { selectedSectorData, selectedIndustryData };
};
export const getInitials = fullName => {
	const namesArray = fullName?.trim()?.split(" ");

	if (namesArray?.length === 1) return `${namesArray?.[0]?.charAt(0)}`;

	return `${namesArray?.[0]?.charAt(0)}${namesArray?.[namesArray?.length - 1]?.charAt(
		0
	)}`;
};

export const performOTPValidationForEnt = val => {
	if (val.length === 4 && val.match(/^[0-9]*$/)) {
		return true;
	}
	return false;
};

export const checkStrIsValidNumerical = str => {
	const regex = new RegExp("^[1-9][0-9]+$");
	return regex.test(str);
};

export const convertNumberToIN = amount => {
	if (amount === undefined || amount === null || Number.isNaN(amount)) {
		return "-";
	}
	return Number(amount).toLocaleString("en-IN");
};

export const checkIsValidPhoneNumber = phNumber => {
	const pattern = /^\d*(?:\.\d{1,2})?$/;
	if (pattern.test(phNumber) && phNumber.length === 10) {
		return true;
	}
	return false;
};

export const getEnterPriseWorkSpaceId = () => {
	const profileData =
		JSON.parse(CommonUtils.getItemFromLocalStorage(ENTERPRISE_PROFILE_DATA)) || {};

	return profileData?.workspaceId || "";
};

export const convertToYearsAndMonths = floatNumber => {
	let years = Math.floor(floatNumber);
	let months = Math.floor((floatNumber - years) * 12);
	if (months === 12) {
		months = 0;
		years += 1;
	}
	const yearsString = years > 1 ? `${years} years` : `${years} year`;
	const monthsString = months > 1 ? `${months} months` : `${months} month`;
	if (years === 0 && months === 0) {
		return "0 months";
	}
	if (years === 0) {
		return monthsString;
	}
	if (months === 0) {
		return yearsString;
	}
	return `${yearsString} and ${monthsString}`;
};

export const getSectorIndustryMixpanelArray = industries => {
	return JSON.stringify(
		industries?.map(e => ({
			"sector id": e?.sector_id,
			"sector name": e?.sector_name,
			"industry id": e?.industry_id,
			"industry name": e?.name,
		}))
	);
};

export const SELF_CHECKOUT_LIMIT = getEnterPriseWorkSpaceId() ? 1000000 : 100000; // 1 Lakh

export const loadScript = url => {
	return new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = true;
		script.src = url;

		document.body.appendChild(script);

		script.addEventListener("load", () => {
			resolve(script);
		});

		script.addEventListener("error", () => {
			reject(new Error(`failed to load ${url}`));
		});
	});
};
export const capitalizeFirstLetter = word => {
	if (typeof word !== "string" || word.length === 0) {
		return null;
	}
	if (word.length === 1) {
		return word.charAt(0).toUpperCase();
	}
	return word.charAt(0).toUpperCase() + word.slice(1);
};

export const isValidInstagramCompanyUrl = url => {
	const regex = /^(http(s)?:\/\/)?(www\.)?instagram\.com\/([a-z0-9_.-]*[a-z][a-z0-9_.-]*)\/?$|^([a-z0-9_.-]*[a-z][a-z0-9_.-]*)\/?$/i;
	return regex.test(url);
};

export const isValidLinkedInCompanyUrl = url => {
	const regex = /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/company\/([a-z0-9-]*[a-z][a-z0-9-]*)\/?$|^([a-z0-9-]*[a-z][a-z0-9-]*)\/?$/i;
	return regex.test(url);
};

export const isValidFacebookCompanyUrl = url => {
	const regex = /^(?:https?:\/\/)?(?:www\.)?(facebook|fb)\.com\/([a-z0-9.]*[a-z][a-z0-9.]*)\/?$|^([a-z0-9.]*[a-z][a-z0-9.]*)\/?$/i;
	return regex.test(url);
};

export const validateUrl = url => {
	const regex = /^(https?:\/\/)?([a-z0-9]+(-[a-z0-9]+)*\.)+(com|co|in|org|int|net|edu|gov|aws)(\.[a-z]{2,})?\/?$/i;
	return regex.test(url);
};

export const getIndustry = industryArr => {
	if (industryArr) {
		const arr = industryArr.map(industry => industry?.title);
		const industryString = arr.join(", ");
		return industryString;
	}
	return "";
};

export const getPersistedFormData = () => {
	const { id: userId } = getUserDetails();
	return JSON.parse(localStorage.getItem(userId + SAVE_JOB_POST) || null);
};

export const setPersistedFormData = (toBeStoredData = "") => {
	const { id: userId } = getUserDetails();
	localStorage.setItem(
		userId + SAVE_JOB_POST,
		typeof toBeStoredData === "string" ? toBeStoredData : null
	);
};

export const isEmptyCheck = val => {
	if (val && typeof val === "object" && Object.keys(val)?.length === 0) return true;
	if (val && Array.isArray(val) && val.length === 0) return true;
	return !val;
};
export const getMatchIndex = (options, text) =>
	options.findIndex(
		option => option?.description?.toLowerCase() === text?.trim()?.toLowerCase()
	);

export const formatPhoneNumber = (phone = "") => {
	if (phone.length > 0) {
		return `+91 ${phone}`;
	}

	return "";
};
export const capitalizeName = (str = "") => {
	return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};
export const isIOS = () => {
	const platform =
		window?.navigator?.userAgent || window?.navigator?.platform || "unknown";
	return /iPhone|iPod|iPad/.test(platform);
};

export const isMobile = () => {
	const isMobileView = navigator.userAgent
		?.toLowerCase()
		?.match(/android|blackBerry|iPhone|iPad|iPod|mini|iemobile|wpdesktop/i);
	return Boolean(isMobileView);
};

export const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9.]+$/;

export const getOrderSelected = (options, selectedId) => {
	const optionIds = options?.map(e => e?.id);
	const orderSelected = optionIds?.indexOf(selectedId) + 1;
	return orderSelected;
};

export const truncateString = (str, maxLength, truncate = false) => {
	if (str?.length <= maxLength || !truncate) return str;

	return `${str.slice(0, maxLength)}...`;
};

export const calculateDayDiff = date => {
	const endDate = dayjs(date);
	const diff = endDate.diff(dayjs(), "day");

	dayjs.extend(localizedFormat);
	const formatDate = dayjs(endDate).format("LL");

	return [formatDate, diff];
};

export const getUserEnterpriseEmail = () =>
	CommonUtils.getItemFromLocalStorage("__useremail__");

export const getContactSalesJotFormLink = () => {
	const {
		organization: { id: orgId, name: orgName } = {},
		full_name: fullName,
		phone_number: phoneNeumber,
		email,
	} = getUserDetails();

	return `https://apna.jotform.com/231633084102848?fullName=${fullName}&phoneNumber=${phoneNeumber}&email=${email}&companyName=${orgName}&orgId=${orgId}`;
};

export const checkIsSubstring = (text, titleText) => {
	return titleText
		?.toString()
		?.toLowerCase()
		?.includes(
			text
				?.toString()
				?.toLowerCase()
				?.trim()
		);
};

export const suggestedFFProperties = data => {
	const temp = {
		"suggestion suggested (int)": data?.length || 0,
		"suggestion suggested (arry)": data || [],
		"suggestion selected (int)": data.filter(val => val?.is_selected)?.length || 0,
		"suggestion selected (arry)": data.filter(val => val?.is_selected) || [],
		"Flow type": "Job listing page - Field change suggestion",
		"Modal type": "Job listing page - Field change suggestion model",
	};
	return temp;
};

export const getCommonEventsData = (
	jobData,
	creatorOrganization,
	enterpriseProfileData,
	orgSearchData
) => {
	const userData = getUserDetails();
	const temp = {
		"Job ID": jobData?.id,
		"User ID": userData?.id,
		"Creator Org ID": jobData?.creator_organization?.id,
		"Org ID": jobData?.organization?.id,
		"Org Type":
			jobData?.organization?.domains?.length > 0
				? "Whitelisted Domain"
				: "Non Domain Whitelisted",
		"Hiring For":
			jobData?.organization?.id === jobData?.creator_organization?.id
				? "my company"
				: "my clients",
		"Creator Org Type": jobData?.creator_organization?.domains?.length
			? "Domain Whitelisted"
			: "Non Domain Whitelisted",
		"Recruiter ID": jobData?.created_by?.id,
		"Recruiter Name": jobData?.created_by?.full_name,
		"Current Status": SET_JOB_UPDATE_STATUS[jobData.status],
		user_type: jobData?.created_by?.is_consultant ? "Consultant" : "Non consultant",
		account_type: checkIsEnterprise() ? "Enterprise" : "Non enterprise",
		"User_role --- admin/recruiter":
			enterpriseProfileData?.userRole === USER_ROLE.ADMIN ? "Admin" : "Recruiter",
		"Mobile Number": userData?.phone_number,
		Company: jobData?.organization?.name,
		is_existing_org: creatorOrganization?.id ? "Yes" : "No",
		"Business Lead Type": orgSearchData[0]?.business_lead_type,
		"Organization Type": creatorOrganization?.organization_type,
		"Organization Id": creatorOrganization?.id,
		is_consultant: userData?.is_consultant ? "Yes" : "No",
		"New Menu": checkIsNewLayout() ? "Yes" : "No",
		creator_organization: creatorOrganization,
	};
	return temp;
};

export default CommonUtils;
