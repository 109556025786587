import React from "react";

const IncreasedJobSearchIcon = () => {
	return (
		<svg
			width="286"
			height="96"
			viewBox="0 0 286 96"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.543474"
				y="0.543474"
				width="274.913"
				height="26.9131"
				rx="4.89127"
				fill="white"
				stroke="#DFE1E6"
				strokeWidth="1.08695"
			/>
			<path
				d="M11.0233 17.3231C12.1208 18.4221 13.5823 19.0819 15.1325 19.1781C16.6827 19.2743 18.2146 18.8003 19.4395 17.8454L23.6544 22.0602C23.8039 22.2046 24.0041 22.2845 24.2119 22.2827C24.4197 22.2809 24.6185 22.1975 24.7654 22.0506C24.9124 21.9036 24.9957 21.7049 24.9975 21.4971C24.9993 21.2892 24.9195 21.089 24.7751 20.9396L20.5602 16.7247C21.5575 15.4448 22.029 13.8327 21.8786 12.2172C21.7281 10.6016 20.9671 9.10432 19.7506 8.0306C18.5341 6.95689 16.9539 6.38765 15.3322 6.43898C13.7105 6.49031 12.1694 7.15835 11.0233 8.30684C10.4311 8.89875 9.96128 9.60154 9.64076 10.3751C9.32024 11.1486 9.15527 11.9777 9.15527 12.815C9.15527 13.6523 9.32024 14.4813 9.64076 15.2549C9.96128 16.0284 10.4311 16.7312 11.0233 17.3231ZM12.144 9.42912C12.9275 8.64562 13.9584 8.15803 15.0611 8.04941C16.1638 7.9408 17.2701 8.21788 18.1914 8.83344C19.1127 9.44901 19.7921 10.365 20.1138 11.4253C20.4355 12.4856 20.3796 13.6246 19.9556 14.6484C19.5317 15.6721 18.7659 16.5172 17.7887 17.0396C16.8116 17.562 15.6835 17.7294 14.5967 17.5134C13.51 17.2973 12.5317 16.7112 11.8287 15.8547C11.1256 14.9983 10.7413 13.9246 10.7411 12.8165C10.7389 12.1869 10.8618 11.5631 11.1026 10.9813C11.3435 10.3996 11.6974 9.87142 12.144 9.42753V9.42912Z"
				fill="#DFE1E6"
			/>
			<rect
				x="0.416235"
				y="35.7419"
				width="275.167"
				height="58.9497"
				rx="5.01851"
				fill="white"
			/>
			<rect
				x="8.30322"
				y="43.6288"
				width="20.7577"
				height="20.7577"
				rx="2.49741"
				fill="#F4F2F6"
			/>
			<path
				d="M10.9004 47.0782C10.9004 46.606 11.2831 46.2233 11.7553 46.2233H20.4243C20.8965 46.2233 21.2792 46.606 21.2792 47.0782V61.706C21.2792 61.7533 21.241 61.7915 21.1937 61.7915H10.9859C10.9387 61.7915 10.9004 61.7533 10.9004 61.706V47.0782Z"
				fill="#8C8594"
			/>
			<path
				d="M17.0755 57.2509H15.1051C14.9327 57.2509 14.793 57.3906 14.793 57.563V61.7916H17.3877V57.563C17.3877 57.3906 17.2479 57.2509 17.0755 57.2509Z"
				fill="white"
			/>
			<rect
				x="17.3857"
				y="54.0074"
				width="1.94605"
				height="1.94605"
				rx="0.249741"
				fill="white"
			/>
			<rect
				x="12.8462"
				y="54.0074"
				width="1.94605"
				height="1.94605"
				rx="0.249741"
				fill="white"
			/>
			<rect
				x="17.3857"
				y="50.764"
				width="1.94605"
				height="1.94605"
				rx="0.249741"
				fill="white"
			/>
			<rect
				x="12.8462"
				y="50.764"
				width="1.94605"
				height="1.94605"
				rx="0.249741"
				fill="white"
			/>
			<rect
				x="17.3857"
				y="47.5208"
				width="1.94605"
				height="1.94605"
				rx="0.249741"
				fill="white"
			/>
			<rect
				x="12.8462"
				y="47.5208"
				width="1.94605"
				height="1.94605"
				rx="0.249741"
				fill="white"
			/>
			<path
				d="M21.2764 53.2633C21.2764 52.9579 21.5239 52.7103 21.8293 52.7103H25.9128C26.2182 52.7103 26.4658 52.9579 26.4658 53.2633V61.7365C26.4658 61.7671 26.441 61.7918 26.4105 61.7918H21.3317C21.3011 61.7918 21.2764 61.7671 21.2764 61.7365V53.2633Z"
				fill="#D1CED4"
			/>
			<path
				d="M22.8984 54.906C22.8984 54.7681 23.0103 54.6562 23.1482 54.6562H24.5947C24.7327 54.6562 24.8445 54.7681 24.8445 54.906V56.3525C24.8445 56.4905 24.7327 56.6023 24.5947 56.6023H23.1482C23.0103 56.6023 22.8984 56.4905 22.8984 56.3525V54.906Z"
				fill="white"
			/>
			<path
				d="M22.8984 58.1495C22.8984 58.0116 23.0103 57.8998 23.1482 57.8998H24.5947C24.7327 57.8998 24.8445 58.0116 24.8445 58.1495V59.5961C24.8445 59.734 24.7327 59.8458 24.5947 59.8458H23.1482C23.0103 59.8458 22.8984 59.734 22.8984 59.5961V58.1495Z"
				fill="white"
			/>
			<path d="M36.5532 49.8453H127.347" stroke="#DFE1E6" strokeWidth="3.32988" />
			<path d="M36.5532 58.17H76.5118" stroke="#DFE1E6" strokeWidth="3.32988" />
			<g clipPath="url(#clip0_637_43443)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15.5829 74.5642V70.8531C15.5829 70.7096 15.5054 70.5722 15.3763 70.4945L12.7291 68.8332C12.5677 68.7316 12.361 68.7316 12.1996 68.8332L9.54597 70.4885C9.41683 70.5722 9.33936 70.7096 9.33936 70.8531V74.5642C9.33936 74.8033 9.55242 75.0005 9.81069 75.0005H15.1115C15.3698 75.0005 15.5829 74.8033 15.5829 74.5642ZM11.4729 72.5029H13.424V75.0003H11.4729V72.5029Z"
					fill="#DFE1E6"
				/>
			</g>
			<path d="M19.958 71.8787H188.117" stroke="#DFE1E6" strokeWidth="3.32988" />
			<g clipPath="url(#clip1_637_43443)">
				<path
					d="M14.9624 80.3077V79.6833C14.9624 79.339 14.6824 79.059 14.3381 79.059H10.2798C9.76345 79.059 9.34326 79.4791 9.34326 79.9955V83.7416C9.34326 84.4287 9.90331 84.6781 10.2798 84.6781H14.9624C15.3068 84.6781 15.5868 84.3981 15.5868 84.0538V80.932C15.5868 80.5877 15.3068 80.3077 14.9624 80.3077ZM14.3381 83.1173H13.7137V81.8685H14.3381V83.1173ZM10.2798 80.3077C10.1994 80.3041 10.1235 80.2696 10.0679 80.2115C10.0123 80.1533 9.98127 80.0759 9.98127 79.9955C9.98127 79.915 10.0123 79.8377 10.0679 79.7795C10.1235 79.7214 10.1994 79.6869 10.2798 79.6833H14.3381V80.3077H10.2798Z"
					fill="#DFE1E6"
				/>
			</g>
			<path d="M19.958 81.8683H127.347" stroke="#DFE1E6" strokeWidth="3.32988" />
			<rect
				x="0.416235"
				y="35.7419"
				width="275.167"
				height="58.9497"
				rx="5.01851"
				stroke="#DFE1E6"
				strokeWidth="0.83247"
			/>
			<g filter="url(#filter0_d_637_43443)">
				<circle cx="258.739" cy="33.673" r="21.739" fill="white" />
			</g>
			<path
				d="M247.87 42.5044L254.96 34.7693C255.169 34.5418 255.525 34.5334 255.744 34.751L257.891 36.8835C258.107 37.0989 258.459 37.0932 258.669 36.8709L265.533 29.5969"
				stroke="#005F3E"
				strokeWidth="2.71737"
				strokeLinecap="round"
			/>
			<path
				d="M260.926 26.4268H268.703V34.2037L260.926 26.4268Z"
				fill="#005F3E"
				stroke="#005F3E"
				strokeWidth="1.81158"
			/>
			<defs>
				<filter
					id="filter0_d_637_43443"
					x="231.565"
					y="11.9341"
					width="54.3475"
					height="54.3474"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="5.43474" />
					<feGaussianBlur stdDeviation="2.71737" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_637_43443"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_637_43443"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_637_43443">
					<rect
						width="6.24353"
						height="6.24353"
						fill="white"
						transform="translate(9.34521 68.7568)"
					/>
				</clipPath>
				<clipPath id="clip1_637_43443">
					<rect
						width="6.24353"
						height="6.24353"
						fill="white"
						transform="translate(9.34521 78.7466)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default IncreasedJobSearchIcon;
