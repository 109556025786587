/* eslint-disable no-param-reassign */
import httpClient from "axios";
import config from "../../../config";
import apiClient, { updateUrl } from "../../../utils/apiClients";
import { VERIFICATION_STATUSES, VERIFICATION_STEPS } from "../../../utils/constants";
import {
	CONTRACT_UPLOAD,
	RESEND_EMAIL_V2,
	TRIGGER_CLIENT_EMAIL_VERIFICATION,
	UPDATE_JOB_ORG,
	LINK_COMPANY_AND_CERTIFICATE_CARD,
	VERIFICATION_DOC_UPLOAD,
	VERIFY_COMPANY_CARD_DETAILS,
	VERIFY_EMAIL,
	VERIFY_CLIENT_EMAIL,
	VERIFY_CREATOR_EMAIL,
	VERIFY_OTP_AADHAR_NUMBER,
	TOGGLE_POC_UPLOAD,
	VERIFY_KYC_DOC,
	VERIFY_CONSULTANT_MANDATE,
	GET_REQUEST_ID_EKYC,
	GET_MOBILE_NUMBER_EKYC,
	UPDATE_CV_STATUS,
	EDIT_EMAIL_API,
	GET_KYC_DETAILS,
	VERIFY_OTP_FROM_APNA_VERIFICATION_VERSION,
	TRIGGER_OTP_EKYC_VERSION,
	VERIFICATION_DETAILS_JVS_API,
	VERIFICATION_LATEST_DETAILS_JVS_API,
	DOCUMENT_UPLOAD,
	VERIFY_EKYC,
	VERIFY_POC_DOC,
	DB_VERIFICATION_DETAILS_JVS_API,
	DB_VERIFICATION_LATEST_DETAILS_JVS_API,
	DB_VERIFICATION_DETAILS_API,
	DB_VERIFICATION_LATEST_DETAILS_API,
	DB_UPDATE_CV_STATUS,
	UPDATE_DB_ORG,
} from "../constants/apiUrls";
import { getUserDetails } from "../../../utils/commonUtils";

// const apiClient = httpClient.create({
// 	baseURL: config.baseUrl,
// });

// apiClient.interceptors.request.use(con => {
// 	const TOKEN = CommonUtils.getItemFromLocalStorage("__token__");
// 	if (TOKEN) con.headers.Authorization = `Token ${TOKEN}`;
// 	con.headers["Content-Type"] = "application/json;charset=UTF-8";
// 	return con;
// });

export const verifyEmail = (jobId, email, additionalProps) => {
	const data = new FormData();
	data.append("email", email);
	if (additionalProps) {
		const { alternateEmailOption, alternateEmailSkipReason } = additionalProps;
		data.append("alternate_email_option", alternateEmailOption);
		data.append("alternate_email_skip_reason", alternateEmailSkipReason);
	}
	return apiClient.post(updateUrl(VERIFY_EMAIL, { job_id: jobId }), data);
};

export const resendEmailV2 = jobId => {
	return apiClient.post(updateUrl(RESEND_EMAIL_V2, { job_id: jobId }), {});
};

export const updateJobOrg = (jobId, data) => {
	return apiClient.post(updateUrl(UPDATE_JOB_ORG, { job_id: jobId }), data);
};

export const updateDBOrg = data => {
	return apiClient.post(UPDATE_DB_ORG, data);
};

// Client verification APIs
export const clientEmailVerification = (jobId, email) => {
	return apiClient.post(
		updateUrl(TRIGGER_CLIENT_EMAIL_VERIFICATION, { job_id: jobId }),
		{ email }
	);
};

export const verifyClientEmail = token => {
	// COT
	return apiClient.put(
		updateUrl(VERIFY_CLIENT_EMAIL, { verification_token: token }),
		{}
	);
};

export const verifyCreatorEmail = params => {
	return apiClient.get(VERIFY_CREATOR_EMAIL + params);
};

export const contractUpload = (jobId, docId) => {
	const data = {
		docs: [
			{
				doc_id: docId,
			},
		],
	};
	return apiClient.post(updateUrl(CONTRACT_UPLOAD, { job_id: jobId }), data);
};

export const postVerifyCompanyVerificationEnteredCardDetails = ({
	payload = {},
	jobId,
}) => {
	return apiClient.post(
		updateUrl(VERIFY_COMPANY_CARD_DETAILS, { JOB_ID: jobId }),
		payload
	);
};

export const linkCeritifcateCardAndCompany = ({ payload = {}, jobId }) => {
	return apiClient.post(
		updateUrl(LINK_COMPANY_AND_CERTIFICATE_CARD, { JOB_ID: jobId }),
		payload
	);
};

export const uploadVerificationDocument = ({
	file = "",
	docNumber = "",
	docType = "",
	isInternal = false,
	legalName = "",
	city = "",
	jobId = null,
}) => {
	const data = {
		doc_number: docNumber,
		type: docType,
		file,
	};
	if (isInternal) {
		data.legal_name = legalName;
		data.city = city;
	}
	return apiClient.post(updateUrl(VERIFICATION_DOC_UPLOAD, { JOB_ID: jobId }), data);
};

export const verificationDetailsAPI = ({
	jobId,
	callLatest = false,
	isDBVer = false,
}) => {
	let URL;
	if (callLatest)
		URL = `${
			isDBVer
				? DB_VERIFICATION_LATEST_DETAILS_API.replace("%JOB_ID%", jobId)
				: VERIFICATION_LATEST_DETAILS_JVS_API.replace("%JOB_ID%", jobId)
		}`;
	else
		URL = `${
			isDBVer
				? DB_VERIFICATION_DETAILS_API.replace("%JOB_ID%", jobId)
				: VERIFICATION_DETAILS_JVS_API.replace("%JOB_ID%", jobId)
		}`;
	return apiClient.get(URL);
};

export const skipEmailVerification = jobId => {
	const data = new FormData();
	data.append("skip_email", true);
	return apiClient.post(updateUrl(VERIFY_EMAIL, { job_id: jobId }), data);
};

export const togglePocUpload = ({ jobId, data }) => {
	return apiClient.post(updateUrl(TOGGLE_POC_UPLOAD, { job_id: jobId }), data);
};

export const verifyAndMarkCompanyVerificationDone = ({ jobId, data = {} }) => {
	return apiClient.post(updateUrl(UPDATE_CV_STATUS, { job_id: jobId }), data);
};

export const dbVerifyAndMarkCompanyVerificationDone = ({
	recruiterId,
	orgId,
	data = {},
}) => {
	const URL = `${config.baseUrl +
		DB_UPDATE_CV_STATUS.replace("%REC_AND_ORG_ID%", `db_${recruiterId}_${orgId}`)}`;
	return apiClient.post(URL, data);
};

export const verifyKycDoc = ({ jobId, data }) => {
	return apiClient.post(updateUrl(VERIFY_KYC_DOC, { job_id: jobId }), data);
};

export const verifyPocDoc = ({ jobId, data }) => {
	return apiClient.put(updateUrl(VERIFY_POC_DOC, { job_id: jobId }), data);
};

export const verifyEkyc = ({ jobId, data }) => {
	return apiClient.put(updateUrl(VERIFY_EKYC, { job_id: jobId }), data);
};

export const getEmailVerificationStatus = async jobId => {
	try {
		const res = await verificationDetailsAPI({ jobId, callLatest: true });
		const steps = res?.data?.verification_steps || [];

		const emailStep = steps.find(
			({ step }) => step === VERIFICATION_STEPS.EMAIL_VERIFICATION
		);

		if (emailStep) {
			return (
				emailStep.status === VERIFICATION_STATUSES.COMPLETED ||
				emailStep.status === VERIFICATION_STATUSES.APPROVED
			);
		}

		return false;
	} catch (e) {
		return false;
	}
};

export const verifyConsultantMandate = ({ jobId, data }) => {
	return apiClient.put(updateUrl(VERIFY_CONSULTANT_MANDATE, { job_id: jobId }), data);
};

export const requestIDForEKYC = () => {
	const URL = `${GET_REQUEST_ID_EKYC}`;
	return apiClient.get(URL);
};

export const triggerOTPForEKYC = (data, apiVersion = 2) => {
	const URL = `${TRIGGER_OTP_EKYC_VERSION.replace("%VERSION%", apiVersion)}`;
	return apiClient.post(URL, data);
};

export const mobileNumberForEKYC = data => {
	const URL = `${GET_MOBILE_NUMBER_EKYC}`;
	return apiClient.post(URL, data);
};

export const verifyOTPforAadharNumber = (data, jobId = "") => {
	const URL = `${VERIFY_OTP_AADHAR_NUMBER.replace("%JOB_ID%", jobId)}`;
	return apiClient.post(URL, data);
};

export const verifyOTPforAadhaarNumberAsync = ({ payload = {}, apiVersion = 2 }) => {
	const URL = `${VERIFY_OTP_FROM_APNA_VERIFICATION_VERSION.replace(
		"%VERSION%",
		apiVersion
	)}`;
	return apiClient.post(URL, payload);
};

export const verifyCreatorEmailFromEmailPage = params => {
	const baseUrlTemp = config.baseUrl || "https://backend-stg.apna.co";
	return httpClient.get(baseUrlTemp + VERIFY_CREATOR_EMAIL + params);
};

export const verifyClientEmailFromEmailPage = token => {
	// COT
	const baseUrlTemp = config.baseUrl || "https://backend-stg.apna.co";

	return httpClient.put(
		updateUrl(baseUrlTemp + VERIFY_CLIENT_EMAIL, { verification_token: token }),
		{}
	);
};

export const editCreatorEmail = ({ jobId, payload }) => {
	const URL = `${EDIT_EMAIL_API.replace("%JOB_ID%", jobId)}`;
	return apiClient.put(URL, payload);
};

export const getKycDetails = data => {
	const URL = `${GET_KYC_DETAILS}`;
	return apiClient.post(URL, data);
};

export const documentUpload = (file, docType) => {
	const data = new FormData();
	data.append("file", file);
	data.append("doc_type", docType);
	const URL = `${DOCUMENT_UPLOAD}`;
	return apiClient.post(URL, data);
};

export const getDbVerificationDetails = ({ callLatest = false }) => {
	let URL;
	const userDetails = getUserDetails();
	const userId = userDetails?.id;
	if (callLatest)
		URL = `${DB_VERIFICATION_LATEST_DETAILS_JVS_API.replace("%USER_ID%", userId)}`;
	else URL = `${DB_VERIFICATION_DETAILS_JVS_API.replace("%USER_ID%", userId)}`;
	return apiClient.get(URL);
};

// export default apiClient;
