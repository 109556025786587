import React from "react";

const DataBaseIcon = ({ size = "20", fill = "#5E6C84", ...props }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_755_8467)">
				<path
					d="M8.37334 4.28279L8.17024 5.46727C8.13632 5.66513 8.21763 5.86506 8.38002 5.98305C8.47188 6.04977 8.58069 6.08373 8.69003 6.08373C8.77398 6.08373 8.85825 6.06373 8.93538 6.02313L9.99904 5.46393L11.0628 6.0232C11.1399 6.06373 11.2242 6.08377 11.3082 6.08377H11.3096C11.6005 6.08338 11.8363 5.84741 11.8363 5.55643C11.8363 5.51596 11.8317 5.47648 11.823 5.43858L11.6247 4.28275L12.4853 3.44396C12.629 3.30386 12.6807 3.09429 12.6187 2.90339C12.5567 2.71246 12.3916 2.57331 12.193 2.54448L11.0039 2.37166L10.4719 1.29391C10.3831 1.11394 10.1997 1 9.99897 1C9.79826 1 9.61492 1.11398 9.52615 1.29398L8.99434 2.37162L7.805 2.54445C7.60641 2.57328 7.44135 2.71243 7.37937 2.90336C7.31728 3.09426 7.36907 3.30382 7.51278 3.44392L8.37334 4.28279Z"
					fill={fill}
				/>
				<path
					d="M18.0118 14.0078C17.2951 14.6088 16.372 14.9713 15.3657 14.9713C15.0303 14.9713 14.7041 14.9308 14.3917 14.8548C14.5833 15.3658 14.6883 15.9188 14.6883 16.4959V17.0607C14.6883 17.8001 14.4184 18.4773 13.9727 19.0002H17.0602C18.1297 19.0002 18.9997 18.1302 18.9997 17.0607V16.4959C18.9997 15.5341 18.624 14.6585 18.0118 14.0078Z"
					fill={fill}
				/>
				<path
					d="M10.0008 13.9166C11.6931 13.9166 13.0699 12.5398 13.0699 10.8475C13.0699 9.15511 11.6931 7.77832 10.0008 7.77832C8.30846 7.77832 6.93164 9.15511 6.93164 10.8475C6.93164 12.5398 8.3085 13.9166 10.0008 13.9166Z"
					fill={fill}
				/>
				<path
					d="M15.3652 13.9166C17.0576 13.9166 18.4344 12.5398 18.4344 10.8475C18.4344 9.15511 17.0576 7.77832 15.3652 7.77832C14.6129 7.77832 13.9231 8.05057 13.3887 8.50148C13.8513 9.16791 14.123 9.9765 14.123 10.8474C14.123 11.7184 13.8513 12.5269 13.3887 13.1934C13.9231 13.6444 14.6129 13.9166 15.3652 13.9166Z"
					fill={fill}
				/>
				<path
					d="M4.63356 13.9166C5.3859 13.9166 6.0757 13.6443 6.61011 13.1934C6.14742 12.527 5.87577 11.7184 5.87577 10.8474C5.87577 9.9765 6.14742 9.16791 6.61011 8.50148C6.07574 8.05057 5.38594 7.77832 4.63356 7.77832C2.94124 7.77832 1.56445 9.15511 1.56445 10.8475C1.56445 12.5398 2.94128 13.9166 4.63356 13.9166Z"
					fill={fill}
				/>
				<path
					d="M9.99923 14.9713C8.99295 14.9713 8.06982 14.6088 7.3532 14.0078C6.74098 14.6585 6.36523 15.5341 6.36523 16.4959V17.0607C6.36523 18.1302 7.23528 19.0002 8.30466 19.0002H11.6937C12.7632 19.0002 13.6332 18.1302 13.6332 17.0607V16.4959C13.6332 15.5341 13.2574 14.6585 12.6452 14.0078C11.9286 14.6088 11.0055 14.9713 9.99923 14.9713Z"
					fill={fill}
				/>
				<path
					d="M5.31132 16.4957C5.31132 15.9186 5.4164 15.3656 5.60796 14.8546C5.29553 14.9306 4.96942 14.9711 4.63393 14.9711C3.62765 14.9711 2.70459 14.6086 1.98793 14.0076C1.37575 14.6583 1 15.5339 1 16.4957V17.0606C1 18.13 1.87001 19 2.93943 19H6.02706C5.58128 18.4771 5.31132 17.7999 5.31132 17.0606V16.4957Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_755_8467">
					<rect
						width={size}
						height={size}
						fill="white"
						transform="translate(1 1)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default DataBaseIcon;
