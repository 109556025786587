import {
	SET_ENTERPRISE_PROFILE,
	SET_USAGE_LIST,
	ENTERPRISE_USER_EMAIL,
	ENTERPRISE_WORKSPACE_DATA,
	ENTERPRISE_SELECTED_JOB,
	SET_SELECTED_LEFT_MENU_NAVIGATION_ITEM,
} from "../constants/EnterpriseGradingTypes";
import {
	ENTERPRISE_PROFILE_DATA,
	ENTERPRISE_USER_DATA,
	PATH_NAME,
} from "../enterpriseGrading/constants/constants";
import CommonUtils from "../utils/commonUtils";

const persistedProfileState =
	JSON.parse(CommonUtils.getItemFromLocalStorage(ENTERPRISE_PROFILE_DATA)) || {};
const persistedUserState =
	JSON.parse(CommonUtils.getItemFromLocalStorage(ENTERPRISE_USER_DATA)) || {};
const initialState = {
	usageList: null,
	enterpriseProfileData: {
		...persistedProfileState,
		...persistedUserState,
	},
	enterpriseUserEmail: "",
	enterpriseWorkspaceData: {},
	selectedJob: {
		id: "",
		isDuplicate: false,
	},
	selectedLeftMenuNavigationItem: PATH_NAME.JOBS_PAGE,
};

const enterpriseGrading = (state = initialState, action) => {
	switch (action.type) {
		case SET_USAGE_LIST: {
			return {
				...state,
				usageList: action.payload,
			};
		}
		case SET_ENTERPRISE_PROFILE: {
			return {
				...state,
				enterpriseProfileData: action.payload,
			};
		}
		case ENTERPRISE_USER_EMAIL: {
			return {
				...state,
				enterpriseUserEmail: action.payload,
			};
		}
		case ENTERPRISE_WORKSPACE_DATA: {
			return {
				...state,
				enterpriseWorkspaceData: action.payload,
			};
		}
		case ENTERPRISE_SELECTED_JOB: {
			return {
				...state,
				selectedJob: action.payload,
			};
		}
		case SET_SELECTED_LEFT_MENU_NAVIGATION_ITEM: {
			return {
				...state,
				selectedLeftMenuNavigationItem: action.payload,
			};
		}
		default:
			return state;
	}
};

export default enterpriseGrading;
