import {
	CHECK_IS_TAB_SUMMARY_FETCHED,
	DECREASE_TAB_COUNT,
	INCREASE_TAB_COUNT,
	RESET_TAB_SUMMARY_DATA,
	SET_TAB_SUMMARY_DATA,
	UPDATE_TABS_COUNT,
	UPDATE_TAB_COUNT,
} from "../constants/types";

const initialState = {
	job_id: null,
	tabs: {},
	show_rejected_sub_tabs: null,
	isTabDataFetched: false,
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_TAB_SUMMARY_DATA:
			return {
				...payload,
			};
		case INCREASE_TAB_COUNT:
			return {
				...state,
				tabs: {
					...state.tabs,
					[payload.tab]: {
						...state.tabs[payload.tab],
						count: state.tabs[payload.tab]?.count + 1 || 1,
					},
				},
			};
		case DECREASE_TAB_COUNT:
			return {
				...state,
				tabs: {
					...state.tabs,
					[payload.tab]: {
						...state.tabs[payload.tab],
						count:
							state.tabs[payload.tab]?.count - 1 >= 0
								? state.tabs[payload.tab]?.count - 1
								: 0,
					},
				},
			};
		case UPDATE_TAB_COUNT:
			return {
				...state,
				tabs: {
					...state.tabs,
					[payload.tab]: {
						...state.tabs[payload.tab],
						count: payload?.count,
					},
				},
			};
		case RESET_TAB_SUMMARY_DATA:
			return {
				...initialState,
			};
		case CHECK_IS_TAB_SUMMARY_FETCHED:
			return {
				...state,
				isTabDataFetched: payload,
			};
		case UPDATE_TABS_COUNT:
			return {
				...state,
				tabs: {
					...payload.tabs,
				},
			};
		default: {
			return state;
		}
	}
};
