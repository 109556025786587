/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import httpClient from "axios";
import config from "../config";
import url from "../config/urls";
import CommonUtils, {
	checkIsEnterprise,
	getEnterPriseWorkSpaceId,
	checkIsNewLayout,
	getUserEnterpriseEmail,
} from "./commonUtils";
import { JOB_APPLICANT_PAGE_SIZE } from "../constants";
import {
	COIN_PRODUCT,
	JOB_POST_TYPE,
	LOGIN_VIA_OTP,
	TYPES_OF_DASHBOARDS,
} from "./constants";

const axios = httpClient.create({
	baseURL: config.baseUrl,
});

export const enterpriseAxios = httpClient.create({
	baseURL: config.enterpriseBaseUrl,
});

axios.interceptors.request.use(con => {
	const TOKEN = CommonUtils.getItemFromLocalStorage("__token__");
	if (TOKEN) con.headers.Authorization = `Token ${TOKEN}`;
	con.headers["Content-Type"] = "application/json;charset=UTF-8";
	return con;
});

enterpriseAxios.interceptors.request.use(con => {
	const TOKEN = CommonUtils.getItemFromLocalStorage("__token__");
	if (TOKEN) con.headers.Authorization = `Token ${TOKEN}`;
	con.headers["Content-Type"] = "application/json;charset=UTF-8";
	return con;
});

// changing common header object to function for run time auth token change
export const getCommonHeader = () => {
	return undefined;
};

export const updateUrl = (endPoint, data = {}) => {
	if (!data || !Object.keys(data).length) return endPoint;

	return Object.keys(data).reduce((acc, curr) => {
		return acc.replace(`%${curr}%`, data[curr]);
	}, endPoint);
};

const loginV2 = data => {
	const URL = config.baseUrl + url.LOGIN_V2;
	return axios.post(URL, data);
};

const loginV3 = data => {
	const URL = config.baseUrl + url.LOGIN_V3;
	return axios.post(URL, data);
};

const logoutV1 = () => {
	const URL = config.baseUrl + url.LOGOUT;
	return axios.post(URL, null);
};

const logoutV2 = () => {
	const URL = `${config.oauthBaseUrl + url.LOGOUT_V2}`;
	return axios.post(URL, null);
};

const getOTP = params => {
	const URL = config.baseUrl + url.OTP;

	return Promise.resolve(axios.get(URL, { params }))
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getOTPV2 = params => {
	const URL = config.baseUrl + url.OTP_V2;

	return Promise.resolve(axios.get(URL, { params }))
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getOTPV3 = async (params, appCheckToken) => {
	const URL = config.baseUrl + url.OTP_V3;
	const headers = {
		"X-Firebase-AppCheck": appCheckToken,
	};
	return axios.post(URL, params, { headers });
};

const getJobs = () => {
	const isEnterprise = checkIsEnterprise();
	let URL;
	if (isEnterprise) {
		URL = `${config.baseUrl + url.ENTERPRISE_V1_JOBS}`;
	} else {
		URL = `${config.baseUrl + url.JOBS_V7}`;
	}
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getPaginatedJobs = (queryParams = "") => {
	let URL = `${config.baseUrl + url.JOBS_V7}${queryParams}`;

	if (checkIsNewLayout()) {
		URL = `${config.baseUrl + url.JOBS_V8}${queryParams}`;
	}

	const workspaceId = getEnterPriseWorkSpaceId();

	if (workspaceId) {
		URL = `${config.baseUrl +
			url.ENTERPRISE_JOBS_LIST}${queryParams}&workspace_id=${workspaceId}`;
	}

	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp?.data || {};
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobApplications = data => {
	const URL = `${config.baseUrl + url.JOB_DATA + data}/applications/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobDetail = data => {
	let URL = `${config.baseUrl + url.JOBS_V7 + data}/`;
	const workspaceId = getEnterPriseWorkSpaceId();

	if (workspaceId) {
		URL = `${config.baseUrl +
			url.ENTERPRISE_JOBS_LIST +
			data}/?workspace_id=${workspaceId}`;
	}
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getDetailsFromDatabase = jobId => {
	const URL = `${url.DATABASE_INTEGRATION.replace("%jobId%", jobId)}`;
	return Promise.resolve(
		axios.get(`${config.accountsUrl}${URL}`, { headers: getCommonHeader() })
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getMatchedJobs = data => {
	const URL = `${config.baseUrl + url.JOB_DATA + data}/suggestions/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const changeJobStatus = (jobId, status, remark) => {
	let URL = `${config.baseUrl + url.JOBS_V3 + jobId}/activate/`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}

	const data = {
		status,
	};
	// eslint-disable-next-line no-unused-expressions
	remark && (data.comment = remark);
	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const reactivateJob = (jobId, status, remark) => {
	let URL = `${config.baseUrl + url.JOBS_V3 + jobId}/activate/`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}

	const data = {
		status,
	};
	// eslint-disable-next-line no-unused-expressions
	remark && (data.comment = remark);
	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

export const getFeebackJob = jobId => {
	const URL = `${config.baseUrl + url.JOBS + jobId}/feedback/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

export const submitFeedbackJob = (jobId, data) => {
	const URL = `${config.baseUrl + url.JOBS + jobId}/feedback/`;
	return Promise.resolve(
		axios.post(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getBlockedUser = jobId => {
	const URL = `${config.baseUrl + url.BANNED_USER + jobId}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getDepartmentData = params => {
	const URL = url.DEPARTMENT_LIST_DATA;
	return axios.get(URL, {
		params,
	});
};

const getCategoryFromDepartment = params => {
	const URL = url.CATEGORY_DEPARTMENT_INFERENCE;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params,
	});
};
const getJobCategories = params => {
	let URL = config.baseUrl + url.NEW_JOB_CATEGORIES;
	if (params) URL += `?${params}`;
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getFuzzyJobRoles = (params = "") => {
	const URL = `${url.JOB_ROLES_SUGGESTIONS}${params}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const getEducationLevel = () => {
	const URL = config.baseUrl + url.EDUCATION_LEVELS;
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getOrganizations = () => {
	const URL = config.baseUrl + url.ORGANIZATION_NAME;
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const postJob = payload => {
	const isEnterprise = checkIsEnterprise();
	const workspaceId = getEnterPriseWorkSpaceId();
	let URL;
	if (isEnterprise) {
		URL = `${config.baseUrl + url.ENTERPRISE_V1_JOBS}?workspace_id=${workspaceId}`;
	} else {
		URL = config.baseUrl + url.JOBS_V7;
	}

	let data = payload;
	if (workspaceId && isEnterprise) {
		data = {
			...payload,
			workspace_id: workspaceId,
			job_type: "enterprise",
		};
	}

	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const editJob = (id, payload) => {
	const isEnterprise = checkIsEnterprise();
	const workspaceId = getEnterPriseWorkSpaceId();
	let URL;
	if (isEnterprise) {
		URL = `${config.baseUrl +
			url.ENTERPRISE_V1_JOBS +
			id}?workspace_id=${workspaceId}`;
	} else {
		URL = `${config.baseUrl + url.JOBS_V7 + id}/`;
	}

	let data = payload;

	if (workspaceId && isEnterprise) {
		data = {
			...payload,
			workspace_id: workspaceId,
			job_type: "enterprise",
		};
	}
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const employerEditJob = (id, data) => editJob(id, data);

const acceptTerms = () => {
	const URL = config.baseUrl + url.ACCEPT_TERMS;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const extendExpiry = (data, jobId) => {
	const URL = `${config.baseUrl + url.JOBS_V3 + jobId}/set-expiry/`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			headers: getCommonHeader(),
			data,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getCityWiseAreas = (cityId, subCityId = "") => {
	const URL = `${config.baseUrl + url.CITIES_LIST + cityId}/areas/${
		subCityId ? `?sub_city_id=${subCityId}` : ""
	}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getCitiesList = () => {
	const URL = config.baseUrl + url.CITIES_LIST;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getQueryResult = data => {
	const URL = config.baseUrl + url.SQL_QUERY;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data: {
				query: data,
			},
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobsPostedCountOfHRNumber = number => {
	const URL = `${config.baseUrl + url.JOB_POST_COUNT + number}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateUserPersona = data => {
	const URL = config.baseUrl + url.USER_PERSONA;

	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const uploadCompanyDocs = (data, userId) => {
	let URL = `${config.baseUrl + url.USERS + userId}/documents/`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateDocs = (data, userId, documentId) => {
	let URL = `${config.baseUrl + url.USERS + userId}/documents/${documentId}/`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getUserDocs = (userId, orgId = null) => {
	let URL = `${config.baseUrl + url.USERS + userId}/documents/`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: getCommonHeader(),
			params: {
				organization_id: orgId,
			},
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getEmployerInviteText = source => {
	const URL = `${config.baseUrl + url.EMPLOYER_INVITE_TEXT}?source=${source}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const sendEmployerInvite = phoneNums => {
	const URL = `${config.baseUrl + url.SEND_EMPLOYER_INVITE}?phones=${phoneNums}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateJobApplicationFeedback = data => {
	let URL = `${config.baseUrl + url.JOB_APPLICATIONS + data.id}/feedback/`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}

	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobAppliedCandidate = (id, pageNo, pageSize = JOB_APPLICANT_PAGE_SIZE) => {
	const URL = `${config.baseUrl +
		url.EMPLOYER_JOBS_LIST_V2 +
		id}/applications/?page=${pageNo}&page_size=${pageSize}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getAllQuestions = jobId => {
	const URL = `${config.baseUrl + url.ASSESSMENT}job/${jobId}/questions/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getAuthToken = data => {
	const URL = `${config.baseUrl + url.GET_AUTH_TOKEN}`;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const sendShowPhoneNumberEvent = data => {
	const URL = `${config.baseUrl + url.SEND_PHONE_NUMBER_EVENT}`;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getEmployerHrNumberList = () => {
	// sample url: {{baseUrl}}/api/employer/v1/employer_hr_number_list/
	const URL = `${config.baseUrl}${url.EMPLOYER_HR_NUMBER_LIST}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const resendHrNumVerificationLink = ({ hrNo, jobId }) => {
	const URL = `${config.baseUrl}${url.EMPLOYER_HR_NUMBER_STATUS}?hr_number=${hrNo}`;
	const data = {
		job_id: jobId,
	};
	return Promise.resolve(
		axios({
			method: "post",
			headers: getCommonHeader(),
			url: URL,
			data,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getHrNumVerificationStatus = (hrNo, employerNo) => {
	const URL = `${config.baseUrl}${url.EMPLOYER_HR_NUMBER_STATUS}?hr_number=${hrNo}&employer_number=${employerNo}`;
	return Promise.resolve(
		axios({
			method: "get",
			headers: getCommonHeader(),
			url: URL,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getATSFunnelSteps = () => {
	const URL = `${config.baseUrl + url.ATS_FUNNEL_STEPS}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateJobATSTemplate = (templateId, jobId, data) => {
	const URL = `${config.baseUrl +
		url.UPDATE_JOB_ATS_TEMPLATE +
		templateId}/?job_id=${jobId}`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateEmployerECCAccess = (jobId, data) => {
	const URL = `${config.baseUrl +
		url.UPDATE_EMPLOYER_ECC_ACCESS +
		jobId}/ats-ecc-employer/`;

	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getEccPreferenceConfig = () => {
	const URL = `${config.baseUrl}${url.ECC_PREFERENCE_CONFIG}`;
	return axios
		.get(URL, {
			headers: getCommonHeader(),
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobsLocation = () => {
	const URL = `${config.baseUrl + url.EMPLOYER_JOBS_LOCATION}`;

	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp?.data || [];
		})
		.catch(err => {
			throw new Error(err);
		});
};

const fetchCandidateResumeLink = (params = "") => {
	const URL = `${url.GET_CANDIDATE_RESUME}${params}`;
	return axios
		.get(URL, {
			headers: getCommonHeader(),
		})
		.then(resp => {
			return resp?.data || [];
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateEccPreference = (jobId, data) => {
	const URL = `${config.baseUrl}${url.UPDATE_EMPLOYER_ECC_ACCESS}${jobId}/preference`;
	return axios({
		method: "put",
		url: URL,
		data,
		headers: getCommonHeader(),
	}).catch(err => {
		throw new Error(err);
	});
};

const getCreditExpiryOptions = () =>
	axios.get(url.CREDIT_EXPIRY_OPTIONS, {
		headers: getCommonHeader(),
	});

const initateNumberMaskCallMobile = (
	employerId,
	employerPhoneNumber,
	candidateId,
	candidatePhoneNumber,
	cityId,
	jobId
) => {
	const URL = url.INITIATE_NUMBER_MASK_CALL_MOBILE;
	const data = {
		source: "web",
		from: {
			type: "employer",
			phone: `+91${employerPhoneNumber}`,
			id: employerId,
		},
		to: {
			type: "candidate",
			phone: `+91${candidatePhoneNumber}`,
			id: candidateId,
		},
		city_id: cityId,
		job_id: String(jobId),
	};
	return axios.post(URL, data, { headers: getCommonHeader() });
};

const initateNumberMaskCallDesktop = (
	employerId,
	employerPhoneNumber,
	candidateId,
	candidatePhoneNumber,
	cityId,
	jobId
) => {
	const URL = url.INITIATE_NUMBER_MASK_CALL_DESKTOP;
	const data = {
		source: "web",
		from: {
			type: "employer",
			phone: `+91${employerPhoneNumber}`,
			id: employerId,
		},
		to: {
			type: "candidate",
			phone: `+91${candidatePhoneNumber}`,
			id: candidateId,
		},
		city_id: cityId,
		job_id: String(jobId),
	};
	return axios.post(URL, data, { headers: getCommonHeader() });
};

const numberMaskCallStatus = callId => {
	const URL = `${url.NUMBER_MASK_CALL_STATUS}${callId}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
const getCompanyDomains = (id, isGetByJobId = false) =>
	axios.get(
		`${url.ORGANIZATION_DOMAINS}?${
			isGetByJobId ? "job_id" : "organization_id"
		}=${id}`,
		{
			headers: getCommonHeader(),
		}
	);

const sendEmailVerificationLink = (jobId, email) =>
	axios.post(
		`${url.JOB_DATA_V2}${jobId}/resend_email_link/`,
		{
			...(email && { hr_email: email }),
		},
		{
			headers: getCommonHeader(),
		}
	);

const uploadPocDoc = ({ jobId, payload }) => {
	let URL = `${url.POC_DOC_V2.replace("%JOB_ID%", jobId)}`;
	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const patchPocDoc = (userId, orgId, docId, payload) => {
	let URL = `${url.USERS}${userId}/${orgId}/pocdoc/${docId}/`;
	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return axios.put(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getPocDocs = jobId => {
	let URL = `${url.POC_DOC_V2.replace("%JOB_ID%", jobId)}`;
	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const getEmailVerificationStatus = (creatorId, email) =>
	axios.get(url.EMAIL_VERIFICATION_STATUS, {
		headers: getCommonHeader(),
		params: {
			hr_email: email,
			created_by_id: creatorId,
		},
	});

const updatePocVerificationStatus = (jobId, status, isHr) => {
	const URL = `${url.INTERNAL_USERS_V2}${jobId}/verify_poc/`;
	return axios.put(
		URL,
		{ status },
		{
			headers: getCommonHeader(),
			params: {
				employer_to_verify: isHr ? "hr" : "creator",
			},
		}
	);
};

const getCompanyPrefillData = jobId => {
	let URL = url.COMPANY_PREFILL;
	if (jobId) {
		URL += `?job_id=${jobId}`;
	}

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
const fetchSalarySuggestion = (params = "") => {
	const URL = `${url.SALARY_SUGGESTION_URL}${params}`;
	return axios
		.get(URL, {
			headers: getCommonHeader(),
		})
		.then(resp => {
			return resp?.data || [];
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getEmployerBanDetails = phoneNo =>
	axios.get(`${url.BAN_DETAILS}${phoneNo}/`, {
		headers: getCommonHeader(),
	});

const requestReactivation = payload =>
	axios.post(url.REACTIVATION_REQUEST, payload, { headers: getCommonHeader() });

const acceptReactivationConsent = () =>
	axios.post(url.ACCEPT_REACTIVATION_CONSENT, null, { headers: getCommonHeader() });

const getRecruitersList = () =>
	axios.get(`${url.RECRUITERS_LIST}`, {
		headers: getCommonHeader(),
	});

const getUserProfile = () =>
	axios.get(url.USER_PROFILE, {
		headers: getCommonHeader(),
	});

const getUserWorkspace = jobId => {
	const URL = url.USER_WORKSPACE + (jobId ? `?job_id=${jobId}` : "");
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
const checkTncAgreement = () =>
	axios.get(`${url.TNC_AGREEMENT}`, {
		headers: getCommonHeader(),
	});

const updateTncAgreement = () => {
	const URL = url.UPDATE_TNC_AGREEMENT;
	return axios.put(
		URL,
		{},
		{
			headers: getCommonHeader(),
		}
	);
};

const getSimilarJobs = payload => {
	const URL = url.SIMILAR_JOBS;
	return axios.post(URL, payload, { headers: getCommonHeader() });
};

const accountDetails = () => {
	const userProfile = JSON.parse(CommonUtils.getItemFromLocalStorage("__user__"));
	const userId = userProfile && userProfile.id;
	const URL = `${url.ACCOUNT_DETAILS}${userId}?club_by_expiry=true`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const createAccount = (notEligibleForFreeCoins = false) => {
	const userProfile = JSON.parse(CommonUtils.getItemFromLocalStorage("__user__"));
	const userId = userProfile && userProfile.id;
	const URL = url.ACCOUNT_DETAILS;

	const data = {
		user_id: `${userId}`,
		ignore_complementary_coins: notEligibleForFreeCoins,
	};

	return Promise.resolve(
		axios.post(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const validatePhoneNumber = number => {
	const URL = `${url.VALIDATE_USER + number}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const getPaymentPendingInfo = () => {
	let URL = `${url.PAYMENT_PENDING}`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const autoRenewalChange = (jobId, renewStatus, serviceId) => {
	let URL = `${config.baseUrl + url.JOBS + jobId + url.AUTO_RENEWAL_CHANGE}`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}

	const data = {
		auto_renew: renewStatus,
		service_id: serviceId,
	};

	return Promise.resolve(
		axios.put(URL, data, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const getTransactions = (accountId, pageNo = 1, itemsPerPage = 10, type) => {
	let URL = `${url.TRANSACTIONS +
		accountId}?page=${pageNo}&limit=${itemsPerPage}&sort_on=timestamp&sort_order=desc`;
	if (type !== "all") {
		URL += `&type=${type}`;
	}

	return Promise.resolve(
		axios.get(URL, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
export const initiateTransfer = data => {
	const URL = url.INIT_TRANSFER;
	return axios.post(URL, data, {
		headers: getCommonHeader(),
	});
};

export const verifyTransfer = data => {
	const URL = url.VERIFY_TRANSFER;
	return axios.post(URL, data, {
		headers: getCommonHeader(),
	});
};
const debitCoinsAndUpdateJobStatus = (jobId, payload) => {
	let URL = `${config.baseUrl + url.DEBIT_COINS_UPDATE_JOB_STATUS + jobId}/`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}

	return Promise.resolve(
		axios.put(URL, payload, {
			headers: getCommonHeader(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const deleteJob = (jobId = "") => {
	const URL = `${config.baseUrl}${url.DELETEJOB}${jobId}`;
	return axios
		.delete(URL, {
			headers: getCommonHeader(),
		})
		.then(response => response)
		.catch(error => {
			throw new Error(error);
		});
};

const checkCouponCode = (payload = {}) => {
	const URL = `${url.CHECK_COUPON_URL}`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getAllPackages = () => {
	const URL = `${config.baseUrl + url.PACKAGES}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params: {
			sort_on: "price.mrp",
			source: "emdash",
		},
	});
};

const getAllActivationPlans = (params = {}, payload = {}) =>
	axios.put(url.SERVICES, payload, {
		headers: getCommonHeader(),
		params,
	});

export const getJobActivationPlans = (payload = {}, pricingModal = JOB_POST_TYPE.PPL) => {
	const URL = `${url.JOB_ACTIVATION_PLANS}${pricingModal}`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const checkForFirstPurchase = (userId = "") => {
	const URL = `${url.CHECK_FIRST_PURCHASE}${userId}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const getSelectedActivationPlan = (params = {}) => {
	const URL = `${url.SELECTED_SERVICE_V2}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params,
	});
};

const initiateBuyCoins = (payload = {}) => {
	const URL = `${url.GENERATE_PAYMENT_ORDER}`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const generatePaymentOrder = (payload = {}, orderId) => {
	const URL = `${url.GENERATE_PAYMENT_ORDER}/${orderId}/place`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const confirmOrderPayment = (payload = {}, orderId) => {
	const URL = `${url.GENERATE_PAYMENT_ORDER}/${orderId}/validate`;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getApnaProducts = () => {
	const URL = `${url.APNA_PRODUCTS_URL}?title=${COIN_PRODUCT}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const resendTransactionOTP = params => {
	const URL = `${url.ACC_OTP}`;
	return Promise.resolve(axios.post(URL, params, { headers: getCommonHeader() }))
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const reportResume = data => {
	return axios.post(url.REPORT_RESUME_URL, data, { headers: getCommonHeader() });
};

const fetchStateDetails = () => {
	return axios.get(url.STATES_URL, {
		headers: getCommonHeader(),
	});
};

const fetchCitiesList = (queryParam = "") => {
	return axios.get(`${url.STATES_URL}${queryParam}`, {
		headers: getCommonHeader(),
	});
};

const getGstDetails = userId => {
	return axios.get(`${url.GET_GST_DETAILS}/${userId}`, {
		headers: getCommonHeader(),
	});
};

const postGstDetails = (payload, userId) => {
	return axios.post(`${url.POST_GST_DETAILS}/${userId}`, payload, {
		headers: getCommonHeader(),
	});
};

const getInvoice = orderId => {
	const URL = `${url.INVOICE_URL}${orderId}`;
	return axios.get(URL, { headers: getCommonHeader() });
};
const getOrderDetails = orderId => {
	const URL = `${url.ORDER_URL}${orderId}`;
	return axios.get(URL, { headers: getCommonHeader() });
};
const getSalesOrderList = ({ params: param }) => {
	const URL = `${url.PENDING_ORDER_LIST}`;
	const params = {
		...param,
		sources: "SFDASH,PRICINGCONTRACTDASH",
		statuses: "init,processing",
		sort_on: "created_at",
		sort_order: "desc",
	};
	return axios.get(URL, { headers: getCommonHeader(), params });
};

const checkEligibleForPaidPromotion = () => {
	const URL = `${url.PAID_PROMOTION_ELIGIBILITY_URL}`;
	return axios.get(URL, { headers: getCommonHeader() });
};

const postVerifyCompanyVerificationEnteredCardDetails = ({ apiKey, payload = {} }) => {
	const URL = `${url.VERIFY_COMPANY_CARD_DETAILS.replace("%CARD%", apiKey)}`;
	return axios.post(URL, payload, { headers: getCommonHeader() });
};

const reportMembersAndConsultancy = ({ reported, payload }) => {
	return axios.post(
		url.REPORT_MEMBER_AND_CONSULTANCY.replace("%REPORTED%", reported),
		payload,
		{
			headers: getCommonHeader(),
		}
	);
};

const addTeamMember = payload => {
	return axios.post(url.ADD_TEAM_MEMBER, payload, {
		headers: getCommonHeader(),
	});
};

const getAdminMembersConfig = () => {
	const URL = `${url.GET_ADMIN_MEMBERS_CONFIG}`;
	return axios.get(URL, { headers: getCommonHeader() });
};

const getAdminTeamMembers = ({ ordId }) => {
	const URL = `${url.GET_ADMIN_TEAM_MEMBERS}${ordId}`;
	return axios.get(URL, { headers: getCommonHeader() });
};

const getAdminConsultanciesTeamMembers = ({ ordId, consultancyId }) => {
	const URL = `${url.GET_ADMIN_TEAM_MEMBERS}${ordId}&consultancy_id=${consultancyId}`;
	return axios.get(URL, { headers: getCommonHeader() });
};

const checkIfJobWillBePaidJob = (queryParams = "") => {
	const URL = `${url.CHECK_MONETISED}${queryParams}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		queryParams,
	});
};

const checkForDuplicateJob = (params = {}) => {
	const URL = `${url.DUPLICATE_JOB_CHECK}`;

	return axios.get(URL, {
		headers: getCommonHeader(),
		params,
	});
};

const getExpiryFeedbackStatus = jobId => {
	const URL = url.EXPIRY_FEEDBACK_STATUS.replace("{jobId}", jobId);
	return axios.get(URL, { headers: getCommonHeader() });
};

const triggerSupportCallRequest = ({ jobId, employerId, payload = {} }) => {
	const URL = `${url.TRIGGER_SUPPORT_CALL_REQUEST.replace("%JOB_ID%", jobId).replace(
		"%EMPLOYER_ID%",
		employerId
	)}`;
	return axios.post(URL, payload, { headers: getCommonHeader() });
};

const getJobApplicationsList = remark => {
	const URL = `${url.JOB_APPLICATIONS}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params: {
			remark,
		},
	});
};

const getBulkCSVDownloadInfo = () =>
	axios.get(url.BULK_CSV_DOWNLOAD, {
		headers: getCommonHeader(),
	});

export const headcountModalRendered = jobId => {
	const URL = `${url.HEADCOUNT_MODAL_RENDERED.replace("%JOB_ID%", jobId)}`;
	return axios.post(URL, jobId);
};
export const setHeadcountValue = (jobId, value) => {
	let URL = `${url.SET_HEADCOUNT_VALUE.replace("%JOB_ID%", jobId)}`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	const data = {
		num_employee: value,
	};
	return axios.put(URL, data);
};
const getCoinRefundDetails = jobId => {
	const URL = `${url.COIN_REFUND_DETAILS}${jobId}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const getJobPostMissingFields = jobId => {
	let URL = `${url.JOB_POST_MISSING_FIELDS.replace("%JOB_ID%", jobId)}`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}

	return axios.get(URL);
};
export const validateRetryPayment = orderId =>
	axios.get(`${url.VALIDATE_RETRY_PAYMENT}${orderId}`, {
		headers: getCommonHeader(),
	});

export const getGSTCompanyDetails = payload =>
	axios.post(url.GST_COMPANY_DETAILS, payload, {
		headers: getCommonHeader(),
	});

export const postGstDetailsInCheckout = (payload, userId) => {
	return axios.post(`${url.POST_GST_IN_CHECKOUT}/${userId}/`, payload, {
		headers: getCommonHeader(),
	});
};

export const getCategoryOptions = params => {
	const URL = url.TITLE_CAT_OPTIONS;

	return axios.get(URL, { headers: getCommonHeader(), params });
};
export const getApnaDatabaseEligibility = () =>
	axios.get(`${config.accountsUrl}${url.APNA_DB_ELIGIBILITY}`, {
		header: getCommonHeader(),
	});

export const getEmployerJobPostPrefillData = params => {
	const URL = url.EMPLOYER_POST_JOB_PREFILL;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params,
	});
};
export const getAllowedJobTitles = jobId => {
	let URL = `${url.ALLOWED_JOB_TITLES.replace("%JOB_ID%", jobId)}?approved=true`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	// query param to be passed for enterprise user
	let params = {};
	if (workspaceId) {
		params = {
			workspace_id: workspaceId,
		};
	}
	return axios.get(URL, {
		headers: getCommonHeader(),
		params,
	});
};
export const createCustomPlan = payload =>
	axios.post(`${url.CREATE_CUSTOM_PLAN}`, payload, {
		headers: getCommonHeader(),
	});

/**
 * @typedef {object} ApnaVerifyPayload
 * @property {string} deviceId
 * @property {string} sessionId
 * @property {"shield" | "fingerprint"} vendor
 * @property {"employer"} source
 */

/**
 *
 * @param {ApnaVerifyPayload} payload
 * @return {Promise<{message: string}>}
 */
const sendDeviceInfo = payload => {
	return axios.post(url.VERIFY_USER, payload, {
		headers: getCommonHeader(),
	});
};

const checkForMicroCityFlag = ({ titleId = "", orgId = "", jobLocationType = "" }) => {
	const params = {
		title_id: titleId,
		org_id: orgId,
		job_location_type: jobLocationType,
	};
	return axios.get(`${url.CHECK_FOR_MICRO_CITY_FLAG}`, {
		headers: getCommonHeader(),
		params,
	});
};
export const getDegreeList = eduLevel => {
	const URL = `${url.DEGREE_LIST.replace("%EDU_LEVEL%", eduLevel)}`;
	return axios.get(URL);
};

const clientTokenExchange = data => {
	const URL = config.oauthBaseUrl + url.LOGIN_TOKEN_EXCHANGE;
	return axios.post(URL, data);
};

export const getLayoutVersion = userId => {
	const URL = `${url.LAYOUT_VERSION.replace("%USER_ID%", userId)}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const updateLayoutVersion = () => {
	const URL = url.ENABLE_LAYOUT_VERSION;
	return axios.post(URL, {
		headers: getCommonHeader(),
	});
};

export const skillRecommendations = (
	entityAttributes = [],
	currentSelectedSkills = [],
	currentSuggestedSkills = [],
	skillSelectedFrom = "suggested"
) => {
	const data = {
		entityAttrs: entityAttributes,
		selectedSkills: currentSelectedSkills,
		currentSuggestions: currentSuggestedSkills,
		selectedFrom: skillSelectedFrom || "suggested",
		maxSuggestionRequired: 10,
	};
	const URL = url.SKILL_RECOMMENDATION;
	return axios.post(URL, data);
};

export const getFuzzySkillSearch = (params = "") => {
	const URL = `${url.SKILL_SEARCH_SUGGESTIONS}${params}`;
	return axios.get(URL);
};
export const getIndustriesList = () => {
	const URL = url.INDUSTRIES_LIST;
	return axios.get(URL);
};
export const updateJoyrideRendered = orgId => {
	const URL = `${url.UPDATE_JOY_RIDE.replace("%ORG_ID%", orgId)}`;
	return axios.post(URL, orgId);
};

export const getEligiblePromotionalUserCoupons = userId => {
	const URL = `${url.GET_PROMOTIONAL_USER_COUPONS.replace("%USER_ID%", userId)}`;
	return axios.get(URL);
};
export const sendEmployerFeedback = (ratedValue, feedbackText, isFeedback) => {
	let data;
	if (!isFeedback) {
		data = {
			rating: ratedValue,
		};
	} else {
		data = {
			feedback: feedbackText,
		};
	}
	const URL = url.SEND_EMPLOYER_FEEDBACK;
	return axios.post(URL, data);
};

export const getAutoRenewDetails = jobId => {
	const URL = `${url.AUTO_RENEW}${jobId}`;
	return axios.get(URL);
};

export const getSuggestedIndustries = orgId => {
	const URL = `${url.SUGGESTED_INDUSTRIES.replace("%ORG_ID%", orgId)}`;
	return axios.get(URL);
};
export const getAllUserEligbleCoupons = ({ userId, pageNumber }) => {
	const params = {
		source: "EMDASH",
		page: pageNumber,
		discount_type: "percent",
		sort_on: "cart_constraints.min_value",
		sort_order: "asc",
	};
	const URL = `${url.ALL_ELIGIBLE_USER_COUPONS.replace("%USER_ID%", userId)}`;

	return axios.get(URL, { headers: getCommonHeader(), params });
};

export const getCityAreaDetails = (lat, lng) => {
	const URL = `${url.GET_CITY_AREA_DETAILS.replace("%LAT%", lat).replace(
		"%LNG%",
		lng
	)}`;
	return axios.get(URL);
};

export const getUserSavedAddresses = (userId, locationType, location = "") => {
	const URL = `${url.GET_USER_SAVED_ADDRESSES.replace("%USER_ID%", userId)
		.replace("%LOC_TYPE%", locationType)
		.replace("%LOCATION%", location)}`;

	return axios.get(URL);
};

export const getSectorIndustry = () => {
	const URL = `${url.GET_SECTOR_INDUSTRY}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const getEnterpriseUserProfile = (email, workspaceId) => {
	const URL = `${url.ENTERPRISE_USER_PROFILE}`;

	let params = {};

	if (email) {
		params = {
			...params,
			email: encodeURI(email?.toLowerCase()),
		};
	}
	if (workspaceId) {
		params = {
			...params,
			workspace_id: workspaceId,
		};
	}

	return enterpriseAxios.get(URL, {
		params,
		headers: getCommonHeader(),
	});
};

export const getMemberProfileData = workspaceId => {
	const URL = `${url.MEMBER_PROFILE_DATA.replace(
		"%WORKSPACE_ID%",
		workspaceId
	)}?hide_coins=true`;
	return enterpriseAxios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const getOrganizationInfo = organisationId => {
	const URL = `${url.ORGANIZATION_INFO}`.replace("%ORG_ID%", organisationId);
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const updateUserProfile = ({ workspaceId, payload }) => {
	const URL = `${url.MEMBER_PROFILE_DATA.replace("%WORKSPACE_ID%", workspaceId)}`;

	return enterpriseAxios.patch(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getUsageList = (workspaceId, keyword = "") => {
	let URL = `${url.VIEW_USAGE.replace("%WORKSPACE_ID%", workspaceId)}`;
	if (keyword) {
		URL += `?keyword=${keyword}`;
	}
	return enterpriseAxios.get(URL, {
		headers: getCommonHeader(),
	});
};

const changeCoinLimit = (workspaceId, payload = {}) => {
	const URL = `${url.CHANGE_LIMIT.replace("%WORKSPACE_ID%", workspaceId)}`;
	return enterpriseAxios.patch(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getEnterpriseTransactions = (workspaceId, pageNo = 1, itemsPerPage = 10, type) => {
	let URL = `${url.ENTERPRISE_TRANSACTIONS.replace("%WORKSPACE_ID%", workspaceId)}`;
	URL = `${URL}?page=${pageNo}&limit=${itemsPerPage}&sort_on=timestamp&sort_order=desc`;
	if (type !== "all") {
		URL += `&type=${type}`;
	}

	return enterpriseAxios.get(URL, {
		headers: getCommonHeader(),
	});
};

// buy coins flow
const enterpriseInitiateBuyCoins = (payload = {}, workspaceId) => {
	const URL = `${url.ENTERPRISE_ORDER.replace("%WORKSPACE_ID%", workspaceId)}`;
	return enterpriseAxios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const enterpriseGeneratePaymentOrder = (payload = {}, workspaceId, orderId) => {
	let URL = `${url.ENTERPRISE_ORDER.replace("%WORKSPACE_ID%", workspaceId)}`;
	URL = `${URL}/${orderId}/place`;
	return enterpriseAxios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

const enterpriseConfirmOrderPayment = (payload = {}, orderId, workspaceId) => {
	let URL = `${url.ENTERPRISE_ORDER.replace("%WORKSPACE_ID%", workspaceId)}`;
	URL = `${URL}/${orderId}/validate`;
	return enterpriseAxios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

export const enterpriseValidateRetryPayment = (orderId, workspaceId) => {
	let URL = `${url.ENTERPRISE_ORDER.replace("%WORKSPACE_ID%", workspaceId)}`;
	URL = `${URL}/${orderId}/validate-order-retry-payment`;

	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const enterpriseCheckCouponCode = (payload = {}) => {
	const URL = `${url.CHECK_COUPON_URL}`;
	return enterpriseAxios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

// invite
export const inviteMemberToWorkspace = (workspaceId, payload) => {
	const PATH = `${url.INVITE_MEMBER.replace("%WORKSPACE_ID%", workspaceId)}`;
	const URL = config.enterpriseBaseUrl + PATH;

	const {
		name = "",
		email = "",
		additional_roles: additionalRoles,
		coinsLimit = 0,
		role = "",
		reportingManagerId = "",
	} = payload;

	return enterpriseAxios.post(URL, {
		headers: getCommonHeader(),
		name,
		email,
		additional_roles: [...additionalRoles],
		role,
		coins_usage_limit: coinsLimit,
		reporting_manager_id: reportingManagerId,
	});
};

export const editMemberAccessInWorkspace = (workspaceId, memberId, payload) => {
	const PATH = `${url.EDIT_MEMBER_ACCESS.replace(
		"%WORKSPACE_ID%",
		workspaceId
	)}${memberId}`;

	const {
		additional_roles: additionalRoles,
		coinsLimit = 0,
		role = "",
		reportingManagerId = "",
		newReportingManagerId = "",
	} = payload;

	let params = {};

	if (coinsLimit) {
		params = { ...params, coins_usage_limit: coinsLimit };
	}

	if (role) {
		params = { ...params, role };
	}

	if (additionalRoles?.length) {
		params = { ...params, additional_roles: [...additionalRoles] };
	}

	if (reportingManagerId) {
		params = { ...params, reporting_manager_id: reportingManagerId };
	}

	if (newReportingManagerId) {
		params = { ...params, new_reporting_manager_id: newReportingManagerId };
	}

	const URL = config.enterpriseBaseUrl + PATH;

	return enterpriseAxios.patch(URL, {
		headers: getCommonHeader(),
		...params,
	});
};

export const getTeamMemberDetails = (workspaceId, memberId) => {
	const PATH = `${url.INVITE_MEMBER.replace(
		"%WORKSPACE_ID%",
		workspaceId
	)}/${memberId}/details?hide_team_members=true`;
	const URL = config.enterpriseBaseUrl + PATH;

	return enterpriseAxios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const getManagersList = (workspaceId, memberId, keyWord) => {
	let PATH = `${url.INVITE_MEMBER.replace(
		"%WORKSPACE_ID%",
		workspaceId
	)}/${memberId}/reporting-manager-options`;

	if (keyWord) {
		PATH += `?keyword=${keyWord}`;
	}

	const URL = config.enterpriseBaseUrl + PATH;

	return enterpriseAxios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const deleteMemberFromWorkspace = (
	workspaceId,
	memberId,
	newMemberId = "",
	newReportingManagerId = ""
) => {
	const PATH = `${url.INVITE_MEMBER.replace(
		"%WORKSPACE_ID%",
		workspaceId
	)}/${memberId}/delete `;
	const URL = config.enterpriseBaseUrl + PATH;

	let payload = {};

	if (newMemberId) {
		payload = {
			new_member_id: newMemberId,
		};
	}

	if (newReportingManagerId) {
		payload = {
			...payload,
			new_reporting_manager_id: newReportingManagerId,
		};
	}

	return enterpriseAxios.post(URL, {
		headers: getCommonHeader(),
		...payload,
	});
};

export const getTeamMembersList = (
	workspaceId,
	{ searchKeyWord = "", page = 1, pageLimit = 0, status = null, role = "" }
) => {
	let PATH = `${url.TEAM_MEMBERS_LIST.replace(
		"%WORKSPACE_ID%",
		workspaceId
	)}?page=${page}`;

	if (pageLimit) {
		PATH += `&limit=${pageLimit}`;
	}

	if (searchKeyWord) {
		PATH += `&keyword=${searchKeyWord}`;
	}

	if (status) {
		PATH += `&status=${status}`;
	}

	if (role) {
		PATH += `&role=${role}`;
	}

	const URL = config.enterpriseBaseUrl + PATH;

	return enterpriseAxios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const resendInviteToWorkspace = (workspaceId, memberId) => {
	const PATH = `${url.INVITE_MEMBER.replace(
		"%WORKSPACE_ID%",
		workspaceId
	)}/${memberId}/invite`;
	const URL = config.enterpriseBaseUrl + PATH;

	return enterpriseAxios.post(URL, {
		headers: getCommonHeader(),
	});
};

export const generateEnterpriseJobsReports = ({ workspaceId, startDate, endDate }) => {
	const URL = `${url.JOB_REPORTS.replace("%WORKSPACE_ID%", workspaceId)}`;
	const data = {
		start_time: startDate,
		end_time: endDate,
	};

	return enterpriseAxios.post(URL, data, {
		headers: getCommonHeader(),
	});
};
export const sendEnterpriseLoginOtp = params => {
	const URL = config.baseUrl + url.SEND_ENTERPRISE_LOGIN_OTP;
	return axios.post(URL, params);
};

export const enterpriseFirstTimeLogin = data => {
	const PATH = `${url.ENTERPRISE_FIRST_TIME_LOGIN}`;
	const URL = config.baseUrl + PATH;
	return axios.post(URL, data);
};

export const enterpriseForgotPassword = email => {
	const PATH = `${url.ENTERPRISE_FORGOT_PASSWORD.replace("%EMAIL_ID%", email)}`;
	const URL = config.baseUrl + PATH;
	return axios.post(URL);
};

export const enterpriseUpdatePassword = data => {
	const PATH = `${url.ENTERPRISE_UPDATE_PASSWORD}`;
	const URL = config.baseUrl + PATH;
	return axios.post(URL, data);
};

export const enterpriseRegularLogin = data => {
	const PATH = `${url.ENTERPRISE_REGULAR_LOGIN}`;
	const URL = config.baseUrl + PATH;
	return axios.post(URL, data);
};

export const getEnterpriseInviteStatus = (params = "") => {
	const URL = `${url.INVITE_STATUS}${params}`;
	return axios.get(URL);
};

export const getHRCollaboratorData = userId => {
	const PATH = `${url.HR_COLLABORATOR_DATA.replace("%ENT_USER_ID%", userId)}`;
	const URL = config.enterpriseBaseUrl + PATH;
	const data = {
		status: "active",
	};
	return enterpriseAxios.get(URL, data, {
		header: getCommonHeader(),
	});
};

export const getEnterpriseUserWorkspace = workspaceId => {
	const PATH = `${url.ENTERPRISE_WORKSPACE.replace("%WORKSPACE_ID%", workspaceId)}`;
	const URL = config.baseUrl + PATH;
	return axios.post(URL);
};

export const getEnterpriseBulkCSVInfo = workspaceId => {
	const URL = `${url.ENTERPRISE_BULK_CSV_DOWNLOAD}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params: {
			workspace_id: workspaceId,
		},
	});
};

export const getEnterpriseJobApplicationList = ({ remark, workspaceId }) => {
	const URL = `${url.ENTERPRISE_JOBS_APPLICATION}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
		params: {
			workspace_id: workspaceId,
			remark,
		},
	});
};

export const getCompanyProfile = () => {
	const URL = url.GET_COMPANY_PROFILE;
	return axios.get(URL);
};

export const postSuggestReportCompanyProfile = data => {
	const URL = config.baseUrl + url.POST_SUGGEST_REPORT_COMPANY_PROFILE;
	return axios.post(URL, data);
};

export const postUploadLogo = data => {
	const URL = config.baseUrl + url.POST_UPLOAD_LOGO;
	return axios.post(URL, data);
};
export const updateEnterpriseMigrationNudge = () => {
	const URL = url.ENTERPRISE_UPDATE_MIGRATION_NUDGES;
	return axios.post(URL);
};

export const getNormalizedStandardTitle = (params = "") => {
	const URL = `${url.STANDARD_TITLE_NORMALIZER}${params}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
export const getNeighbourNode = (params = {}) => {
	const URL = `${config.accountsUrl}${url.GET_NEIGHBOUR_NODE}`;
	return axios.post(URL, params);
};

export const checkPPJJobPostingElligibility = (userCity = "") => {
	const params = new URLSearchParams({
		...(userCity ? { user_city: userCity?.toLowerCase() } : {}),
		org_check: true,
	});
	return axios.get(url.GET_USER_ELLIGIBILITY, {
		headers: getCommonHeader(),
		params,
	});
};

export const getPricingContract = ({ entityType, entityId }) => {
	const URL = `${url.GET_PRICING_CONTRACT}${entityType}/${entityId}`;
	return axios.get(URL);
};

// get team structure data
export const getTeamStructure = (workspaceId, memberId) => {
	const URL = `${url.TEAM_STRUCTURE.replace("%WORKSPACE_ID%", workspaceId).replace(
		"%ENTERPRISE_MEMBER_ID%",
		memberId
	)}`;
	return enterpriseAxios.get(URL);
};

// Coins & usage
export const getCoinsUsage = (workspaceId, days) => {
	let URL = `${url.COINS_AND_USAGE.replace("%WORKSPACE_ID%", workspaceId)}team-usage`;

	if (days) {
		URL = `${URL}?usage_days=${days}`;
	}
	return enterpriseAxios.get(URL);
};

export const getCoinsSummary = workspaceId => {
	const URL = `${url.COINS_AND_USAGE.replace(
		"%WORKSPACE_ID%",
		workspaceId
	)}summary/?club_by_expiry=true`;
	return enterpriseAxios.get(URL);
};

export const updateCoinsLimit = (workspaceId, payload) => {
	const URL = `${url.COINS_AND_USAGE.replace("%WORKSPACE_ID%", workspaceId)}limit`;
	return enterpriseAxios.patch(URL, payload, {
		headers: getCommonHeader(),
	});
};

const getAllPackagesPurchaseDetails = (
	coins = "",
	source = TYPES_OF_DASHBOARDS.EMDASH
) => {
	const payload = {
		source,
	};
	const URL = `${config.baseUrl +
		url.PACKAGES_PURCHASE_DETAILS}${coins}&source=${source}`;
	return axios.get(URL, payload, {
		headers: getCommonHeader(),
		...payload,
	});
};

const getBuyCoins3AllPackages = () => {
	const URL = `${config.baseUrl + url.PACKAGES}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

const getApnaPricingPlans = id => {
	const URL = `${url.APNA_PRODUCTS_URL}/prices/user/${id}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
export const getTransferCoinsCheck = ({ orgId, externalOrgId }) => {
	const URL = `${url.CHECK_TRANSFER_COINS.replace("%ORG_ID%", orgId)}${externalOrgId}`;
	return axios.get(URL);
};

export const getOTPForVerificationFlow = (jobId, phoneNumber) => {
	const URL = `${url.SEND_OTP.replace("%JOB_ID%", jobId)}`;
	const data = { phone_number: phoneNumber };
	return axios.post(URL, data);
};

export const verifyOTPForVerificationFlow = (jobId, phoneNumber, otp) => {
	const URL = `${url.VERIFY_OTP.replace("%JOB_ID%", jobId)}`;
	const data = { phone_number: phoneNumber, otp };
	return axios.post(URL, data);
};
export const getContractOrderLinkDetails = orderId => {
	const URL = `${url.CONTRACT_ORDER_DETAILS.replace("%ORDER_ID%", orderId)}`;
	return axios.get(URL);
};
export const getDropoffModalDetails = (params = "") => {
	const URL = `${url.DROPOFF_MODAL_DETAILS}${params}`;
	return axios.get(URL);
};

export const getJobTitleNudgeDetails = (params = "") => {
	const URL = `${url.JOB_TITLE_NUDGE}${params}`;
	return axios.get(URL);
};

export const getDiscountCouponDetails = userId => {
	const URL = `${url.DISCOUNT_COUPON_DETAILS.replace("%USER_ID%", userId)}`;
	return axios.get(URL);
};
export const reportAccountManagerIssue = ({ payload }) => {
	const URL = `${url.REPORT_ACCOUNT_MANAGER_ISSUE}`;
	return axios.post(URL, { ...payload });
};

export const checkMergeExpressCheckoutSelectPlanExperiment = () => {
	const URL = `${url.MERGE_SELECT_PLAN_EXPRESS_CHECKOUT_COHORT}`;
	return axios.get(URL);
};
export const getWorkspaceDetails = (workspaceId, token) => {
	const URL = `${url.WORKSPACE_DETAILS}?workspace_id=${workspaceId}&token=${token}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};

export const sendVerifcationLink = payload => {
	const URL = url.SEND_VERIFICATION_LINK;
	return axios.post(URL, payload, {
		headers: getCommonHeader(),
	});
};

export const informContactUs = () => {
	const URL = `${url.CONTACT_US}`;
	return axios.post(URL);
};

export const getWorkspaceList = () => {
	const { GET_WORKSPACE_LIST } = url;
	const payload = {};
	try {
		const email = getUserEnterpriseEmail();
		if (!Number(CommonUtils.getItemFromLocalStorage(LOGIN_VIA_OTP) || 0)) {
			payload.email = email;
		}
	} catch (e) {
		console.error(e);
	}
	return axios.post(GET_WORKSPACE_LIST, payload);
};

export const getUpdateRequirementsData = jobId => {
	const URL = `${url.UPDATE_REQUIREMENTS_FULFILMENT}?job_id=${jobId}`;
	return axios.get(URL, {
		headers: getCommonHeader(),
	});
};
export const updateFulfilmentDetails = (jobId, payload) => {
	const URL = `${url.UPDATE_FULFILMENT_REQUIREMENTS.replace("%JOB_ID%", jobId)}`;
	return axios.put(URL, payload);
};
export const bookDemo = data => {
	const PATH = `${url.BOOK_DEMO}`;
	const URL = config.baseUrl + PATH;
	return axios.post(URL, data);
};

export {
	logoutV1,
	loginV2,
	getJobs,
	getOTP,
	getOTPV2,
	getOTPV3,
	getQueryResult,
	getJobApplications,
	getJobDetail,
	getDetailsFromDatabase,
	getMatchedJobs,
	reactivateJob,
	changeJobStatus,
	getBlockedUser,
	getDepartmentData,
	getCategoryFromDepartment,
	getJobCategories,
	getEducationLevel,
	getOrganizations,
	postJob,
	editJob,
	acceptTerms,
	extendExpiry,
	getCityWiseAreas,
	getCitiesList,
	getJobsPostedCountOfHRNumber,
	updateUserPersona,
	uploadCompanyDocs,
	getUserDocs,
	getEmployerInviteText,
	sendEmployerInvite,
	updateDocs,
	updateJobApplicationFeedback,
	getJobAppliedCandidate,
	getAllQuestions,
	getAuthToken,
	getATSFunnelSteps,
	updateJobATSTemplate,
	updateEmployerECCAccess,
	getEmployerHrNumberList,
	resendHrNumVerificationLink,
	getHrNumVerificationStatus,
	sendShowPhoneNumberEvent,
	getEccPreferenceConfig,
	updateEccPreference,
	getJobsLocation,
	getPaginatedJobs,
	getCreditExpiryOptions,
	initateNumberMaskCallMobile,
	initateNumberMaskCallDesktop,
	numberMaskCallStatus,
	getCompanyDomains,
	sendEmailVerificationLink,
	getEmailVerificationStatus,
	uploadPocDoc,
	patchPocDoc,
	getPocDocs,
	updatePocVerificationStatus,
	getFuzzyJobRoles,
	fetchCandidateResumeLink,
	getCompanyPrefillData,
	fetchSalarySuggestion,
	getEmployerBanDetails,
	requestReactivation,
	acceptReactivationConsent,
	getRecruitersList,
	getUserProfile,
	getUserWorkspace,
	checkTncAgreement,
	updateTncAgreement,
	getSimilarJobs,
	accountDetails,
	validatePhoneNumber,
	getPaymentPendingInfo,
	autoRenewalChange,
	getTransactions,
	checkCouponCode,
	debitCoinsAndUpdateJobStatus,
	createAccount,
	getAllPackages,
	getAllActivationPlans,
	generatePaymentOrder,
	confirmOrderPayment,
	getApnaProducts,
	resendTransactionOTP,
	initiateBuyCoins,
	reportResume,
	fetchStateDetails,
	fetchCitiesList,
	getGstDetails,
	postGstDetails,
	getInvoice,
	getOrderDetails,
	checkEligibleForPaidPromotion,
	reportMembersAndConsultancy,
	addTeamMember,
	checkIfJobWillBePaidJob,
	getExpiryFeedbackStatus,
	triggerSupportCallRequest,
	deleteJob,
	getJobApplicationsList,
	getBulkCSVDownloadInfo,
	postVerifyCompanyVerificationEnteredCardDetails,
	getSelectedActivationPlan,
	checkForDuplicateJob,
	employerEditJob,
	getAdminMembersConfig,
	getAdminTeamMembers,
	getAdminConsultanciesTeamMembers,
	checkForFirstPurchase,
	getCoinRefundDetails,
	loginV3,
	logoutV2,
	checkForMicroCityFlag,
	clientTokenExchange,
	sendDeviceInfo,
	getSalesOrderList,
	getUsageList,
	enterpriseInitiateBuyCoins,
	enterpriseGeneratePaymentOrder,
	enterpriseConfirmOrderPayment,
	enterpriseCheckCouponCode,
	changeCoinLimit,
	getEnterpriseTransactions,
	getAllPackagesPurchaseDetails,
	getBuyCoins3AllPackages,
	getApnaPricingPlans,
};

export default axios;
