/* eslint-disable no-nested-ternary */
import styled, { createGlobalStyle, css, keyframes } from "styled-components";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import media, { mediaMaxWidth } from "../../../utils/mediaQuery";
import { Row } from "../../../styles/commonStyles";
import { CDN } from "../../../utils/constants";
import { Flex } from "../../../styles/FlexBoxStyles";

const FormFieldsWrapperSalary = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 12px;
	align-items: flex-start;
	gap: 8px;

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}

	${mediaMaxWidth.medium`
		flex-direction: column;
	`}
`;
const FormFieldsSalary = styled.div`
	width: 100%;
	display: flex;
	.salary-input-field-container {
		width: 100%;
	}
`;
const FormFieldsIncentive = styled.div`
	width: 49%;
	display: flex;
	margin-bottom: 12px;
	.salary-input-field-container {
		width: 100%;
	}

	${mediaMaxWidth.medium`
		width: 100%;
	`}
`;
const SectionHeading = styled.div`
	font-size: 20px;
	font-weight: 500;
	color: #172b4d;
	margin-bottom: ${({ mb = 20 }) => `${mb}px`};
`;

const SectionFormFieldsWrapper = styled.div`
	width: 100%;
	flex-direction: ${({ isSalaryField }) => (isSalaryField ? "column" : "row")};
	display: flex;
	align-items: flex-start;
	justify-content: ${({ jc }) => jc || "space-between"};
	flex-wrap: wrap;
	margin-bottom: ${({ mb = 36, jobLocationField, interviewAddress }) =>
		jobLocationField
			? "24px !important"
			: interviewAddress
			? "-20px!important"
			: `${mb}px`};
	margin-top: ${({ mt = 0 }) => `${mt}px`};
	padding: ${({ p = 0 }) => p || "0"};
	background-color: ${({ isHighlighted }) => (isHighlighted ? "#FFFAF0" : "inherit")};

	${({ isHighlighted }) => {
		return (
			isHighlighted &&
			css`
				margin-left: -12px;
				margin-right: -12px;
				padding: 12px;
			`
		);
	}}
`;

const SectionHeadingWrapper = styled(SectionFormFieldsWrapper)``;

const SectionFormFields = styled.div`
	width: 100%;
	max-width: ${props => (props.fullWidth ? "100%" : "50%")};
	padding-right: ${props =>
		props.fullWidth ? "0 !important" : props.minEdu ? "6px !important" : ""};
	margin: ${props => (props.margin ? props.margin : "")};
	position: ${({ customPosition = "static" }) => `${customPosition}`};
	background-color: ${({ isHighlighted }) => (isHighlighted ? "#FFFAF0" : "inherit")};

	${({ isHighlighted }) => {
		return (
			isHighlighted &&
			css`
				margin-left: -12px;
				margin-right: -12px;
				padding: 12px;
			`
		);
	}}
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
	margin-top: ${({ mt = 0 }) => `${mt}px`};

	${props =>
		props.flex &&
		css`
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
		`}

	& .salary-input-field-container, & .experience-input-field-container {
		width: 49%;
	}
	& .salary-input-field-container {
		& label {
			white-space: nowrap;
			font-size: 16px;
			&.Mui-focused,
			&.MuiFormLabel-filled {
				font-size: 17px;
			}
		}

		& .Mui-focused {
			& legend {
				width: 90% !important;
			}
		}
	}
	& .amount-input-field-container {
		display: block;

		& .deposit-amount-input {
			display: block;
		}
	}
	${mediaMaxWidth.small`
		max-width: 100%;
	`};

	& .MuiAutocomplete-option {
		${mediaMaxWidth.small`
		min-height: 32px;
		padding: 0 16px;
	`};
	}
	.anchor-for-dropdowns {
		position: absolute;
		width: 100% !important;
		display: flex;
		z-index: 99;
	}

	${props =>
		props.override &&
		css`
			margin-bottom: ${({ mb = 0 }) => `${mb}px`} !important;
		`}
`;

const WrappedChipsContainer = styled(SectionFormFields)`
	background: #f4f5f7;
	border-radius: 8px;
	padding: 12px 8px;
	margin-top: 12px;
	z-index: 1;
	.suggested-title-heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		display: flex;
		align-items: center;
		color: #5e6c84;
		margin-bottom: 12px;
	}
`;

const ShowMoreCta = styled.div`
	text-decoration-line: underline;
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #0074e8;
	display: inline-flex;
	height: 32px;
	align-items: center;
	position: relative;
	top: -6px;
	cursor: pointer;
`;
const FormFieldsWrapper = styled.div`
	display: grid;
	display: -ms-grid;
	grid-template-columns: 1fr 1fr;
	-ms-grid-columns: 1fr 1fr;
	grid-row-gap: 24px;
	grid-column-gap: 12px;
	margin-bottom: ${({ mb = 24 }) => `${mb}px`};

	${mediaMaxWidth.small`
		grid-template-columns: 1fr;
		-ms-grid-columns: 1fr;
	`}
`;

const SectionSubHeading = styled.div`
	font-size: ${props => (props.isMobile ? "14px" : "16px")};
	line-height: 24px;
	font-weight: 500;
	color: #373737;
	margin-top: ${({ mt = 20 }) => `${mt}px`};
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
	display: ${props => (props.hideTitle ? "none" : "block")};
	gap: 10px;

	.optional-text {
		font-weight: 400;
	}

	svg {
		vertical-align: middle;
	}
`;
const SectionDescription = styled.div`
	font-size: 13px;
	color: #767676;
	line-height: normal;
`;

const SectionHelpText = styled.p`
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: ${props =>
		props.type === "warning"
			? "#BD5B00"
			: props.type === "error"
			? " #d93f4c"
			: "#7b7979"};
	margin-top: ${({ mt = 8 }) => `${mt}px`};
	text-align: ${({ align }) => align || "left"};
	display: ${props => (props.hideSubTitle ? "none" : "block")};
	margin-bottom: ${({ mb = 24 }) => `${mb}px`};
`;

const SectionTitleText = styled.p`
	font-size: 14px;
	line-height: normal;
	letter-spacing: normal;
	color: #373737;
	padding: 0px 4px;
	background: white;
	font-weight: 400;
	padding: 0px 4px;
	background: white;
	font-weight: 400;
	margin-bottom: 10px;
`;

const MinMaxSalarySeperator = styled.div`
	// height: 46px;
	width: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
`;

const JobAssessmentSection = styled.div`
	display: flex;
	position: relative;
	flex-wrap: wrap;
`;

const AssessmentChips = styled(Chip)`
	font-family: "Inter", sans-serif !important;
	font-style: normal;
	margin-right: 8px !important;
	margin-bottom: 12px !important;
	font-weight: ${props => (props.selected ? 500 : 400)};
	background-color: ${props => (props.selected ? "#EBF3FE" : "#ffffff")} !important;
	color: ${props => (props.selected ? "#2863AC" : "#172B4D")} !important;
	padding-right: ${({ pr = 12 }) => `${pr}px`};
	border: 1px solid ${props => (props.selected ? "#2863AC" : "#B3BAC5")};
	text-transform: capitalize;
	z-index: 0;
	font-size: 14px;
	.font-400 {
		font-weight: 400 !important;
	}
`;

const DegreeChips = styled.div`
	display: flex;
	border: 1px solid #2863ac;
	align-items: center;
	gap: 8.51px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	border-radius: 16px;
	padding: 6px 12px;
	background-color: #ebf3fe;
	color: #2863ac;
	cursor: pointer;
`;
const ExtraDegreeChip = styled.div`
	display: flex;
	border: 1px solid #0074e8;
	align-items: center;
	gap: 8.51px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	border-radius: 16px;
	padding: 6px 18px;
	background-color: #ffffff;
	color: #5e6c84;
	cursor: pointer;
`;
const Rows = styled(Row)`
	${mediaMaxWidth.large`
		display: none
	`};
`;

const Section = styled.div`
	font-family: "Inter", sans-serif !important;
	& > * {
		font-family: "Inter", sans-serif !important;
	}
	& ${SectionFormFieldsWrapper} {
		${SectionFormFields}:first-child {
			padding-right: 25px;
		}
	}

	& svg.places-api-location-icon {
		width: 24px;
		height: 24px;
		margin-right: 16px;
		fill: rgba(0, 0, 0, 0.7);
	}
	${mediaMaxWidth.small`
		& ${SectionFormFieldsWrapper} {
			${SectionFormFields}:first-child {
				padding-right: 0;
			}
			${SectionFormFields} .salary-input-field-container, ${SectionFormFields} .experience-input-field-container {
				width: 100%;
			}
			${SectionFormFields} .salary-input-field-container:not(:last-child), ${SectionFormFields} .experience-input-field-container:not(:last-child) {
				margin-bottom: 24px;
			}
			${SectionFormFields} .number_of_openings {
				width: 100%;
			}
		}
	`};
	${mediaMaxWidth.large`
		& ${SectionFormFieldsWrapper} {
			margin-bottom: 0;
			${SectionFormFields}:first-child {
				padding-right: 0;
			}
			${SectionFormFields} {
				margin-bottom: 24px;
			}
		}
	`};
	& .MuiSvgIcon-root {
		width: 22px;
		height: 22px;
	}
`;

const WhatsappIconContainer = styled.span`
	position: absolute;
	margin-left: 5px;
`;
const HelperTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
const HintTextWrapper = styled.div`
	padding: 5px 18px 6px 8px;
	border-radius: 4px;
	background-color: #ecf6fe;
	@media screen and (min-width: 800px) {
		width: max-content;
	}
`;
const HintText = styled.div`
	font-size: 13px;
	text-align: left;
	color: #b56f3c;
`;
const ErrorText = styled.div`
	color: #f44336;
	margin-left: 14px;
	margin-right: 14px;
	font-size: 0.75rem;
	margin-top: 3px;
	text-align: left;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 400;
	line-height: 1.66;
	letter-spacing: 0.03333em;
`;

const PopularTag = styled.span`
	border-radius: 3px;
	background-color: #83bde4;
	color: white;
	font-size: 10px;
	font-weight: 600;
	padding: 0px 10px;
	border: 1px solid #d9d9d9;
	line-height: 20px;
	margin: 8px 4px 0 10px;

	@media (max-width: 768px) {
		margin: ${({ margin }) => margin || "8px 0 0 10px"};
	}
`;

const TagsContainer = styled.div`
	max-height: auto;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: ${({ degreeSection }) => (degreeSection ? "12px" : "")};
	padding-bottom: ${({ degreeSection }) => (degreeSection ? "8px" : "")};
`;

const ShowMoreContainer = styled.div`
	text-align: center;
	width: 100%;
	padding: 0px 10px 0 0;
	font-weight: 500;
	font-size: 12px;
	cursor: pointer;
	position: relative;
	top: 25px;
`;

const ShowMoreButton = styled.span`
	border-radius: 12px;
	border: solid 1px #dae0e5;
	padding: 0px 16px;
	background: #fff;
	display: flex;
	max-width: 120px;
	margin: 0 auto;
	color: #9ba9b8;
	align-items: center;
	justify-content: center;
	height: 25px;
	&:hover {
		background: #f5f7fb;
	}

	span {
		margin-right: 5px;
	}
`;

const DegreeToggleContainer = styled.div`
	margin-top: 20px;
`;

const DegreeToggleSubContainer = styled.div`
	display: flex;
	align-items: center;
`;

const DegreeTagsHeading = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #373737;
	margin-right: 10px;
`;
const ImageContainer = styled.div`
	${mediaMaxWidth.medium`
		display: none;
	`}
`;
const ImageWrapper = styled.div`
	border-radius: 4px;
	padding: 16px;
	background-color: #fff1d1;
	background-image: url(${CDN}/guaranteeguy.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right;
`;
const ImageTitle = styled.div`
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.29;
	letter-spacing: normal;
	color: #b56f3c;
	max-width: 70%;
`;

const BannerContainer = styled.a`
	height: 40px;
	border-radius: 4px;
	display: flex;
	width: 100%;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	padding-left: 20px;
`;

const HowToGetCandidatesContainer = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff1d1;
	margin-bottom: 25px;
	line-height: 1;
	padding: 6px 0;
`;

const BannerMessage = styled.div`
	flex: 0 0 60%;
	padding: 0 20px 0 12px;
	font-size: 12px;
	color: #444;
	font-weight: 500;
	line-height: 1.2;
`;

const PlayIconContainer = styled.div`
	display: flex;
	flex: 0.3;
	justify-content: flex-end;
`;

const GroupImage = styled.img`
	width: 7px;
	display: flex;
	flex: 1.3;
	margin-bottom: 2px;
`;

const IconTextContainer = styled.div``;
const IconTextWrapper = styled.div`
	display: flex;
	align-items: center;
`;
const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: ${props => (props.fe ? "flex-end" : "")};
	cursor: ${props => (props.pointer ? "pointer" : "")};
	margin-top: ${({ mt = 0 }) => `${mt}px`};
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
`;
const TextContainer = styled.div``;

const Text = styled.p`
	display: flex;
	flex: 5;
	align-items: flex-start;
	margin: 0;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.33;
	letter-spacing: normal;
	color: #b56f3c;
	padding: 8px;
`;
const LabelText = styled.p`
	font-size: 14px;
	line-height: 1.43;
	color: #6a6a6a;
	margin: 0 0 0 15px;
`;

const FeeDepositRemarkWrapper = styled.div`
	grid-column: 1/-1;
	-ms-grid-column-span: 1/-1;
`;

const EccPreferenceMessageContainer = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff1d1;
	width: 100%;
	margin-bottom: 16px;
	align-items: center;
	padding: 12px;

	& div {
		justify-content: flex-end;
	}
`;

const EccPreferenceMessage = styled.div`
	flex: 0 0 50%;
	font-size: 12px;
	line-height: 1.3;
`;

const EccPreferenceText = styled.p`
	color: #444;
	font-weight: ${({ fontWeight = 400 }) => `${fontWeight}`};
	margin-bottom: 0;
`;

const SeeHowItWorksMessage = styled.div`
	flex: 0 0 48%;
`;

const DuplicateJobHelpText = styled.p`
	padding: 4px 6px;
	margin-top: 8px;
	display: flex;
	align-items: center;
	background-color: rgba(159, 210, 244, 0.2);
	border-radius: 4px;

	font-size: 12px;
	line-height: 133%;
	letter-spacing: 0.002em;
	color: #31445a;
	margin-bottom: 0px;
	svg {
		margin: 0 8px;
		transform: rotate(-180deg) scale(1.6);
	}
`;

const SectionHelpContainer = styled.div`
	width: 100%;
`;

const WarningBanner = styled.div`
	display: flex;
	background: #fff6e0;
	border: 1px solid #ffd166;
	border-radius: 4px;
	padding: 12px;
	margin-bottom: ${({ mb = 32 }) => `${mb}px`};
	margin-top: ${({ mt = 0 }) => `${mt}px`};
	column-gap: 12px;
	width: 100%;
	.description {
		display: inline;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.0025em;
		color: #172b4d;
		@media (max-width: 768px) {
			font-size: 12px;
		}
	}
	.closeIconDeposit {
		cursor: pointer;
	}
	svg {
		flex-shrink: 0;
	}
`;

const CodeOfConductCTA = styled.a.attrs(() => ({
	href: "https://apna.co/employer-code-of-conduct",
	target: "_blank",
}))`
	color: #388bf2;
	text-decoration: underline;
	cursor: pointer;
	white-space: nowrap;
	background: none;
	border: none;
	padding: 0;
`;
const Checkbox = styled.input.attrs({ type: "checkbox" })`
	width: 15px;
	height: 15px;
	margin: 0px;
	cursor: pointer;
`;

const JobPostColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ gap = 16 }) => `${gap}px`};
	width: 100%;
`;

const RadioGroupContainer = styled.div`
	display: flex;
	gap: 16px;

	& .MuiFormControlLabel-root {
		margin-left: 0px;
	}

	${mediaMaxWidth.medium`
		flex-direction: column;
		align-items: center;
	`}
`;

const InterviewAddressTextContainer = styled.div`
	background-color: #f4f5f7;
	padding: 6px 8px;
	min-width: 200px;
	margin-bottom: 5px;
`;

const DescriptionSubTextContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ShowGSTError = styled.div`
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 133%;
	letter-spacing: 0.0025em;
	color: #d93f4c;
	margin-top: 4px;
	text-transform: capitalize;

	svg {
		margin-right: 2px;
		transform: rotate(180deg);
	}
`;

const AdditionalSalaryPerksContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: auto;
	gap: 12px;
	background-color: ${({ isHighlighted }) => (isHighlighted ? "#FFFAF0" : "inherit")};
	width: 100%;
	${({ isHighlighted }) => {
		return (
			isHighlighted &&
			css`
				margin-left: -12px;
				margin-right: -12px;
				padding: 12px;
			`
		);
	}};
`;

const AdditonalPerkChipsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 12px;
	margin-bottom: 0px;
`;

const AdditionalPerkChips = styled(Chip)`
	font-family: "Inter", sans-serif !important;
	font-style: normal;
	font-weight: ${props => (props.selected ? 500 : 400)};
	background-color: ${props => (props.selected ? "#EBF3FE" : "#ffffff")} !important;
	color: ${props => (props.selected ? "#2863AC" : "#172B4D")} !important;
	border: 1px solid ${props => (props.selected ? "#2863AC" : "#B3BAC5")};
	font-size: ${props => (props.isMobile ? "12px" : "14px")};
	text-transform: capitalize;
	padding-right: 14px;
	& .MuiChip-label {
		padding: 0 10px;
	}
`;

const PerkTextField = styled.input`
	padding: 10px;
	padding-left: 12px;
	padding-right: 50px;
	width: 320px;
	height: 32px;
	font-weight: 400;
	font-size: 14px;
	background: #ffffff;
	border: 1px solid #172b4d;
	border-radius: 24px;
	color: #172b4d;
	margin-top: 5px;
	&:focus {
		outline: none;
		border-color: "#4d3951";
	}
`;

const PerkAddButton = styled.input`
	margin-left: -55px;
	width: 40px;
	height: 20px;
	font-weight: 500;
	font-size: 14px;
	color: #1f8268;
	background: #ffffff;
	border: 0;
	overflow: hidden;
	cursor: pointer;
	margin-top: 7px;
	border-radius: 24px;
`;

const SalaryBreakdownModalTable = styled.div`
	background-color: #fff2eb;
	box-sizing: border-box;
	border: 1px solid #fed9c3;
	border-radius: 8px;
`;

const SalaryBreakdownModalHr = styled.div`
	height: 0px;
	top: 96px;
	border: 1px solid #fed9c3;
	justify-self: center;
	margin: 10px 15px 0px;
`;

// TODO: Take understanding from Aashwin G about position
const SalaryBreakdownModalTitleRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.heading {
		font-family: "Inter", sans-serif !important;
		font-weight: 500;
		font-size: 20px;
		color: #172b4d;
		text-align: center;
	}
	.closebutton {
		all: unset;
		height: fit-content;
		cursor: pointer;
	}
`;

const SalaryBreakdownModalParagraph = styled.div`
	height: 40px;
	font-family: "Inter", sans-serif !important;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #31445a;

	${mediaMaxWidth.medium`
		height: 55px;
	`}
`;

const ModalRow = styled.div`
	font-size: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	gap: 8px;
	.description {
		padding-left: 16px;
		padding-top: 18px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.0025em;
		color: #31445a;
	}
	.amount {
		padding: 0px 16px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #31445a;
	}
`;

const ModalPopover = styled.div`
	padding: 20px;
	width: 400px;
	display: flex;
	flex-direction: column;
	gap: 8px;

	${mediaMaxWidth.medium`
		width: auto;
	`}
`;

const ModalPopoverForTags = styled(ModalPopover)`
	padding: 24px;
`;

const TopTwoRows = styled.div`
	margin-bottom: 10px;
`;
const BottomRow = styled.div`
	margin-bottom: 8px;
`;

const SectionContainer = styled.div`
	border-top: 1px solid #dfe1e6;
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	font-family: "Inter", sans-serif !important;
	& > * {
		font-family: "Inter", sans-serif !important;
	}
`;

export const GlobalStylesForHidingHelpIcon = createGlobalStyle`
	${mediaMaxWidth.large`
		.faq-bot-button  {
			display: none !important; 
		}
	`}

	${props =>
		props.verification &&
		css`
			.faq-bot-button {
				display: none !important;
			}
		`}
	${props =>
		props.onboarding &&
		css`
			.faq-bot-button {
				display: none !important;
			}
			.flyy-launcher-button {
				display: none !important;
			}
		`}
`;

const CitySubCityDropDownContainer = styled.div`
	padding: 6px 0px;
	margin-left: 16px;
	width: 100%;
	display: flex;

	.subcitySpan {
		width: 16px;
		background-color: #fff;
	}
	.nameContainer {
		flex: 1;
	}
	${({ isSubCity }) => {
		return (
			isSubCity &&
			css`
				padding: 0px;
				width: 100%;
				margin-left: 0px;
				.nameContainer:hover {
					background-color: #f4f5f7;
				}
				&:hover {
					background-color: #fff;
				}
				.nameContainer {
					padding: 8px 24px 8px;
				}
				.subcitySpan {
					border-right: 1px solid #b3bac5;
				}
			`
		);
	}}
`;
const PopularTag1 = styled.span`
	background-color: #eaf8f4;
	color: #005f3e;
	font-size: 12px;
	padding: 2px 8px;
	line-height: 16px;
	border-radius: 16px;
	font-weight: 500;
	display: inline-flex;
	align-items: center;
	svg {
		padding-right: 3px;
	}
`;

const JobTagsTitle = styled.span`
	margin-right: 7px;
	font-weight: 500;
`;

const PopularTag2 = styled(PopularTag1)`
	margin-left: 0px;
	margin-bottom: 5px;
	${mediaMaxWidth.medium`
		margin-left: 0px;
		margin-bottom: 5px;
	`}
`;

const VideoContainer = styled.div`
	display: flex;
	align-items: center;

	${mediaMaxWidth.medium`
		margin-bottom: 25px;
	`}
`;
const FixedIncentiveSalaryContainer = styled.div`
	display: flex;
	width: 100%;
	margin: 0 auto;
	right: 0;
	flex-wrap: wrap;
	${mediaMaxWidth.medium`
		flex-direction: column;
	`}
`;
const SalaryBreakupHeading = styled.div`
	font-weight: 500;
	font-size: 16px;
	${mediaMaxWidth.medium`
		font-size: 14px;
	`}
	padding-bottom: 2px;
`;
const SalaryBreakupContentRow = styled.div`
	font-size: 14px;
	${mediaMaxWidth.medium`
	font-size: 12px;
`}
	font-weight: 400;
	display: flex;
	justify-content: space-between;
	padding-top: 12px;
`;
const SalaryBreakupTotal = styled.div`
	font-size: 14px;
	${mediaMaxWidth.medium`
font-size: 12px;
`}
	font-weight: 600;
	display: flex;
	justify-content: space-between;
`;
const SalaryBreakupBanner = styled.div`
	background: #fff6e0;
	border: 1px solid #ffd166;
	border-radius: 4px;
	padding: 16px;
	column-gap: 12px;
	width: 100%;
`;
const FormFieldsWrapperSalaryNew = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}
`;
const IncentiveFields = styled.div`
	display: inline-block;
	width: ${({ width = 0 }) => `${width}%`};
`;
const FixedSalaryFields = styled.div`
	display: inline-block;
	width: ${({ width = 0 }) => `${width}%`};
`;
const FormFieldsSalaryNew = styled.div`
	display: flex;
	width: 100%;
	.salary-input-field-container {
		width: 100%;
	}
	.salary-input-field-box-min {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		background-color: #ffffff;
	}
	.salary-input-field-box-max {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	.max-salary .MuiOutlinedInput-notchedOutline {
		border-radius: 0 5px 5px 0;
	}

	.min-salary .MuiOutlinedInput-notchedOutline {
		border-radius: 5px 0 0 5px;
	}
`;
const FormFieldsIncentiveNew = styled.div`
	display: inline-flex;
	width: 100%;
	vertical-align: top;
	.salary-input-field-container {
		width: 100%;
	}

	${mediaMaxWidth.medium`
		margin-bottom: 0px;
	`}
`;
const ErrorContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: ${({ mt = 4 }) => `${mt}px`};
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
	gap: 5.34px;
	width: 100%;
`;
const ErrorMessage = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.0025em;
	color: #d93f4c;
`;
const MinMaxSalarySeperatorNew = styled.div`
	background: #dfe1e6;
	padding: 0px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	border-top: 1px solid #b3bac5;
	border-bottom: 1px solid #b3bac5;
`;
const MinMaxAgeSeperatorNew = styled.div`
	background: #dfe1e6;
	padding: 0px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: flex-start;
	height: 43px;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	border-top: 1px solid #b3bac5;
	border-bottom: 1px solid #b3bac5;
	${mediaMaxWidth.medium`
		height: 45px;
	`}
`;
const AddIconContainer = styled.div`
	display: inline-flex;
	padding: 67px 0px 0px 0px;
	height: 48px;
	vertical-align: top;
	justify-content: center;
	align-items: center;
	width: 5%;
	${mediaMaxWidth.medium`
		padding: 0px;
		justify-content: flex-start;
		margin-top:-4px;
		margin-bottom: -14px;
	`}
`;
const SalaryBreakdownModalHrNew = styled.div`
	height: 0px;
	top: 96px;
	border: 1px solid #ffea92;
	justify-self: center;
	margin: 12px 0px;
	border-bottom: 0px;
	hr {
		visibility: hidden;
		clear: both;
	}
`;
const HelperText = styled.div`
	font-family: "Inter", sans-serif !important;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: ${({ color = "#5e6c84" }) => color};
	padding-left: ${({ pl = 0 }) => `${pl}px`};
	margin-top: ${({ mt = 0 }) => `${mt}px`};
`;

const SuggestedSkillsHeading = styled.div`
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #5e6c84;
	margin-top: 12px;
	margin-bottom: 12px;
`;

const PreferredSkillsBanner = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	box-sizing: border-box;
	align-items: flex-start;
	padding: 12px;
	gap: 16px;
	background: #ebf3fe;
	border: 1px solid #c1dbfb;
	border-radius: 4px;
	margin-bottom: 12px;
`;
const HeadingSubheadingContainer = styled.div``;
const HeadingText = styled.div`
	font-weight: 600;
	font-size: ${({ fs = 14 }) => `${fs}px`};
	line-height: 20px;
	color: #172b4d;
	display: ${props => (props.flex ? "flex" : "")};
	justify-content: ${props => (props.center ? "center" : "")};
	text-align: ${props => (props.textAlign ? "center" : "")};
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
`;
const SubheadingText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: ${({ color = "#5E6C84" }) => color};
	display: ${props => (props.flex ? "flex" : "")};
	justify-content: ${props => (props.center ? "center" : "")};
	margin-top: ${({ mt = 0 }) => `${mt}px`};
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
`;
const CrossIconContainer = styled.div`
	display: flex;
	padding: 0px;
	justify-self: flex-end;
`;
const CrossButton = styled.button`
	background: #ebf3fe;
	border: 0;
`;
const SelectedJobTagContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 0px;
	flex-wrap: ${({ degreeSection }) => (degreeSection ? "wrap" : "")};
	gap: ${({ degreeSection }) => (degreeSection ? "12px" : "")};
`;
const SkillErrorContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 5px;
	margin-top: 3px;
	gap: 5.34px;
	width: 100%;
`;
const MandatoryMessage = styled.div`
	display: flex;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #cc0000;
`;
const TooltipText = styled.div`
	font-weight: ${({ fw = 400 }) => fw};
	font-size: ${({ fs = 12 }) => `${fs}px`};
	line-height: 16px;
	color: ${({ color = "#5e6c84" }) => color};
	padding-top: 1px;
`;
const TextIconContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ gap = 5.33 }) => `${gap}px`};
	margin-top: ${({ mt = 0 }) => `${mt}px`};
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
`;
const SkillTagContainer = styled.div``;
const SelectedTagsContainer = styled.div`
	display: flex;
	margin-top: ${({ mt = 12 }) => `${mt}px`};
	width: 100%;
	flex-wrap: wrap;
`;
const ToolTipContainer = styled.div`
	margin-top: ${({ mt = 3 }) => `${mt}px`};
	padding-right: ${({ pr = 0 }) => `${pr}px`};
	.diversity-tt-icon {
		transform: ${({ isInline }) => (isInline ? "translateY(-4px)" : "")};
	}
`;

const ToolTip = withStyles(() => ({
	tooltip: {
		borderRadius: "4px",
		backgroundColor: "#021330",
		color: "#FFF",
		maxWidth: "224px",
		fontSize: "12px",
		fontWeight: "400",
		pointerEvents: "all",
		padding: "8px",
	},
	arrow: {
		color: "#021330",
	},
}))(Tooltip);

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: "#1F8268";
    transform: scale(1.6);
  }
`;
const Beacon = styled.span`
	animation: ${pulse} 1s ease-in-out infinite;
	background-color: "#1F8268";
	border-radius: 50%;
	display: inline-block;
	height: 20px;
	width: 20px;
`;

const AgeInputSection = styled.div`
	display: flex;
	width: 100%;
	max-width: 50%;
	justify-content: flex-start;
	${mediaMaxWidth.medium`
		max-width: 100%;
	`}
`;
const AdditionalText = styled.div`
	font-weight: ${({ fw = 400 }) => fw};
	font-size: ${({ fs = 14 }) => `${fs}px`};
	line-height: 20px;
	color: ${({ color = "#0074e8" }) => color};
	cursor: ${props => (props.cursor ? "pointer" : "")};
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
	margin-top: ${({ mt = 0 }) => `${mt}px`};
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: ${props => (props.fs ? "flex-start" : "center")};
	${mediaMaxWidth.medium`
		margin-top:"16px";
	`};
`;
const OptionGroupHeading = styled.div`
	font-weight: 500;
	font-size: ${({ fs = 12 }) => `${fs}px`};
	line-height: 16px;
	color: #42526e;
	cursor: default;
	background: ${props => (props.location ? "rgba(244, 245, 247, 0.5)" : "")};
	width: 100%;
	padding: ${props => (props.location ? "10px 0px 10px 8px" : "0px 0px 10px 16px")};
	margin: ${props => (props.location ? "6px 0px 8px 0px" : "0px")};
`;
const OptionText = styled.div`
	font-weight: 400;
	font-size: ${({ fs = 14 }) => `${fs}px`};
	line-height: 20px;
	padding: ${props => (props.btmsheet ? "0px 0px 20px 16px" : "8px 0px 8px 8px")};
	color: #172b4d;
	display: flex;
	align-items: center;
	width: 100%;
	cursor: ${props => (props.pointer ? "pointer" : "")};
	.addText {
		color: #00857c;
	}
	.boldText {
		font-weight: 500;
	}
	&:hover {
		background: ${props => (props.bs ? "" : "#e0e0e0")};
	}
`;

const EnterpriseChangeCompanyNudge = styled.div`
	display: flex;
	flex-direction: row;
	background: ${({ isError }) => (isError ? "#FBECED" : "#fff6e0")};
	border: 1px solid ${({ isError }) => (isError ? "#F3C3C8" : "#ffd166")};
	border-radius: 4px;
	padding: 10px 12px;
	width: 100%;
	gap: 10px;
`;
const EnterpriseMigrationText = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #172b4d;
	.enterprise-cta {
		color: #0074e8;
		text-decoration: underline;
		padding: 0px 5px;
		cursor: pointer;
	}
	.bold-text {
		font-weight: 500;
		padding: 0px 5px;
	}
`;

const BannerCTA = styled.a`
	color: ${({ isDisabled }) => (isDisabled ? "#B3BAC5" : "#388bf2")};
	text-decoration: underline;
	cursor: pointer;
	white-space: nowrap;
	background: none;
	border: none;
	padding: 0;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	${media.medium`
		margin-left: 5px;
	`}
`;

const BackwardCompatBanner = styled(Flex)`
	background-color: ${props => (props.isFieldMissing ? "#FFF6E0" : "#EAF8F4")};
	padding: 12px;
	margin: 0 -25px 24px;
	border: ${props =>
		props.isFieldMissing ? "1px solid #FFD166" : "1px solid #BDE9DD"};
	border-radius: 4px;
	position: fixed;
	top: 0;
	opacity: 1;
	width: 100%;
	z-index: 100;
	${mediaMaxWidth.medium`
	  margin: 0 -12px 24px;
	`}
`;

const BannerBody = styled(Flex)`
	${mediaMaxWidth.medium`
		flex-wrap: wrap;
	`}
`;

const PricingFilterContainer = styled.div`
	background: ${({ isFieldMissing }) => (isFieldMissing ? "#FFFAF0" : "none")};
	width: 100%;
	padding: ${({ isFieldMissing }) => (isFieldMissing ? "16px 0px" : "0px")};
	${props =>
		props?.isNewField &&
		css`
			width: calc(100% + 24px);
			padding: 0px;
			position: relative;
			border-left-color: #d3bef1;
			border-left-width: 6px;
			border-left-style: solid;
			border-radius: 0px;
			margin-left: -24px;
			padding-left: 18px;
		`}
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 12px;
	background: #ebf3fe;
	border-radius: 4px;
	${mediaMaxWidth.medium`
		margin-bottom: 5px;
		padding: 10px 6px;
		flex-direction: column;
		justify-content: flex-start;
	`}
`;

const IconTextContainerNormalizer = styled.div`
	display: flex;
	align-items: ${props => (props.jt ? "flex-start" : "center")};
	justify-content: flex-start;
	gap: ${props => (props.jt ? "8px" : "")};
	margin-top: ${({ mt = 0 }) => `${mt}px`};
`;
const InfoIconContainer = styled.div`
	margin: 0px 5px;
	transform: translateY(3px);
	${mediaMaxWidth.medium`
		transform: translateY(0px);
	`}
`;
const BannerText = styled.div`
	font-family: "Inter", sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #172b4d;
	.normalized-jt {
		padding: 0px 3px;
		font-weight: 500;
	}
`;
const UpdateCtaContainer = styled.div`
	${mediaMaxWidth.medium`
		width: 100%;
		justify-content: flex-start;
		padding: 0px 25px;
	`}
`;
const UpdateTitleCta = styled.button`
	border: 0;
	background: #ebf3fe;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: right;
	letter-spacing: 0.0025em;
	color: #2bb792;
	cursor: pointer;
	align-items: center;
	text-align: center;
	${mediaMaxWidth.medium`
		font-size: 14px;
	`}
`;
const DropdownMenuContainer = styled.div`
	margin-top: 4px;
	min-width: 352px;
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: left;
	overflow-y: auto;
	max-height: 330px;
	height: fit-content;
	overflow-x: hidden;
	width: ${({ isNewLayout }) => (isNewLayout ? "95.7%" : "92.5%")};
`;

const MobileOnlyStickContainer = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	${mediaMaxWidth.medium`
		position: fixed;
		left: 50%;
		bottom: 16px;
		z-index: 10;
		transform: translateX(-50%);
		display: flex;
   		justify-content: center;
	`}
`;
const SectionDivider = styled.div`
	background: #f8f8f8 !important;
	height: 12px;
	width: calc(100% + 48px);
	margin-left: -24px;
`;

const SectionDivider2 = styled.div`
	background: var(--light-neutrals-neutral-200, #dfe1e6) !important;
	height: 1px;
	width: calc(100% + 48px);
	margin-left: -24px;
`;
const ChipToolTipContainer = styled.div`
	border-radius: 16px;
	display: flex;
	justify-content: space-between;
	background: #f4f5f7;
	align-items: center;
	gap: 6px;
`;

const YellowWarningBanner = styled.div`
	display: flex;
	padding: 10px 12px;
	width: 100%;
	align-items: center;
	gap: 16px;
	align-self: stretch;
	border-radius: 4px;
	border: 1px solid var(--yellow-yellow-100, #ffea92);
	background: var(--yellow-yellow-50, #fff9db);
	${mediaMaxWidth.medium`
		margin-top: 4px;
		margin-bottom: ${({ isGender }) => (isGender ? "20px" : "0px")};
		gap: 8px;
	`}
`;
const BannerTextContainer = styled.div`
	display: flex;
	color: var(--dark-neutrals-neutral-800, #172b4d);
	font-size: 14px;
	font-family: "Inter", sans-serif !important;
	line-height: 20px;
`;

const DiversityMobileHeading = styled.div`
	gap: 5px;
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
`;
const DiversityMobileText = styled.span`
	color: var(--dark-neutrals-neutral-800, #172b4d);
	font-size: 14px;
	font-family: "Inter", sans-serif !important;
	font-weight: 600;
	line-height: 20px;
	padding-right: 5px;
`;
const BannerMainContainer = styled.div`
	background: ${props => (props.bg ? props.bg : "")};
	border-radius: ${props => (!props.discount ? "4px" : "0px")};
	border: ${props => (props.border ? props.border : "")};
	display: flex;
`;
const BannerContent = styled.div`
	color: ${props => (props.discount ? "#fff" : "#172b4d")};
	font-family: "Inter", sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: flex-start;
	gap: 8px;
	padding: 10px 12px;
	.bold {
		font-weight: 600;
	}
`;

const AdditionalBoxSubheading = styled.div`
	display: flex;
	color: var(--mid-neutrals-neutral-600, #5e6c84);
	font-family: "Inter", sans-serif !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
`;

const AdditionalBoxHeadingSubheadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
`;

const OptionalFieldsChipContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 8px;
	flex-wrap: wrap;
`;

const StyledSectionHeading = styled.div`
	display: flex;
	gap: 5px;
`;
const EducationListItem = styled.span`
	color: ${props => (props.notSuggested ? "#f44336" : "#000")};
	font-size: 14px;
`;

const AgeSectionContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 20px 0px;
	gap: 4px;
`;

const HeadingAndTooltipContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
`;

const HeadingAndCrossContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

const CrossIconContainerForAdditionalSection = styled.div`
	display: flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
`;

const RelTitleTooltipContainer = styled.div`
	text-align: center;
	margin: 12px 8px 24px 16px;
	${mediaMaxWidth.medium`
		margin: 20px -2px;
	`}
`;
const ConfirmCta = styled.div`
	color: #fff;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	border-radius: 4px;
	background: #1f8268;
	display: flex;
	padding: 10px 16px;
	justify-content: center;
	align-items: center;
	width: fit-content;
	cursor: pointer;
	${mediaMaxWidth.medium`
		padding: 10px 12px;
	`};
`;
const TextfieldToolTip = withStyles(() => ({
	tooltip: {
		borderRadius: "8px",
		backgroundColor: "#021330",
		color: "#FFF",
		fontSize: "12px",
		fontWeight: "400",
		pointerEvents: "all",
		paddingright: "16px",
		minWidth: "378px",
		marginTop: "10px",
		"@media (max-width:768px)": {
			minWidth: "fit-content",
		},
	},
	arrow: {
		color: "#021330",
	},
}))(Tooltip);

module.exports = {
	Section,
	PopularTag1,
	PopularTag2,
	SectionHeading,
	SectionFormFieldsWrapper,
	SectionFormFields,
	SectionSubHeading,
	SectionDescription,
	SectionHeadingWrapper,
	Rows,
	SectionTitleText,
	AssessmentChips,
	DegreeChips,
	ExtraDegreeChip,
	JobAssessmentSection,
	SectionHelpText,
	WhatsappIconContainer,
	HelperTextWrapper,
	HintTextWrapper,
	HintText,
	ErrorText,
	PopularTag,
	TagsContainer,
	ShowMoreContainer,
	ShowMoreButton,
	ImageContainer,
	ImageWrapper,
	ImageTitle,
	BannerContainer,
	PlayIconContainer,
	GroupImage,
	IconTextContainer,
	IconTextWrapper,
	IconContainer,
	TextContainer,
	Text,
	FormFieldsWrapper,
	FeeDepositRemarkWrapper,
	LabelText,
	DegreeTagsHeading,
	DegreeToggleContainer,
	DegreeToggleSubContainer,
	HowToGetCandidatesContainer,
	BannerMessage,
	EccPreferenceMessageContainer,
	EccPreferenceMessage,
	EccPreferenceText,
	SeeHowItWorksMessage,
	DuplicateJobHelpText,
	SectionHelpContainer,
	WarningBanner,
	Checkbox,
	JobPostColumnContainer,
	RadioGroupContainer,
	InterviewAddressTextContainer,
	CodeOfConductCTA,
	DescriptionSubTextContainer,
	ShowGSTError,

	SectionContainer,
	AdditionalSalaryPerksContainer,
	AdditonalPerkChipsContainer,
	AdditionalPerkChips,
	PerkTextField,
	PerkAddButton,
	SalaryBreakdownModalTable,
	ModalRow,
	SalaryBreakdownModalHr,
	SalaryBreakdownModalTitleRow,
	SalaryBreakdownModalParagraph,
	ModalPopover,
	ModalPopoverForTags,
	TopTwoRows,
	BottomRow,
	FormFieldsWrapperSalary,
	FormFieldsSalary,
	FormFieldsIncentive,
	GlobalStylesForHidingHelpIcon,
	MinMaxSalarySeperator,
	CitySubCityDropDownContainer,
	JobTagsTitle,
	VideoContainer,
	AddIconContainer,
	FixedIncentiveSalaryContainer,
	SalaryBreakupHeading,
	SalaryBreakupContentRow,
	SalaryBreakupTotal,
	SalaryBreakupBanner,
	FormFieldsWrapperSalaryNew,
	FormFieldsSalaryNew,
	FormFieldsIncentiveNew,
	MinMaxSalarySeperatorNew,
	SalaryBreakdownModalHrNew,
	ErrorContainer,
	ErrorMessage,
	IncentiveFields,
	FixedSalaryFields,
	HelperText,
	SuggestedSkillsHeading,
	PreferredSkillsBanner,
	HeadingSubheadingContainer,
	HeadingText,
	SubheadingText,
	CrossIconContainer,
	CrossButton,
	SelectedJobTagContainer,
	SkillErrorContainer,
	MandatoryMessage,
	TooltipText,
	ToolTipContainer,
	TextIconContainer,
	ToolTip,
	SkillTagContainer,
	SelectedTagsContainer,
	Beacon,
	AgeInputSection,
	AdditionalText,
	ButtonContainer,
	OptionGroupHeading,
	OptionText,
	EnterpriseMigrationText,
	EnterpriseChangeCompanyNudge,
	BannerCTA,
	BackwardCompatBanner,
	BannerBody,
	PricingFilterContainer,
	Container,
	IconTextContainerNormalizer,
	BannerText,
	UpdateTitleCta,
	UpdateCtaContainer,
	InfoIconContainer,
	DropdownMenuContainer,
	MobileOnlyStickContainer,
	SectionDivider,
	ChipToolTipContainer,
	WrappedChipsContainer,
	ShowMoreCta,
	YellowWarningBanner,
	BannerTextContainer,
	DiversityMobileHeading,
	DiversityMobileText,
	BannerMainContainer,
	BannerContent,
	AdditionalBoxSubheading,
	AdditionalBoxHeadingSubheadingContainer,
	OptionalFieldsChipContainer,
	StyledSectionHeading,
	EducationListItem,
	MinMaxAgeSeperatorNew,
	SectionDivider2,
	AgeSectionContainer,
	HeadingAndTooltipContainer,
	HeadingAndCrossContainer,
	CrossIconContainerForAdditionalSection,
	RelTitleTooltipContainer,
	ConfirmCta,
	TextfieldToolTip,
};
