import React from "react";
import { TrueEccPreferenceOptionIcons } from "../../assets/svgs/TrueECCIcons";
import config from "../../config";
import { trackClickEvents } from "../utils/trackers";

export const NEW_CANDIDATE = "New Candidate";

export const CONNECTION_TABS = {
	NEW_CANDIDATES: "not_connected",
	CONNECTED: "connected",
	REJECTED: "rejected",
	NOT_RELEVANT: "not_relevant",
};
export const SUB_TABS = {
	NOT_RELEVANT: "not_relevant",
	REJECTED: "rejected",
};
export const CONNECTION_STATUS = {
	[CONNECTION_TABS.NEW_CANDIDATES]: "Review Pending",
	[CONNECTION_TABS.CONNECTED]: "Reviewed",
	[CONNECTION_TABS.REJECTED]: "Rejected",
	[CONNECTION_TABS.NOT_RELEVANT]: "Not Relevant",
};

export const JOB_APPLICATIONS_PAGE_SIZE = 10;

export const NUMBER_SHARED_STATUS = {
	REQUESTED: "requested",
	NOT_SHARED: "not_shared",
	SHARED: "shared",
};

export const JOB_APPLICATION_STATES = [
	{
		label: "Select status",
		value: "new_candidate",
		iconColor: "",
		bgColour: "#F4F5F7",
	},
	{
		label: "Shortlisted",
		value: "shortlisted",
		iconColor: "#1F8268",
		bgColour: "#EAF8F4",
	},
	{
		label: "Call Not Connected",
		value: "call_not_connected",
		iconColor: "#E18D00",
		bgColour: "#FFF9DB",
	},
	{
		label: "Documents/CV Pending",
		value: "document_pending",
		iconColor: "#E18D00",
		bgColour: "#FFF9DB",
	},
	{
		label: "Interview Scheduled",
		value: "interview_fixed",
		iconColor: "#1F8268",
		bgColour: "#EAF8F4",
	},
	{
		label: "Absent for Interview",
		value: "absent_for_interview",
		iconColor: "#E18D00",
		bgColour: "#FFF9DB",
	},
	{ label: "Job Offered", value: "hired", iconColor: "#0074E8", bgColour: "#EBF3FE" },
];
export const HIRING_STATUS = {
	REVIEW_PENDING: "new_candidate",
	SHORTLISTED: "shortlisted",
	CALL_NOT_CONNECTED: "call_not_connected",
	DOCUMENTS_PENDING: "documents_pending",
	INTERVIEW_FIXED: "interview_fixed",
	ABSENT_FOR_INTERVIEW: "absent_for_interview",
	JOB_OFFERED: "hired",
};

export const TRUE_ECC_CALL_DETAIL = "TRUE_ECC_CALL_DETAIL";
export const TRUE_ECC_NUM_MASK_EDU = "TRUE_ECC_NUM_MASK_EDU";

export const TECC_PREF_POPUP_SHOWN_FOR = "TECC_PREF_POPUP_SHOWN_FOR";

export const CALL_STATUS = {
	callInitiated: "call initiated",
	callEnded: "call ended",
	callDisconnected: "call disconnected",
	callFailed: "call failed",
};

export const CALL_INTERVAL = {
	pollingInterval: 5000,
	showCallFailBannerInterval: 4000,
};

export const C2C_CALL_STATUS = {
	ongoing: "INITIATED",
	answered: "ANSWER",
	disconnected: ["FAILED_LEG1", "BUSY_LEG1", "NOANSWER_LEG1"],
	failed: ["FAILED_LEG2", "BUSY_LEG2", "NOANSWER_LEG2", "CANCEL"],
};

export const CONNECTION_REMARK = {
	CALL: "call",
	WHATSAPP: "wa",
	CSV: "csv",
	REJECT: "reject",
};

export const CONNECT_REMARK_FULL = {
	DESKTOP_CALL: "desktop_call",
	DESKTOP_WHATSAPP: "desktop_wa",
	MOBILE_WEB_WHATSAPP: "mobile_web_wa",
	MOBILE_WEB_CALL: "mobile_web_call",
	CANDIDATE_CALL: "candidate_call",
	DESKTOP_CSV: "desktop_csv",
	MOBILE_CSV: "mobile_csv",
	WHATSAPP_MINI: "wa_mini",
};

export const MODAL = {
	showCallConnectStepOne: "showCallConnectStepOne",
	showConnectedOnCall: "showConnectedOnCall",
	rejectedCandidate: "rejectedCandidate",
	showCallFailedStepOne: "showCallFailedStepOne",
	connectedOnWhatsapp: "connectedOnWhatsapp",
	showDidConnectOptions: "showDidConnectOptions",
};

export const TOAST_STATUS = {
	DISCONNECTED: { value: "disconnected", label: "disconnected" },
	FAILED: { value: "failed", label: "failed" },
	REJECTED: { value: "rejected", label: "Rejected" },
	CONNECTED: { value: "connected", label: "Connected" },
	CALL_SUCCESS: { value: "call_success", label: "call_success" },
	NOT_CONNECTED: { value: "not_connected", label: "Not connected" },
	HIRING_STATUS_SUCCESS: { value: "status_updated", label: "status_updated" },
	HIRING_STATUS_FAIL: { value: "status_fail", label: "status_fail" },
	NETWORK_ERROR: { value: "network_error", label: "Something went wrong." },
	DANGER: { value: "danger" },
	SUCCESS: { value: "success" },
};

export const AUTO_HIDE_TIME = 5;

export const JOB_TABS = {
	INFO: "INFO",
	STATS: "STATS",
};
export const PREFERENCE_OPTIONS = [
	{
		key: "call+whatsapp",
		headItems: [
			{ icon: <TrueEccPreferenceOptionIcons.Call />, text: "Calls" },
			{ icon: <TrueEccPreferenceOptionIcons.Whatsapp />, text: "Whatsapp" },
		],
		items: [
			{
				available: true,
				label: "Call",
				description: "Candidates can call you (9am- 6pm)",
			},
			{
				available: true,
				label: "Whatsapp",
				description: "Apna will send all applications in a single WhatsApp chat",
			},
			{
				available: true,
				label: "Website",
				description: "Manage applications on website",
			},
		],
		data: { call: true, whatsapp: true },
		briefInfo: "Stay updated on all platforms for faster hiring",
	},
	{
		key: "whatsapp",
		headItems: [
			{ icon: <TrueEccPreferenceOptionIcons.Whatsapp />, text: "Whatsapp" },
		],
		items: [
			{
				available: false,
				label: "Call",
				description: "Candidates cannot call you directly",
			},
			{
				available: true,
				label: "Whatsapp",
				description: "Apna will send all applications in a single WhatsApp chat",
			},
			{
				available: true,
				label: "Website",
				description: "Manage applications on website",
			},
		],
		data: { call: false, whatsapp: true },
		briefInfo: "Receive updates on WhatsApp from Apna without candidate calls",
	},
	{
		key: "website",
		headItems: [
			{ icon: <TrueEccPreferenceOptionIcons.Website />, text: "Website" },
			{ icon: <TrueEccPreferenceOptionIcons.CSV />, text: "CSV Download" },
		],
		items: [
			{
				available: false,
				label: "Call",
				description: "Candidates cannot call you directly",
			},
			{
				available: false,
				label: "Whatsapp",
				description:
					"Apna or candidates will not be able to send you WhatsApp directly",
			},
			{
				available: true,
				label: "Website",
				description:
					"Manage applications on the website along with Excel download option",
			},
		],
		data: { call: false, whatsapp: false },
		briefInfo:
			"Manage applications on the website without candidate calls & WhatsApp from Apna",
	},
];

export const TABS = [
	{
		label: "Review Pending",
		value: CONNECTION_TABS.NEW_CANDIDATES,
		trackerEvent: trackClickEvents.notConnectedTab,
	},
	{
		label: "Reviewed",
		value: CONNECTION_TABS.CONNECTED,
		trackerEvent: trackClickEvents.connectedTab,
	},
	{
		label: "Not Relevant",
		value: CONNECTION_TABS.REJECTED,
		trackerEvent: trackClickEvents.rejectedTab,
	},
];

export const POPUP_DELAY = 3000;

export const DEFER_CONNECTION_UPDATE_DELAY = 1000;

export const STALE_JOBS = "STALE_JOBS";

export const NUMBER_MASKING_DISABLED = true;

export const RECENTLY_UPDATED_QUEUE_SIZE = 2;

export const CITIES_DICT = {
	SURAT: {
		id: 16,
		name: "surat",
	},
};

export const LANGUAGE_NAME = {
	speak: "Speaking",
	read: "Reading",
	write: "Writing",
};

export const LOW_RESPONSE_RATE = 15;
export const LOW_RESPONSE_SCORE = 2;

export const SORT_APPLICATION_OPTIONS = [
	{ label: "Match Score", value: "matchScore" },
	{ label: "Recent Applications", value: "appliedTime" },
	{ label: "Distance", value: "distance" },
];
export const DIRECT_CALL_ACTION = "DIRECT_CALL_ACTION";

export const TAB_AWARENESS_STAGE = {
	REVIEW_PENDING: "Review Pending",
	REVIEWED: "Reviewed",
	NOT_RELEVANT_TEMP: "Not Relevant",
	NOT_RELEVANT: "Not Relevant/Reject",
	NOT_RELEVANT_CTA: "Not Relevant CTA",
	APPLIED_CANDIDATES: "Applied Candidates",
	REJECTED: "Rejected",
};

export const TAB_AWARENESS_TEXT = {
	REVIEW_PENDING:
		"Candidates who have applied to your job but have not been reviewed yet.",
	REVIEWED: {
		FOR_CSV:
			"Please reach out to these candidates actively as they can not contact you from apna app.",
		FOR_OTHERS:
			"Once a candidate is reviewed, apna shares your contact detail with candidate for further communication. These candidate may reach out to you via WhatsApp or Call",
	},
	NOT_RELEVANT:
		"Candidates marked as 'Not Relevant' can be found here. These candidates can not reach out to you anymore from apna app. You can click on 'Restore' to connect with these candidates again.",
	NOT_RELEVANT_CTA:
		"Mark candidate as 'Not Relevant' if you don't find the candidate suitable for job. This will help apna find better suited candidates for your job posting.",
	APPLIED_CANDIDATES:
		" Find all the pending candidates not reviewed yet & ongoing/shortlisted candidates.",
	REJECTED:
		"Find all the candidates who are marked Rejected by you. These candidates can not reach out to you. You can click on ‘Restore’ to connect again.",
};

export const TAB_AWARENESS_HEADING = {
	REVIEW_PENDING: "Applications waiting to be reviewed.",
	REVIEWED: "Reviewed candidates can be found here.",
	NOT_RELEVANT: "Not relevant candidates for this job.",
	NOT_RELEVANT_CTA: "Don't like your candidate?",
	APPLIED_CANDIDATES: "Applications Ongoing",
	REJECTED: "Rejected Candidates",
};

export const TRUE_ECC_PREFERENCE = {
	CALL_AND_WHATSAPP: "Call + Whatsapp",
	WHATSAPP: "Whatsapp",
	CSV: "Website + CSV Download",
};

export const SORT_FILTERS = {
	RESUME: "resume",
	DRIVING_LICENSE: "drivingLicense",
};
export const FILTER_OPTIONS = [
	{
		value: "drivingLicense",
		label: "Driving License",
	},
	{
		value: "resume",
		label: "Resume",
	},
];

// Cities where Sort and Filter feature is to be shown
export const SORT_FILTER_CITIES_STAGE = [
	1,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	16,
	17,
	18,
	19,
	20,
	22,
	23,
	24,
	25,
	26,
	27,
	28,
	29,
	30,
	31,
	32,
	33,
	34,
	35,
	36,
	37,
	38,
	39,
	40,
	41,
	42,
	43,
	44,
	45,
	46,
	47,
	48,
	49,
	50,
	51,
	52,
	53,
	54,
	55,
	56,
	57,
	58,
	59,
	60,
	61,
	62,
	63,
	64,
	65,
	66,
	67,
	68,
	69,
	70,
	71,
	72,
	73,
	74,
	75,
	76,
	77,
	78,
	79,
	80,
	81,
	82,
];
export const SORT_FILTER_CITIES_PROD = [4];

export const SORT_FILTER_CITIES =
	config.env === "production" ? SORT_FILTER_CITIES_PROD : SORT_FILTER_CITIES_STAGE;

export const SORT_AND_FILTER_EVENTS = {
	EMPLOYER_FILTER_CLICKED: "Employer Filter Clicked",
	EMPLOYER_SORT_SUBMITTED: "Employer Sort Submitted",
	EMPLOYER_SORT_CLICKED: "Employer Sort Clicked",
	EMPLOYER_SORT_RESPONSE_RECIEVED: "Employer Sort Response Received",
	EMPLOYER_FILTER_RESPONSE_RECIEVED: "Employer Filter Response Received",
};

export const CANDIDATE_DECLARATION = "Candidate information is self-declared.";
export const UPDATE_CSV_DOWNLOAD_COMPLETED = "UPDATE_CSV_DOWNLOAD_COMPLETED";
export const CSV_DOWNLOAD_STATUS_ATTEMPTS_LIMIT = 10;
export const CSV_DOWNLOAD_STATUS_ATTEMPT_INTERVAL = 3000;
export const CSV_DOWNLOAD_OPTIONS = {
	FILTERED_CANDIDATES: "filtered_candidates",
	PRIORITY_CANDIDATES: "priority_candidates",
	ALL_CANDIDATES: "all_candidates",
};
export const CSV_DOWNLOAD_APPLICATIONS_LIMIT = 750;
export const CSV_DOWNLOAD_EVENTS = {
	CSV_DOWNLOAD_CTA_CLICK: "CSV Download CTA Click",
	CSV_DOWNLOAD_POP_UP_SHOWN: "Download CSV Pop-up shown",
	CSV_DOWNLOAD_OPTION_SELECTED: "CSV Download Option Selected",
	CSV_DOWNLOAD_POP_UP_CLOSED: "CSV Download pop-up Closed",
	CSV_DOWNLOAD_SUBMIT_CLICKED: "CSV Download Submit Clicked",
	CSV_DOWNLOAD_SUCCESSFUL: "CSV Download Successful",
};
export const BULK_CSV_DOWNLOAD_EVENTS = {
	BULK_CSV_DOWNLOAD_CTA_CLICK: "Bulk CSV Download CTA Click",
	BULK_CSV_DOWNLOAD_POP_UP_SHOWN: "Bulk CSV Download Pop-up shown",
	BULK_CSV_DOWNLOAD_OPTION_SELECTED: "Bulk CSV Download Option Selected",
	BULK_CSV_DOWNLOAD_SUBMIT_CLICKED: "Bulk CSV Download Submit Clicked",
	BULK_CSV_DOWNLOAD_POP_UP_CLOSED: "Bulk CSV Download pop-up Closed",
	BULK_CSV_DOWNLOAD_SUCCESSFUL: "Bulk CSV Download Successful",
};
export const ENTERPRISE_BULK_CSV_DOWNLOAD_EVENTS = {
	ENTERPRISE_BULK_CSV_DOWNLOAD_CTA_CLICK: "Enterprise Bulk CSV Download CTA Click",
	ENTERPRISE_BULK_CSV_DOWNLOAD_POP_UP_SHOWN:
		"Enterprise Bulk CSV Download Pop-up shown",
	ENTERPRISE_BULK_CSV_DOWNLOAD_OPTION_SELECTED:
		"Enterprise Bulk CSV Download Option Selected",
	ENTERPRISE_BULK_CSV_DOWNLOAD_SUBMIT_CLICKED:
		"Enterprise Bulk CSV Download Submit Clicked",
	ENTERPRISE_BULK_CSV_DOWNLOAD_POP_UP_CLOSED:
		"Enterprise Bulk CSV Download pop-up Closed",
	ENTERPRISE_BULK_CSV_DOWNLOAD_SUCCESSFUL: "Enterprise Bulk CSV Download Successful",
};
export const DEFAULT_APPLIED_TIME_FILTER_VALUE = 60;
export const APPLIED_TIME_FILTER_START = "startTime";
export const APPLIED_TIME_FILTER_END = "endTime";
