import { OPEN_JOBPOSTDB_MODAL } from "../constants/types";

const initialState = {
	isDBModalOpen: false,
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	if (type === OPEN_JOBPOSTDB_MODAL) {
		return {
			isDBModalOpen: payload,
		};
	}
	return state;
};
