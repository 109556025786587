/* eslint-disable func-names */
/* eslint-disable import/prefer-default-export */
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import {
	TRUE_ECC_CALL_DETAIL,
	CALL_STATUS,
	C2C_CALL_STATUS,
	TOAST_STATUS,
	TRUE_ECC_NUM_MASK_EDU,
	RECENTLY_UPDATED_QUEUE_SIZE,
	CONNECTION_REMARK,
	TECC_PREF_POPUP_SHOWN_FOR,
	TRUE_ECC_PREFERENCE,
	SORT_FILTERS,
	DEFAULT_APPLIED_TIME_FILTER_VALUE,
	CONNECTION_TABS,
} from "../constants";
import CommonUtils from "../../utils/commonUtils";
import { GENDER_DATA } from "../../utils/constants";
import { sizes } from "../../utils/mediaQuery";
import { TAB_AWARENESS_FLOW_SHOWN } from "../constants/types";

/**
 *
 * @param {"f" | "m"} g
 */
export const getGenderText = g => GENDER_DATA[g];

export const getMessageTimeStamp = (timeStamp, format = "lll") => {
	dayjs.extend(LocalizedFormat);
	const date = dayjs(timeStamp);
	return date.format(format);
};

export const updateCallDetailInLS = callDetail => {
	CommonUtils.setItemInLocalStorage(TRUE_ECC_CALL_DETAIL, JSON.stringify(callDetail));
};

export const getCallStatus = c2cStatus => {
	if (c2cStatus === C2C_CALL_STATUS.ongoing) {
		return CALL_STATUS.callInitiated;
	}
	if (c2cStatus === C2C_CALL_STATUS.answered) {
		return CALL_STATUS.callEnded;
	}
	if (C2C_CALL_STATUS.failed.includes(c2cStatus)) {
		return CALL_STATUS.callFailed;
	}
	if (C2C_CALL_STATUS.disconnected.includes(c2cStatus)) {
		return CALL_STATUS.callDisconnected;
	}
	return CALL_STATUS.callFailed;
};

export function getToastText(toastStatus, name = "") {
	switch (toastStatus?.value) {
		case TOAST_STATUS.DISCONNECTED.value:
			return `You disconnected the call with ${name || "the candidate"}.`;
		case TOAST_STATUS.FAILED.value:
			return `Your call could not connect with ${name || "the candidate"}.`;
		case TOAST_STATUS.CALL_SUCCESS.value:
			return `You called ${name || "the candidate"} successfully.`;
		case TOAST_STATUS.HIRING_STATUS_SUCCESS.value:
			return `${name || "Candidate"} has been marked '${toastStatus?.label}'`;
		case TOAST_STATUS.HIRING_STATUS_FAIL.value:
			return "Network error. Try again.";
		case TOAST_STATUS.CONNECTED.value:
		case TOAST_STATUS.REJECTED.value:
			return `${name || "Candidate"} is moved to '${toastStatus?.label}' section.`;
		case TOAST_STATUS.NETWORK_ERROR.value:
			return toastStatus?.label;
		default:
			return toastStatus?.message || "";
	}
}

export const trueEccEnabled = (citiesList, cityId) =>
	citiesList.find(city => city?.id === cityId)?.tecc_disabled === false;

export const getNumMaskEduCount = () => {
	const count = parseInt(localStorage.getItem(TRUE_ECC_NUM_MASK_EDU), 10);
	return Number.isNaN(count) ? 0 : count;
};

export const incrementNumMaskCount = () => {
	localStorage.setItem(TRUE_ECC_NUM_MASK_EDU, getNumMaskEduCount() + 1);
};

export const shouldShowNumMaskEdu = () => getNumMaskEduCount() < 2;

export const addToQueue = (usersQueue = [], user) => {
	const existingUserIndex = usersQueue?.findIndex(
		candidate => candidate?.id === user?.id
	);
	const updatedQueue = JSON.parse(JSON.stringify(usersQueue));
	if (existingUserIndex === -1) {
		if (updatedQueue.length === RECENTLY_UPDATED_QUEUE_SIZE) {
			updatedQueue.pop();
		}
		updatedQueue.unshift(user);
	} else {
		// replace user if already exists
		updatedQueue[existingUserIndex] = user;
	}

	return updatedQueue;
};

export const sortBy = prop => {
	return function(a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		}
		if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	};
};
export const getConnectionRemark = source => {
	if (!source) return "";

	const isMobile = window.innerWidth <= sizes.medium;

	const platform = isMobile ? "mobile_web" : "desktop";

	if (source === CONNECTION_REMARK.REJECT) return platform;

	return `${platform}_${source}`;
};

export const statusActionsText = {
	getNumberSharedContent: (
		candidateName,
		username,
		nextStatus,
		connectionRemark,
		stageThreeStatus,
		tabData
	) => {
		// remove after full stage 3 implementation
		const tabLabel = tabData?.tabs && tabData?.tabs[nextStatus]?.label;
		if (stageThreeStatus)
			return {
				heading: `${candidateName ||
					"Candidate"}, ${username} will be moved to '${tabLabel}' tab - `,
				content: "Your number is shared with candidate",
				connectionRemark,
				nextStatus,
			};

		return {
			heading: "Number shared with candidate:",
			content: ` ${candidateName ||
				"Candidate"} will be moved to '${tabLabel}' tab.`,
			connectionRemark,
			nextStatus,
		};
	},
	getRestoredContent: (candidateName, username, nextStatus, isSameTab, tabData) => {
		const tabLabel = tabData?.tabs && tabData?.tabs[nextStatus]?.label;
		return {
			heading: "Candidate Restored:",
			content: `${candidateName || "Candidate"} ${
				isSameTab ? "is restored to" : "will be moved to"
			} '${tabLabel}' tab.`,
		};
	},
	getRejectedContent: (
		candidateName,
		username,
		nextStatus,
		isSameTab,
		stageThreeStatus,
		tabData
	) => {
		let status = nextStatus;
		if (nextStatus === CONNECTION_TABS.NOT_RELEVANT)
			status = CONNECTION_TABS.REJECTED;
		const tabLabel = tabData?.tabs && tabData?.tabs[status]?.label;
		if (stageThreeStatus)
			return {
				heading: `${candidateName || "Candidate"}, ${username || ""} ${
					isSameTab ? "is moved to" : "will be moved to"
				} ${tabLabel} Tab - `,
				content: "Your number is not shared with candidate",
				nextStatus,
			};

		return {
			heading: `Candidate marked as ${tabLabel}`,
			content: isSameTab
				? ` ${candidateName || "Candidate"} is moved to '${tabLabel}' tab.`
				: ` ${candidateName || "Candidate"} will be moved to '${tabLabel}' tab.`,
			nextStatus,
		};
	},
};

export const setTEccPrefrenceShownFor = jobId => {
	try {
		const data = JSON.parse(localStorage.getItem(TECC_PREF_POPUP_SHOWN_FOR));
		data.push(jobId);
		localStorage.setItem(TECC_PREF_POPUP_SHOWN_FOR, JSON.stringify(data));
	} catch (e) {
		localStorage.setItem(TECC_PREF_POPUP_SHOWN_FOR, JSON.stringify([jobId]));
	}
};

export const isTEccPrefrenceShownFor = jobId => {
	try {
		const data = JSON.parse(localStorage.getItem(TECC_PREF_POPUP_SHOWN_FOR));
		if (data.includes(jobId)) return true;
	} catch (e) {
		return false;
	}

	return false;
};

export const getEccPreferenceText = preference => {
	const { call, whatsapp } = preference || {};

	if (preference === null || (call === null && whatsapp === null)) return null;

	if (call && whatsapp) return TRUE_ECC_PREFERENCE.CALL_AND_WHATSAPP;

	if (whatsapp) return TRUE_ECC_PREFERENCE.WHATSAPP;

	return TRUE_ECC_PREFERENCE.CSV;
};

export const getAge = (dob = null) => {
	if (!dob) return null;
	const birthdate = new Date(dob);
	const currentDate = new Date();
	const diff = currentDate - birthdate; // This is the difference in milliseconds
	return Math.floor(diff / 31557600000); // Divide by 1000*60*60*24*365.25
};

export const setTabAwarenessFlowShownInLS = () => {
	localStorage.setItem(TAB_AWARENESS_FLOW_SHOWN, true);
};

export const getTabAwarenessFlowShownFromLS = () => {
	return localStorage.getItem(TAB_AWARENESS_FLOW_SHOWN);
};

export const getWhatsAppURL = (candidateDetails = {}, jobTitle = "", orgName = "") => {
	const text = `Hi ${candidateDetails?.full_name},\nI got your application for ${jobTitle} in our company - ${orgName} on Apna platform and I want to speak to you. Please send your CV on WhatsApp to me so I can go through it and call you for an interview. Thanks!`;
	const waLink =
		candidateDetails?.json_data?.candidate_wa_deeplink ||
		`http://wa.me/+91${candidateDetails?.username}`;
	const url = `${waLink}?text=${encodeURIComponent(text)}`;
	return url;
};

export const validateIsPopularFilterApplied = (key, value) => {
	switch (key) {
		case SORT_FILTERS.DRIVING_LICENSE:
			return value;
		case SORT_FILTERS.RESUME:
			return value;
		default:
			break;
	}
	return false;
};
// On input value of x number of days, this function returns current date+time(endDate) and date after subtracting input value(startDate)
// Used for dateAndTime filter
export const getStartAndEndDate = (
	defaultFilterValue = DEFAULT_APPLIED_TIME_FILTER_VALUE
) => {
	dayjs.extend(utc);
	const endTime = dayjs()
		.utc()
		.format();
	const startTimeRaw = dayjs(endTime)
		.subtract(defaultFilterValue, "day")
		.utc()
		.format();
	const dayStartString = "00:00:00Z";
	const startTime = `${startTimeRaw.split("T")[0]}T${dayStartString}`;
	return [startTime, endTime];
};

export const getFiltersInitialState = allFilters => {
	const [startTime, endTime] = getStartAndEndDate(DEFAULT_APPLIED_TIME_FILTER_VALUE);
	let filtersState = { startTime, endTime };

	Object.entries(allFilters).forEach(filter => {
		switch (filter[0]) {
			case SORT_FILTERS.RESUME:
				if (filter[1]) {
					filtersState = { ...filtersState, resume: false };
				}
				break;
			case SORT_FILTERS.DRIVING_LICENSE:
				if (filter[1]) {
					filtersState = { ...filtersState, drivingLicense: false };
				}
				break;
			default:
				break;
		}
	});
	return filtersState;
};
export const checkIsGigCategoryJob = tabData => {
	if (
		!tabData?.tabs?.not_connected &&
		tabData?.tabs?.connected &&
		tabData?.tabs?.rejected
	)
		return true;
	return false;
};
