import {
	SHOW_UPLOAD_DOC,
	HIDE_UPLOAD_DOC,
	TOGGLE_HR_NUM_VERIFICATION_MODAL,
	TOGGLE_EDIT_WARNING_MODAL,
	SHOW_JOB_VERIFICATION_MODAL,
	HIDE_JOB_VERIFICATION_MODAL,
	SHOW_POC_DOC_UPLOAD_MODAL,
	HIDE_POC_DOC_UPLOAD_MODAL,
	TOGGLE_KNOW_MORE_MODAL,
	SHOW_DELETE_JOB_MODAL,
	HIDE_DELETE_JOB_MODAL,
	TOGGLE_CSV_DOWNLOAD_POPUP,
	OPEN_TRY_NEW_UI_MODAL,
	ONBOARDING_NEW_UI_MODAL,
	OPEN_NAVIGATION_SIDE_DRAW,
	SHOW_FIRST_TIME_USER_ONBOARDING_MODAL,
	IS_NEW_LAYOUT_ENABLED,
	IS_PPJ_EDUCATIONAL_MODAL_OPEN,
	CONTRACT_PRICING_AUTO_CORRECT,
	CONTACT_SALES_MODAL,
	COIN_EXPIRY_VIEW_ALL,
	COMPANY_LOGO_MODAL,
} from "../constants/ModalTypes";
import CommonUtils from "../utils/commonUtils";
import { ENABLE_NEW_LAYOUT, NEW_USER_MODAL_STATE } from "../utils/constants";

const persistedNewUserModalState =
	JSON.parse(CommonUtils.getItemFromLocalStorage(NEW_USER_MODAL_STATE)) || {};

const persistedNewLayoutEnabled = JSON.parse(
	CommonUtils.getItemFromLocalStorage(ENABLE_NEW_LAYOUT)
);

const initialState = {
	uploadDoc: false,
	hrNumber: {
		open: false,
		number: "",
		jobId: null,
	},
	editWarning: {
		open: false,
		changedField: "",
		data: null,
	},
	jobVerificationModal: {
		show: false,
		showUpdateEmailOption: false,
	},
	pocDocUploadModal: false,
	knowMoreModal: false,
	deleteJobModal: {
		show: false,
		selectedJobId: null,
	},
	csvDownloadPopup: {
		showPopup: false,
		isDownloadSource: false,
		filename: "",
		jobId: "",
	},
	openNewUIModal: false,
	newUIOnboardingModal: {
		showLoader: false,
		showModal: false,
		hasJobs: false,
		showConfetti: false,
	},
	openNavigationSideDraw: { toggleSideDraw: false, show: false },
	firstTimeOnboardingUser: {
		showModal: false,
		isFirstTimeUser: false,
		postJobVisited: false,
		...persistedNewUserModalState,
	},
	newLayoutEnabled: persistedNewLayoutEnabled, // default to null
	isPPJEduModalOpen: false,
	contractPricingAutoCorrectModalOpen: false,
	contactSalesModal: false,
	showCoinExpiryModal: false,
	companyLogoModal: {
		show: false,
		modalType: "",
	},
};

const modal = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_UPLOAD_DOC:
			return {
				...state,
				uploadDoc: true,
			};
		case HIDE_UPLOAD_DOC:
			return {
				...state,
				uploadDoc: false,
			};
		case TOGGLE_HR_NUM_VERIFICATION_MODAL:
			return {
				...state,
				hrNumber: {
					open: !state?.hrNumber?.open,
					number: action?.hrNumber,
					jobId: action?.jobId,
				},
			};
		case TOGGLE_EDIT_WARNING_MODAL:
			return {
				...state,
				editWarning: {
					open: !state?.editWarning?.open,
					changedField:
						action?.changedField || state?.editWarning?.changedField,
					data: action?.data || state?.editWarning?.data,
				},
			};
		case SHOW_JOB_VERIFICATION_MODAL:
			return {
				...state,
				jobVerificationModal: {
					show: true,
					showUpdateEmailOption: action.showUpdateEmailOption,
				},
			};
		case HIDE_JOB_VERIFICATION_MODAL:
			return {
				...state,
				jobVerificationModal: {
					show: false,
					showUpdateEmailOption: false,
				},
			};
		case SHOW_POC_DOC_UPLOAD_MODAL:
			return {
				...state,
				pocDocUploadModal: true,
			};
		case HIDE_POC_DOC_UPLOAD_MODAL:
			return {
				...state,
				pocDocUploadModal: false,
			};
		case TOGGLE_KNOW_MORE_MODAL:
			return {
				...state,
				knowMoreModal: action.show,
			};
		case SHOW_DELETE_JOB_MODAL:
			return {
				...state,
				deleteJobModal: {
					show: true,
					selectedJobId: action?.payload?.jobId,
				},
			};
		case HIDE_DELETE_JOB_MODAL:
			return {
				...state,
				deleteJobModal: {
					show: false,
					selectedJobId: null,
				},
			};
		case TOGGLE_CSV_DOWNLOAD_POPUP: {
			return {
				...state,
				csvDownloadPopup: {
					...action?.payload,
				},
			};
		}
		case OPEN_TRY_NEW_UI_MODAL:
			return {
				...state,
				openNewUIModal: action?.payload,
			};
		case ONBOARDING_NEW_UI_MODAL:
			return {
				...state,
				newUIOnboardingModal: {
					...state?.newUIOnboardingModal,
					...action.payload,
				},
			};
		case SHOW_FIRST_TIME_USER_ONBOARDING_MODAL: {
			const newState = {
				...state?.firstTimeOnboardingUser,
				...action?.payload,
			};

			CommonUtils.setItemInLocalStorage(
				NEW_USER_MODAL_STATE,
				JSON.stringify(newState)
			);

			return {
				...state,
				firstTimeOnboardingUser: newState,
			};
		}
		case OPEN_NAVIGATION_SIDE_DRAW:
			return {
				...state,
				openNavigationSideDraw: { ...action?.payload },
			};

		case IS_NEW_LAYOUT_ENABLED: {
			const newState = action?.payload;

			CommonUtils.setItemInLocalStorage(ENABLE_NEW_LAYOUT, newState);

			return {
				...state,
				newLayoutEnabled: newState,
			};
		}

		case IS_PPJ_EDUCATIONAL_MODAL_OPEN: {
			return {
				...state,
				isPPJEduModalOpen: action?.payload,
			};
		}
		case CONTRACT_PRICING_AUTO_CORRECT: {
			return {
				...state,
				contractPricingAutoCorrectModalOpen: action?.payload,
			};
		}

		case CONTACT_SALES_MODAL: {
			return {
				...state,
				contactSalesModal: action?.payload,
			};
		}
		case COIN_EXPIRY_VIEW_ALL: {
			return {
				...state,
				showCoinExpiryModal: action?.payload,
			};
		}

		case COMPANY_LOGO_MODAL: {
			return {
				...state,
				companyLogoModal: action.payload,
			};
		}
		default:
			return state;
	}
};

export default modal;
