import React from "react";
import styled from "styled-components";
import { CDN } from "../utils/constants";

const ApnaTagCoin = ({ size, mt, mb, ml, mr }) => {
	return (
		<ApnaTagCoinContainer
			src={`${CDN}/apna_tag_coin.png`}
			alt="apnaCoin"
			size={size}
			mt={mt}
			mr={mr}
			mb={mb}
			ml={ml}
		/>
	);
};

const ApnaTagCoinContainer = styled.img`
	width: ${props => props.size || 20}px;
	height: ${props => props.size || 20}px;
	margin-top: ${({ mt = 0 }) => `${mt}px`};
	margin-bottom: ${({ mb = 0 }) => `${mb}px`};
	margin-left: ${({ ml = 0 }) => `${ml}px`};
	margin-right: ${({ mr = 0 }) => `${mr}px`};
`;

export default ApnaTagCoin;
