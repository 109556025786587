import * as React from "react";

const ElipseLoader = props => {
	const { fillColor = "#2BB792" } = props;
	return (
		<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.5 10A7.5 7.5 0 1 1 10 2.5"
				stroke={fillColor}
				strokeWidth={3}
				strokeLinecap="round"
			/>
		</svg>
	);
};

export const OrderVerificationLoader = props => {
	const { fillColor = "#2BB792", size = "54" } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 54 54"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M54 27C54 25.3431 52.6569 24 51 24C49.3431 24 48 25.3431 48 27H54ZM27 6C28.6569 6 30 4.65685 30 3C30 1.34315 28.6569 0 27 0V6ZM48 27C48 38.598 38.598 48 27 48V54C41.9117 54 54 41.9117 54 27H48ZM27 48C15.402 48 6 38.598 6 27H0C0 41.9117 12.0883 54 27 54V48ZM6 27C6 15.402 15.402 6 27 6V0C12.0883 0 0 12.0883 0 27H6Z"
				fill={fillColor}
			/>
		</svg>
	);
};

export default ElipseLoader;
