import { ELIGIBLE_FOR_REPORTS } from "../constants/types";

const initialState = {
	isEligibleForReports: null,
};

export default (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ELIGIBLE_FOR_REPORTS:
			return {
				...state,
				isEligibleForReports: payload,
			};
		default:
			return state;
	}
};
