import React from "react";
import styled from "styled-components";
import ChatIcon from "../../assets/svgs/ChatIcon";
import BriefcaseAltIcon from "../../assets/svgs/BriefcaseAltIcon";
import UsersIcon from "../../assets/svgs/UserIcon";
import media, { mediaMaxWidth } from "../../utils/mediaQuery";
import config from "../../config";
import homepageData from "../../cms/homepage-data";

const { homepage } = homepageData || {};

const data = [
	{
		id: 1,
		title: "5 Crore+",
		subTitle: "Users <br/> on apna ",
		imageLink: "cloudinary/home_page_images/1cr-jobs-image.png",
		icon: <UsersIcon />,
	},
	{
		id: 3,
		title: "50 Lakh+",
		subTitle: "Jobs posted <br/> on apna",
		imageLink: "cloudinary/home_page_images/15-lac-job-image.png",
		icon: <BriefcaseAltIcon />,
	},
	{
		id: 2,
		title: "3.5 Crore+",
		subTitle: "Interviews & Professional <br/> Conversations Last Month",
		imageLink: "cloudinary/home_page_images/80-lac-interview-image.png",
		icon: <ChatIcon />,
	},
];

const appStatsFromCms = homepage?.data.attributes?.appStats || [];

// eslint-disable-next-line no-unused-expressions
appStatsFromCms?.forEach((stat, i) => {
	data[i].title = stat?.title;
	data[i].subTitle = stat?.subTitle;
});

function PastHighlights() {
	const { IMAGE_BASE_URL } = config;
	return (
		<>
			<HighLightContainerDesktop>
				{data.map(h => {
					return (
						<HighLight key={h.id}>
							<Image
								src={`${IMAGE_BASE_URL}${h.imageLink}?w=168`}
								height={168}
								width={168}
							/>
							<HighlightContentContainer>
								<HighlightTitle>{h.title}</HighlightTitle>
								<HighLightSubtitle
									dangerouslySetInnerHTML={{ __html: h.subTitle }}
								/>
							</HighlightContentContainer>
						</HighLight>
					);
				})}
			</HighLightContainerDesktop>
			<HighLightContainerMobile>
				{data.map(h => {
					return (
						<HighLight key={h.id}>
							<IconContainer>{h.icon}</IconContainer>
							<HighlightContentContainer>
								<HighlightTitle>{h.title}</HighlightTitle>
								<HighLightSubtitle
									dangerouslySetInnerHTML={{ __html: h.subTitle }}
								/>
							</HighlightContentContainer>
						</HighLight>
					);
				})}
			</HighLightContainerMobile>
		</>
	);
}

export default PastHighlights;

const Image = styled.img`
	height: 84px;
	width: 84px;

	@media (min-width: 1024px) and (max-width: 1200px) {
		height: 64px;
		width: 64px;
	}
`;

const HighLightContainerDesktop = styled.div`
	display: none;
	max-width: 1200px;
	margin: auto;
	${media.medium`
        display: flex;
        justify-content: space-between;
    	> * + * {
            margin-left: 16px;
        }
    `}
`;

const HighLight = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	${mediaMaxWidth.medium`
		align-items: flex-start;
	`}
`;

const HighlightContentContainer = styled.div`
	margin-left: 12px;
	${mediaMaxWidth.medium`
        margin-left: 8px;
    `}
`;

const HighlightTitle = styled.h4`
	font-weight: bold;
	font-size: 18px;
    line-height: 22px;
	color: #31445a;
	margin-bottom: 0;
	${media.medium`
		min-width: 76px;
	`}
	${mediaMaxWidth.medium`
        font-size: 20px;
        line-height: 1.1;
    `}

    ${mediaMaxWidth.small`
        font-size: 16px;
        line-height: 1.1;
    `}

	${mediaMaxWidth.extraSmall`
        font-size: 12px;
        line-height: 1.1;
    `}
`;

const HighLightSubtitle = styled.p`
	font-weight: normal;
	font-size: 16px;
	line-height: 18px;
	color: #31445a;
	margin-bottom: 0;

	${mediaMaxWidth.medium`
        font-size: 11px;
    `}

    ${mediaMaxWidth.small`
        font-size: 11px;
    `}

	${mediaMaxWidth.extraSmall`
        font-size: 11px;
    `}
`;

// Mobile Styles

const IconContainer = styled.div`
	min-width: 32px;
	height: 32px;
	background: rgb(77 57 81 / 10%);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		height: 20px;
		width: 20px;
	}
`;

const HighLightContainerMobile = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	max-width: 1200px;
	margin: 36px auto;
	padding: 0 16px;
	> * + * {
		margin-left: 10px;

		${media.small`
			margin-left: 20px;
		`}
	}
	${media.medium`
        display: none;
    `}
`;
