import * as React from "react";

const InfoDescriptionIcon = ({ fillColor = "#2863AC", ...props }) => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M10 18.333A8.336 8.336 0 0 1 1.667 10C1.667 5.4 5.4 1.667 10 1.667S18.333 5.4 18.333 10 14.6 18.333 10 18.333Zm0-9.166a.836.836 0 0 0-.833.833v3.333c0 .459.375.834.833.834a.836.836 0 0 0 .833-.834V10A.836.836 0 0 0 10 9.167Zm.833-3.334H9.167V7.5h1.666V5.833Z"
			fill={fillColor}
		/>
	</svg>
);

export default InfoDescriptionIcon;
