import { UPDATE_CSV_DOWNLOAD_STATUS } from "../constants/types";

const initialState = {
	isDownloading: false,
	downloadingCSVForJobId: null,
};
export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case UPDATE_CSV_DOWNLOAD_STATUS:
			return { ...state, ...payload };
		default:
			return state;
	}
};
