/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import { CDN, SUGGESTED_BROWSERS } from "../../utils/constants";
import config from "../../config";

const DownloadBrowser = ({ icon, name, minVersion, installLink }) => {
	return (
		<Container>
			<Icon src={`${CDN}/${icon}.png`} width={36} height={36} />
			<BrowserName>{name}</BrowserName>
			<spn>
				&gt;=
				{minVersion}
			</spn>
			<InstallLink href={installLink}>Install for free</InstallLink>
		</Container>
	);
};

const BrowserNotSupported = () => {
	const { IMAGE_BASE_URL } = config;
	return (
		<Root>
			<Banner src={`${IMAGE_BASE_URL}employerDashboard_FE/error.gif`} />
			<Title>
				We are sorry but your browser is not supported. Please upgrade your
				browser below
			</Title>
			<BrowserWrapper>
				{SUGGESTED_BROWSERS.map(browser => (
					<DownloadBrowser key={browser.name} {...browser} />
				))}
			</BrowserWrapper>
		</Root>
	);
};

export default BrowserNotSupported;

const Root = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: rgb(77, 57, 81);
	font-family: "Inter", sans-serif;
`;

const Title = styled.h1`
	text-align: center;
	margin-bottom: 16px;
`;

const BrowserWrapper = styled.div``;

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	align-items: center;
`;

const Icon = styled.img`
	margin-right: 16px;
	cursor: pointer;
`;

const BrowserName = styled.p`
	margin-right: 16px;
`;

const InstallLink = styled.a`
	margin-left: 16px;
`;

const Banner = styled.img``;
