import * as React from "react";

const WorkFromHomeIcon = props => {
	const { fill = "#172B4D" } = props;
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M19.3861 7.16071C20.8082 7.16071 21.9611 6.00545 21.9611 4.58036C21.9611 3.15527 20.8082 2 19.3861 2C17.9641 2 16.8112 3.15527 16.8112 4.58036C16.8112 6.00545 17.9641 7.16071 19.3861 7.16071Z"
				fill={fill}
			/>
			<path d="M16.0389 16.4673H17.6954V18.1272H16.0389V16.4673Z" fill={fill} />
			<path
				d="M13.0239 7.43921C13.3452 7.15359 13.7587 6.99632 14.1882 6.99632C14.6176 6.99632 15.0311 7.15363 15.3524 7.43921L15.8141 7.8496V6.38331C15.8141 3.9696 13.8545 2.00592 11.4458 2.00592C9.33597 2.00592 7.57073 3.51268 7.16522 5.50878C7.82847 6.51827 8.99081 8.49152 9.48796 10.5826L13.0239 7.43921Z"
				fill={fill}
			/>
			<path
				d="M21.4152 20.8204H20.6075V15.2444L20.9882 15.5828C21.0995 15.6818 21.238 15.7304 21.376 15.7304C21.5375 15.7304 21.6982 15.6638 21.8137 15.5332C22.028 15.2911 22.0059 14.9207 21.7643 14.706L20.05 13.182V10.1449C20.05 9.82128 19.7881 9.55889 19.4652 9.55889C19.1422 9.55889 18.8803 9.82128 18.8803 10.1449V12.1423L14.576 8.31588C14.3545 8.11905 14.0212 8.11905 13.7998 8.31588L6.61153 14.7059C6.36993 14.9207 6.34782 15.2911 6.56212 15.5332C6.77646 15.7754 7.1461 15.7975 7.38767 15.5827L7.76829 15.2443V20.8204H5.88095V18.2637C6.13075 18.1872 6.37114 18.0616 6.59876 17.8893V16.8558C6.24919 16.7735 5.92958 16.5848 5.68723 16.3111C5.04532 15.5859 5.11176 14.4725 5.83536 13.8292L8.4749 11.4828C8.0306 8.64241 5.86864 5.68092 5.76653 5.5424C5.65626 5.39287 5.48173 5.30463 5.29618 5.30463C5.11063 5.30463 4.9361 5.39287 4.82583 5.5424C4.71196 5.69681 2.03762 9.35971 2.03762 12.4519C2.03762 13.9612 2.33797 15.3893 2.88336 16.4732C3.36446 17.4294 3.99973 18.0461 4.71137 18.2638V20.8204H2.58481C2.26184 20.8204 2 21.0828 2 21.4065C2 21.7301 2.26184 21.9925 2.58481 22H20.6076H21.4153C21.7382 21.9925 22.0001 21.7301 22.0001 21.4065C22.0001 21.0828 21.7382 20.8204 21.4152 20.8204ZM14.8693 15.8812C14.8693 15.5576 15.1312 15.2952 15.4541 15.2952H18.2802C18.6032 15.2952 18.865 15.5576 18.865 15.8812V18.7132C18.865 19.0368 18.6032 19.2992 18.2802 19.2992H15.4541C15.1312 19.2992 14.8693 19.0368 14.8693 18.7132V15.8812ZM11.667 20.8204V16.4672H10.4974V20.8204H9.32783V15.8812C9.32783 15.5575 9.58966 15.2952 9.91263 15.2952H12.2518C12.5748 15.2952 12.8366 15.5576 12.8366 15.8812V20.8204H11.667Z"
				fill={fill}
			/>
		</svg>
	);
};

export default WorkFromHomeIcon;
