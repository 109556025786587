import React from "react";

const PanIcon3 = () => (
	<svg
		width="14"
		height="16"
		viewBox="0 0 14 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.16732 3.83301H12.834C13.2923 3.83301 13.6673 4.20801 13.6673 4.66634C13.6673 5.12467 13.2923 5.49967 12.834 5.49967H1.16732C0.708984 5.49967 0.333984 5.12467 0.333984 4.66634C0.333984 4.20801 0.708984 3.83301 1.16732 3.83301Z"
			fill="#BD5B00"
		/>
		<path
			d="M1.16732 8.83366H12.834C13.2923 8.83366 13.6673 8.45866 13.6673 8.00033C13.6673 7.54199 13.2923 7.16699 12.834 7.16699H1.16732C0.708984 7.16699 0.333984 7.54199 0.333984 8.00033C0.333984 8.45866 0.708984 8.83366 1.16732 8.83366Z"
			fill="#BD5B00"
		/>
		<path
			d="M1.16732 12.1667H5.33398C5.79232 12.1667 6.16732 11.7917 6.16732 11.3333C6.16732 10.875 5.79232 10.5 5.33398 10.5H1.16732C0.708984 10.5 0.333984 10.875 0.333984 11.3333C0.333984 11.7917 0.708984 12.1667 1.16732 12.1667Z"
			fill="#BD5B00"
		/>
		<path
			d="M1.16732 15.4997H5.33398C5.79232 15.4997 6.16732 15.1247 6.16732 14.6663C6.16732 14.208 5.79232 13.833 5.33398 13.833H1.16732C0.708984 13.833 0.333984 14.208 0.333984 14.6663C0.333984 15.1247 0.708984 15.4997 1.16732 15.4997Z"
			fill="#BD5B00"
		/>
		<path
			d="M9.84245 13.1414L9.25078 12.5497C8.92578 12.2247 8.40078 12.2247 8.07578 12.5497C7.75078 12.8747 7.75078 13.3997 8.07578 13.7247L9.25911 14.9081C9.58411 15.2331 10.1091 15.2331 10.4341 14.9081L13.0758 12.2664C13.4008 11.9414 13.4008 11.4164 13.0758 11.0914C12.7508 10.7664 12.2258 10.7664 11.9008 11.0914L9.84245 13.1414Z"
			fill="#BD5B00"
		/>
		<path
			d="M0.333984 1.33333C0.333984 1.79167 0.708984 2.16667 1.16732 2.16667H12.834C13.2923 2.16667 13.6673 1.79167 13.6673 1.33333C13.6673 0.875 13.2923 0.5 12.834 0.5H1.16732C0.708984 0.5 0.333984 0.875 0.333984 1.33333Z"
			fill="#BD5B00"
		/>
	</svg>
);

export default PanIcon3;
