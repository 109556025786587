import React from "react";

const Icon2 = () => (
	<svg
		width="18"
		height="17"
		viewBox="0 0 18 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.6666 3.99984H12.3333V2.33317C12.3333 1.40817 11.5916 0.666504 10.6666 0.666504H7.33329C6.40829 0.666504 5.66663 1.40817 5.66663 2.33317V3.99984H2.33329C1.40829 3.99984 0.674959 4.7415 0.674959 5.6665L0.666626 14.8332C0.666626 15.7582 1.40829 16.4998 2.33329 16.4998H15.6666C16.5916 16.4998 17.3333 15.7582 17.3333 14.8332V5.6665C17.3333 4.7415 16.5916 3.99984 15.6666 3.99984ZM10.6666 3.99984H7.33329V2.33317H10.6666V3.99984Z"
			fill="#005F3E"
		/>
	</svg>
);

export default Icon2;
