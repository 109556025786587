import { css } from "styled-components";

export const sizes = {
	extraLarge: 1200,
	large: 992,
	medium: 768,
	small: 576,
	extraSmall: 430,
	extraExtraSmall: 390,
	mini: 320,

	// Breakpoint for New UI ( enterprise and menu navigation)
	xs: 360,
	sm: 721,
	md: 991,
	lg: 1024,
	mlg: 1100,
	xl: 1440,
};

const media = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (min-width: ${sizes[label]}px) {
			${css(...args)};
		}
	`;
	return acc;
}, {});

export const mediaMaxWidth = Object.keys(sizes).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (max-width: ${sizes[label] - 1}px) {
			${css(...args)};
		}
	`;
	return acc;
}, {});

export default media;
