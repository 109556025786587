import React from "react";

const Icon1 = () => (
	<svg
		width="16"
		height="20"
		viewBox="0 0 16 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.325 1.1335L1.49167 3.72516C0.891667 3.99183 0.5 4.59183 0.5 5.25016V9.16683C0.5 13.7918 3.7 18.1168 8 19.1668C12.3 18.1168 15.5 13.7918 15.5 9.16683V5.25016C15.5 4.59183 15.1083 3.99183 14.5083 3.72516L8.675 1.1335C8.25 0.941829 7.75 0.941829 7.325 1.1335ZM5.74167 13.5752L3.58333 11.4168C3.25833 11.0918 3.25833 10.5668 3.58333 10.2418C3.90833 9.91683 4.43333 9.91683 4.75833 10.2418L6.33333 11.8085L11.2333 6.9085C11.5583 6.5835 12.0833 6.5835 12.4083 6.9085C12.7333 7.2335 12.7333 7.7585 12.4083 8.0835L6.91667 13.5752C6.6 13.9002 6.06667 13.9002 5.74167 13.5752Z"
			fill="#004BA9"
		/>
	</svg>
);

export default Icon1;
