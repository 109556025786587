import * as React from "react";

function ChatIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 256 256"
			focusable="false"
			color="rgb(255, 255, 255)"
			style={{
				userSelect: "none",
				width: "24px",
				height: "24px",
				display: "inline-block",
				fill: "rgb(255, 255, 255)",
				flexShrink: 0,
			}}
		>
			<g color="rgb(255, 255, 255)" weight="fill">
				<path d="M128.00146,24.002A104.02442,104.02442,0,0,0,36.814,178.041l-8.55468,29.91406a16.00544,16.00544,0,0,0,19.78125,19.78125l29.91406-8.54688A104.00785,104.00785,0,1,0,128.00146,24.002Z" />
			</g>
		</svg>
	);
}

export default ChatIcon;
