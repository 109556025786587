import React from "react";
import styled from "styled-components";
import { Link } from "@material-ui/core";
import * as Sentry from "@sentry/browser";
import { ApnaTimeLogo } from "../../assets/svg";
import Modal from "../Modal/Modal";
import Mixpanel from "../../utils/Mixpanel";
import { checkIsEnterprise, getUserDetails } from "../../utils/commonUtils";
import Spinner from "../JobVerificationComponents/common/Loader/Spinner";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, isChunkLoadError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.

		return {
			hasError: true,
			error,
			isChunkLoadError: JSON.stringify(error)?.includes("ChunkLoadError"),
		};
	}

	componentDidCatch(error) {
		const { phone_number: phoneNumber, id: userId, ...rest } = getUserDetails() || {};
		const date = new Date();
		const eventTraceId = `errorID_${date.toLocaleDateString()}_${date.toLocaleTimeString()}_${userId}`;

		try {
			if (JSON.stringify(error)?.includes("ChunkLoadError")) {
				window.location.reload();
				return;
			}
		} catch (err) {
			console.error(err);
		}

		Sentry.captureException(error, {
			tags: {
				section: "SOMETHING_WENT_WRONG_POPUP_SHOWN",
				eventTraceId,
			},
		});
		Mixpanel.track("Something went wrong popup shown", {
			"User ID": userId,
			"Employer Phone Number": phoneNumber,
			eventTraceId,
			...rest,
		});
	}

	defaultrenderError = () => (
		<Modal
			open
			disableAutoFocus={false}
			disableBackdropClick={false}
			disableEscapeKeyDown={false}
			maxWidth={640}
			paddingSpacing={[0, 0, 0]}
		>
			<ErrorBoundaryContainer>
				<ErrorBoundaryDesc>
					<ApnaTimeLogo />
					<ErrorBoundaryText>Something went wrong !!!</ErrorBoundaryText>
					<ErrorBoundaryCta href={checkIsEnterprise() ? "/enterprise" : "/"}>
						Go back to Home
					</ErrorBoundaryCta>
				</ErrorBoundaryDesc>
			</ErrorBoundaryContainer>
		</Modal>
	);

	render() {
		const { hasError, isChunkLoadError } = this.state;
		const { children, renderError } = this.props;

		if (isChunkLoadError) return <Spinner />;

		if (hasError) {
			// You can render any custom fallback UI
			return <>{renderError ? renderError() : this.defaultrenderError()}</>;
		}

		return children;
	}
}

const ErrorBoundaryContainer = styled.div`
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f8f8f8;
	padding: 24px;
`;

const ErrorBoundaryDesc = styled.div`
	text-align: center;
	padding: 50px;

	& svg {
		width: 80px;
		height: 80px;
	}

	& a {
		font-size: 20px;
	}
`;

const ErrorBoundaryText = styled.p`
	color: #000;
	font-size: 28px;
	margin: 26px 0 40px;
	font-weight: 500;
`;

const ErrorBoundaryCta = styled(Link)`
	padding: 10px 20px;
	border-radius: 4px;
	background-color: #2bb793;
	color: #fff !important;
	font-weight: 500;

	&:hover {
		text-decoration: none !important;
	}
`;

export default ErrorBoundary;
