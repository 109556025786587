import { UPDATE_DATABASE_ELIGIBILITY } from "../constants/types";

const initialState = {
	data: {},
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	if (type === UPDATE_DATABASE_ELIGIBILITY) {
		return {
			...payload,
		};
	}
	return state;
};
