import Clevertap from "./Clevertap";
import { isClevertap } from "./MoEngage";

export const handleEventTracker = function({ eventName, eventData }) {
	if (isClevertap()) {
		Clevertap.trackEvent(eventName, eventData);
	}
};

export const handleUserLogin = function({ userData }) {
	if (isClevertap()) {
		Clevertap.onUserLogin({
			Site: userData,
		});
	}
};

export const handleProfile = function({ userData }) {
	if (isClevertap()) {
		Clevertap.profile({
			Site: userData,
		});
	}
};
