import React from "react";
import DashboardIcon from "../../assets/DashBoardIcon";
import DataBaseIcon from "../../assets/DataBaseIcon";
import JobsIcon from "../../assets/JobsIcon";
import ManageCoinsIcon from "../../assets/ManageCoinsIcon";
import PeoplesIcon from "../enterpriseIcons/PeoplesIcon";
import ReportsIcon from "../../assets/ReportsIcon";

export const APP_BAR_HEIGHT = "56px";
export const SIDE_DRAW_WIDTH = "240px";

export const USER_ROLE = {
	ADMIN: "admin",
	RECRUITER: "recruiter",
	MANAGER: "manager",
};

export const PATH_NAME = {
	DASHBOARD: "/",
	PROFILE: "/profile",
	POST_JOB: "/post-job",
	JOBS_PAGE: "/jobs",
	BUY_COINS: "/buy-coins",
	COINS_AND_USAGE: "/coins",
	REPORTS: "/reports-dashboard",
	DATABASE: "/database",
	TEAM_MEMBERS: "/team-members",
	SEARCH_CANDIDATES: "/search-candidates",
	SAVED_SEARCHES: "/saved-searches",
	MANAGE_FOLDERS: "/manage-folders",
	INVITE_CENTER: "/invite-center",
	DOWNLOAD_APPLICATIONS: "/download-applications",
	SEE_REPORTS: "/reports",
	VERIFICATION: "/verification",
	LOGIN: "/login",
	ENTERPRISE_DASHBOARD: "/enterprise",
	ENTERPRISE_LOGIN: "/enterprise/login",
	RESET: "/reset",
};

export const SIDE_DRAW_CONTENT_KEYS = {
	DASHBOARD: "dashboard",
	JOBS: "jobs",
	DATABASE: "database",
	REPORTS: "reports",
	TEAM_MEMBERS: "team_members",
	MANAGE_COINS: "manage_coins",
};

export const SIDE_DRAW_CONTENT_ITEMS = {
	[SIDE_DRAW_CONTENT_KEYS.DASHBOARD]: {
		key: "dashboard",
		icon: <DashboardIcon />,
		activeIcon: <DashboardIcon fill="#005F3E" />,
		text: "Dashboard",
		link: PATH_NAME.DASHBOARD,
	},
	[SIDE_DRAW_CONTENT_KEYS.JOBS]: {
		key: "jobs",
		icon: <JobsIcon />,
		activeIcon: <JobsIcon fill="#005F3E" />,
		text: "Jobs",
		link: PATH_NAME.JOBS_PAGE,
	},
	[SIDE_DRAW_CONTENT_KEYS.DATABASE]: {
		key: "database",
		icon: <DataBaseIcon />,
		activeIcon: <DataBaseIcon fill="#005F3E" />,
		text: "Database",
		link: PATH_NAME.DATABASE,
		// subMenuItems: [
		// 	{
		// 		key: "SearchCandidates",
		// 		text: "Search Candidates",
		// 		link: PATH_NAME.SEARCH_CANDIDATES,
		// 	},
		// 	{
		// 		key: "SavedSearches",
		// 		text: "Saved Searches",
		// 		link: PATH_NAME.SAVED_SEARCHES,
		// 	},
		// 	{
		// 		key: "ManageFolders",
		// 		text: "Manage Folders",
		// 		link: PATH_NAME.MANAGE_FOLDERS,
		// 	},
		// 	{
		// 		key: "InviteCenter",
		// 		text: "Invite Center",
		// 		link: PATH_NAME.INVITE_CENTER,
		// 	},
		// ],
	},
	[SIDE_DRAW_CONTENT_KEYS.REPORTS]: {
		key: "reports",
		icon: <ReportsIcon />,
		activeIcon: <ReportsIcon fill="#005F3E" />,
		text: "Reports",
		link: PATH_NAME.REPORTS,
	},
	[SIDE_DRAW_CONTENT_KEYS.TEAM_MEMBERS]: {
		key: "team_members",
		icon: <PeoplesIcon />,
		activeIcon: <PeoplesIcon fill="#005F3E" />,
		text: "Team members",
		link: PATH_NAME.TEAM_MEMBERS,
	},
	[SIDE_DRAW_CONTENT_KEYS.MANAGE_COINS]: {
		key: "coins_usage",
		icon: <ManageCoinsIcon />,
		activeIcon: <ManageCoinsIcon fill="#005F3E" />,
		text: "Coins & usage",
		link: PATH_NAME.COINS_AND_USAGE,
	},
};

export const SIDE_CONTENT_USER_ROLE = {
	[USER_ROLE.ADMIN]: {
		SET_ONE: [
			SIDE_DRAW_CONTENT_KEYS.JOBS,
			SIDE_DRAW_CONTENT_KEYS.DATABASE,
			SIDE_DRAW_CONTENT_KEYS.REPORTS,
		],
		SET_TWO: [
			SIDE_DRAW_CONTENT_KEYS.TEAM_MEMBERS,
			SIDE_DRAW_CONTENT_KEYS.MANAGE_COINS,
		],
	},

	[USER_ROLE.MANAGER]: {
		SET_ONE: [
			SIDE_DRAW_CONTENT_KEYS.JOBS,
			SIDE_DRAW_CONTENT_KEYS.DATABASE,
			SIDE_DRAW_CONTENT_KEYS.REPORTS,
		],
		SET_TWO: [
			SIDE_DRAW_CONTENT_KEYS.TEAM_MEMBERS,
			SIDE_DRAW_CONTENT_KEYS.MANAGE_COINS,
		],
	},

	[USER_ROLE.RECRUITER]: {
		SET_ONE: [
			SIDE_DRAW_CONTENT_KEYS.JOBS,
			SIDE_DRAW_CONTENT_KEYS.DATABASE,
			SIDE_DRAW_CONTENT_KEYS.REPORTS,
		],
		SET_TWO: [SIDE_DRAW_CONTENT_KEYS.MANAGE_COINS],
	},
};

export const ENTERPRISE_PROFILE_DATA = "ENTERPRISE_PROFILE_DATA";
export const ENTERPRISE_USER_DATA = "ENTERPRISE_USER_DATA";
export const ENTERPRISE_USAGE_DATA = "ENTERPRISE_USAGE_DATA";
export const SIDEDRAW_PRICING_UPDATE_BANNER = "SIDEDRAW_PRICING_UPDATE_BANNER";
