/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import {
	editForm,
	selectedJob,
	sendNotification,
	showPocDocUploadModal,
	updateJobEmailVerified,
	updateJobPhoneNumDetails,
} from "../../actions";
import { JobVerificationIcons, RefreshIcon } from "../../assets/svg";
import Button from "../../components/Buttons/Button";
import {
	getHrNumVerificationStatus,
	getJobDetail,
	resendHrNumVerificationLink,
	sendEmailVerificationLink,
} from "../../utils/apiClients";
import { checkForValidEmail, isMatchingEmail } from "../../utils/commonUtils";
import JobModalCommonContainer from "./components/JobModalCommonContainer";
import { Spacing } from "../../styles/SpacingStyles";
import { mediaMaxWidth } from "../../utils/mediaQuery";
import { isEmailVerified } from "../../utils";
import { useCounter } from "../../utils/hooks";
import {
	DASHBOARD_TYPES,
	POC_VERIFICATION_STATUS,
	VERIFICATION_COUNT_DOWN_LIMIT,
} from "../../utils/constants";
import Mixpanel from "../../utils/Mixpanel";

const { EmailIcon, WhatsAppIcon, DocIcon } = JobVerificationIcons;

const Item = ({ icon, children, actionText, action, actionDisable }) => {
	return (
		<StyledItem>
			<StyledItemInner>
				<IconContainer>{icon}</IconContainer>
				<InfoContainer>{children}</InfoContainer>
			</StyledItemInner>
			<ActionCTA onClick={action} disabled={actionDisable}>
				{actionText}
			</ActionCTA>
		</StyledItem>
	);
};

const POCVerificationOptionCard = ({
	text,
	actionIcon,
	actionText,
	action,
	children,
	// isFast,
}) => {
	return (
		<CardWithDashedBorder>
			<VerificationOptionText>{text}</VerificationOptionText>
			{children || (
				<POCVerificationCTA onClick={action}>
					{actionIcon}&nbsp;&nbsp;&nbsp;{actionText}
				</POCVerificationCTA>
			)}

			{/* {isFast && (
				<FastStepTag>
					<ThunderIcon />
					&nbsp;&nbsp;Faster step
				</FastStepTag>
			)} */}
		</CardWithDashedBorder>
	);
};

const JobVerificationModal = ({
	showUpdateEmailOption,
	handleClose,
	selectedJobDetails,
	userDetails,
	history,
	dispatchEditFormNew,
	dispatchJobPhoneNumberDetails,
	updateSelectedJob,
	notify,
	openPocUploadDocModal,
	dispatchUpdateJobEmailVerified,
}) => {
	const {
		id: jobId,
		email_verification_details: { is_verified: emailVerified } = {},
		phone_number_details: { hr } = {},
		job_data: { interview_info: { hr_email: hrEmail, hr_name: hrName } = {} } = {},
		creator_organization: {
			domains: companyDomains = [],
			id: orgId,
			name: orgName,
		} = {},
		created_by: {
			id: createdById,
			phone_number: createdByPhoneNumber,
			lead_type: leadType,
		} = {},
		poc_verification: {
			creator_status: creatorPocVerificationStatus = POC_VERIFICATION_STATUS.NON_VERIFIED,
			hr_status: hrPocVerificationStatus = POC_VERIFICATION_STATUS.NON_VERIFIED,
		} = {},
	} = selectedJobDetails || {};

	const selectedAccountDetails = useSelector(
		state => state.forms?.selectedAccountDetails
	);
	const currentDashboardType = useSelector(
		state => state?.internalUser?.internalDashboardType
	);

	const [state, setState] = useState({
		showEmailInput: false,
		inputEmail: "",
		emailError: false,
		sendEmailLoading: false,
		isEmailCounterActive: false,
	});

	const [emailCounter, startEmailCounter, clearEmailCounter] = useCounter({
		duration: VERIFICATION_COUNT_DOWN_LIMIT,
		onCounterEnd: () => setState(s => ({ ...s, isEmailCounterActive: false })),
		onCounterCleanup: () => setState(s => ({ ...s, isEmailCounterActive: false })),
		onCounterChange: async counter => {
			if (counter % 10 === 0) {
				try {
					const isHrEmailVerified = await isEmailVerified(
						currentDashboardType === DASHBOARD_TYPES.VERIFICATION
							? selectedAccountDetails?.recruiter?.id
							: createdById,
						hrEmail
					);
					if (isHrEmailVerified) {
						setState(s => ({ ...s, isEmailCounterActive: false }));
						notify(
							"success",
							`Your email id ${hrEmail} is verified. Your job post will be reviewed by our team on priority.`
						);
						dispatchUpdateJobEmailVerified(jobId);
					}
				} catch (e) {
					notify("error", "Something went wrong");
				}
			}
		},
	});

	const [hrNumberCounter, startHrNumberCounter, clearHrNumberCounter] = useCounter({
		duration: VERIFICATION_COUNT_DOWN_LIMIT,
		onCounterEnd: () => setState(s => ({ ...s, isHrNumberCounterActive: false })),
		onCounterCleanup: () => setState(s => ({ ...s, isHrNumberCounterActive: false })),
		onCounterChange: async counter => {
			if (counter % 10 === 0) {
				try {
					const resp = await getHrNumVerificationStatus(
						hr?.hr_number,
						hr?.employer_number
					);
					if (resp.status === 200) {
						const hrObj = resp?.data;
						if (hrObj?.is_under_review_verified || hrObj?.is_self_verified) {
							setState(s => ({ ...s, isHrNumberCounterActive: false }));
							notify(
								"success",
								"This Phone Number is verified! Your job will be made live soon"
							);
							dispatchJobPhoneNumberDetails(jobId, hrObj);
						}
					}
				} catch (err) {
					throw new Error(err);
				}
			}
		},
	});

	useEffect(() => {
		if (!state.isEmailCounterActive) {
			clearEmailCounter();
		}
		if (!state.isHrNumberCounterActive) {
			clearHrNumberCounter();
		}
	}, [state.isEmailCounterActive, state.isHrNumberCounterActive]);

	const postNewJob = () => {
		handleClose();
		dispatchEditFormNew();
		history.push("/post-job");
	};

	const onUploadPocDoc = () => {
		Mixpanel.track(
			"Upload POC Clicked",
			{
				"Job ID": jobId,
				"Company Name": orgName,
				"Employer ID": createdById,
				"Uploaded by ID": userDetails?.id,
				"Employer Number": createdByPhoneNumber,
				"Org Id": orgId,
				"Lead Type": leadType,
				Source: "popup",
			},
			selectedJobDetails
		);
		handleClose();
		openPocUploadDocModal();
	};

	const resendEmailVerificationLink = async () => {
		setState(s => ({ ...s, isEmailCounterActive: true }));
		startEmailCounter();
		try {
			const isHrEmailVerified = await isEmailVerified(
				currentDashboardType === DASHBOARD_TYPES.VERIFICATION
					? selectedAccountDetails?.recruiter?.id
					: createdById,
				hrEmail
			);
			if (isHrEmailVerified) {
				setState(s => ({ ...s, isEmailCounterActive: false }));
				notify(
					"success",
					`Your email id ${hrEmail} is verified. Your job post will be reviewed by our team on priority.`
				);
				dispatchUpdateJobEmailVerified(jobId);
			} else {
				await sendEmailVerificationLink(jobId);
				notify("success", "Verification Link has been sent to your email.");
			}
			Mixpanel.track(
				"Email Resend Link Button Click",
				{
					"Job ID": jobId,
					"Employer ID": createdById,
					"Employer Number": createdByPhoneNumber,
					"HR Number": hr?.hr_number,
					"Org Id": orgId,
					Source: "popup",
					isEmailVerified: emailVerified,
				},
				selectedJobDetails
			);
		} catch (e) {
			notify("error", "Something went wrong");
		}
	};

	const updateEmail = async () => {
		if (isMatchingEmail(companyDomains, state.inputEmail)) {
			setState(s => ({ ...s, sendEmailLoading: true }));
			try {
				await sendEmailVerificationLink(jobId, state.inputEmail);
				notify("success", "Verification Link has been sent to your email.");
			} catch (e) {
				notify("error", "Something went wrong.");
			}
			setState(s => ({ ...s, sendEmailLoading: false }));
			handleClose();
			const { status, data: updateJobData } = await getJobDetail(jobId);
			if (status === 200 && updateJobData) {
				updateSelectedJob(updateJobData);
			}
		} else {
			setState(s => ({ ...s, emailError: true }));
		}
	};

	const resendHrNumberVerificationLink = async () => {
		const resp = await resendHrNumVerificationLink({
			hrNo: hr?.hr_number,
			jobId,
		});

		startHrNumberCounter();
		setState(s => ({ ...s, isHrNumberCounterActive: true }));
		if (resp?.status === 200) {
			if (!(resp?.data?.is_under_review_verified || resp?.data?.is_self_verified)) {
				notify("success", "Link sent. Kindly check whatsapp/SMS");
			} else {
				// Already verified
				setState(s => ({ ...s, isHrNumberCounterActive: false }));
				notify(
					"success",
					"This Phone Number is already verified! Your job will be made live soon"
				);

				const { result, message, ...hrPhoneDetails } = resp?.data;
				dispatchJobPhoneNumberDetails(jobId, hrPhoneDetails);
			}
		}
	};

	const isHrNumVerified = hr?.is_under_review_verified || hr?.is_self_verified;

	const isPocVerificationRequired =
		!emailVerified &&
		creatorPocVerificationStatus === POC_VERIFICATION_STATUS.NON_VERIFIED &&
		hrPocVerificationStatus === POC_VERIFICATION_STATUS.NON_VERIFIED;

	const actionsCount = (() => {
		let c = 0;
		if (!isHrNumVerified) c += 1;
		if (isPocVerificationRequired) c += 1;
		return c;
	})();

	const verificationRequiredView = () => {
		const isCompanyDomain = isMatchingEmail(companyDomains, hrEmail);
		const hrEqualToEmployer = hr?.hr_number === hr?.employer_number;
		return (
			<>
				{isCompanyDomain && !emailVerified && (
					<Item
						icon={<EmailIcon />}
						actionText={
							state.isEmailCounterActive
								? `${emailCounter.m} : ${emailCounter.s}`
								: "Resend Link"
						}
						action={resendEmailVerificationLink}
						actionDisable={state.isEmailCounterActive}
					>
						Verify {hrEqualToEmployer ? "your" : <Bold>{hrName}&apos;s</Bold>}{" "}
						email <Bold>{hrEmail}</Bold> by clicking on the link sent to{" "}
						{hrEqualToEmployer ? "your" : "the"} mailbox
					</Item>
				)}
				{isHrNumVerified === false && (
					<Item
						icon={<WhatsAppIcon />}
						actionText={
							state.isHrNumberCounterActive
								? `${hrNumberCounter.m} : ${hrNumberCounter.s}`
								: "Resend Link"
						}
						action={resendHrNumberVerificationLink}
						actionDisabled={state.isHrNumberCounterActive}
					>
						Verify your HR number <Bold>{hr?.hr_number}</Bold> by clicking on
						the link sent on whatsapp/SMS
					</Item>
				)}
				{!isCompanyDomain && isPocVerificationRequired && (
					<Item icon={<DocIcon />} actionText="Upload" action={onUploadPocDoc}>
						Kindly attach your personal/association proof issued by the
						company you are hiring for
					</Item>
				)}
				<InfoBox>
					<InfoHeading>Why verification?</InfoHeading>
					<InfoList>
						<InfoListItem>Unlock faster job approval</InfoListItem>
						<InfoListItem>
							Fraudsters cannot post job under your company name
						</InfoListItem>
					</InfoList>
				</InfoBox>
			</>
		);
	};

	const updatePocDetailsView = () => {
		return (
			<>
				<POCVerificationOptionCard
					text="Please provide your official email id to get your account verified. We will share a verification link to this email."
					actionIcon={<RefreshIcon />}
					actionText="Update email"
					action={() => setState(s => ({ ...s, showEmailInput: true }))}
					isFast
				>
					{state.showEmailInput && (
						<>
							<EmailInputContainer>
								<StyledTextField
									id="hr-email-input"
									name="hr-email-input"
									type="email"
									placeholder="Enter you company email"
									autoComplete="none"
									variant="outlined"
									color="secondary"
									fullWidth
									value={state.inputEmail}
									onChange={event => {
										// Event Pooling...
										const inputEmail = event.target.value;
										setState(s => ({
											...s,
											inputEmail,
											emailError: false,
										}));
									}}
								/>
								<SendLinkCTA
									loading={state.sendEmailLoading}
									disabled={
										!checkForValidEmail(state.inputEmail) ||
										state.sendEmailLoading
									}
									onClick={updateEmail}
								>
									Verify Email
								</SendLinkCTA>
							</EmailInputContainer>
							{state.emailError && (
								<EmailVerificationErrorText>
									Please use{" "}
									{companyDomains
										.join(", ")
										.replace(/, ([^,]*)$/, " or $1")}{" "}
									for quick employer verification
								</EmailVerificationErrorText>
							)}
						</>
					)}
				</POCVerificationOptionCard>
			</>
		);
	};

	return (
		<JobModalCommonContainer
			onCloseClick={handleClose}
			headerText={
				showUpdateEmailOption
					? "Your job is under review"
					: `${actionsCount} action${
							actionsCount > 1 ? "s" : ""
					  } pending for verification`
			}
		>
			<Spacing mb={1.5} />
			{showUpdateEmailOption ? updatePocDetailsView() : verificationRequiredView()}
			<PostNewJobContainer>
				<PostNewJobCTA onClick={postNewJob}>Post another job</PostNewJobCTA>
			</PostNewJobContainer>
		</JobModalCommonContainer>
	);
};

// Styles
const StyledItem = styled.div`
	padding: 16px 20px;
	border: 1px solid #eaedf2;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;

	${mediaMaxWidth.medium`
		padding: 12px 10px;
		justify-content: center;
		flex-wrap: wrap;
	`}
`;

const StyledItemInner = styled.div`
	display: flex;
	flex-grow: 1;
`;
const IconContainer = styled.div`
	display: flex;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
	background: rgba(49, 68, 90, 0.1);
	border-radius: 100%;
	flex-shrink: 0;
`;
const InfoContainer = styled.div`
	padding: 0 20px;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #31445a;
	flex-grow: 1;

	${mediaMaxWidth.medium`
		padding: 0 0 12px 6px;
	`}
`;
const ActionCTA = styled(Button)`
	min-height: 32px;
	min-width: 120px;
	padding: 0 16px;
	height: auto;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	flex-shrink: 0;
`;
const InfoBox = styled.div`
	background: rgba(252, 159, 91, 0.07);
	border-radius: 4px;
	padding: 12px 20px;
`;
const InfoHeading = styled.div`
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #fc9f5b;
`;
const InfoList = styled.ul`
	margin: 8px 16px 0;
`;
const InfoListItem = styled.li`
	font-size: 13px;
	line-height: 15px;
	color: #31445a;
	margin: 4px 0;
`;

const PostNewJobContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 24px;
`;

const PostNewJobCTA = styled.div`
	color: #2bb793;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
`;

const CardWithDashedBorder = styled.div`
	padding: 20px;
	border: 1px dashed #eaedf2;
	border-radius: 4px;
	background: rgba(245, 247, 251, 0.3);
	position: relative;
`;

const VerificationOptionText = styled.div`
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: #31445a;
	margin-bottom: 20px;
`;

const POCVerificationCTA = styled(Button)`
	min-width: 180px;
	padding: 6px 16px;
	height: auto;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
`;

// const FastStepTag = styled.div`
// 	position: absolute;
// 	left: 0;
// 	top: 20px;
// 	border-radius: 0 4px 4px 0;
// 	background: rgba(252, 159, 91, 0.15);
// 	display: flex;
// 	align-items: center;
// 	padding: 8px;
// 	color: #fc9f5b;
// 	font-weight: 500;
// 	font-size: 12px;
// 	line-height: 14px;

// 	${mediaMaxWidth.medium`
// 		top: 42px;
// 		font-size: 10px;
// 		line-height: 12px;
// 		padding: 6px;
// 	`}
// `;

const SendLinkCTA = styled(Button)`
	height: auto;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	border-radius: 2px;
`;

const EmailInputContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: stretch;
`;

const EmailVerificationErrorText = styled.div`
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #cb2f4b;
	text-align: center;
	margin-top: 4px;
`;

// const POCVerificationOptionSeparator = styled.div`
// 	color: #9ba9b8;
// 	font-weight: 500;
// 	font-size: 12px;
// 	line-height: 14px;
// 	display: flex;
// 	align-items: center;
// 	margin: 8px 0;

// 	&::before,
// 	&::after {
// 		content: "";
// 		height: 0px;
// 		border-bottom: 1px solid #eaedf2;
// 		flex-grow: 1;
// 	}

// 	&::before {
// 		margin-right: 8px;
// 	}
// 	&::after {
// 		margin-left: 8px;
// 	}
// `;

const Bold = styled.span`
	font-weight: 500;
`;

const StyledTextField = styled(TextField)`
	width: 50%;
	.MuiInputBase-root {
		border-radius: 2px;
		.MuiInputBase-input {
			font-size: 14px;
			padding: "8px 16px";
		}
	}
`;

const mapStateToProps = ({ jobData, forms: { userDetails } }) => ({
	selectedJobDetails: jobData?.selectedJobDetails,
	userDetails,
});

const mapDispatchToProps = dispatch => ({
	dispatchEditFormNew: () => {
		dispatch(
			editForm({
				showForm: true,
				editFormData: {},
			})
		);
	},
	notify: (type, message) => {
		dispatch(
			sendNotification({
				message_type: type,
				message,
			})
		);
	},
	dispatchJobPhoneNumberDetails: (jobId, hr) => {
		dispatch(updateJobPhoneNumDetails({ jobId, hr }));
	},
	updateSelectedJob: data => {
		dispatch(selectedJob(data));
	},
	openPocUploadDocModal: () => {
		dispatch(showPocDocUploadModal());
	},
	dispatchUpdateJobEmailVerified: jobId => {
		dispatch(updateJobEmailVerified(jobId));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(JobVerificationModal));
