import * as Sentry from "@sentry/browser";
import store from "../store";
import { sendNotification } from "../actions";
import ErrorStatuses from "./ErrorStatuses.json";
import Mixpanel from "./Mixpanel";

export const LogErrorsOnSentry = err => {
	const errorCodes4xx = [400, 404];

	if (errorCodes4xx.includes(err?.status) && process.env.NODE_ENV === "production") {
		const params = {
			data: err?.data,
			url: err?.config?.url,
		};

		const urlMap = {
			pocdoc: "pocdoc",
			applications: "job-applications",
			"v7/jobs": "v7 jobs details",
			feedback: "feedback",
			assessments: "assessments",
			statistics: "statistics",
		};
		let message = `${err?.status} - ${ErrorStatuses[(err?.status)]}`;
		const keys = Object.keys(urlMap);
		const foundKey = keys.find(key => err?.config?.url?.includes(key));
		if (foundKey) {
			message += ` - ${urlMap[foundKey]}`;
		}
		const sentryLogger = {
			message: {
				message,
				params,
			},
		};
		Mixpanel.track("EMPDASH: Sentry API Errors", {
			...params,
			message,
			data: JSON.stringify(err),
		});
		Sentry.captureEvent(sentryLogger);
	}
};

export function handleError(err) {
	if (ErrorStatuses[(err?.status)]) {
		store.dispatch(
			sendNotification({
				message_type: "error",
				message: err?.data?.error?.message || ErrorStatuses[(err?.status)],
			})
		);
	}
	LogErrorsOnSentry(err);
}

export default {};
