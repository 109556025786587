import styled, { css } from "styled-components";
import media, { mediaMaxWidth } from "../utils/mediaQuery";
import { Flex } from "./FlexBoxStyles";

const Section = styled.section`
	background-color: ${props => props.bgColor || "transparent"};
`;
const Container = styled.div`
	margin: 0 auto;

	${media.extraSmall`
        max-width: 100%;
    `};
	${media.small`
        max-width: 540px;
    `};
	${media.medium`
        max-width: 720px;
    `};
	${media.large`
        max-width: 960px;
    `};
	${media.extraLarge`
        max-width: 1200px;

		${({ showNewJobDetailContent }) =>
			showNewJobDetailContent &&
			css`
				max-width: 2400px;
			`}
    `};

	${props =>
		(props.isEnterprise || props.isNewLayout) &&
		props.isPostJob &&
		css`
			padding: 0;
			background-color: #f8f8f8 !important;

			${mediaMaxWidth.medium`
				padding-left: 0px !important;
				padding-right: 0px !important;
			`}
		`}
	${props =>
		props.isPostJob &&
		css`
			background-color: #f8f8f8 !important;

			${mediaMaxWidth.medium`
				padding-left: 0px !important;
				padding-right: 0px !important;
			`}
		`}
`;
const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Columns = styled.div`
	width: 100%;
`;

export const IconWrapper = styled(Flex)`
	align-items: center;
	justify-content: center;

	${props =>
		props?.pointer &&
		css`
			cursor: pointer;
		`}
`;

export { Section, Container, Row, Columns };
