export const TOTAL_COIN_BALANCE = "TOTAL_COIN_BALANCE";
export const ACCOUNT_ID = "ACCOUNT_ID";
export const BUY_COINS = "BUY_COINS";
export const UPDATE_APNA_COIN_PRODUCT = "UPDATE_APNA_COIN_PRODUCT";
export const EXTRA_COINS_REQUIRED = "EXTRA_COINS_REQUIRED";
export const RENEWAL_SUCCESS_MODAL = "RENEWAL_SUCCESS_MODAL";
export const DEBIT_COINS_DETAILS = "DEBIT_COINS_DETAILS";
export const PAYMENT_STATUS_SUCCESS = "PAYMENT_STATUS_SUCCESS";
export const IS_ACCOUNT_DETAILS_FETCHED = "IS_ACCOUNT_DETAILS_FETCHED";
export const SHOW_ACTIVATION_PLANS_POPUP = "SHOW_ACTIVATION_PLANS_POPUP";
export const PAID_JOBS_COUNT = "PAID_JOBS_COUNT";
export const SHOW_COMPLIMENTARY_COINS_MESSAGE = "SHOW_COMPLIMENTARY_COINS_MESSAGE";
export const REFETCH_ACTIVATION_PLANS = "REFETCH_ACTIVATION_PLANS";
export const UPDATE_SELECTED_ACTIVATION_PLAN = "UPDATE_SELECTED_ACTIVATION_PLAN";
export const SHOW_APNA_PROMISE_MODAL = "SHOW_APNA_PROMISE_MODAL";
export const FREE_BANNER_JOBS = "FREE_BANNER_JOBS";
export const UPDATE_REFUND_DETAILS = "UPDATE_REFUND_DETAILS";
export const INVALID_RETRY_PAYMENT_MODAL = "INVALID_RETRY_PAYMENT_MODAL";
export const IS_FIRST_PURCHASE = "IS_FIRST_PURCHASE";
export const FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS";
export const MIN_BALANCE_FOR_TRANSFER = "MIN_BALANCE_FOR_TRANSFER";
export const JOB_POSTING_MODAL = "JOB_POSTING_MODAL";
export const BACK_TO_SELECT_PLAN = "BACK_TO_SELECT_PLAN";
export const SELECT_PLAN_VERSION = "SELECT_PLAN_VERSION";
export const INSUFFICIENT_BALANCE_NUDGE = "INSUFFICIENT_BALANCE_NUDGE";
export const ACCOUNT_TYPE = "ACCOUNT_TYPE";
export const TNC_MODAL = "TNC_MODAL";
export const SET_COINS_SUMMARY = "SET_COINS_SUMMARY";
export const SET_NEW_LAYOUT_HEADER_HEIGHT = "SET_NEW_LAYOUT_HEADER_HEIGHT";
export const SET_PRICING_CONTRACT_DATA = "SET_PRICING_CONTRACT_DATA";
export const SET_COINS_META_DATA = "SET_COINS_META_DATA";
export const SET_BUY_COINS_3_RENDER_VIEW = "SET_BUY_COINS_3_RENDER_VIEW";
export const SET_PACKAGES = "SET_PACKAGES";
export const SET_USER_CART_DETAILS = "SET_USER_CART_DETAILS";
export const SET_PRICING_PLANS = "SET_PRICING_PLANS";
export const SET_POST_PAYMENT_ORDER_DETAILS = "SET_POST_PAYMENT_ORDER_DETAILS";
export const SET_USER_CART_SELECTPLAN = "SET_USER_CART_SELECTPLAN";
export const EXPRESS_CHECKOUT_SELECTPLAN_MERGE_COHORT =
	"EXPRESS_CHECKOUT_SELECTPLAN_MERGE_COHORT";
