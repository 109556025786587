/* eslint-disable no-empty-character-class */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import mixpanel from "mixpanel-browser";
import config from "../config";
import {
	ENTERPRISE_PROFILE_DATA,
	ENTERPRISE_USAGE_DATA,
	ENTERPRISE_USER_DATA,
} from "../enterpriseGrading/constants/constants";

import {
	DEVICE_IDENTIFIER,
	DEVICE_INFO,
	ENABLE_NEW_LAYOUT,
	getReleaseVersion,
	URL_PARAMS,
	URL_PARAM_KEYS,
	UTM_CONSTANTS_VALUES,
	UTM_PARAMS,
	GENDER_DATA,
	ENGLISH_LEVEL_OLD_NEW_MAPPING,
} from "./constants";

window.mixpanel = mixpanel;
mixpanel.init(config.mixpanelKey, {
	api_host: config.mixpanelProxyURL,
});

// to remove cyclic depdency from Commonutlis
const getItemFromLocalStorage = key => {
	let data = null;
	try {
		if (key) {
			data = localStorage.getItem(key);
		}
		return data;
	} catch (error) {
		return data;
	}
};

const getQueryParam = (url, param) => {
	// Expects a raw URL
	const newParam = param.replace(/[[]/, "[").replace(/[]]/, "]");
	const regexS = `[?&]${newParam}=([^&#]*)`;
	const regex = new RegExp(regexS);
	const results = regex.exec(url);
	if (
		results === null ||
		(results && typeof results[1] !== "string" && results[1].length)
	) {
		return "";
	}
	return decodeURIComponent(results[1]).replace(/\W/gi, " ");
};

const campaignParams = () => {
	const campaign_keywords = "utm_source utm_medium utm_campaign utm_content utm_term".split(
		" "
	);
	let kw = "";
	const params = {};
	let index;
	for (index = 0; index < campaign_keywords.length; ++index) {
		kw = getQueryParam(document.URL, campaign_keywords[index]);
		if (kw.length) {
			params[`${campaign_keywords[index]} [last touch]`] = kw;
		}
	}
	mixpanel.people.set(params);
	mixpanel.register(params);
};

campaignParams();
// const envCheck = process.env.NODE_ENV === "development"
const actions = {
	identify: id => {
		mixpanel.identify(id);
	},
	alias: id => {
		mixpanel.alias(id);
	},
	track: (name, props, jobData) => {
		const extraJobData = {
			Openings: jobData && jobData.no_of_openings,
			Gender: jobData && jobData.gender && GENDER_DATA[jobData.gender],
			Education: jobData && jobData.education_level,
			"English Requirements":
				ENGLISH_LEVEL_OLD_NEW_MAPPING[(jobData?.job_data?.english)],
			Experience: jobData?.experience_in_years,
			Shift: jobData?.shift,
			Deposit:
				jobData?.job_data?.json_data?.deposit?.amount ||
				jobData?.job_data?.deposit?.amount,
			"Application From": jobData?.applicant_location,
			Area: jobData?.address?.area?.name,
			City: jobData?.address?.area?.city?.name,
			"Job ID": jobData?.id,
			"Expiry Date": jobData?.expiry,
			"Is Paid": !!jobData?.coins_spent,
			"Category ID": jobData?.job_category?.id,
			"Job Department": jobData?.jobDepartment?.name,
			"Job Department ID": jobData?.jobDepartment?.id,
			"Job Sub-Department": jobData?.jobSubDepartment?.name,
			"Job Sub-Department ID": jobData?.jobSubDepartment?.id,
			"Coins Spent": jobData?.coins_spent,
			"Job Type": jobData?.job_type,
		};

		let fpData = {};

		const deviceIdentifier = localStorage.getItem(DEVICE_IDENTIFIER);
		const deviceInfo = JSON.parse(localStorage.getItem(DEVICE_INFO)) || {};

		if (deviceIdentifier) {
			fpData[DEVICE_IDENTIFIER] = deviceIdentifier;
			fpData = { ...fpData, ...deviceInfo };
		}

		const memberProfileData =
			JSON.parse(getItemFromLocalStorage(ENTERPRISE_USER_DATA)) || {};
		const userProfileData =
			JSON.parse(getItemFromLocalStorage(ENTERPRISE_PROFILE_DATA)) || {};
		const userUsageSummary =
			JSON.parse(getItemFromLocalStorage(ENTERPRISE_USAGE_DATA)) || {};
		const enterpriseWorkspace =
			JSON.parse(getItemFromLocalStorage("__enterpriseworkspace__")) || {};
		let enterpriseData = {
			account_type: "NON_ENT",
			user_type: memberProfileData?.isEnterpriseUser ? "ENT" : "NON_ENT",
			workspace_id: userProfileData?.workspaceId,
			org_id: memberProfileData?.organisationId,
			user_id: userProfileData?.coreUserId,
			user_role: memberProfileData?.userRole,
			user_permission: memberProfileData?.additionalUserRoles?.join(", "),
			company_coins: userUsageSummary?.coins_balance,
			current_usage: userUsageSummary?.current_usage,
			usage_limit_type: userUsageSummary?.usage_reset_frequency,
			usage_limit: userUsageSummary?.allowed_usage,
			job_type: jobData?.job_type,
			is_consultant: enterpriseWorkspace?.creator_organization?.is_consultant
				? "Yes"
				: "No",
			new_menu: JSON.parse(getItemFromLocalStorage(ENABLE_NEW_LAYOUT)) || false,
		};

		if (window.location.pathname.includes("enterprise")) {
			const {
				coins_balance: coinBalance = 0,
				usage_limit: {
					current_usage: currentUsage = 0,
					default_max_allowed_usage: defaultAllowedUsage = 0,
					max_allowed_usage: maxAllowedUsage = 0,
					usage_reset_frequency: resetFrequency = "",
				} = {},
			} = userUsageSummary;

			const {
				creator_organization: {
					name: organisationName = "",
					organization_type: organisationType = "",
				} = {},
			} = enterpriseWorkspace;

			enterpriseData = {
				...enterpriseData,
				workspace_id: userProfileData?.workspaceId,
				org_id: memberProfileData?.organisationId,
				"Organization Type": organisationType,
				"Organization Name": organisationName,
				"User ID": userProfileData?.coreUserId,
				user_role: memberProfileData?.userRole,
				"Mobile Number": memberProfileData?.userPhoneNumber,
				user_permission: memberProfileData?.additionalUserRoles?.join(", "),
				account_type: "ENT",
				user_type: "ENT",
				company_coins: coinBalance,
				current_usage: currentUsage,
				limit_enabled: !!resetFrequency || maxAllowedUsage > 0,
				default_limit: defaultAllowedUsage,
				usage_limit_type: resetFrequency || "NA",
				usage_limit: maxAllowedUsage > 0 ? maxAllowedUsage : "NA",
			};
		}

		// Get Params from URL
		const { search } = window.location;
		const params = new URLSearchParams(search);
		const query = {};
		// eslint-disable-next-line no-restricted-syntax
		for (const [key, value] of params.entries()) {
			if (URL_PARAM_KEYS.includes(key)) {
				// incase of whatsapp campign
				if (
					key === UTM_PARAMS.utm_custom_2 &&
					value?.includes(
						UTM_CONSTANTS_VALUES[UTM_PARAMS.utm_custom_2]?.toLowerCase()
					)
				) {
					query[key] = UTM_CONSTANTS_VALUES[UTM_PARAMS.utm_custom_2];
				} else {
					query[key] = value;
				}
			}
		}
		// If URL params are not stored in Local Storage, capture them from URL and store them.
		if (!localStorage.getItem(URL_PARAMS)) {
			if (Object.keys(query).length > 0) {
				localStorage.setItem(URL_PARAMS, JSON.stringify(query));
			}
		} else {
			localStorage.setItem(
				URL_PARAMS,
				JSON.stringify({
					...JSON.parse(localStorage.getItem(URL_PARAMS)),
					...query,
				})
			);
		}

		const utmData = JSON.parse(localStorage.getItem(URL_PARAMS));
		const empVersion = getReleaseVersion();

		const isJobExpired = expiryDate => {
			const today = new Date();
			const dateOfExpiry = new Date(expiryDate);
			if (today?.toDateString() === dateOfExpiry?.toDateString()) {
				return true;
			}
			if (today < dateOfExpiry) {
				return false;
			}
			return true;
		};

		const eccJobDataProperties = {
			"Job ID": jobData?.id,
			"Is Active": jobData?.expiry && isJobExpired(jobData?.expiry),
			"Job City": jobData?.address?.area?.city?.name,
			"Job Area": jobData?.address?.area?.name,
			"Job Title": jobData?.title,
			"Job Category": jobData?.category,
			"Job Department": jobData?.department?.name,
			"Call Preference": jobData?.tecc_employer_preference_values?.call,
			"WA Alert Preference": jobData?.tecc_employer_preference_values?.wa_mini,
			"ECC Type": {
				call: jobData?.tecc_employer_preference_values?.call,
				wa_mini: jobData?.tecc_employer_preference_values?.wa_mini,
				whatsapp: jobData?.tecc_employer_preference_values?.whatsapp,
			},
			Is_Paid: !!jobData?.coins_spent,
			"Coins Spent": jobData?.coins_spent,
			"Organization Name": jobData?.organisation?.name,
			"Organization Lead Type": jobData?.organisation?.business_lead_type,
			"Experience Requirement": jobData?.experience_in_years,
			"HR ID": jobData?.hr?.id,
			"HR Name": jobData?.hr?.full_name,
			"HR Organization": jobData?.hr?.consultancy,
			"HR Lead Type": jobData?.hr?.lead_type,
			// TO-DO
			// "Job Cost Type": "TO BE ADDED",
		};

		mixpanel.track(
			name,
			jobData
				? {
						...extraJobData,
						...eccJobDataProperties,
						...utmData,
						...props,
						...enterpriseData,
						"Build Version": Number(empVersion),
				  }
				: {
						...props,
						...utmData,
						...fpData,
						...enterpriseData,
						"Build Version": Number(empVersion),
				  }
		);
	},
	getGroup: (name, value) => {
		return mixpanel.get_group(name, value);
	},
	trackWithoutGenricProps: (name, props) => {
		mixpanel.track(name, props);
	},
	people: {
		set: props => {
			mixpanel.people.set(props);
		},
		set_once: props => {
			mixpanel.people.set_once(props);
		},
		union: (key, val) => {
			mixpanel.people.union(key, val);
		},
	},
};

const Mixpanel = actions;

export default Mixpanel;
