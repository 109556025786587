import React from "react";
import styled from "styled-components";
import FieldJobIcon from "../../assets/JobPostFormIcons/FieldJobIcon";
import SingleJobLocationIcon from "../../assets/JobPostFormIcons/SingleJobLocationIcon";
import WorkFromHomeIcon from "../../assets/JobPostFormIcons/WorkFromHomeIcon";
import Icon1 from "../../assets/VerificationIcons/WhyVerifyCardIcons.js/Icon1";
import Icon2 from "../../assets/VerificationIcons/WhyVerifyCardIcons.js/Icon2";
import Icon3 from "../../assets/VerificationIcons/WhyVerifyCardIcons.js/Icon3";
import AadharIcon1 from "../../assets/VerificationIcons/WhyVerifyCardIcons.js/AadharIcon1";
import AadharIcon2 from "../../assets/VerificationIcons/WhyVerifyCardIcons.js/AadharIcon2";
import AadharIcon3 from "../../assets/VerificationIcons/WhyVerifyCardIcons.js/AadharIcon3";
import PanIcon2 from "../../assets/VerificationIcons/WhyVerifyCardIcons.js/PanIcon2";
import PanIcon3 from "../../assets/VerificationIcons/WhyVerifyCardIcons.js/PanIcon3";
import {
	APPLICATION_LOCATION_VALUES_FOR_NON_WFH,
	APPLICATION_LOCATION_VALUES_FOR_WFH,
	DEPOSIT_STATUS,
	ENGLISH_LEVEL_FORM_DATA,
} from "../../utils/constants";
import NewFieldNudge from "../../assets/JobPostFormIcons/NewFieldNudge";
import ApnaTagCoin from "../../components/ApnaTagCoin";

export const BASIC_DETAILS_OBJECT = {
	companyName: "companyName",
	jobTitle: "jobTitle",
	jobDepartment: "jobDepartment",
	jobSubDepartment: "jobSubDepartment",
	minimumEducation: "minimumEducation",
	jobType: "jobType",
	shiftType: "shiftType",
	englishLevel: "englishLevel",
	minSalary: "minSalary",
	maxSalary: "maxSalary",
	incentive: "incentive",
	numberOfOpenings: "numberOfOpenings",
	isWfh: "isWfh",
	includesIncetivesOrNot: "includesIncetivesOrNot",
	jobCity: "job_city",
	toggleChangeCompanyModal: "toggleChangeCompanyModal",
	depositStatus: "depositStatus",
	amount: "amount",
	depositReason: "depositReason",
	purpose: "purpose",
	whenToPay: "whenToPay",
	assetsOrInventory: "assetsOrInventory",
	jobLocationType: "jobLocationType",
	interviewCity: "interviewCity",
	interviewArea: "interviewArea",
	interviewCompleteAddress: "interviewCompleteAddress",
	interviewGoogleAddress: "interviewGoogleAddress",
	jobLocationCity: "jobLocationCity",
	jobLocationArea: "jobLocationArea",
	jobLocationCompleteAddress: "jobLocationCompleteAddress",
	jobLocationGoogleAddress: "jobLocationGoogleAddress",
	singleJobApplicantLocation: "singleJobApplicantLocation",
	interviewMethod: "interviewMethod",
	wfhJobApplicationLocation: "wfhJobApplicationLocation",
	fieldJobLocationType: "fieldJobLocationType",
	salaryType: "salaryType",
	remoteApplicantEligible: "remoteApplicantEligible",
	jobLocationAdditionalDetails: "jobLocationAdditionalDetails",
	userCustomJobAddress: "userCustomJobAddress",
};

export const BASIC_ERROR_MESSAGES = {
	jobTitleRequired: "Please select a job role",
	jobDepartmentRequired: "Please select department / function",
	jobTypeRequired: "Please select a job type",
	jobSubDepartmentRequired: "Please select category / role",
	salaryTypeRequired: "Please select a pay type",
	incentiveRequired: "Please enter the average incentive",
	minSalaryRequired: "Please enter the minimum salary",
	minMaxFixedSalaryRequired: "Please enter the minimum & maximum fixed salary",
	minSalaryError: "Min salary must be less than Max salary",
	fixedMinMaxError: "Maximum Salary should be greater than minimum salary",
	maxSalaryRequired: "Please enter the maximum salary",
	maxSalaryGreater: "Max salary should be at least ₹ 1000",
	maxSalaryLess: "Max salary cannot exceed ₹ 149,999",
	maxSalaryError: "Max salary must be greater than Min salary",
	numberOpeningsRequired: "Please enter a value",
	enterMinMaxInc: "Please enter min and max salary",
	lessOpenings: "Please enter a value greater than 0",
	maxOpenings:
		"We allow only 250 openings per job, for more than 250 openings please post more than 1 job",
	englishLevelRequired: "Please select an education level",

	jobCityRequired: "Please enter a value",
	companyNameInvalid: "Please enter a valid company name",
	amountRequired: "Please enter a value",
	amountMin: "Please enter a value greater than 0",
	purposeRequired: "Please enter purpose",
	depositReasonRequired: "Please select a reason",
	depositStatusRequired: "Please choose one option",
	whenToPayRequired: "Please choose one option",
	companyNameRequired: "Please enter company name",
	jobLocationTypeRequired: "Please select a job location",
	interviewCity: "Please select city",
	interviewArea: "Please select area",
	interviewCompleteAddress: "Please enter a value",
	jobLocationCity: "Please select city",
	jobLocationArea: "Please select area",
	completeAddressIsRequired: "Please provide your complete google address",
	completeOfficeAddressIsRequired: "Please provide your complete office address",
	singleJobApplicantLocation: "Please select one option",
	interviewMethod: "Please select one option",
	wfhJobApplicationLocation: "Please select one option",
	fieldJobLocationType: "Please select one option",
	incentiveSalaryGreater: "Incentive should be at least ₹ 1000",
	incentiveSalaryLess: "Incentive cannot exceed ₹ 99,999",
	totalEarningPotentialCannotExceed: "Total salary cannot exceed ₹ 149,999",
	maxDepositAmount: "Sorry! Deposit higher than ₹ 3000 is not allowed.",
	areaLocality: "Please provide your area / locality",
	remoteApplicant: "Please select an option",
};
export const JOB_DETAILS_ERROR = {
	amountRequired: "Please enter a value",
	jobDescRequired: "Please enter a value",
	jobDescInvalid: "Please enter a valid job description",
	purposeRequired: "Please enter purpose",
	amountMin: "Please enter a value greater than 0",
	depositStatusRequired: "Please choose one option",
	depositReasonRequired: "Please select a reason",
	englishLevelRequired: "Please select an option",
	minAgeRequired: "Please enter a value",
	maxAgeRequired: "Please enter a value",
	minAgeError: "Min. age must be less than Max. age",
	maxAgeError: "Max. age must be greater than Min. age",
	minAgeLowerBoundError: "Min. age should be atleast 18",
	minAgeUpperBoundError: "Min. age cannot exceed 60",
	maxAgeUpperBoundError: "Max. age cannot exceed 60",
	minimumEducationRequired: "Please select minimum education",
	minYearsRequired: "Please enter a value",
	minYearsMax: "Please enter a value less than 20",
	minYearsError: "Min years must be less than max years",
	maxYearsError: "Max years must be greater than min years",
	minYearLess: "Please enter a value greater than 0",
	maxYearsRequired: "Please enter a value",
	maxYearsBucketRequired: "Please select one option",
	maxYearsRequiredNonZero: "Max experience required cannot be 0",
	maxYearsMin: "Please enter a value less than 30",
	genderRequired: "Please choose one option",
	experienceType: "Please select one option",
	minExpBucketRequired: "Please select minimum experience",
	maxSkillCount: "You cannot add more than 10 skills",
	minSkillCount:
		"Please add %NUM_SKILL% more key skills to make your job visible to right candidates.",
	maxPreferredSkillCount: "You cannot mark more than 4 Preferred Skills",
	isSpecificDepartmentCriteria: "Please select one option",
	maxDepartments: "Max 10 departments are allowed",
	selectedDepartments: "Please select at least one more department",
	isRegionalLanguageRequired: "Please select an option",
	isAssetTagsRequiredErrorMessage: "Please choose at least 1 asset",
};
export const INTERVIEW_INFO_ERROR = {
	companyNameRequired: "Please enter company name",
	companyNameInvalid: "Please enter a valid company name",
	contactNameRequired: "Please enter recruiter's name",
	contactNameInvalid: "only alphabets are allowed",
	contactNumberRequired: "Please enter a value",
	contactNumberInvalid: "Please enter a valid contact number",
	contactEmailRequired: "Please enter a value",
	contactEmailInavlid: "Please enter a valid email",
	landmarkRequired: "Please enter a value",
	buildingNameRequired: "Please enter a building name",
	streetNameRequired: "Please enter a value",
	areaRequired: "Please enter a value",
	cityRequired: "Please enter a value",
	jobLandmarkRequired: "Please enter a value",
	jobBuildingNameRequired: "Please enter a value",
	jobStreetNameRequired: "Please enter a value",
	jobAreaRequired: "Please enter a value",
	jobCityRequired: "Please enter a value",
	numberNameRequired: "Please enter a value",
	applicantLocationRequired: "Please enter a value",
	eccPreferenceRequired: "Please select a preference",
	selectedEccQuestionOptionsRequired: "Please select atleast 1 option",
	customEccQuestionRequired: "Please enter a value",
	applyForOldJobsRequired: "Please select an option",
	contactPreferenceRequired: "Please select an option",
	waMiniPreferenceRequired: "Please select an option",
	remoteApplicantEligibleRequired: "Please choose one option",
	companyCity: "Please select city",
	companyArea: "Please select area from dropdown",
	companyLocationCompleteAddress: "Please provide your complete google address",
	companyLocationCompleteAddressNew: "Please provide your complete company address",
	interviewCity: "Please select city",
	interviewArea: "Please select area from dropdown",
	interviewMethod: "Please select one option",
	interviewCompleteAddress: "Please provide your complete google address",
	interviewCompleteAddressNew: "Please provide your complete interview address",
};
export const JOB_DETAILS_OBJECT = {
	englishLevel: "englishLevel",
	engDesc: "engDesc",
	depositStatus: "depositStatus",
	amount: "amount",
	depositReason: "depositReason",
	purpose: "purpose",
	ageStatus: "ageStatus",
	minAge: "minAge",
	maxAge: "maxAge",
	minimumEducation: "minimumEducation",
	gender: "gender",
	experienceType: "experienceType",
	minYears: "minYears",
	maxYears: "maxYears",
	experienceBucket: "experienceBucket",
	degree: "degree",
	skill: "skill",
	industries: "industries",
	skillSearch: "skillSearch",
	suggestedSkillSelect: "suggestedSkillSelect",
	selectedTagDelete: "selectedTagDelete",
	isSpecificDepartmentCriteria: "isSpecificDepartmentCriteria",
	selectedDepartments: "selectedDepartments",
	selectedSectorIndustry: "selectedSectorIndustry",
	selectedRelatedTitles: "selectedRelatedTitles",
	isRegionalLanguageRequired: "isRegionalLanguageRequired",
	isGenderDiversityPreference: "isGenderDiversityPreference",
	isAssetTagsRequired: "isAssetTagsRequired",
};
export const INTERVIEW_INFO_DATA = {
	companyName: "companyName",
	contactName: "contactName",
	contactNumber: "contactNumber",
	contactEmail: "contactEmail",
	buildingName: "buildingName",
	city: "city",
	area: "area",
	jobAddressCheckbox: "jobAddressCheckbox",
	jobBuildingName: "jobBuildingName",
	jobCity: "jobCity",
	jobArea: "jobArea",
	applicantLocation: "applicantLocation",
	googleJobBuilding: "googleJobBuilding",
	googleBuilding: "googleBuilding",
	eccPreferencePreview: "eccPreferencePreview",
	eccPreference: "eccPreference",
	applyForOldJobs: "applyForOldJobs",
	selectedEccQuestionOptions: "selectedEccQuestionOptions",
	customEccQuestion: "customEccQuestion",
	trueEccPreference: "trueEccPreference",
	trueEccApplyToAll: "trueEccApplyToAll",
	creatorRecruiter: "creatorRecruiter",

	companyCity: "companyCity",
	companyArea: "companyArea",
	companyLocationCompleteAddress: "companyLocationCompleteAddress",
	companyLocationGoogleAddress: "companyLocationGoogleAddress",
	isCompanyAddressSame: "isCompanyAddressSame",
	interviewCity: "interviewCity",
	interviewArea: "interviewArea",
	interviewCompleteAddress: "interviewCompleteAddress",
	interviewGoogleAddress: "interviewGoogleAddress",
	interviewMethod: "interviewMethod",
	interviewLocationAdditionalDetails: "interviewLocationAdditionalDetails",
	companyLocationAdditionalDetails: "companyLocationAdditionalDetails",
};

export const INTERVIEW_INFO_MESSAGES = {
	strictWarningText: "This may reduce the number of applications on your job",
};

export const JOB_POST_FIELDS = {
	jobTitle: "job_title",
	minimumEducation: "minimum_education",
	jobType: "jobType",
	shiftType: "shiftType",
	experienceType: "experience_type",
	gender: "gender",
	minSalary: "min_salary",
	maxSalary: "max_salary",
	incentive: "incentive",
	minYears: "min_years",
	maxYears: "max_years",
	numberOfOpenings: "number_of_openings",
	isWfh: "is_wfh",
	engDesc: "eng_desc",
	jobExpiryDuration: "jobExpiryDuration",
	depositStatus: "depositStatus",
	purpose: "purpose",
	amount: "amount",
	englishLevel: "english_level",
	depositReason: "reason",
	modeOfInterview: "mode_of_interview",
	companyName: "company_name",
	contactName: "contact_name",
	contactNumber: "contact_number",
	contactEmail: "contact_email",
	landmark: "landmark",
	buildingName: "building_name",
	area: "area",
	city: "city",
	jobAddressCheckbox: "jobAddressCheckbox",
	jobBuildingName: "job_building_name",
	jobArea: "job_area",
	jobCity: "job_city",
	applicantLocation: "applicant_location",
	includesIncetivesOrNot: "is_incentives_involved",
	creatorOrg: "creator_org",
	remoteApplicantEligible: "remote_applicant_eligible",
};

export const FULL_TIME = "FULL_TIME";
export const PART_TIME = "PART_TIME";
export const defaultCategoryConfigId = [11];

export const JOB_TYPE_OPTIONS = [
	{
		name: "Full Time",
		id: FULL_TIME,
	},
	{
		name: "Part Time",
		id: PART_TIME,
	},
	{
		name: "Both (Full-Time and Part-Time)",
		id: "FULL_PART_TIME",
	},
];

export const JOB_TYPE_OPTIONS_CONFIG = {
	[FULL_TIME]: "Full Time",
	[PART_TIME]: "Part Time",
	FULL_PART_TIME: "Both (Full-Time and Part-Time)",
	"Full Time": FULL_TIME,
	"Part Time": PART_TIME,
	"Both (Full-Time and Part-Time)": "FULL_PART_TIME",
};

export const JOB_LOCATION_TYPE = {
	SINGLE_JOB_LOCATION: "SINGLE_JOB_LOCATION",
	WORK_FROM_HOME: "WORK_FROM_HOME",
	FIELD_JOB: "FIELD_JOB",
};

export const BE_VALUES_FOR_LOCATION_TYPES = {
	SINGLE_JOB_LOCATION: "single_location",
	WORK_FROM_HOME: "wfh",
	FIELD_JOB: "field_location",
	single_location: "SINGLE_JOB_LOCATION",
	wfh: "WORK_FROM_HOME",
	field_location: "FIELD_JOB",
};

export const UR_JOB_DETAILS_TEXT_MAPPING = {
	single_location: "Single Job Location",
	wfh: "Work from Home",
	field_location: "Field Job",
};

export const JOB_POST_MIXPANEL_PROPERTIES_VALUES = {
	SINGLE_JOB_LOCATION: "Single Job location",
	WORK_FROM_HOME: "Work from home",
	FIELD_JOB: "Field City job",
};

export const JOB_LOCATION_TYPE_HEADER_DESCRIPTION = {
	SINGLE_JOB_LOCATION: {
		heading: "Work from office",
		description: "Candidates would be required to work from a fixed location",
		icon: color => <SingleJobLocationIcon fill={color} />,
		value: "SINGLE_JOB_LOCATION",
		"data-job-value": "SINGLE_JOB_LOCATION",
	},
	WORK_FROM_HOME: {
		heading: "Work from home",
		description:
			"Candidates would be required to work from home (their own premises)",
		icon: color => <WorkFromHomeIcon fill={color} />,
		value: "WORK_FROM_HOME",
		"data-job-value": "WORK_FROM_HOME",
	},
	FIELD_JOB: {
		heading: "Field job",
		description:
			"Candidates would be required to work in the field, with minimal time spent in the office",
		icon: color => <FieldJobIcon fill={color} />,
		value: "FIELD_JOB",
		"data-job-value": "FIELD_JOB",
	},
};

export const VERIFICATION_CARD_TYPE = {
	WHY_VERIFY: "WHY_VERIFY",
	WHY_AADHAR: "WHY_AADHAR",
	WHY_PAN: "WHY_PAN",
	WHY_DL: "WHY_DL",
	WHY_CLIENT_CONNECTION: "WHY_CLIENT_CONNECTION",
};
export const NEW_VERIFICATION_CARDS_CONSTANTS = {
	WHY_VERIFY: {
		heading: "Why Verify ?",
		textRow1: "Prevent misuse of your company information",
		textRow2: "Make your job live within minutes",
		textRow3: "Connect with job-ready candidates instantly",
		icon1: () => <Icon1 />,
		icon2: () => <Icon2 />,
		icon3: () => <Icon3 />,
	},
	WHY_VERIFY_WHITELISTED: {
		heading: "Why Verify ?",
		textRow1: "Prevent misuse of your company information",
		textRow2: "Make your job live within minutes",
		textRow3: "Get access to FREE coins",
		icon1: () => <Icon1 />,
		icon2: () => <Icon2 />,
		icon3: () => <ApnaTagCoin size={15} />,
	},
	WHY_VERIFY_WHITELISTED_DB: {
		heading: "Why Verify ?",
		textRow1: "Prevent misuse of your company information",
		textRow2: "Hire from +3 crore  pool of  candidates in apnaDatabase ",
		textRow3: "Get access to FREE coins",
		icon1: () => <Icon1 />,
		icon2: () => <Icon2 />,
		icon3: () => <ApnaTagCoin size={15} />,
	},
	WHY_VERIFY_DB: {
		heading: "Why Verify ?",
		textRow1: "Prevent misuse of your company information",
		textRow2: "Hire from +3 crore  pool of  candidates in apnaDatabase ",
		textRow3: "Get access to FREE coins",
		icon1: () => <Icon1 />,
		icon2: () => <Icon2 />,
		icon3: () => <ApnaTagCoin size={15} />,
	},
	WHY_AADHAR: {
		heading: "Why Aadhar?",
		textRow1: "Prevent your identity from being misused by fraudsters",
		textRow2: "Instant OTP based verification. No other process required.",
		textRow3: "Compliant with UIDAI & govt. regulations",
		icon1: () => <AadharIcon1 />,
		icon2: () => <AadharIcon2 />,
		icon3: () => <AadharIcon3 />,
	},
	WHY_PAN: {
		heading: "Why PAN Card?",
		textRow1: "Prevent fraudulent activities to ensure your safety",
		textRow2: "Instant identity verification",
		textRow3: "Compliant with govt regulations.",
		icon1: () => <AadharIcon1 />,
		icon2: () => <PanIcon2 />,
		icon3: () => <PanIcon3 />,
	},
	WHY_DL: {
		heading: "Why Driving Licence?",
		textRow1: "Prevent fraudulent activities to ensure your safety",
		textRow2: "Instant identity verification",
		textRow3: "Compliant with govt regulations.",
		icon1: () => <AadharIcon1 />,
		icon2: () => <PanIcon2 />,
		icon3: () => <PanIcon3 />,
	},
	WHY_CLIENT_CONNECTION: {
		heading: "Why Client Connection?",
		textRow1: "Prevent misuse of your client’s information",
		textRow2: "Be able to use your client’s name in the job post.",
		textRow3: "Connect with job-ready candidates instantly",
		icon1: () => <Icon1 />,
		icon2: () => <Icon2 />,
		icon3: () => <Icon3 />,
	},
};
export const INTERVIEW_METHODS = {
	IN_PERSON_INTERVIEW: "in-person",
	TELEPHONIC_ONLINE_INTERVIEW: "call",
	"in-person": "In-person interview",
	call: "Telephonic/Online interview",
};

export const INTERVIEW_METHODS_MIXPANEL_VALUE = {
	"in-person": "In-person",
	call: "Telephonic",
};

export const INTERVIEW_METHOD_OPTIONS = [
	{
		label: "In-person interview",
		value: INTERVIEW_METHODS.IN_PERSON_INTERVIEW,
	},
	{
		label: "Telephonic/Online interview",
		value: INTERVIEW_METHODS.TELEPHONIC_ONLINE_INTERVIEW,
	},
];

export const INTERVIEW_ADDRESS_TYPE = {
	SAME_AS_JOB_LOCATION: true,
	DIFFERENT_FROM_JOB_LOCATION: false,
};
export const INTERVIEW_ADDRESS_OPTIONS = [
	{
		label: "Same as job location",
		value: INTERVIEW_ADDRESS_TYPE.SAME_AS_JOB_LOCATION,
	},
	{
		label: "Different from job location",
		value: INTERVIEW_ADDRESS_TYPE.DIFFERENT_FROM_JOB_LOCATION,
	},
];

export const APPLICANT_LOCATION_OPTIONS_WFH = [
	{
		label: "Specific city",
		value: APPLICATION_LOCATION_VALUES_FOR_NON_WFH.ENTIRE_CITY,
	},
	{
		label: "Anywhere in India",
		value: APPLICATION_LOCATION_VALUES_FOR_WFH.ENTIRE_INDIA,
	},
];

export const CONFIG_ALLOW_POST_FIELD_JOBS = "allow_post_field_jobs_in_job_post";

export const EXPERIENCE_LEVELS = {
	FRESHER: "fresher",
	ANY: "any",
	EXPERIENCED: "experienced",
	fresher: "f",
	any: "a",
	experienced: "e",
};

export const EXPERIENCE_LEVELS_FE_BE_MAP = {
	f: "fresher",
	a: "any",
	e: "experienced",
};
export const EXPERIENCED_BUCKETS = [
	{ label: "6 months", minYears: 0.5, maxYears: 31 },
	{ label: "1 year", minYears: 1, maxYears: 31 },
	{ label: "2 years", minYears: 2, maxYears: 31 },
	{ label: "3 years", minYears: 3, maxYears: 31 },
	{ label: "5 years", minYears: 5, maxYears: 31 },
	{ label: "10 years", minYears: 10, maxYears: 31 },
];

export const DUPLICATE_ERROR_WARNING_TEXTS = {
	SINGLE_JOB_LOCATION: {
		warning:
			"Other recruiters in your company have listed the same job in the same location. If you still post in <location>, you may get lesser or no applications.",
		error:
			"Duplicate, cannot proceed. You have the same job active in the same location. Please try in a different location or after active job expires.",
	},
	WORK_FROM_HOME: {
		warning:
			"Other recruiters have listed the same job in the same city for this company. If you still post this job, you may get lesser or no applications.",
		error:
			"Duplicate, cannot proceed. You have the same job active in the same city. Please try in a different city or after active job expires.",
		warning_anywhere:
			"Other recruiters have listed the same job for this company. If you still post this job, you may get lesser or no applications.",
		error_anywhere:
			"Duplicate, cannot proceed. You have the same job active. Please try after active job expires",
	},
	FIELD_JOB: {
		warning:
			"Other recruiters have listed the same job in the same city for this company. If you still post this job, you may get lesser or no applications.",
		error:
			"Duplicate, cannot proceed. You have the same job active in the same city. Please try in a different city or after active job expires.",
	},
};

export const missingFieldsStepMapping = {
	deposit_mode: 0,
	tecc_employer_preference: 2,
	company_address: 2,
	company_address_area: 2,
	company_address_city: 2,
	salary_incentive: 0,
	perks_tag: 0,
};
export const missingFieldsStepMappingOrdered = {
	step1: ["salary_incentive", "perks_tag", "deposit_mode"],
	step3: [
		"tecc_employer_preference",
		"company_address",
		"company_address_area",
		"company_address_city",
	],
};
export const MISSING_FIELD_FLOW_TYPE_LEVELS = {
	mandatory: [1, 3],
	optional: [2, 4],
};
export const DEFAULT_MIN_EXPERIENCE_VALUE = 0;
export const MAX_OLD_VIEW_EXPERIENCE_VALUE = 29;
export const FIELD_JOBS_AREA_OPTIONS_KEYS = {
	SPECIFIC_AREA: "SPECIFIC_AREA",
	SAME_CITY: "SAME_CITY",
};

export const FIELD_JOBS_AREA_OPTIONS_KEYS_MIXPANEL_VALUES = {
	SPECIFIC_AREA: "SPECIFIC_AREA",
	SAME_CITY: "SAME_CITY",
};

export const FIELD_JOBS_AREA_OPTIONS_FE_BE_SYNC = {
	null: "SAME_CITY",
	undefined: "SAME_CITY",
	SPECIFIC: "SPECIFIC_AREA",
	SPECIFIC_AREA: "SPECIFIC",
	SAME_CITY: null,
};

export const FIELD_JOBS_AREA_OPTIONS = [
	{
		value: FIELD_JOBS_AREA_OPTIONS_KEYS.SPECIFIC_AREA,
		label: "One specific area",
	},
	{
		value: FIELD_JOBS_AREA_OPTIONS_KEYS.SAME_CITY,
		label: "Anywhere in the city",
	},
];

export const CUSTOM_OPTION = "CUSTOM_OPTION";
export const FEEDBACK_LENGTH_CUTOFF = 125;
export const ADDITIONAL_SALARY_PERKS = {
	OTHER_PERKS: "other_perks",
};
export const FORM_SECTION_HEADINGS_DESCRIPTION = {
	INTERVIEW_METHOD: {
		heading: "Interview method and address",
		description: "Let candidates know how interview will be conducted for this job.",
	},
	FIELD_JOB_INTERVIEW_METHOD: {
		heading: "Company Address",
		description: "Let candidates know where the company is located at",
	},
	INTERVIEWER_DETAILS: {
		heading: "Interviewer Details",
		description: "Who would be connecting with candidates for this job ?",
	},
	BASIC_JOB_DETAILS: {
		heading: "Job details",
		description: "We use this information to find the best candidates for the job.",
	},
	BASIC_LOCATION_DETAILS: {
		heading: "Location",
		description: "Let candidates know where they will be working from.",
	},
	SALARY_COMPENSATION: {
		heading: "Compensation",
		description:
			"Job postings with right salary & incentives will help you find the right candidates.",
	},
	SAMPLE: {
		heading: "",
		description: "",
	},
	REQUIREMENT_DETAILS: {
		heading: "Candidate Requirements",
		description:
			"We’ll use these requirement details to make your job visible to the right candidates.",
	},
	SCREENING: {
		heading: "Screening questions",
		description: "We use this information to find the best candidates for this job",
	},
	JOB_DESCRIPTION: {
		heading: "Job Description",
		description:
			"Describe the responsibilities of this job and other specific requirements here.",
	},
};

export const MISSING_FIELDS = {
	DEPOSIT_MODE: "deposit_mode",
	TECC_EMPLOYER_PREFERENCE: "tecc_employer_preference",
	COMPANY_ADDRESS: "company_address",
	COMPANY_ADDRESS_CITY: "company_address_city",
	COMPANY_ADDRESS_AREA: "company_address_area",
	SALARY_INPUT_FIELD: "salary_incentive",
	SALARY_PERKS_FIELD: "perks_tag",
};

export const SALARY_ERROR_TYPES = {
	EMPTY_MIN: "Empty Min",
	EMPTY_MAX: "Empty Max",
	EMPTY_INCENTIVE: "Empty Incentive",
	MAX_LESS_THAN_MIN: "Max < Min",
	HIGH_MIN_SALARY: "High Total Sal",
	LOW_TOTAL_SALARY: "Low Total Sal",
};

export const SUBCITY_LABEL_MAPPINGS = {
	"Delhi-NCR": "Delhi-NCR (All NCR region cities)",
};

export const JOB_POST_FORM_STEPS = {
	BASIC_DETAILS: 0,
	CANDIDATE_REQUIREMENT: 1,
	INTERVIEW_INFORMATION: 2,
	JOB_PREVIEW: 3,
	SELECT_PLAN: 4,
};

const JoyrideHeading = styled.div`
	display: flex;
	gap: 5px;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	padding: 0px;
`;
const HeadingText = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: "172b4d";
`;
const NewNudge = styled.div`
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const JoyrideContent = styled.div`
	display: flex;
	width: 100%;
	color: #5e6c84;
	justify-content: flex-start;
	padding: 0px;
`;

export const JOYRIDE_STEPS_DEPARTMENT = [
	{
		title: (
			<JoyrideHeading style={{ textAlign: "left" }}>
				<HeadingText>Department / Function</HeadingText>
				<NewNudge>
					<NewFieldNudge />
				</NewNudge>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				Department / Function is a high-level grouping of tasks performed within
				your company to support your business goals.
			</JoyrideContent>
		),
		target: ".department-input",
		placement: "bottom",
		styles: {
			options: {
				width: 388,
				innerHeight: 172,
			},
		},
		disableBeacon: true,
	},
];
export const JOYRIDE_STEPS_SUBDEPARTMENT = [
	{
		title: (
			<JoyrideHeading>
				<HeadingText>Category / Role</HeadingText>
				<NewNudge>
					<NewFieldNudge />
				</NewNudge>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				Category / Role helps us understand the responsibilities and nature of
				work within a Department / Function
			</JoyrideContent>
		),

		target: ".subdepartment-input",
		placement: "bottom",
		styles: {
			options: {
				width: 388,
			},
		},
		disableBeacon: true,
	},
];
export const JOB_DETAILS_JOYRIDE_STEPS_INDUSTRY = [
	{
		title: (
			<JoyrideHeading>
				<HeadingText>Industry Preference</HeadingText>
				<NewNudge>
					<NewFieldNudge />
				</NewNudge>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				Preferred industry will help us target and match your job with candidates
				who have prior experience working in the industry.
			</JoyrideContent>
		),
		target: ".industry-preference-input",
		styles: {
			options: {
				width: 388,
			},
			buttonClose: {
				background: "#1F8268",
			},
		},
		placementBeacon: "top",
		isFixed: true,
		disableBeacon: true,
	},
];
export const JOYRIDE_APNA_DB_CTA = [
	{
		title: "",
		content: (
			<>
				<JoyrideContent>
					<iframe
						width={window.innerWidth > 991 ? "560" : "350"}
						height={window.innerWidth > 991 ? "315" : "185"}
						src="https://www.youtube.com/embed/LZ2huwDVr3o?rel=0"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
					/>
				</JoyrideContent>
				<div style={{ textAlign: "left" }}>
					<div
						style={{
							fontWeight: "800",
							marginBottom: "20px",
							fontSize: "20px",
							color: "rgb(48 105 227)",
						}}
					>
						{`\u{1F4E2}`}
						&nbsp; Introducing apnaDatabase!
					</div>
					<div style={{ marginBottom: "10px" }}>
						{`\u{1F4CC}`}
						&nbsp; Get access to
						<b> 40 Million+ candidates </b>
					</div>
					<div style={{ marginBottom: "10px" }}>
						{`\u{1F4CC}`}
						&nbsp;
						<b>Pay as you go - </b>
						No long term packages
					</div>
					<div style={{ marginBottom: "10px" }}>
						{`\u{1F4CC}`}
						&nbsp;
						<b> Use existing coin balance </b>
						to unlock profiles
					</div>
				</div>
			</>
		),
		target: ".apnaDatabaseCta",
		placement: "right",
		styles: {
			options: {
				width: window.innerWidth > 991 ? 560 : 350,
			},
			buttonClose: {
				display: "none",
				background: "white",
			},
		},
		disableBeacon: true,
	},
];

export const ADDITIONAL_PREFERENCES_JOYRIDE_STEPS = [
	{
		title: (
			<JoyrideHeading>
				<HeadingText>Introducing Additional Requirement</HeadingText>
				<NewNudge>
					<NewFieldNudge />
				</NewNudge>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				Candidates who match these requirements will be given preference for your
				job
			</JoyrideContent>
		),
		placement: "bottom",
		target: ".additional-requirements-field",
		styles: {
			options: {
				width: 388,
				padding: 0,
			},
			buttonClose: {
				background: "#1F8268",
				padding: 0,
			},
		},
		isFixed: true,
		disableBeacon: true,
	},
];
export const JOB_DETAILS_JOYRIDE_STEPS_SKILLS = [
	{
		title: (
			<JoyrideHeading>
				<HeadingText>Skills</HeadingText>
				<NewNudge>
					<NewFieldNudge />
				</NewNudge>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				Add job-specific skills to target and attract high-quality candidates
				through skill-matching. Please select as many (up to 10) to attract a
				large pool of candidates.
			</JoyrideContent>
		),
		target: ".skill-section-autocomplete",
		placement: "bottom",
		styles: {
			options: {
				width: 388,
				padding: 0,
			},
			buttonClose: {
				background: "#1F8268",
				padding: 0,
			},
		},
		placementBeacon: "top",
		isFixed: true,
	},
];
export const JOB_DETAILS_JOYRIDE_STEPS_DEGREE = [
	{
		title: (
			<JoyrideHeading>
				<HeadingText>Degree preference</HeadingText>
				<NewNudge>
					<NewFieldNudge />
				</NewNudge>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				Preferred Degree will help us target and match your job with relevant
				candidates.
			</JoyrideContent>
		),
		target: ".degree-input-field",
		placement: "bottom",
		styles: {
			options: {
				width: 388,
			},
			buttonClose: {
				background: "#1F8268",
			},
		},
		disableBeacon: true,
	},
];
export const JOB_PREVIEW_EDIT_JOYRIDE = [
	{
		title: (
			<JoyrideHeading>
				<HeadingText>Review & Edit</HeadingText>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				You can now review and edit your job postings from here
			</JoyrideContent>
		),
		target: ".previewEditCta",
		placement: "bottom",
		styles: {
			options: {
				width: 388,
			},
			buttonClose: {
				background: "#1F8268",
			},
		},
		disableBeacon: true,
	},
];
export const NEW_USER_JOB_POST_JOYRIDE = [
	{
		title: (
			<JoyrideHeading>
				<HeadingText>Post a job</HeadingText>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				You can post a new job from here to start receiving applications
			</JoyrideContent>
		),
		target: ".jobPostCta",
		placement: "bottom",
		styles: {
			options: {
				width: 388,
			},
			buttonClose: {
				background: "#1F8268",
			},
		},
		disableBeacon: true,
	},
];
export const NEW_USER_DATABASE_JOYRIDE = [
	{
		title: (
			<JoyrideHeading>
				<HeadingText>Database search</HeadingText>
			</JoyrideHeading>
		),
		content: (
			<JoyrideContent>
				Search and access profiles from a large pool of candidates
			</JoyrideContent>
		),
		target: ".databaseCta",
		placement: "left",
		styles: {
			options: {
				width: 388,
			},
			buttonClose: {
				background: "#1F8268",
			},
			overlay: { minHeight: "100vh" },
		},
		disableBeacon: true,
	},
];

export const EDU_LEVELS_FOR_DEGREE_SECTION = {
	DIPLOMA: 7,
	GRADUATE: 1,
	POST_GRADUATE: 2,
};

export const MIN_EDU_LEVELS_OBJECT = {
	TENTH_OR_BELOW_10TH: "10th or Below 10th",
	TWELTH_PASS: "12th Pass",
	DIPLOMA: "Diploma",
	GRADUATE: "Graduate",
	POST_GRADUATE: "Post Graduate",
};
export const REQ_EDU_LEVELS = {
	DIPLOMA: "1,2,7",
	GRADUATE: "1,2",
	POST_GRADUATE: "2",
};
export const EMAIL_REGEX = new RegExp(
	/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
);
export const MOBILE_NUMBER_REGEX = new RegExp(
	"\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]?\\d{9,})+",
	"g"
);

export const NO_OPTIONS_TEXT = {
	CITY: "City not found",
	AREA: "Area not found",
	SELECT_CITY: "Please select a city",
};

export const USER_SOURCES = {
	EDIT_JOB: "Edit Job",
	DUPLICATE_JOB: "Duplicate Job",
	RENEW_JOB: "Renew Job",
	SELECT_PLAN: "Select Plan",
	HEADER_CTA: "Header CTA",
	BWCP: "BWCP",
	ONBOARDING: "Onboarding",
	A2H: "A2H",
	OTHERS: "Others",
	WHATSAPP: "WhatsApp",
};

export const USER_TYPE = {
	INTERNAL: "Internal User",
	NEW: "New User",
	EXISTING: "Existing User",
};

export const JOB_POST_ANY_OPTIONS_MAP = {
	GRADUATE: "Any Graduate",
	INDUSTRY: "Any Industry",
	POST_GRADUATE: "Any Postgraduate",
};

export const AGE_STATUS = {
	YES: "Yes",
	NO: "No",
};

export const JOB_DETAILS_BANNER_DESCRIPTIONS = {
	DEGREE: {
		heading: "Job will be promoted to candidates with selected degrees",
		subText: "Although candidates with other degrees can still apply",
	},
	INDUSTRY: {
		heading: "Job will be promoted to candidates with selected industries",
		subText: "Although candidates with other industry experience can still apply",
	},
	SKILL: {
		heading: "Job will be promoted to candidates with selected skills",
		subText:
			"Although candidates who have mentioned other skills in their profile can still apply",
	},
	MUST_HAVE:
		"Only candidates that meet your 'Must-have qualification' will see this job.",
	EXPERIENCE_LEVELS: {
		ANY: "Both fresher & experienced candidates will be able to apply",
		FRESHER:
			"Only candidates with upto 1 year of experience will be eligible to apply",
	},
	LANGUAGE:
		"The candidates who apply will be able to speak atleast one of selected languages",
	RELATED_TITLE: "Adding related titles helps in reaching more relevant candidates",
};
export const NON_STD_JOB_TITLE_NUDGE =
	"We have noticed you have entered a custom title. We recommend selecting title from dropdown as it results in faster matching and better candidates.";

export const DEPARTMENT_CRITERIA_OPTION = {
	DEPARTMENT_ONLY: "DEPARTMENT_ONLY",
	SUBDEPARTMENT_ONLY: "SUBDEPARTMENT_ONLY",
	MULTIPLE_DEPARTMENTS: "MULTIPLE_DEPARTMENTS",
	ANY_DEPARTMENT: "ANY_DEPARTMENT",
};

export const NUDGE_FOR_PREFFERED_DEPARTMENT = "NUDGE_FOR_PREFFERED_DEPARTMENT";
export const SHOW_KALEYRA_CALL_HELP = false;

export const DEPARTMENT_CRITERIA_OPTION_MXP_VALUE = {
	DEPARTMENT_ONLY: "JD Only",
	SUBDEPARTMENT_ONLY: "Subdepartment Only",
	MULTIPLE_DEPARTMENTS: "Multiple departments",
	ANY_DEPARTMENT: "Any department",
};

export const DEPARTMENT_CRITERIA_OPTION_PRICING_MAP = {
	DEPARTMENT_ONLY: "same_department",
	SUBDEPARTMENT_ONLY: "same_sub_department",
	MULTIPLE_DEPARTMENTS: "multiple_departments",
	ANY_DEPARTMENT: "all_departments",
	BASIC: "basic",
};

export const ANY_SECTOR_INDUSTRY_OBJECT = {
	id: -1,
	title: "Any Industry",
};
export const LOCATION_HEADING_TEXT = {
	LOCATION_PREVIEW: "Location preview",
	OFFICE_ADDRESS: "Office address / landmark *",
	SPECIFIC_AREA_ADDRESS: "Area / locality *",
	COMPANY_ADDRESS: "Company Address *",
	INTERVIEW_ADDRESS: "Interview Address *",
};

export const PRICING_EDIT_WARNING_MODAL_CONFIG = {
	SUBDEPARTMENT: {
		open: true,
		header: "Changing basic details",
		description: "We noticed that you are hiring for a different job category / Role",
	},
	EXPERIENCE: {
		open: true,
		header: "Changing candidate requirement",
		description:
			"We noticed that you are trying to change candidate's total experience for which you have already paid.",
	},
	CANDIDATE_DEPARTMENT_EXPERIENCE: {
		open: true,
		header: "Changing candidate requirement",
		description:
			"We noticed that you are trying to change candidate's total experience for which you have already paid.",
	},
};

export const SAVE_JOB_POST = "SAVE_JOB_POST";
export const SAVE_JOB_POST_TIMER = 5000;
export const TURN_ON_EDIT_PRICING_RESTRICTION = false;

export const LOCATION_TYPE = {
	OFFICE_ADDRESS: "Office address",
	COMPANY_ADDRESS: "Company address",
	INTERVIEW_ADDRESS: "Interview address",
};

export const SALARY_MAX_LIMIT = 149999;
export const INCENTIVE_MAX_LIMIT = 99999;

export const ALLOWED_ALL_EXPERIENCES = [
	EXPERIENCE_LEVELS.EXPERIENCED,
	EXPERIENCE_LEVELS.ANY,
	EXPERIENCE_LEVELS.FRESHER,
];

export const PRICING_RESTRICTION_RULES = {
	[DEPARTMENT_CRITERIA_OPTION.SUBDEPARTMENT_ONLY]: {
		experience: ALLOWED_ALL_EXPERIENCES,
		departmentOption: [
			DEPARTMENT_CRITERIA_OPTION.ANY_DEPARTMENT,
			DEPARTMENT_CRITERIA_OPTION.DEPARTMENT_ONLY,
			DEPARTMENT_CRITERIA_OPTION.MULTIPLE_DEPARTMENTS,
			DEPARTMENT_CRITERIA_OPTION.SUBDEPARTMENT_ONLY,
		],
	},
	[DEPARTMENT_CRITERIA_OPTION.DEPARTMENT_ONLY]: {
		experience: ALLOWED_ALL_EXPERIENCES,
		departmentOption: [
			DEPARTMENT_CRITERIA_OPTION.ANY_DEPARTMENT,
			DEPARTMENT_CRITERIA_OPTION.DEPARTMENT_ONLY,
			DEPARTMENT_CRITERIA_OPTION.MULTIPLE_DEPARTMENTS,
		],
	},
	[DEPARTMENT_CRITERIA_OPTION.MULTIPLE_DEPARTMENTS]: {
		experience: ALLOWED_ALL_EXPERIENCES,
		departmentOption: [
			DEPARTMENT_CRITERIA_OPTION.ANY_DEPARTMENT,
			DEPARTMENT_CRITERIA_OPTION.MULTIPLE_DEPARTMENTS,
		],
	},
	[DEPARTMENT_CRITERIA_OPTION.ANY_DEPARTMENT]: {
		experience: ALLOWED_ALL_EXPERIENCES,
		departmentOption: [DEPARTMENT_CRITERIA_OPTION.ANY_DEPARTMENT],
	},
};

export const TRUE_ECC_PREFERENCE_SELECTION = {
	YES: "yes",
	YES_TO_RECRUITER: "yes + other recruiter",
	NO: "no",
};

export const GENDER_DEFAULT_VALUE = "both";

export const APPLICATION_LOCATION_DEFAULT_VALUE =
	APPLICATION_LOCATION_VALUES_FOR_NON_WFH.ENTIRE_CITY;

export const GENDER_OPTION = [
	{
		label: "Both",
		value: "both",
	},
	{
		label: "Male",
		value: "male",
	},
	{
		label: "Female",
		value: "female",
	},
];

export const EXPERIENCE_OPTION = [
	{
		label: "Any",
		value: EXPERIENCE_LEVELS.ANY,
	},
	{
		label: "Experienced only",
		value: EXPERIENCE_LEVELS.EXPERIENCED,
	},
	{
		label: "Fresher only",
		value: EXPERIENCE_LEVELS.FRESHER,
	},
];

export const DEFAULT_VALUES_FOR_BULK_JOB = {
	MINIMUM_EDUCATION: MIN_EDU_LEVELS_OBJECT.TENTH_OR_BELOW_10TH,
	ENGLISH_LEVEL: ENGLISH_LEVEL_FORM_DATA.noEnglish,
};

export const DEFAULT_VALUES_FOR_PPJ = {
	JOB_TYPE: FULL_TIME,
	REMOTE_APPLICANT_ELIGIBLE: true,
	DEPOSIT_STATUS: DEPOSIT_STATUS.NO,
};

export const PPJ_MIXPANEL_EVENT_NAMES = {
	BANNER_SHOWN: "PPJ banner shown",
	BANNER_CLOSE: "PPJ banner close click",
	BANNER_KNOW_MORE_CLICK: "PPJ banner know more click",
	EDUCATIONAL_MODAL_SHOWN: "PPJ education modal shown",
	EDUCATIONAL_MODAL_CLOSED: "PPJ education modal close click",
	EDUCATION_MODAL_S1_CONTINUE_CLICK: "PPJ education modal step 1 continue click",
	EDUCATION_MODAL_S2_CONTINUE_CLICK: "PPJ education modal step 2 continue click",
	EDUCATION_MODAL_S3_CONTINUE_CLICK: "PPJ education modal step 3 continue click",
	EDUCATION_MODAL_S4_CONTINUE_CLICK: "PPJ education modal step 4 continue click",
	EDUCATION_MODAL_BACK_CLICK: "PPJ education back button clicked",
};

export const PPJ_BANNER_SOURCE = {
	0: "employer page",
	1: "step 1",
	2: "step 2",
	3: "step 3",
};

export const PPJ_EDUCATIONAL_MODAL_STEP_EVENT = {
	1: PPJ_MIXPANEL_EVENT_NAMES.EDUCATION_MODAL_S1_CONTINUE_CLICK,
	2: PPJ_MIXPANEL_EVENT_NAMES.EDUCATION_MODAL_S2_CONTINUE_CLICK,
	3: PPJ_MIXPANEL_EVENT_NAMES.EDUCATION_MODAL_S3_CONTINUE_CLICK,
	4: PPJ_MIXPANEL_EVENT_NAMES.EDUCATION_MODAL_S4_CONTINUE_CLICK,
};
export const PPJ_PRE_LAUNCH_BANNER = "PPJ_PRE_LAUNCH_BANNER";
export const PPJ_PRE_LAUNCH_BANNER_MP_EVENT = {
	BANNER_SHOWN: "PPJ pre-launch banner shown",
	BANNER_CLOSED: "PPJ pre-launch banner close click",
};

export const JPF_FORM_STEP_NAME = {
	JOB_DETAILS: "job_details",
	CANDIDATE_REQUIREMENTS: "candidate_requirements",
	INTERVIEW_INFORMATION: "interview_information",
};

export const FORM_HEADINGS_FOR_PREVIEW = {
	JOB_DETAILS: "Job Details",
	CANDIDATE_REQUIREMENTS: "Candidate Requirements",
	INTERVIEW_INFORMATION: "Interview Information",
};

export const DAY_SHIFT_TYPE_FORM_DATA = "day";

export const JOB_SHIFT_TYPE_TEXT_PREVIEW = {
	DAY_SHIFT: "Day shift",
	NIGHT_SHIFT: "Night shift",
};
export const DEPOSIT_AWARENESS_BANNER_SHOWN = "DEPOSIT_AWARENESS_BANNER_SHOWN";

export const JOB_PREVIEW_EDIT_JOYRIDE_SHOWN = "JOB_PREVIEW_EDIT_JOYRIDE_SHOWN";

export const ENGLISH_LEVEL_STRINGS_FOR_PREVIEW = {
	NO_ENGLISH: {
		key: "noEnglish",
		val: "No English Required",
	},
	INTERMEDIATE_ENGLISH: {
		key: "intermediateEnglish",
		val: "Good English",
	},
	BASIC_ENGLISH: {
		key: "basicEnglish",
		val: "Basic English",
	},
	ADVANCED_ENGLISH: {
		key: "advancedEnglish",
		val: "Advanced English",
	},
	DEFAULT_VAL: "No English",
};

export const NATURE_OF_JOB = {
	BULK: "Bulk job",
	PPJ: "PPJ",
	PPL: "PPL",
};
export const LANGUAGE_ELLIGILBILITY = {
	YES: "Yes",
	NO: "No",
};

export const ASSET_TAGS_ELIGIBILITY = {
	YES: "Yes",
	NO: "No",
};

export const DUPLICATE_JOB_SOURCE = {
	JOB_LISTING: "Job listing",
	UR_PAGE: "UR page",
	JOB_MENU: "Job Menu",
};

export const NEW_USER_JOYRIDE_SHOWN = {
	JOB_POST: "JOB_POST_CTA_JOYRIDE_SHOWN",
	DATABASE: "DATABASE_CTA_JOYRIDE_SHOWN",
	WORKSPACE_SWITCHER: "WORKSPACE_SWITCH_CTA_JOYRIDE_SHOWN",
};
export const JOYRIDE_MP_EVENTS = {
	JOB_POST_JOYRIDE_SHOWN: "Post a job coachmark shown",
	JOB_POST_JOYRIDE_CLOSED: "Post a job coachmark closed",
	DATABASE_JOYRIDE_SHOWN: "Databse search coachmark shown",
	DATABASE_JOYRIDE_CLOSED: "Database search coachmark closed",
};
export const MAX_INDUSTRIES_LIMIT = 3;

export const COMPANY_TYPE_SELECTED = {
	STANDARD: "standard",
	NON_STANDARD: "non_standard",
};
export const JPF_FORM_STEP_INDEX = {
	BASIC_DETAILS: 0,
	CANDIDATE_REQUIREMENTS: 1,
	INTERVIEW_INFORMATION: 2,
	JOB_PREVIEW: 3,
};

export const JOB_POST_ACTIONS = {
	PREFILL: "prefill",
};

export const JOB_POST_QUERY_PARAMS = {
	JOB_TITLE_ID: "ti",
	JOB_TITLE_NAME: "jt",
	DEPARTMENT_ID: "di",
	SUBDEPARTMENT_ID: "si",
	CLASSIFICATION: "ci",
};

export const GENDER_PREF_OPTIONS = [
	{ label: "Yes", val: true },
	{ label: "No", val: false },
];

export const FEEDBACK_MODAL_OPTIONS = {
	NEW_USER: {
		CANDIDATE: "I am looking for a job (मुझे नौकरी चाहिए)",
		LOCATION_UNAVAILABLE: "Could not find the location I am hiring for",
		TIME_ISSUE: "Not enough time, will do it later",
		FREE_JOB_POSTING: "Looking for free job posting",
		OTHER_REASON: "Other reasons",
	},
	EXISTING_USER: {
		IRRELEVANT_CANDIDATES: "Less matching candidates to my job last time",
		LESS_CANDIDATES: "Less applications received last time",
		TIME_ISSUE: "Not enough time, will do it later",
		REQUIRED_VALUES_UNAVAILABLE:
			"Couldn’t find the right job title, department or category",
		OTHER_REASON: "Other reasons",
	},
};

export const FEEDBACK_MODAL_SHOWN = "FEEDBACK_MODAL_SHOWN";

export const DROPOFF_MODAL_TYPES = {
	COIN_EXPIRY: "COIN_EXPIRY",
	DISCOUNT_COUPON: "DISCOUNT_COUPON",
	CANDIDATE_COUNT: "CANDIDATE_COUNT",
};
export const ADDITIONAL_FIELDS_KEYS = {
	AGE: "age",
	GENDER: "gender",
	REGIONAL_LANGUAGES: "regionalLanguages",
	SKILLS: "skill",
	ASSETS: "assets",
	DEGREE: "degree",
	PREFERRED_INDUSTRY: "preferredIndustry",
};

export const EDU_LEVEL_IDS_FOR_DEGREE_SECTION = [1, 2, 7];

export const DEFAULT_ADDITIONAL_FIELDS_OBJECTS_LIST = [
	{
		key: ADDITIONAL_FIELDS_KEYS.AGE,
		label: "Age",
		selected: false,
	},
	{
		key: ADDITIONAL_FIELDS_KEYS.GENDER,
		label: "Gender",
		selected: false,
	},
	{
		key: ADDITIONAL_FIELDS_KEYS.SKILLS,
		label: "Skills",
		selected: false,
	},
	{
		key: ADDITIONAL_FIELDS_KEYS.REGIONAL_LANGUAGES,
		label: "Regional Languages",
		selected: false,
	},
	{
		key: ADDITIONAL_FIELDS_KEYS.ASSETS,
		label: "Assets",
		selected: false,
	},
];

export const DEFAULT_ADDITIONAL_FIELDS_KEYS_LIST = [
	ADDITIONAL_FIELDS_KEYS.AGE,
	ADDITIONAL_FIELDS_KEYS.GENDER,
	ADDITIONAL_FIELDS_KEYS.SKILLS,
	ADDITIONAL_FIELDS_KEYS.REGIONAL_LANGUAGES,
	ADDITIONAL_FIELDS_KEYS.ASSETS,
];

export const RELATED_TITLE_TOOLTIP_SHOWN = "RELATED_TITLE_TOOLTIP_SHOWN";
export const JPF_PAGE_HASH = {
	STEP_1: "#basic",
	STEP_2: "#candidate-requirement",
	STEP_3: "#interview-information",
	STEP_4: "#job-preview",
};

export const PJF_STEPS = {
	0: "Job details",
	1: "Candidate requirements",
	2: "Interviewer information",
	3: "Job preview",
	4: "Select plan",
};

export const JOB_STATUS = {
	ACTIVE: "Active",
	UR: "Under review",
	EXPIRED: "Expired",
	NOT_APPROVED: "Not approved",
	SELECT_PLAN: "Select Plan",
};

export const TYPE_OF_JOB = {
	NEW_JOB: "New Job",
	EDIT_JOB: "Edit Job",
	DUPLICATE_JOB: "Duplicate Job",
};

export const LOW_FF_NUDGE_MP_EVENTS = {
	JOB_LISTING_PAGE_SUGGESTION_BANNER_SHOWN:
		"Job listing page - Field change suggestion banner shown",
	UPDATE_REQUIREMENTS_CLICK: "Update requirement button click",
	SUCCESSFULLY_UPDATED_NUDGE_SHOWN: "Successfully updated popup/message shown",
	MODAL_UPDATE_BUTTON_CLICKED: "Update button click",
	JOB_LISTING_PAGE_SUGGESTION_MODAL_SHOWN:
		"Job listing page - Field change suggestion modal shown",
	CROSS_ICON_CLICK: "Cross Icon Click",
	CANCEL_BUTTON_CLICK: "Cancel button click",
};
