import {
	UPDATE_PPJ_CONFIG,
	UPDATE_BULK_JOB_CONFIG,
	UPDATE_EMPDASH_RELATED_TITLE_RATIO_CONFIG,
} from "../constants/ConfigTypes";

const initialState = {
	ppj: {
		enable: true,
		enable_for_all: false,
		enable_for_all_creator_orgs: true,
		share_sub_dept_in_skill_suggestions: true,
		related_title_x_factor: 1,
		show_related_title: true,
		preview_config: {
			default_open_preview_page: 1,
			enable_for_all: false,
			show_preview_actions_ppl: ["renew", "duplicate", "edit"],
			show_preview_actions_ppj: ["renew", "duplicate", "edit"],
		},
	},
	bulkJob: { enable_for_all: false, whitelisted_dep_ids: [12] },
	relatedTitleRatio: { ratio: [3, 2, 3, 2], targetLen: 10 },
};

const config = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_PPJ_CONFIG:
			return {
				...state,
				ppj: action.payload,
			};
		case UPDATE_BULK_JOB_CONFIG:
			return {
				...state,
				bulkJob: action.payload,
			};
		case UPDATE_EMPDASH_RELATED_TITLE_RATIO_CONFIG:
			return {
				...state,
				relatedTitleRatio: action.payload,
			};
		default:
			return state;
	}
};

export default config;
