import React from "react";

const ReportsIcon = ({ fill = "#5E6C84", ...props }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_755_8484)">
				<path
					d="M16.2222 2H3.77778C2.8 2 2 2.8 2 3.77778V16.2222C2 17.2 2.8 18 3.77778 18H16.2222C17.2 18 18 17.2 18 16.2222V3.77778C18 2.8 17.2 2 16.2222 2ZM6.44444 14.4444C5.95556 14.4444 5.55556 14.0444 5.55556 13.5556V9.11111C5.55556 8.62222 5.95556 8.22222 6.44444 8.22222C6.93333 8.22222 7.33333 8.62222 7.33333 9.11111V13.5556C7.33333 14.0444 6.93333 14.4444 6.44444 14.4444ZM10 14.4444C9.51111 14.4444 9.11111 14.0444 9.11111 13.5556V6.44444C9.11111 5.95556 9.51111 5.55556 10 5.55556C10.4889 5.55556 10.8889 5.95556 10.8889 6.44444V13.5556C10.8889 14.0444 10.4889 14.4444 10 14.4444ZM13.5556 14.4444C13.0667 14.4444 12.6667 14.0444 12.6667 13.5556V11.7778C12.6667 11.2889 13.0667 10.8889 13.5556 10.8889C14.0444 10.8889 14.4444 11.2889 14.4444 11.7778V13.5556C14.4444 14.0444 14.0444 14.4444 13.5556 14.4444Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_755_8484">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ReportsIcon;
