import React from "react";

const PanIcon2 = () => (
	<svg
		width="20"
		height="18"
		viewBox="0 0 20 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.1673 9L17.134 6.675L17.4173 3.6L14.409 2.91667L12.834 0.25L10.0007 1.46667L7.16732 0.25L5.59232 2.90833L2.58398 3.58333L2.86732 6.66667L0.833984 9L2.86732 11.325L2.58398 14.4083L5.59232 15.0917L7.16732 17.75L10.0007 16.525L12.834 17.7417L14.409 15.0833L17.4173 14.4L17.134 11.325L19.1673 9ZM7.81732 12.3417L5.83398 10.3417C5.50898 10.0167 5.50898 9.49167 5.83398 9.16667L5.89232 9.10833C6.21732 8.78333 6.75065 8.78333 7.07565 9.10833L8.41732 10.4583L12.709 6.15833C13.034 5.83333 13.5673 5.83333 13.8923 6.15833L13.9507 6.21667C14.2757 6.54167 14.2757 7.06667 13.9507 7.39167L9.01732 12.3417C8.67565 12.6667 8.15065 12.6667 7.81732 12.3417Z"
			fill="#005F3E"
		/>
	</svg>
);

export default PanIcon2;
