import {
	TOTAL_COIN_BALANCE,
	ACCOUNT_ID,
	BUY_COINS,
	UPDATE_APNA_COIN_PRODUCT,
	EXTRA_COINS_REQUIRED,
	RENEWAL_SUCCESS_MODAL,
	DEBIT_COINS_DETAILS,
	PAYMENT_STATUS_SUCCESS,
	IS_ACCOUNT_DETAILS_FETCHED,
	SHOW_ACTIVATION_PLANS_POPUP,
	PAID_JOBS_COUNT,
	SHOW_COMPLIMENTARY_COINS_MESSAGE,
	REFETCH_ACTIVATION_PLANS,
	UPDATE_SELECTED_ACTIVATION_PLAN,
	SHOW_APNA_PROMISE_MODAL,
	FREE_BANNER_JOBS,
	UPDATE_REFUND_DETAILS,
	INVALID_RETRY_PAYMENT_MODAL,
	IS_FIRST_PURCHASE,
	FETCH_TRANSACTIONS,
	MIN_BALANCE_FOR_TRANSFER,
	JOB_POSTING_MODAL,
	BACK_TO_SELECT_PLAN,
	INSUFFICIENT_BALANCE_NUDGE,
	ACCOUNT_TYPE,
	TNC_MODAL,
	SET_COINS_SUMMARY,
	SET_NEW_LAYOUT_HEADER_HEIGHT,
	SET_PRICING_CONTRACT_DATA,
	SET_COINS_META_DATA,
	SET_BUY_COINS_3_RENDER_VIEW,
	SET_PACKAGES,
	SET_USER_CART_DETAILS,
	SET_PRICING_PLANS,
	SET_POST_PAYMENT_ORDER_DETAILS,
	SET_USER_CART_SELECTPLAN,
	EXPRESS_CHECKOUT_SELECTPLAN_MERGE_COHORT,
} from "../constants/MonetisationTypes";
import {
	APP_BAR_HEIGHT,
	ENTERPRISE_USAGE_DATA,
} from "../enterpriseGrading/constants/constants";
import CommonUtils from "../utils/commonUtils";
import {
	RETRY_PAYMENT_MODAL_CODES,
	ACTIVATION_PLANS_TYPE,
	JOB_POSTING_MODAL_TYPE,
	BUY_COINS_3_RENDER_VIEW,
} from "../utils/constants";
import {
	BUY_COINS_PACKAGES,
	BUY_COINS_PRICING_PLANS,
} from "../views/JobsPage/selectPlan/constants";

const persistedPackages =
	JSON.parse(CommonUtils.getItemFromLocalStorage(BUY_COINS_PACKAGES)) || [];

const persistedPricingPlans =
	JSON.parse(CommonUtils.getItemFromLocalStorage(BUY_COINS_PRICING_PLANS)) || {};

const initialState = {
	totalCoinBalance: null,
	buyCoinsSideDraw: {
		show: false,
		coins: null,
		moneyAmount: null,
		packType: null,
		imageURL: "",
		packageId: "",
	},
	accountId: null,
	accountType: null,
	apnaCoinProduct: {},
	coinsRequiredToPostJob: 0,
	showRenewalSuccessModal: false,
	showActivationPlansPopup: false,
	debitCoinsApiDetails: {
		jobId: "",
		service: {},
		status: 1,
		jobData: {},
		bulkUserInput: {},
	},
	paymentStatusSuccess: false,
	isAccountDetailsFetched: false,
	paidJobsCount: 0,
	showComplimentaryCoinsMessage: false,
	isRefetchActivationPlans: false,
	selectedJobActivationPlan: {},
	showApnaPromiseModal: false,
	freeBannerJobs: 0,
	refundDetails: {
		coins: 0,
		jobId: "",
	},
	selectPlanWarningDetails: {
		show: false,
		jobData: {},
		modalType: ACTIVATION_PLANS_TYPE.SUFFICIENT_FOR_SOME_PLANS,
		redirectionSource: "",
	},
	invalidRetryPaymentModal: {
		show: false,
		coins: 0,
		modalType: RETRY_PAYMENT_MODAL_CODES[3],
	},
	isFirstPurchase: false,
	expiredFreeJobDetails: {
		expiredFreeJobsList: [],
		totalJobs: 0,
		yLimitBreachedJobs: 0,
	},
	fetchTransactions: false,
	isFreeJobDetailsFetched: false,
	jobPostingModal: {
		show: false,
		modalType: JOB_POSTING_MODAL_TYPE.LOADER,
	},
	redirectedToSP: {
		jobId: "",
		coinsRefunded: 0,
	},
	insufficientBalNudgeDetails: {
		show: false,
		couponId: "",
		minPurchase: 0,
		discountPercent: 0,
		maxDiscount: 0,
		couponAppliedSource: "",
	},
	openTnCModal: false,
	coinsSummary: {},
	freeJobBanner: {
		show: false,
		ctaClicked: false,
	},
	newLayoutHeaderHeight: APP_BAR_HEIGHT,
	pricingContractData: {
		isEligible: false,
		modal: {
			show: false,
			modalType: "",
		},
	},
	buyCoins3: {
		coinsMetaData: [],
		renderView: BUY_COINS_3_RENDER_VIEW.DEFAULT,
		packages: persistedPackages,
		pricingPlans: persistedPricingPlans,
		userCart: null,
		postPaymentOrderDetails: {
			razorPayOrderId: "",
		},
	},
	userCartSelectPlanPage: {},
	expressCheckoutSelectPlanMergeCohort: false,
};

const monetisation = (state = initialState, action) => {
	switch (action.type) {
		case TOTAL_COIN_BALANCE:
			return {
				...state,
				totalCoinBalance: action.payLoad,
			};
		case ACCOUNT_ID:
			return {
				...state,
				accountId: action.payLoad,
			};
		case ACCOUNT_TYPE:
			return {
				...state,
				accountType: action.payload,
			};
		case BUY_COINS:
			return {
				...state,
				buyCoinsSideDraw: {
					...action.payLoad,
				},
			};
		case UPDATE_APNA_COIN_PRODUCT:
			return {
				...state,
				apnaCoinProduct: action.payload,
			};
		case EXTRA_COINS_REQUIRED:
			return {
				...state,
				coinsRequiredToPostJob: action.payLoad,
			};
		case RENEWAL_SUCCESS_MODAL:
			return {
				...state,
				showRenewalSuccessModal: action.payLoad,
			};
		case DEBIT_COINS_DETAILS:
			return {
				...state,
				debitCoinsApiDetails: {
					...action.payLoad,
				},
			};
		case PAYMENT_STATUS_SUCCESS:
			return {
				...state,
				paymentStatusSuccess: action.payLoad,
			};
		case IS_ACCOUNT_DETAILS_FETCHED:
			return {
				...state,
				isAccountDetailsFetched: action.payload,
			};
		case SHOW_ACTIVATION_PLANS_POPUP:
			return {
				...state,
				showActivationPlansPopup: action.payload,
			};
		case PAID_JOBS_COUNT:
			return {
				...state,
				paidJobsCount: action.payload,
			};
		case SHOW_COMPLIMENTARY_COINS_MESSAGE:
			return {
				...state,
				showComplimentaryCoinsMessage: action.payload,
			};
		case REFETCH_ACTIVATION_PLANS:
			return {
				...state,
				isRefetchActivationPlans: action.payload,
			};
		case UPDATE_SELECTED_ACTIVATION_PLAN:
			return {
				...state,
				selectedJobActivationPlan: action.payload,
			};

		case SHOW_APNA_PROMISE_MODAL: {
			return {
				...state,
				showApnaPromiseModal: action.payload,
			};
		}
		case FREE_BANNER_JOBS: {
			return {
				...state,
				freeBannerJobs: action.payload,
			};
		}
		case UPDATE_REFUND_DETAILS: {
			return {
				...state,
				refundDetails: action.payload,
			};
		}

		case INVALID_RETRY_PAYMENT_MODAL: {
			return {
				...state,
				invalidRetryPaymentModal: action.payload,
			};
		}
		case IS_FIRST_PURCHASE: {
			return {
				...state,
				isFirstPurchase: action.payload,
			};
		}

		case FETCH_TRANSACTIONS: {
			return {
				...state,
				fetchTransactions: action.payload,
			};
		}

		case MIN_BALANCE_FOR_TRANSFER: {
			return {
				...state,
				minBalanceForTransfer: action.payload,
			};
		}
		case JOB_POSTING_MODAL: {
			return {
				...state,

				jobPostingModal: {
					...action.payload,
				},
			};
		}
		case BACK_TO_SELECT_PLAN: {
			return {
				...state,
				redirectedToSP: {
					...action.payload,
				},
			};
		}
		case INSUFFICIENT_BALANCE_NUDGE: {
			return {
				...state,
				insufficientBalNudgeDetails: {
					...state.insufficientBalNudgeDetails,
					...action.payload,
				},
			};
		}

		case TNC_MODAL: {
			return {
				...state,
				openTnCModal: action.payload,
			};
		}
		case SET_COINS_SUMMARY: {
			CommonUtils.setItemInLocalStorage(
				ENTERPRISE_USAGE_DATA,
				JSON.stringify(action.payload)
			);
			return {
				...state,
				totalCoinBalance: action.payload.coins_balance,
				coinsSummary: action.payload,
			};
		}

		case SET_NEW_LAYOUT_HEADER_HEIGHT: {
			return {
				...state,
				newLayoutHeaderHeight: action.payload,
			};
		}

		case SET_PRICING_CONTRACT_DATA: {
			return {
				...state,
				pricingContractData: action.payload,
			};
		}
		case SET_COINS_META_DATA: {
			return {
				...state,
				buyCoins3: {
					...state.buyCoins3,
					coinsMetaData: action.payload,
				},
			};
		}
		case SET_BUY_COINS_3_RENDER_VIEW: {
			return {
				...state,
				buyCoins3: {
					...state.buyCoins3,
					renderView: action.payload,
				},
			};
		}
		case SET_PACKAGES: {
			CommonUtils.setItemInLocalStorage(
				BUY_COINS_PACKAGES,
				JSON.stringify(action.payload)
			);
			return {
				...state,
				buyCoins3: {
					...state.buyCoins3,
					packages: action.payload,
				},
			};
		}

		case SET_USER_CART_DETAILS: {
			return {
				...state,
				buyCoins3: {
					...state.buyCoins3,
					userCart: action.payload,
				},
			};
		}

		case SET_PRICING_PLANS: {
			CommonUtils.setItemInLocalStorage(
				BUY_COINS_PRICING_PLANS,
				JSON.stringify(action.payload)
			);
			return {
				...state,
				buyCoins3: {
					...state.buyCoins3,
					pricingPlans: action.payload,
				},
			};
		}

		case SET_POST_PAYMENT_ORDER_DETAILS: {
			return {
				...state,
				buyCoins3: {
					...state.buyCoins3,
					postPaymentOrderDetails: action.payload,
				},
			};
		}
		case SET_USER_CART_SELECTPLAN: {
			return {
				...state,
				userCartSelectPlanPage: action.payload,
			};
		}
		case EXPRESS_CHECKOUT_SELECTPLAN_MERGE_COHORT: {
			return {
				...state,
				expressCheckoutSelectPlanMergeCohort: action.value,
			};
		}
		default:
			return state;
	}
};

export default monetisation;
