import React from "react";

const AadharIcon1 = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.1667 7.1668C12.45 7.1668 12.725 7.20013 13 7.2418V4.3168C13 3.65013 12.6083 3.05013 12 2.7918L7.41667 0.791797C6.99167 0.608464 6.50833 0.608464 6.08333 0.791797L1.5 2.7918C0.891666 3.05846 0.5 3.65846 0.5 4.3168V7.3168C0.5 11.1001 3.16667 14.6418 6.75 15.5001C7.20833 15.3918 7.65 15.2335 8.08333 15.0418C7.50833 14.2251 7.16667 13.2335 7.16667 12.1668C7.16667 9.40846 9.40833 7.1668 12.1667 7.1668Z"
			fill="#004BA9"
		/>
		<path
			d="M12.1673 8.8335C10.3257 8.8335 8.83398 10.3252 8.83398 12.1668C8.83398 14.0085 10.3257 15.5002 12.1673 15.5002C14.009 15.5002 15.5007 14.0085 15.5007 12.1668C15.5007 10.3252 14.009 8.8335 12.1673 8.8335ZM12.1673 9.9835C12.684 9.9835 13.1007 10.4085 13.1007 10.9168C13.1007 11.4252 12.6757 11.8502 12.1673 11.8502C11.659 11.8502 11.234 11.4252 11.234 10.9168C11.234 10.4085 11.6507 9.9835 12.1673 9.9835ZM12.1673 14.4585C11.3923 14.4585 10.7173 14.0752 10.3007 13.4835C10.3423 12.8835 11.559 12.5835 12.1673 12.5835C12.7757 12.5835 13.9923 12.8835 14.034 13.4835C13.6173 14.0752 12.9423 14.4585 12.1673 14.4585Z"
			fill="#004BA9"
		/>
	</svg>
);

export default AadharIcon1;
