import * as React from "react";

const FieldJobIcon = props => {
	const { fill = "#172B4D" } = props;
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1537_100696)">
				<path
					d="M12.0089 13.7503H11.9911C10.9962 13.7503 10.1897 14.5568 10.1897 15.5517V22.1986C10.1897 23.1935 10.9962 24 11.9911 24H12.0089C13.0038 24 13.8103 23.1935 13.8103 22.1986V15.5517C13.8103 14.5568 13.0038 13.7503 12.0089 13.7503Z"
					fill={fill}
				/>
				<path
					d="M19.2712 2.03201H16.9584C16.7737 1.67248 16.4001 1.42592 15.9679 1.42592H14.8959C14.5458 1.42592 14.2204 1.25764 14.0053 0.981074C13.5408 0.383887 12.8151 -0.000488281 12 -0.000488281C11.1848 -0.000488281 10.4592 0.383887 9.99466 0.981074C9.77951 1.25764 9.45466 1.42592 9.10404 1.42592H8.03201C7.59982 1.42592 7.22623 1.67248 7.04154 2.03201H4.72873C4.44841 2.03201 4.22107 2.25936 4.22107 2.53967C4.22107 2.81998 4.44841 3.04732 4.72873 3.04732H7.04154C7.22623 3.40686 7.59982 3.65342 8.03201 3.65342H9.10404C9.45419 3.65342 9.77951 3.8217 9.99466 4.09826C10.4592 4.69545 11.1848 5.07982 12 5.07982C12.8151 5.07982 13.5408 4.69545 14.0053 4.09826C14.2204 3.8217 14.5453 3.65342 14.8959 3.65342H15.9679C16.4001 3.65342 16.7737 3.40686 16.9584 3.04732H19.2712C19.5515 3.04732 19.7789 2.81998 19.7789 2.53967C19.7789 2.25936 19.5515 2.03201 19.2712 2.03201ZM12 3.94686C11.2172 3.94686 10.5829 3.31264 10.5829 2.52982C10.5829 1.74701 11.2172 1.11279 12 1.11279C12.7828 1.11279 13.417 1.74701 13.417 2.52982C13.417 3.31264 12.7828 3.94686 12 3.94686Z"
					fill={fill}
				/>
				<path
					d="M17.8144 8.6231C17.5725 7.37529 16.432 6.16826 15.1875 5.89263C14.4319 5.7356 13.4039 5.58185 12 5.57904C10.5966 5.58138 9.56859 5.7356 8.8125 5.89263C7.56797 6.16826 6.4275 7.37482 6.18562 8.6231C6.03375 9.38388 5.84578 11.5045 5.86125 12.9075C5.84578 14.3104 6.03375 16.4315 6.18562 17.1919C6.4275 18.4401 7.56797 19.6467 8.8125 19.9223C8.98359 19.9579 9.16968 19.9931 9.36984 20.0269V15.5606C9.36984 14.1108 10.5497 12.9309 11.9995 12.9309C13.4494 12.9309 14.6292 14.1108 14.6292 15.5606V20.0269C14.8294 19.9931 15.0155 19.9579 15.1866 19.9223C16.4311 19.6467 17.5716 18.4401 17.8134 17.1919C17.9653 16.4315 18.1533 14.3104 18.1378 12.9075C18.1533 11.5045 17.9653 9.38342 17.8134 8.6231H17.8144ZM9.03609 10.7461C9.00281 10.9172 8.84672 11.0826 8.67562 11.1206C8.57203 11.1422 8.43093 11.1633 8.23828 11.1637C8.04562 11.1637 7.905 11.1422 7.80093 11.1206C7.63031 11.0826 7.47375 10.9172 7.44047 10.7461C7.41984 10.6415 7.39593 10.2154 7.39593 9.63935C7.39593 9.06326 7.41937 8.6367 7.44047 8.53263C7.47375 8.36154 7.62984 8.19607 7.80093 8.1581C7.90453 8.13654 8.04562 8.11545 8.23828 8.11498C8.43093 8.11498 8.57156 8.13654 8.67562 8.1581C8.84625 8.19607 9.00281 8.36154 9.03609 8.53263C9.05672 8.63717 9.08062 9.09607 9.08062 9.63935C9.08062 10.2159 9.05719 10.642 9.03609 10.7461ZM16.5586 10.7461C16.5253 10.9172 16.3692 11.0826 16.1981 11.1206C16.0945 11.1422 15.9534 11.1633 15.7608 11.1637C15.5681 11.1637 15.4275 11.1422 15.3234 11.1206C15.1528 11.0826 14.9962 10.9172 14.963 10.7461C14.9423 10.6415 14.9184 10.2154 14.9184 9.63935C14.9184 9.06326 14.9419 8.6367 14.963 8.53263C14.9962 8.36154 15.1523 8.19607 15.3234 8.1581C15.427 8.13654 15.5681 8.11545 15.7608 8.11498C15.9534 8.11498 16.0941 8.13654 16.1981 8.1581C16.3687 8.19607 16.5253 8.36154 16.5586 8.53263C16.5792 8.63717 16.6031 9.09607 16.6031 9.63935C16.6031 10.2159 16.5797 10.642 16.5586 10.7461Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_1537_100696">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default FieldJobIcon;
