import React from "react";

const Icon3 = () => (
	<svg
		width="20"
		height="14"
		viewBox="0 0 20 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.8917 7.94189C15.0334 8.71689 15.8334 9.76689 15.8334 11.1669V13.6669H18.3334C18.7917 13.6669 19.1667 13.2919 19.1667 12.8336V11.1669C19.1667 9.35023 16.1917 8.27523 13.8917 7.94189Z"
			fill="#BD5B00"
		/>
		<path
			d="M7.50008 7.00016C9.34103 7.00016 10.8334 5.50778 10.8334 3.66683C10.8334 1.82588 9.34103 0.333496 7.50008 0.333496C5.65913 0.333496 4.16675 1.82588 4.16675 3.66683C4.16675 5.50778 5.65913 7.00016 7.50008 7.00016Z"
			fill="#BD5B00"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.5001 7.00016C14.3417 7.00016 15.8334 5.5085 15.8334 3.66683C15.8334 1.82516 14.3417 0.333496 12.5001 0.333496C12.1084 0.333496 11.7417 0.416829 11.3917 0.533496C12.0834 1.39183 12.5001 2.4835 12.5001 3.66683C12.5001 4.85016 12.0834 5.94183 11.3917 6.80016C11.7417 6.91683 12.1084 7.00016 12.5001 7.00016Z"
			fill="#BD5B00"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.50004 7.8335C5.27504 7.8335 0.833374 8.95016 0.833374 11.1668V12.8335C0.833374 13.2918 1.20837 13.6668 1.66671 13.6668H13.3334C13.7917 13.6668 14.1667 13.2918 14.1667 12.8335V11.1668C14.1667 8.95016 9.72504 7.8335 7.50004 7.8335Z"
			fill="#BD5B00"
		/>
	</svg>
);

export default Icon3;
