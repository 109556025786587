// import apiClient from "./apiClients";
import config from "../config";
import url from "../config/urls";
import CommonUtils, { getEnterPriseWorkSpaceId } from "./commonUtils";
import apiClient from "./apiClients";

// const axios = httpClient.create();

// axios.interceptors.response.use(
// 	response => {
// 		if (response.status && response.status === 401) {
// 			CommonUtils.logout();
// 		}
// 		return response;
// 	},
// 	error => {
// 		if (error.response && error.response.status && error.response.status === 401) {
// 			CommonUtils.logout();
// 		}
// 		return error.response;
// 	}
// );
const COMMON_HEADERS = () => {
	const TOKEN = CommonUtils.getItemFromLocalStorage("__token__");
	return {
		Authorization: `Token ${TOKEN}`,
		"Content-Type": "application/json;charset=UTF-8",
	};
};

const axios = apiClient;
/**
 *
 * @param {Object} urlParams Full Params in the form of Serialisable JSON that will be concatenated at the end of the URL.
 */

const getJobsOfInternalUser = urlParams => {
	return Promise.resolve(
		axios.get(config.baseUrl + url.INTERNAL_USER_JOBS_LIST_V4, {
			headers: COMMON_HEADERS(),
			params: urlParams,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getMoreJobsOfInternalUser = newUrl => {
	const urlWithoutHttps = newUrl.slice(4, newUrl.length);
	const urlWithHttps = `https${urlWithoutHttps}`;
	const URL = `${urlWithHttps}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const editJob = (id, data) => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST_V4 + id}/`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const postJob = data => {
	const URL = config.baseUrl + url.INTERNAL_USER_JOBS_LIST_V4;
	return Promise.resolve(
		axios({
			method: "post",
			url: URL,
			data,
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const verifyNumber = jobId => {
	const URL = `${config.baseUrl +
		url.INTERNAL_USER_JOBS_LIST +
		jobId}/verification-status/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const reactivateJob = (jobId, status, comment) => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST + jobId}/${status}/`;
	const data = {
		comment: {
			remark: comment,
		},
	};
	return Promise.resolve(
		axios.put(URL, data, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobDetail = data => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST_V4 + data}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const searchJob = id => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST_V4 + id}/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobAppliedCandidate = id => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST + id}/applicants/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getDailyStatsOfJob = (id, params) => {
	let URL = `${config.baseUrl + url.USER_JOBS_LIST + id}/statistics/`;

	const workspaceId = getEnterPriseWorkSpaceId();
	if (workspaceId) {
		URL += `?workspace_id=${workspaceId}`;
	}
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
			params,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobByPhoneNumber = number => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST_V4}?phone=${number}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getCompanyNameList = character => {
	const URL = `${config.baseUrl + url.ORGANIZATION_NAME}?search=${character}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getVerifiedCompanyNameList = ({ character, minLevel = "l2" }) =>
	axios.get(`${config.baseUrl + url.VERIFIED_ORGANISATION}`, {
		headers: COMMON_HEADERS(),
		params: {
			keyword: character,
			limit: 6,
			min_verification_level: minLevel,
		},
	});

const getJobByCompanyId = id => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST_V4}?org=${id}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const getAllQuestions = jobId => {
	const URL = `${config.baseUrl + url.ASSESSMENT}job/${jobId}/questions/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const addQuestion = (jobId, data) => {
	const URL = `${config.baseUrl + url.ASSESSMENT}job/${jobId}/questions/`;
	return Promise.resolve(
		axios.post(URL, data, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const updateQuestion = (id, data) => {
	const URL = `${config.baseUrl + url.ASSESSMENT}question/${id}/`;
	return Promise.resolve(
		axios.patch(URL, data, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const removeQuestion = id => {
	const URL = `${config.baseUrl + url.ASSESSMENT}question/${id}/`;
	return Promise.resolve(
		axios.delete(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const changeJobRole = (id, data) => {
	const URL = `${config.baseUrl + url.JOBS_V3}${id}/change_role/`;
	return Promise.resolve(
		axios.put(URL, data, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const changeEmployerType = (phoneNumber, data) => {
	const URL = `${config.baseUrl + url.EMPLOYER_TYPE}${phoneNumber}/`;
	return Promise.resolve(
		axios.put(URL, data, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const changeEmployeeCount = async (jobId, data, isDBVer) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.INTERNAL_DB_EMPLOYEE_COUNT.replace("%RECORG_ID%", jobId)}`;
	} else {
		URL = `${config.baseUrl + url.INTERNAL_EMPLOYEE_COUNT}${jobId}/`;
	}
	try {
		const resp = await Promise.resolve(
			axios.put(URL, data, {
				headers: COMMON_HEADERS(),
			})
		);
		return resp;
	} catch (err) {
		throw new Error(err);
	}
};

const updateOrDeleteJobCredits = (data, type) => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST + data.jobId}/credits/`;
	return Promise.resolve(
		axios({
			method: type,
			url: URL,
			data: data.data,
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getJobByConsultantId = id => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST_V4}?consultancy=${id}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getConsultancyList = character => {
	const URL = `${config.baseUrl + url.ORGANIZATION_NAME}?search=${character}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const addComment = (jobId, data) => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST + jobId}/comments/`;
	return Promise.resolve(
		axios.post(URL, data, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateScore = (jobId, data) => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST + jobId}/change_score/`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const updateCompanyStatus = (data, userId) => {
	const URL = `${config.baseUrl + url.INTERNAL_USERS + userId}/verify_company/`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const verifyGSTInfo = gstId => {
	const URL = `${config.baseUrl + url.GST_DETAILS + gstId}/`;
	return Promise.resolve(
		axios({
			method: "get",
			url: URL,
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const fetchQcJobs = (newUrl, params) => {
	let URL = `${config.baseUrl + url.NEW_QC_JOB_LIST}`;
	if (newUrl && newUrl !== "") {
		const urlWithoutHttps = newUrl.slice(4, newUrl.length);
		const urlWithHttps = `https${urlWithoutHttps}`;
		URL = `${urlWithHttps}`;
	}

	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
			params,
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const searchQcJob = jobId => {
	const URL = `${config.baseUrl + url.QC_JOB_LIST}${jobId}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const getUserHistory = userId => {
	const URL = `${config.baseUrl + url.INTERNAL_USERS + userId}/history/`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
// query param added
const getInternalUserTags = (queryParam = "") => {
	const URL = `${config.baseUrl + url.INTERNAL_TAGS}${queryParam}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const addInternalUserLog = (jobId, data, isDBVer = false) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.PICKUP_FOR_DB_VERIFICAION.replace("%JOB_ID%", jobId)}`;
	} else {
		URL = `${config.baseUrl + url.INTERNAL_USER_JOBS_LIST + jobId}/pickup/`;
	}
	return Promise.resolve(
		axios.post(URL, data, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};
const getLanguageConfiguration = () => {
	const URL = `${config.baseUrl + url.LANGUAGES_BY_CITY}`;
	return Promise.resolve(
		axios.get(URL, {
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

const verifyPhoneNumber = ({ employerNumber, hrNumber, data }) => {
	let URL = `${config.baseUrl +
		url.INTERNAL_VERIFY_PHONE_NUMBER}?employer_number=${employerNumber}`;
	if (hrNumber) URL += `&hr_number=${hrNumber}`;
	return Promise.resolve(
		axios({
			method: "put",
			url: URL,
			data,
			headers: COMMON_HEADERS(),
		})
	)
		.then(resp => {
			return resp;
		})
		.catch(err => {
			throw new Error(err);
		});
};

// Kaleyra Intergation
const triggerCall = ({ jobId, employerId, data = {}, recorgId, isDBVer = false }) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.TRIGGER_DB_KALEYRA_CALL.replace("%RECORG_ID%", recorgId)}`;
	} else {
		URL = `${config.baseUrl +
			url.TRIGGER_KALEYRA_CALL.replace("%JOB_ID%", jobId).replace(
				"%EMPLOYER_ID%",
				employerId
			)}`;
	}

	return axios.post(URL, data, {
		headers: COMMON_HEADERS(),
	});
};

const addCallFeedback = ({ jobId, data = {}, isDBVer, recorgId }) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.ADD_DB_CALL_FEEDBACK.replace("%RECORG_ID%", recorgId)}`;
	} else {
		URL = `${config.baseUrl + url.ADD_CALL_FEEDBACK.replace("%JOB_ID%", jobId)}`;
	}
	return axios.post(URL, data, {
		headers: COMMON_HEADERS(),
	});
};

const addEmployerFeedbackComments = ({ jobId, params, recorgId, isDBVer }) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.ADD_EMPLOYER_COMMENT_DB_DASHBOARD.replace("%RECORG_ID%", recorgId)}`;
	} else {
		URL = `${config.baseUrl +
			url.ADD_EMPLOYER_FEEDBACK_COMMENTS.replace("%JOB_ID%", jobId)}`;
	}
	return axios.post(URL, params, {
		headers: COMMON_HEADERS(),
	});
};

const getCallingDetails = ({ callId }) => {
	const URL = `${config.baseUrl + url.GET_CALLING_DETAILS + callId}`;

	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getCallDetailsCount = ({ jobId }) => {
	const URL = `${config.baseUrl + url.GET_CALLING_COUNT.replace("%JOB_ID%", jobId)}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getCallDetailsCountForDb = ({ recorgId }) => {
	const URL = `${config.baseUrl +
		url.GET_CALLING_COUNT_FOR_DB.replace("%RECORG_ID%", recorgId)}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};
const getEmployerFeedbackOptions = () => {
	const URL = `${config.baseUrl + url.GET_EMPLOYER_FEEDBACK_OPTIONS}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getUserComplaintHistory = jobId => {
	const URL = `${config.baseUrl +
		url.GET_USER_COMPLAINT_HISTORY.replace("%JOB_ID%", jobId)}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getInternalUserWorkspace = jobId => {
	const URL = `${config.baseUrl + url.INTERNAL_USER_WORKSPACE}${
		jobId ? `${jobId}/` : ""
	}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getSupportJobsList = (newUrl, params) => {
	let URL = `${config.baseUrl + url.GET_SUPPORT_CALL_JOBS_LIST}`;
	if (newUrl && newUrl !== "") {
		const urlWithoutHttps = newUrl.slice(4, newUrl.length);
		const urlWithHttps = `https${urlWithoutHttps}`;
		URL = `${urlWithHttps}`;
	}

	return axios.get(URL, {
		headers: COMMON_HEADERS(),
		params,
	});
};

const getkaleyraCallHistory = jobId => {
	const URL = `${config.baseUrl +
		url.GET_KALEYRA_CALL_HISTORY.replace("%JOB_ID%", jobId)}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getKaleyraCallHistoryForDb = recruiterId => {
	const URL = `${config.baseUrl +
		url.GET_KALEYRA_CALL_HISTORY_FOR_DB.replace("%RECRUITER_ID%", recruiterId)}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const searchSupportJob = ({ searchParam, value }) => {
	let URL = `${config.baseUrl +
		url.GET_SUPPORT_CALL_JOBS_LIST}?${searchParam}=${value}`;
	if (searchParam === "jobId")
		URL = `${config.baseUrl + url.GET_SUPPORT_CALL_JOBS_LIST}${value}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};
const getCompanyVerificationDetails = jobId => {
	const URL = `${config.baseUrl +
		url.COMPANY_VERIFICATION_DETAILS.replace("%JOB_ID%", jobId)}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getCoinTransactionHistory = (accountId, pageNo = 1, itemsPerPage = 10) => {
	const URL = `${config.baseUrl +
		url.INTERNAL_TRANSACTIONS +
		accountId}?page=${pageNo}&limit=${itemsPerPage}&sort_on=timestamp&sort_order=desc`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getAccountDetails = userId => {
	const URL = `${config.baseUrl + url.ACCOUNT_DETAILS}${userId}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

export const getCoinsSummaryInternal = (workspaceId, userId) => {
	const URL = `${config.enterpriseBaseUrl +
		url.COINS_SUMMARY_INTERNAL.replace("%WORKSPACE_ID%", workspaceId)}${userId}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

export const getTransactionsInternal = ({
	workspaceId,
	userId,
	pageNo = 1,
	itemsPerPage = 10,
}) => {
	const URL = `${config.enterpriseBaseUrl +
		url.COIN_TRANSACTIONS_INTERNAL.replace(
			"%WORKSPACE_ID%",
			workspaceId
		)}?user_id=${userId}&page=${pageNo}&limit=${itemsPerPage}&sort_on=timestamp&sort_order=desc`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getAdditionalDocDetails = ({ jobId, recruiterId, orgId, isDBVer }) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.ADDITIONAL_DOCUMENT_FOR_DB.replace("%RECRUITER_ID%", recruiterId).replace(
				"%ORG_ID%",
				orgId
			)}`;
	} else {
		URL = `${config.baseUrl + url.ADDITIONAL_DOCUMENT.replace("%job_id%", jobId)}`;
	}
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const postAdditionalDocDetails = async ({
	jobId = "",
	docData = "",
	docType = "",
	recruiterId = "",
	orgId = "",
	isDBVer = false,
}) => {
	const data = new FormData();
	data.append("file", docData);
	data.append("type", docType);
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.ADDITIONAL_DOCUMENT_FOR_DB.replace("%RECRUITER_ID%", recruiterId).replace(
				"%ORG_ID%",
				orgId
			)}`;
	} else {
		URL = `${config.baseUrl + url.ADDITIONAL_DOCUMENT.replace("%job_id%", jobId)}`;
	}
	return axios.post(URL, data, {
		headers: COMMON_HEADERS(),
	});
};
const deleteAdditionalDocDetails = ({ jobId, recruiterId, orgId, isDBVer }) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.ADDITIONAL_DOCUMENT_FOR_DB.replace("%RECRUITER_ID%", recruiterId).replace(
				"%ORG_ID%",
				orgId
			)}`;
	} else {
		URL = `${config.baseUrl + url.ADDITIONAL_DOCUMENT.replace("%job_id%", jobId)}`;
	}
	return axios.delete(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getFraudData = ({ jobId, verId, isDBVer }) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl + url.FRAUD_ANALYSIS_DATA.replace("%JOB_ID%", verId)}`;
	} else {
		URL = `${config.baseUrl + url.FRAUD_ANALYSIS_DATA.replace("%JOB_ID%", jobId)}`;
	}
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

const getDsModelFraudData = jobId => {
	const URL = `${config.baseUrl +
		url.DS_MODEL_FRAUD_ANALYSIS_DATA.replace("%JOB_ID%", jobId)}`;
	return axios.get(URL);
};
const postSupportRequestRemark = async (jobId, userId, remark) => {
	const data = new FormData();
	data.append("remark", remark);
	const URL = `${config.baseUrl +
		url.SUPPORT_REQUEST_DATA.replace("%JOB_ID%", jobId).replace(
			"%USER_ID%",
			userId
		)}`;
	return axios.post(URL, data, {
		headers: COMMON_HEADERS(),
	});
};

const getUserAccessType = () => {
	const URL = `${config.baseUrl + url.GET_USER_ACCESS_TYPE}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
	});
};

export const getIsInternalSalesAgent = userId => {
	const URL = `${config.accountsUrl + url.IS_INTERNAL_SALES_AGENT}`;
	const params = {
		user_id: userId,
	};
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
		params,
	});
};

export const updateInvestigationStatus = ({ jobId, score, isDBVer, verId }) => {
	let URL;
	if (isDBVer) {
		URL = `${config.baseUrl +
			url.UPDATE_TNS_INVESTIGATION_STATUS.replace("%JOB_ID%", verId)}`;
	} else {
		URL = `${config.baseUrl +
			url.UPDATE_TNS_INVESTIGATION_STATUS.replace("%JOB_ID%", jobId)}`;
	}
	const data = { ds_score: score };
	return axios.post(URL, data, {
		headers: COMMON_HEADERS(),
	});
};

export const verifyBusinessEmail = (jobId, verificationStatus) => {
	const URL = `${config.baseUrl +
		url.VERIFY_BUSINESS_EMAIL_ON_UR.replace("%JOB_ID%", jobId)}`;
	const data = {
		status: verificationStatus,
	};
	return axios.post(URL, data, {
		headers: COMMON_HEADERS(),
	});
};

export const getAccountsList = ({ pathParams = "" }) => {
	const URL = `${config.baseUrl + url.GET_ACCOUNTS_LIST}${pathParams}`;
	return axios.get(URL);
};

export const getFilteredAccountsList = ({ pathParams = "", urlParams = {} }) => {
	const URL = `${config.baseUrl + url.GET_ACCOUNTS_LIST}${pathParams}`;
	return axios.get(URL, {
		headers: COMMON_HEADERS(),
		params: urlParams,
	});
};

export const getAccountData = accountId => {
	const URL = `${config.baseUrl +
		url.GET_ACCOUNT_DATA.replace("%ACCOUNT_ID%", accountId)}`;
	return axios.get(URL);
};

export const getAccountByCompanyId = orgId => {
	const URL = `${config.baseUrl + url.GET_ACCOUNTS_LIST}?org=${orgId}`;
	return axios.get(URL);
};

export const getAccountByPhoneNumber = phoneNumber => {
	const URL = `${config.baseUrl + url.GET_ACCOUNTS_LIST}?phone=${phoneNumber}`;
	return axios.get(URL);
};

export const getAccountByRecOrgId = id => {
	const URL = `${config.baseUrl + url.GET_ACCOUNT_DATA.replace("%ACCOUNT_ID%", id)}`;
	return axios.get(URL);
};

export {
	getJobsOfInternalUser,
	editJob,
	postJob,
	reactivateJob,
	getJobDetail,
	getMoreJobsOfInternalUser,
	searchJob,
	verifyNumber,
	getJobAppliedCandidate,
	getJobByPhoneNumber,
	getJobByCompanyId,
	getCompanyNameList,
	getVerifiedCompanyNameList,
	getDailyStatsOfJob,
	getAllQuestions,
	addQuestion,
	updateQuestion,
	removeQuestion,
	changeJobRole,
	changeEmployerType,
	changeEmployeeCount,
	updateOrDeleteJobCredits,
	getJobByConsultantId,
	getConsultancyList,
	addComment,
	updateScore,
	updateCompanyStatus,
	verifyGSTInfo,
	fetchQcJobs,
	searchQcJob,
	getUserHistory,
	getInternalUserTags,
	getLanguageConfiguration,
	addInternalUserLog,
	verifyPhoneNumber,
	triggerCall,
	addCallFeedback,
	getCallingDetails,
	getCallDetailsCount,
	addEmployerFeedbackComments,
	getEmployerFeedbackOptions,
	getUserComplaintHistory,
	getInternalUserWorkspace,
	getSupportJobsList,
	getkaleyraCallHistory,
	getKaleyraCallHistoryForDb,
	searchSupportJob,
	getCompanyVerificationDetails,
	getCoinTransactionHistory,
	getAccountDetails,
	getAdditionalDocDetails,
	postAdditionalDocDetails,
	deleteAdditionalDocDetails,
	getFraudData,
	getDsModelFraudData,
	postSupportRequestRemark,
	getUserAccessType,
	getCallDetailsCountForDb,
};
