import { combineReducers } from "redux";
import forms from "./forms";
import formData from "./formData";
import apiData from "./apiData";
import jobData from "./jobData";
import internalUser from "./internalUser";
import modal from "./modal";
import notification from "./notification";
import loader from "./loader";
import searchbar from "./searchbar";
import config from "./config";
import jobApplications from "../true-ecc/reducers/jobApplicationsReducer";
import trueEccCallStatus from "../true-ecc/reducers/callStatusReducer";
import connectionUpdateDetails from "../true-ecc/reducers/connectionUpdateReducer";
import teccDeferedApplications from "../true-ecc/reducers/deferedJobApplicationReducer";
import teccDeferedToasts from "../true-ecc/reducers/deferedStatusToastReducer";
import teccPreference from "../true-ecc/reducers/trueEccPreferenceReducer";
import staleJobStatus from "../true-ecc/reducers/staleJobStatusReducer";
import teccRecentlyUpdatedQueue from "../true-ecc/reducers/recentlyUpdatedQueueReducer";
import extendedProfile from "../true-ecc/reducers/extendedProfileReducer";
import monetisation from "./monetisation";
import tabAwarenessMode from "../true-ecc/reducers/tabAwarenessReducer";
import sortAndFilters from "../true-ecc/reducers/sortFilterReducer";
import viewReports from "../managementCockpit/reducers/viewReports";
import csvDownloadStatus from "../true-ecc/reducers/csvDownloadReducer";
import tabSummaryData from "../true-ecc/reducers/tabSummaryReducer";
import enterpriseGrading from "./enterpriseGrading";
import databaseCandidate from "../true-ecc/reducers/databaseReducer";
import databaseEligibility from "../true-ecc/reducers/databaseEligibility";
import isDBModalOpen from "../true-ecc/reducers/openDbModal";

const rootReducer = combineReducers({
	forms,
	formData,
	apiData,
	jobData,
	internalUser,
	modal,
	notification,
	loader,

	searchbar,
	jobApplications,
	sortAndFilters,
	trueEccCallStatus,
	connectionUpdateDetails,
	teccDeferedApplications,
	teccDeferedToasts,
	teccPreference,
	staleJobStatus,
	teccRecentlyUpdatedQueue,
	monetisation,
	extendedProfile,
	tabAwarenessMode,
	viewReports,
	csvDownloadStatus,
	tabSummaryData,
	enterpriseGrading,
	databaseCandidate,
	databaseEligibility,
	isDBModalOpen,
	config,
});

export default rootReducer;
