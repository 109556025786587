import React from "react";

const JobsIcon = ({ size = "20", fill = "#5E6C84", ...props }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_657_24687)">
				<path
					d="M10.8334 13.3333H9.16675C8.70842 13.3333 8.33342 12.9583 8.33342 12.5H2.50841V15.8333C2.50841 16.75 3.25841 17.5 4.17508 17.5H15.8334C16.7501 17.5 17.5001 16.75 17.5001 15.8333V12.5H11.6667C11.6667 12.9583 11.2917 13.3333 10.8334 13.3333ZM16.6667 5.83333H13.3334C13.3334 3.99167 11.8417 2.5 10.0001 2.5C8.15841 2.5 6.66675 3.99167 6.66675 5.83333H3.33341C2.41675 5.83333 1.66675 6.58333 1.66675 7.5V10C1.66675 10.925 2.40841 11.6667 3.33341 11.6667H8.33342V10.8333C8.33342 10.375 8.70842 10 9.16675 10H10.8334C11.2917 10 11.6667 10.375 11.6667 10.8333V11.6667H16.6667C17.5834 11.6667 18.3334 10.9167 18.3334 10V7.5C18.3334 6.58333 17.5834 5.83333 16.6667 5.83333ZM8.33342 5.83333C8.33342 4.91667 9.08342 4.16667 10.0001 4.16667C10.9167 4.16667 11.6667 4.91667 11.6667 5.83333H8.32508H8.33342Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_657_24687">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default JobsIcon;
