/* eslint-disable dot-notation */

const APP_CONFIG = "APP_CONFIG";

const setSessionData = (key, value) => {
	sessionStorage.setItem(key, JSON.stringify(value));
};

const normalizeConfig = rawConfig => {
	const config = {};

	// eslint-disable-next-line no-restricted-syntax
	for (const item of rawConfig) {
		config[item["key"]] = JSON.parse(item["value"]);
	}
	return config;
};

const getSessionData = key => {
	const data = sessionStorage.getItem(key);
	return data ? JSON.parse(data) : null;
};

const setConfigVaue = value => {
	setSessionData(APP_CONFIG, value);
};

export const getConfigVaue = (key, defaultValue = null) => {
	const configData = getSessionData(APP_CONFIG);
	return configData[key] || defaultValue;
};

export const normalizeConfigAndSet = value => {
	setConfigVaue(normalizeConfig(value));
};

export const CONFIG_STATE = {
	START: "START",
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};
