import * as React from "react";

const NewFieldNudge = props => (
	<svg xmlns="http://www.w3.org/2000/svg" width={45} height={24} fill="none" {...props}>
		<rect width={45} height={24} fill="#F1EAFA" rx={4} />
		<path
			fill="#512194"
			d="M19.01 7.273V16h-1.407l-4.112-5.945h-.073V16h-1.58V7.273h1.414l4.108 5.949h.077v-5.95h1.572Zm4.551 8.855c-.656 0-1.223-.136-1.7-.41a2.77 2.77 0 0 1-1.095-1.167c-.256-.505-.383-1.1-.383-1.785 0-.674.127-1.265.383-1.773A2.9 2.9 0 0 1 21.848 9.8c.463-.287 1.008-.43 1.633-.43.403 0 .784.065 1.141.195.361.128.68.327.955.597.278.27.497.614.656 1.031.16.415.239.91.239 1.483v.473h-5.365v-1.04h3.886a1.66 1.66 0 0 0-.191-.788 1.394 1.394 0 0 0-.525-.55c-.221-.133-.48-.2-.775-.2-.316 0-.593.077-.831.23-.239.15-.425.35-.558.597-.131.244-.198.513-.2.805v.908c0 .38.069.707.208.98.14.27.334.477.584.622.25.142.543.213.878.213.224 0 .427-.031.61-.094.18-.065.339-.16.472-.285.134-.125.235-.28.303-.465l1.44.162c-.09.381-.264.713-.52.998-.253.28-.577.5-.972.656-.394.153-.846.23-1.355.23ZM29.093 16l-1.85-6.545h1.573l1.15 4.602h.06l1.176-4.602h1.555l1.177 4.576h.064l1.133-4.576h1.577L34.854 16h-1.606l-1.228-4.423h-.09L30.704 16h-1.61Z"
		/>
	</svg>
);
export default NewFieldNudge;
