/* eslint-disable no-unused-expressions */
import { call, put, takeLatest, takeEvery, select } from "redux-saga/effects";
import {
	UPLOAD_COMPANY_DOC,
	SUBMIT_APPLICATION_FEEDBACK,
	JOB_APPLICATION_REQUESTED,
	JOB_APPLICATION_PAGE_CHANGE,
} from "./constants/FormTypes";
import {
	openLoader,
	closeLoader,
	updateCompanyDoc,
	openJobUnderReviewModal,
	sendNotification,
	hideJobFeedback,
	changeFeedback,
	changeApplicationStatus,
	changeFeedbackUpdate,
	updateJobApplicantsList,
	updateJobApplicantsCount,
	getJobApplicants,
} from "./actions";
import { checkCompanyFormError } from "./utils/companyDocUpload";
import {
	COMPANY_DOC_VERIFICATION_STATUS,
	JOB_APPLICATION_STATES,
} from "./utils/constants";
import * as ApiClient from "./utils/apiClients";
import Mixpanel from "./utils/Mixpanel";
import { handleEventTracker } from "./utils/handleEventTracker";
import { JOB_APPLICANT_PAGE_SIZE } from "./constants";

const getCompanyFormStates = state => state.forms.companyDoc.formStates;
const getUserDetails = state => state.forms.userDetails;
const getSelectedJob = state => state.jobData.selectedJobDetails;

function* uploadCompanyDoc() {
	const formStates = yield select(getCompanyFormStates);
	const selectedJob = yield select(getSelectedJob);

	const validation = checkCompanyFormError(formStates);
	if (validation.result) {
		yield put(
			sendNotification({
				message_type: "error",
				message: "Unable to save, fill the remaining fields",
			})
		);
		yield put(updateCompanyDoc({ formErrors: { ...validation.errors } }));
		return;
	}

	const userDetails = yield select(getUserDetails);
	if (formStates.document_id && !formStates.diff) {
		yield put(
			sendNotification({
				message_type: "success",
				message: "Profile updated successfully!",
			})
		);
		yield put(
			updateCompanyDoc({
				formStates: {
					editCompanyUrl: false,
					editCompanyDoc: false,
				},
			})
		);
		return;
	}

	let userId = userDetails.id;
	if (userDetails.is_internal_user) {
		userId = selectedJob?.created_by?.id;
	}
	if (!userId) {
		yield put(
			sendNotification({
				message_type: "error",
				message: "Job Creator Details Missing!",
			})
		);
		return;
	}

	yield put(openLoader());
	const formData = new FormData();
	formData.append("type", formStates.document_type);
	formData.append("doc_number", formStates.document_number);
	formData.append("doc2_detail", formStates.docDetail);
	formData.append("company_url", formStates.companyUrl);
	formData.append("organization_id", selectedJob?.creator_organization?.id);

	if (
		formStates.document_id &&
		formStates.verificationStatus !== COMPANY_DOC_VERIFICATION_STATUS.REJECTED
	) {
		if (formStates.fileObject) {
			formData.append("file", formStates.fileObject, formStates.fileObject.name);
		}
		if (formStates.fileObject2) {
			formData.append(
				"doc2_file",
				formStates.fileObject2,
				formStates.fileObject2.name
			);
		}
		if (formStates.docFile === null) {
			formData.append("doc2_file", "");
		}
		if (!formStates.filePublicUrl) {
			formData.append("file", "");
		}
		formData.append("job_id", selectedJob?.id);
		const resp = yield call(
			ApiClient.updateDocs,
			formData,
			userId,
			formStates.document_id,
			selectedJob?.creator_organization?.id
		);
		if (resp && resp.status === 200 && resp.data) {
			yield put(
				sendNotification({
					message: "Profile updated successfully!",
					message_type: "success",
				})
			);
			yield put(
				updateCompanyDoc({
					formStates: {
						...formStates,
						fileObject: null,
						document_id: resp.data.id || "",
						edit: false,
						editCompanyDoc: false,
						document_number: resp.data.doc_number || "",
						document_type: resp.data.type || "",
						filePublicUrl: resp.data.file || "",
						fileName: resp.data.filename || "",
						fileObject2: null,
						docFile: resp.data.doc2_file || "",
						docDetail: resp.data.doc2_detail || "",
						editCompanyUrl: false,
						companyUrl: resp.data.company_url || "",
						fileName2: resp.data.filename2 || "",
						verificationStatus:
							(resp.data.owner &&
								resp.data.owner.company_verification_status) ||
							"",
						diff: false,
					},
				})
			);
		} else {
			yield put(
				sendNotification({
					message_type: "error",
					message: `Invalid ${Object.keys(resp?.data)[0]} Details!`,
				})
			);
		}
	} else if (formStates.companyUrl || formStates.fileObject) {
		if (formStates.fileObject) {
			formData.append("file", formStates.fileObject, formStates.fileObject.name);
		}
		if (formStates.fileObject2) {
			formData.append(
				"doc2_file",
				formStates.fileObject2,
				formStates.fileObject2.name
			);
		}
		const resp = yield call(
			ApiClient.uploadCompanyDocs,
			formData,
			userId,
			selectedJob?.creator_organization?.id
		);

		if (resp && resp.status === 201 && resp.data) {
			const docUploadEvenData = {
				"User ID": userDetails?.id,
				"Lead Type": userDetails?.is_consultant ? "Consultant" : null,
				"Mobile No.": userDetails?.phone_number,
				"Company Name": userDetails?.consultancy?.name,
				"Document Type": resp?.data?.type,
				"Document Number": resp?.data?.doc_number,
				"Uploaded Document Link": resp?.data?.file,
				Source: formStates?.documentSource,
				"Document Copy": Boolean(formStates.fileObject),
				"Document 2 Copy": Boolean(formStates.fileObject2),
			};
			Mixpanel.track("Document Upload", docUploadEvenData);
			handleEventTracker({
				eventName: "Document Uploaded",
				eventData: docUploadEvenData,
			});
			yield put(openJobUnderReviewModal(false));

			yield put(
				sendNotification({
					message: "Profile updated successfully!",
					message_type: "success",
				})
			);
			yield put(
				updateCompanyDoc({
					formStates: {
						...formStates,
						fileObject: null,
						document_id: resp.data.id || "",
						edit: false,
						editCompanyDoc: false,
						document_number: resp.data.doc_number || "",
						document_type: resp.data.type || "",
						filePublicUrl: resp.data.file || "",
						fileName: resp.data.filename || "",
						fileObject2: null,
						docFile: resp.data.doc2_file || "",
						docDetail: resp.data.doc2_detail || "",
						editCompanyUrl: false,
						companyUrl: resp.data.company_url || "",
						fileName2: resp.data.filename2 || "",
						verificationStatus:
							(resp.data.owner &&
								resp.data.owner.company_verification_status) ||
							"",
						diff: false,
					},
				})
			);
		} else {
			yield put(
				sendNotification({
					message_type: "error",
					message: `Invalid ${Object.keys(resp?.data)[0]} Details!`,
				})
			);
		}
	}

	yield put(closeLoader());
}

function* submitApplicationFeedback(action) {
	// TODO: validation pending @sihari
	const selectedJob = yield select(getSelectedJob);
	const currentPageNo = selectedJob?.currentPageNo || 1;
	yield put(openLoader());
	const resp = yield call(ApiClient.updateJobApplicationFeedback, action);
	if (resp && resp.status === 200 && resp.data) {
		// dispatch action to update in store
		yield put(
			changeFeedback(
				{ id: action.id, feedback: resp.data.employer_feedback },
				currentPageNo
			)
		);
		if (resp.data.employer_feedback) {
			yield put(changeFeedbackUpdate({ id: action.id }, currentPageNo));
		}
		yield put(
			changeApplicationStatus(
				{
					id: action.id,
					status: JOB_APPLICATION_STATES.find(
						a => a.value === resp.data.hiring_state
					),
				},
				currentPageNo
			)
		);
		yield put(hideJobFeedback({ id: action.id }, currentPageNo));
		yield put(
			sendNotification({
				message_type: "success",
				message: "Profile updated successfully!",
			})
		);
	} else {
		yield put(
			sendNotification({
				message_type: "error",
				message: "Network error. Try again.",
			})
		);
	}
	yield put(closeLoader());
}

function* getJobApplicationFeedback(action) {
	const { jobId, pageNo } = action;
	const selectedJob = yield select(getSelectedJob);
	const currentPageNo = selectedJob?.currentPageNo || 1;
	const applications = Object.values(selectedJob?.applications?.[currentPageNo] || {});
	const applicantsCount = selectedJob.applicants_count;

	if (
		selectedJob.id === jobId &&
		applications.length !== JOB_APPLICANT_PAGE_SIZE &&
		(!applicantsCount || applicantsCount > JOB_APPLICANT_PAGE_SIZE)
	) {
		yield put(openLoader());

		const resp = yield call(ApiClient.getJobAppliedCandidate, jobId, pageNo);

		if (resp && resp.status === 200 && resp.data) {
			const applicantsData = {};
			yield put(updateJobApplicantsCount(resp.data.count));
			Array.isArray(resp.data.results) &&
				resp.data.results?.forEach(application => {
					const obj = {
						id: application?.application_id,
						candidate_id: application?.id,
						candidate_name: application?.full_name,
						candidate_number: application?.username,
						feedback: application?.employer_feedback,
						edit: !!application.employer_feedback,
						...application,
					};
					applicantsData[obj.application_id] = obj;
				});
			yield put(updateJobApplicantsList(applicantsData, pageNo));
		} else {
			yield put(
				sendNotification({
					message_type: "error",
					message: "Network error. try again",
				})
			);
		}
		yield put(closeLoader());
	}
}

function* newJobApplicantsRequested(action) {
	const { page } = action;
	const selectedJobDetails = yield select(getSelectedJob);
	const currentPageNo = selectedJobDetails?.currentPageNo || 1;
	const applications = Object.values(
		selectedJobDetails?.applications?.[currentPageNo] || {}
	);

	if (applications.length >= JOB_APPLICANT_PAGE_SIZE) return;

	yield put(getJobApplicants(selectedJobDetails.id, page));
}

function* rootSaga() {
	yield takeLatest(UPLOAD_COMPANY_DOC, uploadCompanyDoc);
	yield takeEvery(SUBMIT_APPLICATION_FEEDBACK, submitApplicationFeedback);
	yield takeEvery(JOB_APPLICATION_REQUESTED, getJobApplicationFeedback);
	yield takeEvery(JOB_APPLICATION_PAGE_CHANGE, newJobApplicantsRequested);
}

export default rootSaga;
