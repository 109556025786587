/* eslint-disable import/prefer-default-export */

export const ADD_CHANGE = "ADD_CHANGE";
export const SHOW_FORM = "SHOW_FORM";
export const EDIT_FORM = "EDIT_FORM";
export const CLEAR_EDIT_FORM = "CLEAR_EDIT_FORM";
export const RESET_FORM = "RESET_FORM";
export const DUPLICATE_JOB = "DUPLICATE_JOB";
export const CLEAR_FORM = "CLEAR_FORM";
export const BULK_ADD = "BULK_ADD";
export const SELECTED_JOB = "SELECTED_JOB";
export const SHOW_UNDER_REVIEW_MODAL = "SHOW_UNDER_REVIEW_MODAL";
export const JOB_COUNT_FROM_JOBS_LIST = "JOB_COUNT_FROM_JOBS_LIST";
export const JOB_COUNT_UPDATED = "JOB_COUNT_UPDATED";
export const NO_OF_DAYS_FROM_LAST_JOB = "NO_OF_DAYS_FROM_LAST_JOB";
export const SHOW_PROFILE_PAGE = "SHOW_PROFILE_PAGE";
export const SHOW_CONSULTANT_MODAL = "SHOW_CONSULTANT_MODAL";
export const SHOW_ONBOARDING_MODAL = "SHOW_ONBOARDING_MODAL";
export const SHOW_HEADCOUNT_MODAL = "SHOW_HEADCOUNT_MODAL";
export const CLIENT_CONNECTION_MODAL = "CLIENT_CONNECTION_MODAL";
export const SHOW_CHANGE_COMPANY_MODAL = "SHOW_CHANGE_COMPANY_MODAL";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_COMPANY_DOC = "UPDATE_COMPANY_DOC";
export const UPLOAD_COMPANY_DOC = "UPLOAD_COMPANY_DOC";
export const EDIT_COMPANY_DOC = "EDIT_COMPANY_DOC";
export const SHOW_FEEDBACK_FORM = "SHOW_FEEDBACK_FORM";
export const HIDE_FEEDBACK_FORM = "HIDE_FEEDBACK_FORM";
export const CHANGE_FEEDBACK = "CHANGE_FEEDBACK";
export const CHANGE_FEEDBACK_UPDATE = "CHANGE_FEEDBACK_UPDATE";
export const CHANGE_APPLICATION_STATUS = "CHANGE_APPLICATION_STATUS";
export const SUBMIT_APPLICATION_FEEDBACK = "SUBMIT_APPLICATION_FEEDBACK";
export const UPDATE_JOB_APPLICATION = "UPDATE_JOB_APPLICATION";
export const UPDATE_JOB_APPLICATION_LIST = "UPDATE_JOB_APPLICATION_LIST";
export const UPDATE_CUSTOM_JOB_ROLE = "UPDATE_CUSTOM_JOB_ROLE";
export const UPDATE_IS_LEGACY_JOB = "UPDATE_IS_LEGACY_JOB";
export const JOB_APPLICATION_REQUESTED = "JOB_APPLICATION_REQUESTED";
export const JOB_APPLICATION_SUCCEEDED = "JOB_APPLICATION_SUCCEEDED";
export const JOB_APPLICATION_FAILED = "JOB_APPLICATION_FAILED";
export const JOB_APPLICATION_COUNT = "JOB_APPLICATION_COUNT";
export const JOB_APPLICATION_PAGE_CHANGE = "JOB_APPLICATION_PAGE_CHANGE";
export const JOB_MATCHED_APPLICATION_REQUESTED = "JOB_MATCHED_APPLICATION_REQUESTED";
export const JOB_MATCHED_APPLICATION_UPDATE = "JOB_MATCHED_APPLICATION_UPDATE";
export const UPDATE_SELECTED_JOB_HR_NUM = "UPDATE_SELECTED_JOB_HR_NUM";
export const UPDATE_SELECTED_JOB_EMAIL_VERIFIED = "UPDATE_SELECTED_JOB_EMAIL_VERIFIED";
export const ADD_ATS_FUNNEL_STEP = "ADD_ATS_FUNNEL_STEP";
export const SET_ALL_ATS_FUNNEL_STEPS = "SET_ALL_ATS_FUNNEL_STEPS";
export const SET_ALL_ATS_FUNNEL_VERBOSE_STEPS = "SET_ALL_ATS_FUNNEL_VERBOSE_STEPS";
export const POST_NEW_JOB_EXPIRE_OLD_JOB = "POST_NEW_JOB_EXPIRE_OLD_JOB";
export const SET_AGE_UNLEASH_STATUS = "SET_AGE_UNLEASH_STATUS";
export const SET_CITY_AREAS_FILTER = "SET_CITY_AREAS_FILTER";
export const SET_SELECTED_AREAS = "SET_SELECTED_AREAS";
export const SET_SELECTED_JOB_STATUS = "SET_SELECTED_JOB_STATUS";
export const SET_TOTAL_FILTERED_JOBS = "SET_TOTAL_FILTERED_JOBS";
export const SET_FILTERED_JOBS = "SET_FILTERED_JOBS";
export const UPDATE_FILTERED_JOBS = "UPDATE_FILTERED_JOBS";
export const SET_FILTERS_APPLIED = "SET_FILTERS_APPLIED";
export const SET_FILTERS_PRESENT = "SET_FILTERS_PRESENT";
export const SET_TOTAL_CITIES = "SET_TOTAL_CITIES";
export const SET_TOTAL_AREAS = "SET_TOTAL_AREAS";
export const SET_JOB_STATUS_COUNT = "SET_JOB_STATUS_COUNT";
export const NEW_JOB_POSTED = "NEW_JOB_POSTED";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const UPDATE_POC_DOC = "UPDATE_POC_DOC";
export const UPDATE_SELECTED_JOB_OWNER_DATA = "UPDATE_SELECTED_JOB_OWNER_DATA";
export const SET_SELECTED_PREMIUM_FILTERS = "SET_SELECTED_PREMIUM_FILTERS";
export const SET_JOB_ROLE_DATA = "SET_JOB_ROLE_DATA";
export const SET_EMPLOYER_FEEDBACK_OPTIONS = "SET_EMPLOYER_FEEDBACK_OPTIONS";
export const SET_IS_WORKSPACE_UPDATED = "SET_IS_WORKSPACE_UPDATED";
export const UPDATE_CURR_CREATOR_ORG_STATUS = "UPDATE_CURR_CREATOR_ORG_STATUS";
export const UPDATE_IS_EMPLOYER_KALEYRA_CALL_REQUESTED =
	"UPDATE_IS_EMPLOYER_KALEYRA_CALL_REQUESTED";
export const REMOVE_JOB_FROM_STORE = "REMOVE_JOB_FROM_STORE";
export const SET_VERIFICATION_DETAILS = "SET_VERIFICATION_DETAILS";
export const FETCH_AND_SET_VERIFICATION_DETAILS = "FETCH_AND_SET_VERIFICATION_DETAILS";
export const DUPLICATE_JOB_FOUND = "DUPLICATE_JOB_FOUND";
export const UPDATE_CALL_LATEST_VERIFICATION_API_FLAG =
	"UPDATE_CALL_LATEST_VERIFICATION_API_FLAG";
export const UPDATE_VERIFICATION_MODAL_OPEN_STATE =
	"UPDATE_VERIFICATION_MODAL_OPEN_STATE";
export const SET_JOBS_FIELD_JOB_ALLOW_OPTION = "SET_JOBS_FIELD_JOB_ALLOW_OPTION";
export const SHOW_MISSING_FIELDS_INFO_PANEL = "SHOW_MISSING_FIELDS_INFO_PANEL";
export const SAVE_JPF_MISSING_FIELDS = "SAVE_JPF_MISSING_FIELDS";
export const SET_IS_JPF_ERROR = "SET_IS_JPF_ERROR";
export const SET_JOB_ALLOW_OPTION = "SET_JOB_ALLOW_OPTION";
export const UPDATE_JOB_ALLOW_OPTION = "UPDATE_JOB_ALLOW_OPTION";
export const SET_EDUCATION_OPTIONS = "SET_EDUCATION_OPTIONS";
export const SET_ENGLISH_OPTIONS = "SET_ENGLISH_OPTIONS";
export const SET_JOB_TAGS = "SET_JOB_TAGS";
export const UPDATE_JOB_TAG = "UPDATE_JOB_TAG";
export const SET_DEPARTMENT_LIST = "SET_DEPARTMENT_LIST";
export const SET_CATEGORY_ID = "SET_CATEGORY_ID";
export const SET_MAX_VALUES_FOR_TAGS = "SET_MAX_VALUES_FOR_TAGS";
export const SET_ALLOWED_JOB_TITLES = "SET_ALLOWED_JOB_TITLES";
export const UPDATE_MAX_VALUES_FOR_TAGS = "UPDATE_MAX_VALUES_FOR_TAGS";
export const SET_SUB_DEPARTMENT_LIST = "SET_SUB_DEPARTMENT_LIST";
export const SET_LANGUAGE_TAGS_CONFIG = "SET_LANGUAGE_TAGS_CONFIG";
export const SHOW_REQUEST_SUPPORT_CTA = "SHOW_REQUEST_SUPPORT_CTA";
export const SHOW_SUPPORT_TICKET_MODAL = "SHOW_SUPPORT_TICKET_MODAL";
export const SHOW_SUPPORT_TICKET_INFORMATION_MODAL =
	"SHOW_SUPPORT_TICKET_INFORMATION_MODAL";
export const HEADCOUNT_VALUE = "HEADCOUNT_VALUE";
export const OPTIONAL_FIELD_BANNER_CTA_CLICKED = "OPTIONAL_FIELD_BANNER_CTA_CLICKED";
export const SHOW_SUCCESS_POST_COMPLETING_BWCP_FLOW =
	"SHOW_SUCCESS_POST_COMPLETING_BWCP_FLOW";
export const IS_MANUAL_EDIT = "IS_MANUAL_EDIT";
export const IS_ONLY_BWCP_OPTIONAL_FLOW = "IS_ONLY_BWCP_OPTIONAL_FLOW";
export const SHOW_OPTIONAL_MISSING_FIELDS_PANEL = "SHOW_OPTIONAL_MISSING_FIELDS_PANEL";
export const OPTIONAL_FLOW_MODAL_CTA_CLICKED = "OPTIONAL_FLOW_MODAL_CTA_CLICKED";
export const AFTER_RENEWAL_BWCP_STATUS = "AFTER_RENEWAL_BWCP_STATUS";
export const BWCP_AUTOSCROLL_TIMEOUT = "BWCP_AUTOSCROLL_TIMEOUT";
export const SET_SELECTED_CATEGORY_ID_IN_JOB_POST_FORM =
	"SET_SELECTED_CATEGORY_ID_IN_JOB_POST_FORM";
export const SELECTED_DEGREE = "SELECTED_DEGREE";
export const SELECTED_INDUSTRY = "SELECTED_INDUSTRY";
export const USER_SELECTED_NUM_EMPLOYEE = "USER_SELECTED_NUM_EMPLOYEE";
export const WHY_VERIFY_BOTTOM_SHEET_CLOSE = "WHY_VERIFY_BOTTOM_SHEET_CLOSE";
export const JOYRIDE_BASIC_DETAILS_DISPLAY_STATE = "JOYRIDE_BASIC_DETAILS_DISPLAY_STATE";
export const JOYRIDE_JOB_DETAILS_DISPLAY_STATE = "JOYRIDE_JOB_DETAILS_DISPLAY_STATE";
export const ORG_SEARCH_DATA = "ORG_SEARCH_DATA";
export const NEW_SKILLS_UI_ROLLOUT_ELIGIBILITY = "NEW_SKILLS_UI_ROLLOUT_ELIGIBILITY";
export const USER_SOURCE = "USER_SOURCE";
export const HIDE_VERIFICATION_PAGE_RIGHT_PANE = "HIDE_VERIFICATION_PAGE_RIGHT_PANE";
export const UNDER_REVIEW_BANNER_CTA_TEXT = "UNDER_REVIEW_BANNER_CTA_TEXT";
export const SET_SMILEY_MODAL_OPEN = "SET_SMILEY_MODAL_OPEN";
export const SET_CURRENT_VERIFICATION_STEP = "SET_CURRENT_VERIFICATION_STEP";
export const SET_SHOW_COMPANY_DOC_VERIFY_BANNER = "SET_SHOW_COMPANY_DOC_VERIFY_BANNER";
export const SET_SHOW_NEW_VERIFICATION_CONFIG = "SET_SHOW_NEW_VERIFICATION_CONFIG";
export const CURRENT_VERIFICATION_SCREEN = "CURRENT_VERIFICATION_SCREEN";
export const JOB_POST_SUCCESS_BANNER = "JOB_POST_SUCCESS_BANNER";
export const IS_BACKWARD_FLOW = "IS_BACKWARD_FLOW";
export const MIN_SKILLS_REQUIRED = "MIN_SKILLS_REQUIRED";
export const TROPHY_JOB = "TROPHY_JOB";
export const TROPHY_JOB_ALLOWED = "TROPHY_JOB_ALLOWED";
export const TROPHY_JOB_CONFIRMATION_MODAL = "TROPHY_JOB_CONFIRMATION_MODAL";
export const TROPHY_JOB_CTA_CLICK = "TROPHY_JOB_CTA_CLICK";
export const AUTOSELECT_DEGREE_OPTIONS = "AUTOSELECT_DEGREE_OPTIONS";
export const SET_USER_ACCESS_LEVEL = "SET_USER_ACCESS_LEVEL";
export const SET_ORG_UPDATE_SCREEN_VIA_EMAIL_FLOW =
	"SET_ORG_UPDATE_SCREEN_VIA_EMAIL_FLOW";
export const IS_ENGLISH_PREFILL_REMOVED = "IS_ENGLISH_PREFILL_REMOVED";
export const SET_DEFAULT_NUMBER_OF_OPENINGS_CONFIG =
	"SET_DEFAULT_NUMBER_OF_OPENINGS_CONFIG";
export const VAS_JOB = "VAS_JOB";
export const VAS_JOB_ALLOWED = "VAS_JOB_ALLOWED";
export const VAS_JOB_CONFIRMATION_MODAL = "VAS_JOB_CONFIRMATION_MODAL";
export const VAS_JOB_CTA_CLICK = "VAS_JOB_CTA_CLICK";
export const OTHER_TAG_IDS = "OTHER_TAG_IDS";

export const SET_TITLE_DEPARTMENT_LIST_PAYLOAD = "SET_TITLE_DEPARTMENT_LIST_PAYLOAD";
export const SET_PREVIOUS_DEPARTMENT_CONFIG = "SET_PREVIOUS_DEPARTMENT_CONFIG";
export const SET_SECTOR_INDUSTRY_LIST = "SET_SECTOR_INDUSTRY_LIST";
export const SET_SECTOR_INDUSTRY_CONFIG = "SET_SECTOR_INDUSTRY_CONFIG";
export const ENTERPRISE_WORKSPACE_DATA = "ENTERPRISE_WORKSPACE_DATA";
export const ENTERPRISE_CONFIRM_DISMISS_MODAL = "ENTERPRISE_CONFIRM_DISMISS_MODAL";
export const USER_PROFILE_V2_DATA = "USER_PROFILE_V2_DATA";
export const SHOW_ENTERPRISE_JOBS_MIGRATION_PAGE = "SHOW_ENTERPRISE_JOBS_MIGRATION_PAGE";
export const SET_SHOW_EDIT_WARNING_MODAL_VALUES = "SET_SHOW_EDIT_WARNING_MODAL_VALUES";
export const SET_IS_WFH_ALLOWED = "SET_IS_WFH_ALLOWED";
export const SET_ADD_COMPANY_DETAILS_CTA_CLICKED = "SET_ADD_COMPANY_DETAILS_CTA_CLICKED";
export const SET_WHITELISTED_EMAIL_SUBMITTED = " SET_WHITELISTED_EMAIL_SUBMITTED";
export const SET_ORG_VERIFICATION_LEVEL = "SET_ORG_VERIFICATION_LEVEL";
export const IS_EDITED_FROM_PREVIEW_PAGE = "IS_EDITED_FROM_PREVIEW_PAGE";
export const SET_GET_JOB_DATA_FOR_PREVIEW = "SET_GET_JOB_DATA_FOR_PREVIEW";
export const SET_IS_LATEST_JOB_POST_NEW = "SET_IS_LATEST_JOB_POST_NEW";
export const SET_IS_STEP3_CONTINUE_CLICKED = "SET_IS_STEP3_CONTINUE_CLICKED";
export const SET_SELECTED_ACCOUNT_DETAILS = "SET_SELECTED_ACCOUNT_DETAILS";
export const DUPLICATE_JOB_SOURCE = "DUPLICATE_JOB_SOURCE";
export const JOB_POST_CTA_JOYRIDE_SHOWN = "JOB_POST_CTA_JOYRIDE_SHOWN";
export const DB_KALEYRA_CALL_ID = "DB_KALEYRA_CALL_ID";
export const SET_IS_ENTERED_JPF_FROM_PREVIEW = "SET_IS_ENTERED_JPF_FROM_PREVIEW";
export const DATABASE_CTA_JOYRIDE_SHOWN = "DATABASE_CTA_JOYRIDE_SHOWN";
export const SET_IS_AUTORENEWAL_FLOW = "SET_IS_AUTORENEWAL_FLOW";
export const SET_WORKSPACE_LIST = "SET_WORKSPACE_LIST";
export const SHOW_PJF_DROPOFF_MODAL = "SHOW_PJF_DROPOFF_MODAL";
export const SHOW_PJF_FEEDBACK_MODAL = "SHOW_PJF_FEEDBACK_MODA";
export const CURRENT_JOB_TITLE = "CURRENt_JOB_TITLE";
export const SHOW_FEEDBACK_MODAL_POST_DROPOFF = "SHOW_FEEDBACK_MODAL_POST_DROPOFF";
export const SET_DISCOUNT_COUPON_DATA = "SET_DISCOUNT_COUPON_DATA";
export const SET_JOBS_LIST_FROM_DASHBOARD = "SET_JOBS_LIST_FROM_DASHBOARD";
export const SET_RENEWED_EDIT_FROM_JOBS_PAGE = "SET_RENEWED_EDIT_FROM_JOBS_PAGE";
