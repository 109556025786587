/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import Bowser from "bowser";
import {
	addEngAndEdu,
	addDefaultEngAndEdu,
	sendNotification,
	updateJobPhoneNumDetails,
	toggleActivationWarningModal,
} from "../actions";
import { getEducationLevel, getEmailVerificationStatus } from "./apiClients";
import CommonUtils, {
	isMatchingEmail,
	makeString,
	sendMixpanelCompanyVerificationEventsEvents,
} from "./commonUtils";
import {
	ENGLISH_LEVEL_FORM_DATA,
	UNSUPPORTED_BROWSERS,
	JOB_REMARKS,
	JOB_AUTO_ACTIVATION_RESULT,
	DAYS,
	COMPANY_DOC_VERIFICATION_STATUS,
	POC_VERIFICATION_STATUS,
	TAG_TYPES,
	EMPLOYER_TYPES_KEYS,
	ACTIVATION_WARNING_MODAL_STATES,
	VERIFICATION_STATUSES,
} from "./constants";
import { verifyPhoneNumber } from "./internalApiClients";

export const getSelectedTagIds = (list, type) => {
	return list?.filter(tag => tag.tag_type === type)?.map(obj => obj.id);
};

export const getOtherTagIds = (list, tagTypes) => {
	return list?.filter(tag => !tagTypes.includes(tag.tag_type))?.map(obj => obj?.id);
};

export const sortTags = jobTags => {
	return jobTags?.sort((a, b) => {
		if (a.selected === b.selected) return 0;
		if (a.selected && !b.selected) return -1;
		return 1;
	});
};

export const selectSortedTags = ({ list, selectedIds }) => {
	return sortTags(
		list?.map(tag =>
			selectedIds?.includes(tag.id)
				? { ...tag, selected: true }
				: { ...tag, selected: false }
		)
	);
};

export const parseAllTags = tags => {
	const assetTags = [];
	const languageTags = [];
	const salaryPerksTags = [];
	const skillTags = [];
	const vasTags = [];

	tags?.forEach(tag => {
		const tempObj = { ...tag, selected: false, isShow: true };
		if (tempObj.tag_type === TAG_TYPES.ASSET) assetTags.push(tempObj);
		else if (tempObj.tag_type === TAG_TYPES.SALARY) salaryPerksTags.push(tempObj);
		else if (tempObj.tag_type === TAG_TYPES.LANGUAGE_REQUIREMENTS)
			languageTags.push(tempObj);
		else if (tempObj.tag_type === TAG_TYPES.SKILL) skillTags.push(tempObj);
		else if (tempObj.tag_type === TAG_TYPES.VAS) vasTags.push(tempObj);
	});
	return [assetTags, languageTags, salaryPerksTags, skillTags, vasTags];
};

export const dispatchDefaultEnglishAndEducationOptions = ({
	educationOptions,
	englishOptions,
	dispatch,
}) => {
	dispatch(addDefaultEngAndEdu({ educationOptions, englishOptions }));
};

export const addEnglishAndEducationOptions = ({
	educationOptions,
	englishOptions,
	dispatch,
}) => {
	dispatch(addEngAndEdu({ educationOptions, englishOptions }));
};

export const parseJobAttributes = (jobData, attributes) => {
	const { title, minEducation, english } = attributes;
	const result = [];
	if (title) result.push(jobData.title);
	if (minEducation) result?.push(jobData?.job_data?.min_education);
	if (english) result?.push(jobData?.job_data?.english);
	return result;
};

export const findEducationLevelByName = (educationLevels, level) => {
	let i = 0;
	const len = educationLevels?.length;

	for (i; i < len; i += 1) {
		if (educationLevels[i]?.level === level) {
			return educationLevels[i];
		}
	}
};

export const setFormOneMinEduAndEng = ({
	userFormData,
	formOneData,
	setFormOneData,
	educationLevels,
}) => {
	setFormOneData({
		...formOneData,
		minimum_education: findEducationLevelByName(
			educationLevels,
			userFormData.education_level
		),
		english_level: CommonUtils.getKeyByValue(
			ENGLISH_LEVEL_FORM_DATA,
			userFormData.job_data.english
		),
	});
};

export const findJobTitleById = (jobCategories, title) => {
	let jobTitleObj = {};
	jobTitleObj = jobCategories?.find(val => val.type === title);

	if (!jobTitleObj?.type) {
		return { type: title };
	}

	return jobTitleObj;
};

export const fetchAndDispatchEngAndEdu = dispatch => {
	getEducationLevel()
		.then(resp => {
			if (resp && resp.status === 200 && resp.data) {
				dispatchDefaultEnglishAndEducationOptions({
					englishOptions: Object.values(ENGLISH_LEVEL_FORM_DATA),
					educationOptions: resp.data,
					dispatch,
				});
			}
		})
		.catch(err => {
			throw new Error(err);
		});
};

export const handleFormTwoErrors = ({ formTwoData, formTwoError, setFormTwoError }) => {
	const amount_err = {};

	if (formTwoData.depositStatus === "Yes" && !formTwoData.amount) {
		amount_err.amount = true;
		amount_err.amount_message = "Please enter a value";
	} else if (
		formTwoData.depositStatus === "Yes" &&
		parseInt(formTwoData.amount, 10) <= 0
	) {
		amount_err.amount = true;
		amount_err.amount_message = "Please enter a value greater than 0";
	}

	setFormTwoError({
		...formTwoError,
		...amount_err,
		job_desc:
			!formTwoData?.job_desc?.eng_desc ||
			!CommonUtils.checkForValidJobDescription(formTwoData?.job_desc?.eng_desc),
		depositStatus: !formTwoData.depositStatus,
		purpose: formTwoData.depositStatus === "Yes" && !formTwoData.purpose,
		deposit_reason:
			formTwoData.depositStatus === "Yes" && !formTwoData.deposit_reason,
		english: !formTwoData?.english_level,
	});
};

export const resetFormTwoError = ({ setFormTwoError }) =>
	setFormTwoError({
		job_desc: false,
		depositStatus: false,
		purpose: false,
		amount: false,
		amount_message: "",
		tagErrorMessage: "",
		english: false,
	});

export const selectTag = ({ list, tag }) => {
	return list?.map(obj => (obj.id === tag.id ? { ...obj, selected: true } : obj));
};

export const deSelectTag = ({ list, tag }) => {
	let count = 0;

	const finalList = list?.map(obj => {
		if (obj.selected) count += 1;
		if (!tag || obj.id === tag.id) return { ...obj, selected: false };
		return obj;
	});
	return [finalList, count - 1];
};

export const filterSelectedTagIds = tagList => {
	return tagList?.filter(tag => tag.selected)?.map(obj => obj.id);
};

export const sortEducationOptions = engOptions =>
	engOptions?.sort((a, b) => (a.ordered_id > b.ordered_id ? 1 : -1));

export const applicantLocationOptions = city => [
	{
		label: "Within 10 KM",
		value: "WITHIN_10KM",
	},
	{
		label: "Within 25 KM",
		value: "WITHIN_25KM",
	},
	{
		label: `Entire ${city || "city"}`,
		value: "SAME_CITY",
	},
	{
		label: "Entire India",
		value: "ANYWHERE",
	},
];

export const applicantLocationOptionsWFH = city => [
	{
		label: `Entire ${city || "city"}`,
		value: "SAME_CITY",
	},
	{
		label: "Entire India",
		value: "ANYWHERE",
	},
];

export const findApplicantCityLabel = ({ city, value }) => {
	return applicantLocationOptions(city)?.find(option => option.value === value)?.label;
};

export const jobDepositChangeHandler = ({
	value,
	formTwoData,
	setFormTwoData,
	formTwoError,
	setFormTwoError,
}) => {
	if (value) {
		setFormTwoError({
			...formTwoError,
			deposit_reason: false,
			purpose: false,
		});
	}

	setFormTwoData({
		...formTwoData,
		deposit_reason: value,
		purpose: "",
	});
};

export const findByValueFromFeeList = ({ list, value }) => {
	return list?.find(obj => obj.label === value);
};

export const isBrowserSupported = () => {
	const parser = Bowser.getParser(window.navigator.userAgent);
	const browser = parser.getBrowser();
	const notSupported = UNSUPPORTED_BROWSERS.find(
		obj =>
			obj?.name?.toLowerCase()?.includes(browser?.name?.toLowerCase()) &&
			parseFloat(browser?.version) <= obj.version
	);
	return !notSupported;
};

export const canJobActivate = jobData => {
	const phoneNumbersDetail = jobData?.phone_number_details;
	const hrNumberObj = phoneNumbersDetail?.hr;
	const employerNumberObj = phoneNumbersDetail?.employer;
	if (!(hrNumberObj && employerNumberObj)) return false;
	const isSuspicious = hrNumberObj?.is_suspicious || employerNumberObj?.is_suspicious;
	const isBlocked = hrNumberObj?.is_blocked || employerNumberObj?.is_blocked;
	// If employer or hr number is blocked then job can not be activated.
	if (isBlocked || isSuspicious) return false;

	const isHrNumVerified =
		hrNumberObj?.is_self_verified || hrNumberObj?.is_under_review_verified;

	if (isHrNumVerified) return true;
};

export const handleVerifyPhoneNumberClick = async ({
	employer,
	hr,
	jobData,
	dispatch,
}) => {
	const data = {
		is_under_review_verified: true,
		job_id: jobData?.id,
	};

	try {
		const resp = await verifyPhoneNumber({
			employerNumber: employer,
			hrNumber: hr,
			data,
		});

		if (resp?.status === 200 && resp?.data) {
			dispatch(
				sendNotification({
					message_type: "success",
					message: "Phone number verified",
				})
			);
			if (hr) {
				if (resp?.data?.employer_number === resp?.data?.hr_number) {
					dispatch(
						updateJobPhoneNumDetails({
							jobId: jobData.id,
							hr: resp.data,
							employer: resp.data,
						})
					);
				} else {
					dispatch(
						updateJobPhoneNumDetails({ jobId: jobData.id, hr: resp.data })
					);
				}
			} else {
				dispatch(
					updateJobPhoneNumDetails({
						jobId: jobData.id,
						employer: resp.data,
					})
				);
			}
		}
	} catch (error) {
		dispatch(
			sendNotification({
				message_type: "error",
				message: "Network error. Try again.",
			})
		);
		throw new Error(error);
	}
};

const isDocumentsValid = ({
	dispatch,
	jobData,
	companyVerificationStatus,
	structuredVerificationDetails,
}) => {
	const {
		created_by: { id: creatorId } = {},
		hr: { id: hrId } = {},
		poc_verification: pocVerification,
		email_verification_details: email,
		job_data: { interview_info: interviewInfo } = {},
		creator_organization: creatorOrg,
	} = jobData || {};

	const notifDuration = 15000;

	const isCreatorNotHr = creatorId !== hrId;

	const isMatchingDomain = isMatchingEmail(
		email?.domains || [],
		interviewInfo?.hr_email
	);

	const isEmailVerificationRequired = creatorOrg?.is_verified && isMatchingDomain;

	const isNotEmailVerified = isEmailVerificationRequired
		? !email?.is_verified
		: structuredVerificationDetails?.status !==
		  VERIFICATION_STATUSES.COMPLETED_PENDING;

	if (structuredVerificationDetails?.status === VERIFICATION_STATUSES.APPROVED) {
		return true;
	}
	if (
		(isNotEmailVerified &&
			(!companyVerificationStatus ||
				companyVerificationStatus === COMPANY_DOC_VERIFICATION_STATUS.PENDING ||
				companyVerificationStatus === COMPANY_DOC_VERIFICATION_STATUS.REJECTED) &&
			pocVerification?.creator_status !== POC_VERIFICATION_STATUS.VERIFIED &&
			pocVerification?.hr_status !== POC_VERIFICATION_STATUS.VERIFIED) ||
		![VERIFICATION_STATUSES.APPROVED].includes(structuredVerificationDetails?.status)
	) {
		dispatch(
			sendNotification({
				message_type: "error",
				message: `Please complete any pending verification steps`,
				duration: notifDuration,
			})
		);
		return false;
	}

	const pendingDocs = [];

	if (companyVerificationStatus === COMPANY_DOC_VERIFICATION_STATUS.PENDING)
		pendingDocs.push("Company Doc");

	if (pocVerification?.creator_status === COMPANY_DOC_VERIFICATION_STATUS.PENDING)
		pendingDocs.push(isCreatorNotHr ? "Creator POC doc" : "POC Doc");

	if (
		isCreatorNotHr &&
		pocVerification?.hr_status === COMPANY_DOC_VERIFICATION_STATUS.PENDING
	)
		pendingDocs.push("HR POC doc");

	if (pendingDocs.length) {
		const multipleDocsPending = pendingDocs.length > 1;
		dispatch(
			sendNotification({
				message_type: "error",
				message: `${makeString(pendingDocs)} ${
					multipleDocsPending ? "are" : "is"
				} in pending state. Either mark ${
					multipleDocsPending ? "them" : "it"
				} verified or rejected to continue.`,
				duration: notifDuration,
			})
		);
		return false;
	}

	return true;
};

export const activateJob = ({
	e,
	jobData,
	activeIndex,
	updateJobStatus,
	dispatch,
	action,
	companyVerificationStatus,
	showActivationWarningModal,
	structuredVerificationDetails,
}) => {
	if (jobData?.created_by?.lead_type) {
		if (!canJobActivate(jobData)) {
			dispatch(
				sendNotification({
					message_type: "error",
					message: `Please verify phone numbers to ${action} the job`,
				})
			);
			return;
		}

		if (jobData?.missing_field_string !== "") {
			dispatch(
				sendNotification({
					message_type: "error",
					message: `Please complete the form to ${action} the job`,
				})
			);
			return;
		}

		// Document Validation is not required while re-activating.
		if (
			action !== "re-activate" &&
			!isDocumentsValid({
				dispatch,
				jobData,
				companyVerificationStatus,
				structuredVerificationDetails,
			})
		)
			return;

		if (
			jobData?.created_by?.lead_type !== EMPLOYER_TYPES_KEYS.CONSULTANT &&
			showActivationWarningModal === ACTIVATION_WARNING_MODAL_STATES.CLOSE &&
			jobData?.creator_organization?.organization_type?.toLowerCase() ===
				EMPLOYER_TYPES_KEYS.SMB &&
			(jobData?.created_by?.lead_type === EMPLOYER_TYPES_KEYS.KEY_ACCOUNT ||
				jobData?.created_by?.lead_type === EMPLOYER_TYPES_KEYS.ENTERPRISE)
		) {
			dispatch(toggleActivationWarningModal());
			return;
		}

		updateJobStatus({
			status: "activate",
			jobId: jobData?.id,
			index: activeIndex,
			e,
		});

		const tempData = {
			USER_VERIFICATION_STATUS:
				structuredVerificationDetails?.structuredStepsData?.USER_VERIFICATION
					?.status,
			COMPANY_VERIFICATION_STATUS:
				structuredVerificationDetails?.structuredStepsData?.COMPANY_VERIFICATION
					?.status,
			CONSULTANT_MANDATE_STATUS:
				structuredVerificationDetails?.structuredStepsData?.CONSULTANT_MANDATE
					?.status,
			EMAIL_VERIFICATION_STATUS:
				structuredVerificationDetails?.structuredStepsData?.EMAIL_VERIFICATION
					?.status,
			"Creator Org Type": jobData?.creator_organization?.domains?.length
				? "Domain Whitelisted"
				: "Non Domain Whitelisted",
		};

		sendMixpanelCompanyVerificationEventsEvents({
			eventName: "Job Activated - verification status",
			jobData,
			tempData,
			verificationDetails: structuredVerificationDetails,
		});
		return;
	}
	dispatch(
		sendNotification({
			message_type: "error",
			message: "Select lead type first.",
		})
	);
};

export const isHrPhoneVerificationReq = ({ verifyNumberData, jobPhoneNumsDetail }) => {
	return (
		verifyNumberData?.is_employer_verified !== "Banned" &&
		verifyNumberData?.is_hr_verified !== "Banned" &&
		(!jobPhoneNumsDetail?.hr?.is_under_review_verified ||
			jobPhoneNumsDetail?.hr?.is_suspicious)
	);
};

export const isEmployerPhoneVerificationReq = ({
	verifyNumberData,
	jobPhoneNumsDetail,
}) => {
	return (
		verifyNumberData?.is_employer_verified !== "Banned" &&
		(!jobPhoneNumsDetail?.employer?.is_under_review_verified ||
			jobPhoneNumsDetail?.employer?.is_suspicious)
	);
};

export const isJobApproved = jobData => {
	// if job is active(0) or expired(2)
	if (jobData?.status === 0 || jobData?.status === 2) {
		return true;
	}
	return false;
};

export const checkForJobAutoActivation = jobData => {
	if (jobData?.status !== 1) return null;

	const comments = jobData?.comments;

	let result = null;

	const isNewJob = comments?.every(comment => {
		const remarkType = comment?.remark_type;

		if (remarkType === JOB_REMARKS.AUTO_ACTIVATION_PASS) {
			result = JOB_AUTO_ACTIVATION_RESULT.PASS;
		} else if (remarkType === JOB_REMARKS.AUTO_ACTIVATION_FAIL) {
			result = JOB_AUTO_ACTIVATION_RESULT.FAIL;
		}

		if (remarkType !== JOB_REMARKS.JOB_UPDATED) {
			return true;
		}

		return false;
	});

	if (isNewJob) return result;

	return null;
};

export const generateTimeSeries = step => {
	const dt = new Date(1970, 0, 1);
	const result = [];
	while (dt.getDate() === 1) {
		result.push(
			dt
				.toLocaleString("en-IN", {
					hour: "2-digit",
					minute: "2-digit",
					hour12: true,
				})
				.toUpperCase()
		);
		dt.setMinutes(dt.getMinutes() + step);
	}
	return result;
};

const convertStrTo24Hr = time => {
	// time format hh:mm AM/PM
	if (time) {
		let matchedHours = time.match(/^(\d+)/);
		let hours = matchedHours.length > 1 && Number(matchedHours[1]);
		let matchedMins = time.match(/:(\d+)/);
		const minutes = matchedMins.length > 1 && Number(matchedMins[1]);
		let matchedAMPM = time.match(/\s(.*)$/);
		const AMPM = matchedAMPM.length > 1 && matchedAMPM[1];
		if (AMPM?.toLowerCase() === "pm" && hours < 12) hours += 12;
		if (AMPM?.toLowerCase() === "am" && hours === 12) hours -= 12;

		return {
			hours,
			minutes,
		};
	}
};

export const calculateTimeDiff = (startTime, endTime) => {
	const year = 1970;
	const month = 0; // Jan
	const day = 1;

	const startTimeObj = convertStrTo24Hr(startTime);
	const endTimeObj = convertStrTo24Hr(endTime);

	const startDateTime = new Date(
		year,
		month,
		day,
		startTimeObj?.hours,
		startTimeObj?.minutes
	);
	const endDateTime = new Date(year, month, day, endTimeObj.hours, endTimeObj.minutes);

	let duration = (endDateTime - startDateTime) / (1000 * 60 * 60); // convert mill-sec to hours
	if (duration < 0) duration += 24;

	return duration;
};

export const formatWorkingDays = workingDays => {
	let isContinous = false;
	let formattedStr = "";

	const noOfWorkingDays = workingDays?.length;

	if (noOfWorkingDays === 7) {
		isContinous = true;
		formattedStr = "All days of week";
	} else if (noOfWorkingDays === 1) {
		isContinous = true;
		[formattedStr] = workingDays;
	}

	const workingDaysObjs = DAYS.filter(day => workingDays?.includes(day.value));

	const previousDayNotFound = [];
	const nextDayNotFound = [];

	if (!isContinous) {
		workingDaysObjs.forEach((day, i, array) => {
			const currentDayIndex = day.index;
			const previousDayIndex = currentDayIndex === 0 ? 6 : currentDayIndex - 1;
			const nextDayIndex = currentDayIndex === 6 ? 0 : currentDayIndex + 1;

			const previousDay = array.find(obj => obj.index === previousDayIndex);
			const nextDay = array.find(obj => obj.index === nextDayIndex);
			if (!previousDay) previousDayNotFound.push(day);
			if (!nextDay) nextDayNotFound.push(day);
		});

		let startDay = null;
		let endDay = null;
		if (previousDayNotFound?.length <= 1 && nextDayNotFound?.length <= 1) {
			isContinous = true;
			[startDay] = previousDayNotFound;
			[endDay] = nextDayNotFound;
		}

		const missingDays = DAYS.filter(day => !workingDays?.includes(day.value))?.map(
			day => day.value
		);

		if (noOfWorkingDays >= 4 && noOfWorkingDays <= 6) {
			if (isContinous) {
				formattedStr = `${startDay?.value} - ${endDay?.value}`;
			} else {
				const commaSeparatedMisssingDays = missingDays?.join(", ");

				formattedStr = `Weekly off on ${commaSeparatedMisssingDays}`;
			}
		} else {
			formattedStr = workingDaysObjs?.map(day => day.value)?.join(", ");
		}
	}

	return formattedStr;
};

export const isEmailVerified = async (createdById, email) => {
	const res = await getEmailVerificationStatus(createdById, email);
	return res?.status === 200 && res?.data.is_verified;
};
