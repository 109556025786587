import React from "react";

const DashboardIcon = ({ fill = "#5E6C84", ...props }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_755_7787)">
				<path
					d="M2.88889 10.8889H8.22222C8.71111 10.8889 9.11111 10.4889 9.11111 10V2.88889C9.11111 2.4 8.71111 2 8.22222 2H2.88889C2.4 2 2 2.4 2 2.88889V10C2 10.4889 2.4 10.8889 2.88889 10.8889ZM2.88889 18H8.22222C8.71111 18 9.11111 17.6 9.11111 17.1111V13.5556C9.11111 13.0667 8.71111 12.6667 8.22222 12.6667H2.88889C2.4 12.6667 2 13.0667 2 13.5556V17.1111C2 17.6 2.4 18 2.88889 18ZM11.7778 18H17.1111C17.6 18 18 17.6 18 17.1111V10C18 9.51111 17.6 9.11111 17.1111 9.11111H11.7778C11.2889 9.11111 10.8889 9.51111 10.8889 10V17.1111C10.8889 17.6 11.2889 18 11.7778 18ZM10.8889 2.88889V6.44444C10.8889 6.93333 11.2889 7.33333 11.7778 7.33333H17.1111C17.6 7.33333 18 6.93333 18 6.44444V2.88889C18 2.4 17.6 2 17.1111 2H11.7778C11.2889 2 10.8889 2.4 10.8889 2.88889Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_755_7787">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default DashboardIcon;
