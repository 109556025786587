/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from "react";

const withSuspense = (Component, fallbackComp = "") => props => (
	<Suspense fallback={fallbackComp}>
		<Component {...props} />
	</Suspense>
);

export default withSuspense;
