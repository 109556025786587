import React from "react";
import Text, { TEXT_TYPE } from "../../../components/Text";
import { Flex } from "../../../styles/FlexBoxStyles";
import { WhatsAppFilledInnerIcon } from "../../../assets/svg";
import UrgentlyHiringIcon from "../../../assets/svgs/UrgentlyHiringIcon";
import WhatsAppBoostIcon from "../../../assets/svgs/WhatsAppBoostIcon";
import IncreasedJobSearchIcon from "../../../assets/svgs/IncreasedJobSearchIcon";
import JobBrandingIcon from "../../../assets/svgs/JobBrandingIcon";

export const reviewDetails = [
	{
		id: 1,
		content: (
			<>
				Applications are subject to&nbsp;
				<span>jobs attractiveness and competition</span>
			</>
		),
	},
	{
		id: 2,
		content: (
			<>
				<span>Renew job</span>
				&nbsp;once target applications are reached, to get more applications
			</>
		),
	},
	{
		id: 3,
		content: (
			<>
				<span>Quick response & correct job information</span>
				&nbsp;will give much better results
			</>
		),
	},
	{
		id: 4,
		content: (
			<>
				<span>De-activate</span>
				&nbsp;your job once you have filled your position
			</>
		),
	},
	{
		id: 5,
		content: (
			<>
				Any&nbsp;
				<span>unethical practices</span>
				&nbsp;will be immediately&nbsp;
				<span>reported to the authorities</span>
				&nbsp;with your&nbsp;
				<span>KYC details</span>
			</>
		),
	},
];

export const biggerPlansUses = [
	{
		id: 1,
		content: "Cheaper cost per application",
	},
	{
		id: 2,
		content: "More applications in a single job post",
	},
	{
		id: 3,
		content: "Assured applications as per plan",
	},
	{
		id: 4,
		content: "Valid until fulfilled",
	},
];

export const APNA_PROMISE_HEADER = "Coin Return Guarantee";
export const APNA_PROMISE_HEADER_V2 = "100% Coin return guarantee in 15 days";

export const BULK_HIRING_PLAN_TITLE = "Bulk";

export const PPJ_PLAN_FEATURES = {
	job_branding: {
		isInfoIcon: true,
		description: isAllowed => (
			<Text color={isAllowed ? "#172B4D" : "#C1C7D0"}>Job branding</Text>
		),
		toolTipContent: {
			heading: "Job branding",
			subHeading: "Your job will be listed with your company’s logo",
			icon: <JobBrandingIcon />,
		},
	},

	unlimited_applications: {
		isInfoIcon: false,
		description: isAllowed => (
			<Text color={isAllowed ? "#172B4D" : "#C1C7D0"}>Unlimited applications</Text>
		),
		toolTipContent: {
			heading: "",
			subHeading: "",
			icon: null,
		},
	},
	detailed_job_description: {
		isInfoIcon: false,
		description: isAllowed => (
			<Text color={isAllowed ? "#172B4D" : "#C1C7D0"}>
				Detailed job description
			</Text>
		),
		toolTipContent: {
			heading: "",
			subHeading: "",
			icon: null,
		},
	},
	increased_visibility_job_search: {
		isInfoIcon: true,
		description: isAllowed => (
			<Text color={isAllowed ? "#172B4D" : "#C1C7D0"}>
				Increased visibility on job listing page
			</Text>
		),
		toolTipContent: {
			heading: "Increased visibility",
			subHeading: "Your job will be listed on top of job listing page",
			icon: <IncreasedJobSearchIcon />,
		},
	},
	smart_boost_via_whatsapp: {
		isInfoIcon: true,
		description: isAllowed => (
			<Flex>
				<Text color={isAllowed ? "#172B4D" : "#C1C7D0"}>
					Smart boost via&nbsp;
				</Text>
				<WhatsAppFilledInnerIcon fill={isAllowed ? "#60D66A" : "#E0E0E0"} />
				<Text color={isAllowed ? "#172B4D" : "#C1C7D0"}> &nbsp;WhatsApp</Text>
			</Flex>
		),
		toolTipContent: {
			heading: "Smart boost via ‘WhatsApp’",
			subHeading: "We will boost your job post via WhatsApp",
			icon: <WhatsAppBoostIcon />,
		},
	},
	urgently_hiring_tag: {
		isInfoIcon: true,
		description: isAllowed => (
			<Flex>
				<Text color={isAllowed ? "#172B4D" : "#C1C7D0"}>
					Get noticed with&nbsp;
				</Text>
				<Text
					color={isAllowed ? "#172B4D" : "#C1C7D0"}
					type={TEXT_TYPE.SUBTITLE_2}
				>
					‘Urgently hiring’&nbsp;
				</Text>
				<Text color={isAllowed ? "#172B4D" : "#C1C7D0"}>tag</Text>
			</Flex>
		),
		toolTipContent: {
			heading: "‘Urgently hiring’ tag",
			subHeading: "Your job will be listed with ‘Urgently hiring’ tag",
			icon: <UrgentlyHiringIcon />,
		},
	},
};

export const PPJ_SELECT_PLAN_TITLES = {
	STANDARD: "Standard",
	PREMIUM: "Premium",
};

export const INTIAL_NUMBER_OF_APPLICATION_FOR_BULK_JOB = 1000;

export const BUY_COINS_PACKAGES = "BUY_COINS_PACKAGES";
export const BUY_COINS_PRICING_PLANS = "BUY_COINS_PRICING_PLANS";
