import { SORT_APPLICATION_OPTIONS } from "../constants";
import {
	UPDATE_JOB_FILTERS,
	SET_JOB_FILTERS,
	RESET_JOB_FILTERS,
	CLEAR_JOB_FILTERS_AND_SORTING,
	UPDATE_SORT_BY,
	CLEAR_SORTING,
	TRIGGER_CLEAR_FILTERS,
	SET_TIME_FILTER_APPLIED,
	UPDATE_FILTERED_APPLICATIONS_COUNT,
} from "../constants/types";

const initialState = {
	sortBy: SORT_APPLICATION_OPTIONS[0].value,
	availableFilters: {},
	initiateClearFilters: false,
	isTimeFilterApplied: false,
	filteredApplicationsCount: 0,
};

export default (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_JOB_FILTERS: {
			return { ...state, ...payload };
		}
		case UPDATE_JOB_FILTERS: {
			return {
				...state,
				filters: { ...state.filters, ...payload.filters },
			};
		}
		case UPDATE_SORT_BY: {
			return { ...state, sortBy: payload.sortBy };
		}
		case CLEAR_JOB_FILTERS_AND_SORTING: {
			return { ...state, ...payload };
		}
		case RESET_JOB_FILTERS: {
			return initialState;
		}
		case CLEAR_SORTING: {
			return { ...state, ...payload };
		}
		case TRIGGER_CLEAR_FILTERS: {
			return {
				...state,
				initiateClearFilters: payload,
			};
		}
		case SET_TIME_FILTER_APPLIED: {
			return {
				...state,
				isTimeFilterApplied: payload,
			};
		}
		case UPDATE_FILTERED_APPLICATIONS_COUNT: {
			return {
				...state,
				filteredApplicationsCount: payload,
			};
		}
		default:
			return state;
	}
};
