import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const TEXT_TYPE = {
	DISPLAY_1: "display1",
	HEADING_1: "heading1",
	HEADING_2: "heading2",
	SUBTITLE_1: "subtitle1",
	SUBTITLE_2: "subtitle2",
	SUBTITLE_3: "subtitle3",
	BODY_1: "body1",
	BODY_2: "body2", // default
	BODY_3: "body3",
	TITLE_1: "title1",
	TITLE_2: "title2",
};

/* {

    Figma Related to the Typography Component:
    https://www.figma.com/file/ATf7T2xVfF7bvUmwbz5c0Z/Lunchbox-web-foundation?node-id=78%3A2

} */

const Text = styled(Typography)(
	({ type, color = "#172B4D", mt = 0, mb = 0, ml = 0, mr = 0 }) => ({
		color,

		// TEXT_TYPE.BODY_2
		fontSize: "14px",
		lineHeight: 1.43,
		fontWeight: 400,

		marginTop: `${mt}px`,
		marginBottom: `${mb}px`,
		marginLeft: `${ml}px`,
		marginRight: `${mr}px`,

		"& .MuiTypography-body1": {
			lineHeight: "1.43 !important",
		},

		...(type === TEXT_TYPE.HEADING_1 && {
			fontWeight: 600,
			fontSize: "24px",

			"& .MuiTypography-body1": {
				lineHeight: "1.5 !important",
			},
		}),

		...(type === TEXT_TYPE.HEADING_2 && {
			fontWeight: 600,
			fontSize: "20px",

			"& .MuiTypography-body1": {
				lineHeight: "1.4 !important",
			},
		}),

		...(type === TEXT_TYPE.SUBTITLE_1 && {
			fontWeight: 600,
			fontSize: "16px",

			"& .MuiTypography-body1": {
				lineHeight: "1.5 !important",
			},
		}),

		...(type === TEXT_TYPE.SUBTITLE_2 && {
			fontWeight: 600,
			fontSize: "14px",
		}),

		...(type === TEXT_TYPE.SUBTITLE_3 && {
			fontWeight: 600,
			fontSize: "12px",

			"& .MuiTypography-body1": {
				lineHeight: "1.33 !important",
			},
		}),

		...(type === TEXT_TYPE.BODY_1 && {
			fontSize: "16px",

			"& .MuiTypography-body1": {
				lineHeight: "1.5 !important",
			},
		}),

		...(type === TEXT_TYPE.BODY_3 && {
			fontSize: "12px",

			"& .MuiTypography-body1": {
				lineHeight: "1.33 !important",
			},
		}),
		...(type === TEXT_TYPE.TITLE_1 && {
			fontSize: "40px",
			fontWeight: 600,

			"& .MuiTypography-body1": {
				lineHeight: "1.33 !important",
			},
		}),

		...(type === TEXT_TYPE.TITLE_2 && {
			fontSize: "32px",
			fontWeight: 600,

			"& .MuiTypography-body1": {
				lineHeight: "1.375 !important",
			},
		}),
	})
);

export default Text;
