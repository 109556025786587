import {
	SHOW_FORM,
	EDIT_FORM,
	DUPLICATE_JOB,
	SHOW_PROFILE_PAGE,
	SHOW_CONSULTANT_MODAL,
	SHOW_ONBOARDING_MODAL,
	SHOW_HEADCOUNT_MODAL,
	CLIENT_CONNECTION_MODAL,
	SHOW_CHANGE_COMPANY_MODAL,
	SET_USER_DETAILS,
	UPDATE_COMPANY_DOC,
	EDIT_COMPANY_DOC,
	RESET_FORM,
	CLEAR_EDIT_FORM,
	SET_AGE_UNLEASH_STATUS,
	NEW_JOB_POSTED,
	UPDATE_POC_DOC,
	UPDATE_USER_DETAILS,
	SET_WORKSPACE,
	UPDATE_WORKSPACE,
	SET_IS_WORKSPACE_UPDATED,
	DUPLICATE_JOB_FOUND,
	UPDATE_CALL_LATEST_VERIFICATION_API_FLAG,
	UPDATE_VERIFICATION_MODAL_OPEN_STATE,
	SET_MAX_VALUES_FOR_TAGS,
	UPDATE_MAX_VALUES_FOR_TAGS,
	SET_JOB_ALLOW_OPTION,
	UPDATE_JOB_ALLOW_OPTION,
	SET_EDUCATION_OPTIONS,
	SET_ENGLISH_OPTIONS,
	SET_JOB_TAGS,
	UPDATE_JOB_TAG,
	SET_SUB_DEPARTMENT_LIST,
	SET_DEPARTMENT_LIST,
	SHOW_MISSING_FIELDS_INFO_PANEL,
	SAVE_JPF_MISSING_FIELDS,
	SET_IS_JPF_ERROR,
	SET_ALLOWED_JOB_TITLES,
	SHOW_REQUEST_SUPPORT_CTA,
	SHOW_SUPPORT_TICKET_MODAL,
	SHOW_SUPPORT_TICKET_INFORMATION_MODAL,
	HEADCOUNT_VALUE,
	OPTIONAL_FIELD_BANNER_CTA_CLICKED,
	SHOW_SUCCESS_POST_COMPLETING_BWCP_FLOW,
	USER_SELECTED_NUM_EMPLOYEE,
	IS_MANUAL_EDIT,
	IS_ONLY_BWCP_OPTIONAL_FLOW,
	SHOW_OPTIONAL_MISSING_FIELDS_PANEL,
	OPTIONAL_FLOW_MODAL_CTA_CLICKED,
	AFTER_RENEWAL_BWCP_STATUS,
	BWCP_AUTOSCROLL_TIMEOUT,
	SELECTED_DEGREE,
	SET_CATEGORY_ID,
	WHY_VERIFY_BOTTOM_SHEET_CLOSE,
	SELECTED_INDUSTRY,
	JOYRIDE_BASIC_DETAILS_DISPLAY_STATE,
	JOYRIDE_JOB_DETAILS_DISPLAY_STATE,
	ORG_SEARCH_DATA,
	USER_SOURCE,
	HIDE_VERIFICATION_PAGE_RIGHT_PANE,
	UNDER_REVIEW_BANNER_CTA_TEXT,
	SET_SMILEY_MODAL_OPEN,
	CURRENT_VERIFICATION_SCREEN,
	JOB_POST_SUCCESS_BANNER,
	IS_BACKWARD_FLOW,
	MIN_SKILLS_REQUIRED,
	TROPHY_JOB,
	TROPHY_JOB_ALLOWED,
	TROPHY_JOB_CONFIRMATION_MODAL,
	TROPHY_JOB_CTA_CLICK,
	IS_ENGLISH_PREFILL_REMOVED,
	AUTOSELECT_DEGREE_OPTIONS,
	VAS_JOB,
	VAS_JOB_ALLOWED,
	VAS_JOB_CONFIRMATION_MODAL,
	VAS_JOB_CTA_CLICK,
	OTHER_TAG_IDS,
	SET_TITLE_DEPARTMENT_LIST_PAYLOAD,
	ENTERPRISE_WORKSPACE_DATA,
	ENTERPRISE_CONFIRM_DISMISS_MODAL,
	USER_PROFILE_V2_DATA,
	SHOW_ENTERPRISE_JOBS_MIGRATION_PAGE,
	SET_SHOW_EDIT_WARNING_MODAL_VALUES,
	SET_IS_WFH_ALLOWED,
	SET_ADD_COMPANY_DETAILS_CTA_CLICKED,
	SET_WHITELISTED_EMAIL_SUBMITTED,
	SET_ORG_VERIFICATION_LEVEL,
	IS_EDITED_FROM_PREVIEW_PAGE,
	SET_GET_JOB_DATA_FOR_PREVIEW,
	SET_IS_LATEST_JOB_POST_NEW,
	SET_IS_STEP3_CONTINUE_CLICKED,
	SET_SELECTED_ACCOUNT_DETAILS,
	DUPLICATE_JOB_SOURCE,
	JOB_POST_CTA_JOYRIDE_SHOWN,
	DB_KALEYRA_CALL_ID,
	SET_IS_ENTERED_JPF_FROM_PREVIEW,
	DATABASE_CTA_JOYRIDE_SHOWN,
	SET_IS_AUTORENEWAL_FLOW,
	SET_WORKSPACE_LIST,
	SHOW_PJF_DROPOFF_MODAL,
	SHOW_PJF_FEEDBACK_MODAL,
	CURRENT_JOB_TITLE,
	SHOW_FEEDBACK_MODAL_POST_DROPOFF,
	SET_DISCOUNT_COUPON_DATA,
	SET_JOBS_LIST_FROM_DASHBOARD,
	SET_RENEWED_EDIT_FROM_JOBS_PAGE,
} from "../constants/FormTypes";

import { SHOW_UPLOAD_DOC, HIDE_UPLOAD_DOC } from "../constants/ModalTypes";
import { sortEducationOptions } from "../utils";
import { USER_SOURCES } from "../views/PostJobFormNew/constants";

const DEFAULT_POC_DOC = {
	type: null,
	poc_file: null,
	poc_file2: null,
	pocfile_name: "",
	pocfile2_name: "",
	pocFileObj: null,
	pocFileObj2: null,
	poc_verification_status: null,
	edit: false,
	diff: false,
};

const initialState = {
	showForm: false,
	resetForm: false,
	editFormData: {},
	duplicateJobFormData: {},
	isDuplicateJob: false,
	showProfilePage: false,
	showConsultantModal: false,
	showOnboardingModal: false,
	showHeadcountModalPostJob: false,
	clientConnectionModal: false,
	showCompanyChangeModal: false,
	showRequestSupportCTA: false,
	showSupportTicketModal: false,
	showSupportTicketInformationModal: false,
	currentSelectedDegree: "",
	currentSelectedIndustry: "",
	userSelectedNumEmployee: "",
	userDetails: {},
	companyDoc: {
		formStates: {
			document_type: "",
			document_number: "",
			filePublicUrl: "",
			fileName: "",
			fileObject: null,
			fileObject2: null,
			docFile: null,
			docDetail: "",
			companyUrl: "",
			fileName2: "",
			verificationStatus: "pending",
			documentSource: "",
		},
		formErrors: {
			document_type: false,
			document_number: false,
			file: false,
			edit: false,
			diff: false,
		},
	},
	pocDoc: DEFAULT_POC_DOC,
	pocDocHr: DEFAULT_POC_DOC,
	isAgeSpecificJob: { isEnabled: false, value: [] },
	newJobPosted: true,
	workspace: {},
	isWorkspaceUpdated: false,
	duplicateJobFound: false,
	callLatestVerificationAPI: false,
	showCompanyVerificationModal: false,
	maxAssetTagsCount: 5,
	maxDegreeTagsCount: 4,
	maxSkillTagsCount: 4,
	maxLanguageTagsCount: 4,
	wfh: true,
	wfo: true,
	fjo: true,
	educationOptions: [],
	englishOptions: [],
	headcountValue: "",
	skillTags: [],
	assetTags: [],
	degreeTags: [],
	languageTags: [],
	vasTags: [],
	salaryPerksTags: [],
	departmentList: [],
	isJobPostFormError: false,
	showMissingFieldsPanel: false,
	jobPostMissingFields: [],
	allowedJobTitles: [],
	whyVerifyBottomSheetClose: true,
	subCategory: {
		show: false,
		list: [],
	},
	optionalFieldsBannerCtaClicked: false,
	showSuccessModalPostCompletingBwcpFlow: false,
	isManualEdit: false,
	isOnlyBwcpOptionalFlow: false,
	showOptionalMissingFieldsPanel: false,
	optionalFlowModalCtaClicked: false,
	afterRenewalBwcpStatus: 1,
	bwcpAutoscrollTimeout: false,
	subDepartment: [],
	categoryId: "",
	joyrideBasicDetailsDisplayState: true,
	joyrideJobDetailsDisplayState: true,
	orgSearchData: [],
	userSource: USER_SOURCES.OTHERS,
	hideVerificationPageRightPane: false,
	urBannerCtaText: "",
	smileyModalOpen: false,
	currentVerificationScreen: "",
	showJobPostSuccessBanner: true,
	isBackwardFlow: false,
	minSkillsRequired: 0,
	setIsTrophyJob: false,
	setIsTrophyJobAllowed: false,
	showTrophyJobConfirmationModal: false,
	trophyJobCtaClick: false,
	isEnglishPrefillRemoved: false,
	autoselectDegreeOptions: true,
	enterpriseWorkspaceData: {},
	showEnterpriseDismissModal: false,
	userProfileV2Data: {},
	setIsVasJob: false,
	setIsVasJobAllowed: false,
	showVasJobConfirmationModal: false,
	vasJobCtaClick: false,
	otherTagIds: [],
	showEnterpriseJobsMigrationPage: true,
	pjfEditWarningModal: {
		open: false,
	},
	isWfhAllowed: true,
	isAddCompanyDetailsCtaClicked: false,
	whitelistedEmailSubmitted: false,
	orgVerificationLevel: 0,
	isEditedFromPreview: false,
	jobDataForPreview: {},
	isNewJobPost: false,
	isStep3ContinueClicked: false,
	selectedAccountDetails: {},
	duplicateJobSource: "",
	jobPostCtaJoyrideShown: false,
	dbKaleyraCallId: "",
	isEnteredJpfFromPreview: false,
	databaseCtaJoyrideShown: false,
	isAutorenewalFlow: false,
	workspaceList: [],
	showPjfDropoffModal: false,
	pjfFeedbackModalData: {},
	currentJobTitle: {},
	showFeedbackModalPostDropoff: false,
	discountCouponData: [],
	jobsListFromDashboard: [],
	renewedEditFromjobsPage: false,
};

const forms = (state = initialState, action) => {
	switch (action.type) {
		case SHOW_FORM:
			return {
				...state,
				showForm: action.value,
			};
		case EDIT_FORM:
			return {
				...state,
				showForm: action.values.showForm,
				editFormData: action.values.editFormData,
				callLatestVerificationAPI: true,
			};
		case CLEAR_EDIT_FORM:
			return {
				...state,
				editFormData: {},
			};
		case RESET_FORM:
			return {
				...state,
				resetForm: action.value,
			};
		case DUPLICATE_JOB:
			return {
				...state,
				showForm: action.values.showForm,
				isDuplicateJob: true,
				duplicateJobFormData: action.values.duplicateJobFormData,
			};

		case SHOW_PROFILE_PAGE:
			return {
				...state,
				showProfilePage: action.value,
			};
		case SHOW_CONSULTANT_MODAL:
			return {
				...state,
				showConsultantModal: action.value,
			};
		case SHOW_ONBOARDING_MODAL:
			return {
				...state,
				showOnboardingModal: action.value,
			};
		case SHOW_HEADCOUNT_MODAL:
			return {
				...state,
				showHeadcountModalPostJob: action.value,
			};
		case CLIENT_CONNECTION_MODAL:
			return {
				...state,
				clientConnectionModal: action.value,
			};
		case SHOW_CHANGE_COMPANY_MODAL:
			return {
				...state,
				showCompanyChangeModal: action.value,
			};
		case SET_IS_WORKSPACE_UPDATED:
			return {
				...state,
				isWorkspaceUpdated: action.value,
			};
		case SET_USER_DETAILS:
			return {
				...state,
				userDetails: action.values,
			};
		case UPDATE_USER_DETAILS:
			return {
				...state,
				userDetails: {
					...state.userDetails,
					...action.values,
				},
			};
		case UPDATE_COMPANY_DOC:
			return {
				...state,
				companyDoc: {
					...state.companyDoc,
					formStates: {
						...state.companyDoc.formStates,
						...action.value.formStates,
					},
					formErrors: {
						...state.companyDoc.formErrors,
						...action.value.formErrors,
					},
				},
			};

		case EDIT_COMPANY_DOC:
			return {
				...state,
				companyDoc: {
					...state.companyDoc,
					formStates: {
						...state.companyDoc.formStates,
						edit: true,
					},
				},
			};
		case SHOW_UPLOAD_DOC:
			return {
				...state,
				companyDoc: {
					...state.companyDoc,
					formStates: {
						...state.companyDoc.formStates,
						edit: true,
					},
					formErrors: {
						...state.companyDoc.formErrors,
						document_type: false,
						document_number: false,
						file: false,
					},
				},
			};
		case HIDE_UPLOAD_DOC:
			return {
				...state,
				companyDoc: {
					...state.companyDoc,
					formStates: {
						...state.companyDoc.formStates,
						edit: false,
					},
				},
			};
		case SET_AGE_UNLEASH_STATUS:
			return {
				...state,
				isAgeSpecificJob: {
					isEnabled: action.data.isEnabled,
					value: action.data.categories,
				},
			};
		case NEW_JOB_POSTED:
			return {
				...state,
				newJobPosted: action.data,
			};
		case SET_WORKSPACE:
			return {
				...state,
				workspace: action.data,
			};
		case SET_WORKSPACE_LIST:
			return {
				...state,
				workspaceList: action.data,
			};
		case UPDATE_WORKSPACE: {
			return {
				...state,
				workspace: {
					...state.workspace,
					...action.data,
				},
			};
		}
		case UPDATE_POC_DOC:
			return {
				...state,
				...(action.isHr
					? {
							pocDocHr: {
								...state.pocDocHr,
								...action.value,
							},
					  }
					: {
							pocDoc: {
								...state.pocDoc,
								...action.value,
							},
					  }),
			};
		case DUPLICATE_JOB_FOUND:
			return { ...state, duplicateJobFound: action.payload };
		case UPDATE_CALL_LATEST_VERIFICATION_API_FLAG:
			return { ...state, callLatestVerificationAPI: action.payload };
		case UPDATE_VERIFICATION_MODAL_OPEN_STATE:
			return { ...state, showCompanyVerificationModal: action.payload };
		case SET_MAX_VALUES_FOR_TAGS:
			return {
				...state,
				maxAssetTagsCount: action.payload.maxAssetTagsCount,
				maxDegreeTagsCount: action.payload.maxDegreeTagsCount,
				maxSkillTagsCount: action.payload.maxSkillTagsCount,
			};
		case UPDATE_MAX_VALUES_FOR_TAGS:
			return {
				...state,
				[action.key]: action.payload,
			};
		case SET_JOB_ALLOW_OPTION:
			return {
				...state,
				wfo: action.wfo,
				wfh: action.wfh,
				fjo: action.fjo,
			};
		case UPDATE_JOB_ALLOW_OPTION:
			return {
				...state,
				[action.key]: action.payload,
			};
		case SET_EDUCATION_OPTIONS:
			return {
				...state,
				educationOptions: sortEducationOptions(action.payload),
			};
		case SET_ENGLISH_OPTIONS:
			return {
				...state,
				englishOptions: action.payload,
			};
		case SET_JOB_TAGS:
			return {
				...state,
				assetTags: action.assetTags,
				languageTags: action.languageTags,
				salaryPerksTags: action.salaryPerksTags,
				skillTags: action.skillTags,
				vasTags: action.vasTags,
			};
		case UPDATE_JOB_TAG:
			return {
				...state,
				[action.key]: action.payload,
			};
		case SET_SUB_DEPARTMENT_LIST:
			return {
				...state,
				subDepartment: action.payload,
			};
		case SET_DEPARTMENT_LIST:
			return {
				...state,
				department: action.payload,
			};
		case SET_TITLE_DEPARTMENT_LIST_PAYLOAD:
			return {
				...state,
				allDepartments: action.payload,
			};
		case SET_CATEGORY_ID:
			return {
				...state,
				categoryId: action.payload,
			};
		case SET_ALLOWED_JOB_TITLES:
			return {
				...state,
				allowedJobTitles: action.payload,
			};
		case SHOW_MISSING_FIELDS_INFO_PANEL:
			return { ...state, showMissingFieldsPanel: action.payload };
		case SAVE_JPF_MISSING_FIELDS:
			return { ...state, jobPostMissingFields: action.payload };
		case SET_IS_JPF_ERROR:
			return { ...state, isJobPostFormError: action.payload };
		case HEADCOUNT_VALUE:
			return { ...state, headcountValue: action.value };

		case SHOW_REQUEST_SUPPORT_CTA:
			return {
				...state,
				showRequestSupportCTA: action.value,
			};
		case SHOW_SUPPORT_TICKET_MODAL:
			return {
				...state,
				showSupportTicketModal: action.value,
			};
		case SHOW_SUPPORT_TICKET_INFORMATION_MODAL:
			return {
				...state,
				showSupportTicketInformationModal: action.value,
			};
		case OPTIONAL_FIELD_BANNER_CTA_CLICKED:
			return {
				...state,
				optionalFieldsBannerCtaClicked: action.value,
			};
		case SHOW_SUCCESS_POST_COMPLETING_BWCP_FLOW:
			return {
				...state,
				showSuccessModalPostCompletingBwcpFlow: action.value,
			};
		case SELECTED_DEGREE:
			return {
				...state,
				currentSelectedDegree: action.value,
			};
		case SELECTED_INDUSTRY:
			return {
				...state,
				currentSelectedIndustry: action.value,
			};
		case USER_SELECTED_NUM_EMPLOYEE:
			return {
				...state,
				userSelectedNumEmployee: action.data,
			};
		case WHY_VERIFY_BOTTOM_SHEET_CLOSE:
			return {
				...state,
				whyVerifyBottomSheetClose: action.value,
			};
		case IS_MANUAL_EDIT:
			return {
				...state,
				isManualEdit: action.value,
			};
		case IS_ONLY_BWCP_OPTIONAL_FLOW:
			return {
				...state,
				isOnlyBwcpOptionalFlow: action.value,
			};
		case SHOW_OPTIONAL_MISSING_FIELDS_PANEL:
			return {
				...state,
				showOptionalMissingFieldsPanel: action.value,
			};
		case OPTIONAL_FLOW_MODAL_CTA_CLICKED:
			return {
				...state,
				optionalFlowModalCtaClicked: action.value,
			};
		case AFTER_RENEWAL_BWCP_STATUS:
			return {
				...state,
				afterRenewalBwcpStatus: action.value,
			};
		case BWCP_AUTOSCROLL_TIMEOUT:
			return {
				...state,
				bwcpAutoscrollTimeout: action.value,
			};
		case JOYRIDE_BASIC_DETAILS_DISPLAY_STATE:
			return {
				...state,
				joyrideBasicDetailsDisplayState: action.value,
			};
		case JOYRIDE_JOB_DETAILS_DISPLAY_STATE:
			return {
				...state,
				joyrideJobDetailsDisplayState: action.value,
			};
		case ORG_SEARCH_DATA:
			return {
				...state,
				orgSearchData: action.value,
			};
		case USER_SOURCE:
			return {
				...state,
				userSource: action.value,
			};
		case DUPLICATE_JOB_SOURCE:
			return {
				...state,
				duplicateJobSource: action.value,
			};

		case JOB_POST_CTA_JOYRIDE_SHOWN:
			return {
				...state,
				jobPostCtaJoyrideShown: action.value,
			};
		case DATABASE_CTA_JOYRIDE_SHOWN:
			return {
				...state,
				databaseCtaJoyrideShown: action.value,
			};

		case HIDE_VERIFICATION_PAGE_RIGHT_PANE:
			return {
				...state,
				hideVerificationPageRightPane: action.value,
			};
		case UNDER_REVIEW_BANNER_CTA_TEXT:
			return {
				...state,
				urBannerCtaText: action.value,
			};
		case SET_SMILEY_MODAL_OPEN:
			return {
				...state,
				smileyModalOpen: action.value,
			};

		case CURRENT_VERIFICATION_SCREEN:
			return {
				...state,
				currentVerificationScreen: action.value,
			};
		case JOB_POST_SUCCESS_BANNER:
			return {
				...state,
				showJobPostSuccessBanner: action.value,
			};
		case IS_BACKWARD_FLOW:
			return {
				...state,
				isBackwardFlow: action.value,
			};
		case MIN_SKILLS_REQUIRED:
			return {
				...state,
				minSkillsRequired: action.value,
			};
		case TROPHY_JOB:
			return {
				...state,
				setIsTrophyJob: action.value,
			};
		case TROPHY_JOB_ALLOWED:
			return {
				...state,
				setIsTrophyJobAllowed: action.value,
			};
		case TROPHY_JOB_CONFIRMATION_MODAL:
			return {
				...state,
				showTrophyJobConfirmationModal: action.value,
			};
		case TROPHY_JOB_CTA_CLICK:
			return {
				...state,
				trophyJobCtaClick: action.value,
			};
		case IS_ENGLISH_PREFILL_REMOVED:
			return {
				...state,
				isEnglishPrefillRemoved: action.value,
			};

		case AUTOSELECT_DEGREE_OPTIONS:
			return {
				...state,
				autoselectDegreeOptions: action.value,
			};
		case ENTERPRISE_WORKSPACE_DATA:
			return {
				...state,
				enterpriseWorkspaceData: action.payload,
			};
		case ENTERPRISE_CONFIRM_DISMISS_MODAL:
			return {
				...state,
				showEnterpriseDismissModal: action.payload,
			};
		case USER_PROFILE_V2_DATA:
			return {
				...state,
				userProfileV2Data: action.payload,
			};

		case VAS_JOB:
			return {
				...state,
				setIsVasJob: action.value,
			};
		case VAS_JOB_ALLOWED:
			return {
				...state,
				setIsVasJobAllowed: action.value,
			};
		case VAS_JOB_CONFIRMATION_MODAL:
			return {
				...state,
				showVasJobConfirmationModal: action.value,
			};
		case VAS_JOB_CTA_CLICK:
			return {
				...state,
				vasJobCtaClick: action.value,
			};
		case OTHER_TAG_IDS:
			return {
				...state,
				otherTagIds: action.value,
			};
		case SHOW_ENTERPRISE_JOBS_MIGRATION_PAGE:
			return {
				...state,
				showEnterpriseJobsMigrationPage: action.value,
			};
		case SET_SHOW_EDIT_WARNING_MODAL_VALUES:
			return {
				...state,
				pjfEditWarningModal: action.value,
			};
		case SET_IS_WFH_ALLOWED:
			return {
				...state,
				isWfhAllowed: action.value,
			};
		case SET_ADD_COMPANY_DETAILS_CTA_CLICKED:
			return {
				...state,
				isAddCompanyDetailsCtaClicked: action.value,
			};
		case SET_WHITELISTED_EMAIL_SUBMITTED:
			return {
				...state,
				whitelistedEmailSubmitted: action.value,
			};
		case SET_ORG_VERIFICATION_LEVEL:
			return {
				...state,
				orgVerificationLevel: action.value,
			};
		case IS_EDITED_FROM_PREVIEW_PAGE:
			return {
				...state,
				isEditedFromPreview: action.value,
			};
		case SET_GET_JOB_DATA_FOR_PREVIEW:
			return {
				...state,
				jobDataForPreview: action.value,
			};
		case SET_IS_LATEST_JOB_POST_NEW:
			return {
				...state,
				isNewJobPost: action.value,
			};
		case SET_IS_STEP3_CONTINUE_CLICKED:
			return {
				...state,
				isStep3ContinueClicked: action.value,
			};
		case SET_SELECTED_ACCOUNT_DETAILS:
			return {
				...state,
				selectedAccountDetails: action.value,
			};
		case DB_KALEYRA_CALL_ID:
			return {
				...state,
				dbKaleyraCallId: action.value,
			};
		case SET_IS_ENTERED_JPF_FROM_PREVIEW:
			return {
				...state,
				isEnteredJpfFromPreview: action.value,
			};
		case SET_IS_AUTORENEWAL_FLOW:
			return {
				...state,
				isAutorenewalFlow: action.value,
			};
		case SHOW_PJF_DROPOFF_MODAL:
			return {
				...state,
				showPjfDropoffModal: action.value,
			};
		case SHOW_PJF_FEEDBACK_MODAL:
			return {
				...state,
				pjfFeedbackModalData: action.value,
			};
		case CURRENT_JOB_TITLE:
			return {
				...state,
				currentJobTitle: action.value,
			};
		case SHOW_FEEDBACK_MODAL_POST_DROPOFF:
			return {
				...state,
				showFeedbackModalPostDropoff: action.value,
			};
		case SET_DISCOUNT_COUPON_DATA:
			return {
				...state,
				discountCouponData: action.value,
			};
		case SET_JOBS_LIST_FROM_DASHBOARD:
			return {
				...state,
				jobsListFromDashboard: action.value,
			};
		case SET_RENEWED_EDIT_FROM_JOBS_PAGE:
			return {
				...state,
				renewedEditFromjobsPage: action.value,
			};
		default:
			return state;
	}
};

export default forms;
