import React from "react";

const AadharIcon2 = () => (
	<svg
		width="12"
		height="20"
		viewBox="0 0 12 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.166 0.833496H1.83268C0.916016 0.833496 0.166016 1.5835 0.166016 2.50016V17.5002C0.166016 18.4168 0.916016 19.1668 1.83268 19.1668H10.166C11.0827 19.1668 11.8327 18.4168 11.8327 17.5002V2.50016C11.8327 1.5835 11.0827 0.833496 10.166 0.833496ZM9.33268 15.8335H2.66602C2.20768 15.8335 1.83268 15.4585 1.83268 15.0002V5.00016C1.83268 4.54183 2.20768 4.16683 2.66602 4.16683H9.33268C9.79102 4.16683 10.166 4.54183 10.166 5.00016V15.0002C10.166 15.4585 9.79102 15.8335 9.33268 15.8335Z"
			fill="#005F3E"
		/>
		<path
			d="M8.49935 5H3.49935C3.04102 5 2.66602 5.375 2.66602 5.83333C2.66602 6.29167 3.04102 6.66667 3.49935 6.66667H8.49935C8.95768 6.66667 9.33268 6.29167 9.33268 5.83333C9.33268 5.375 8.95768 5 8.49935 5Z"
			fill="#005F3E"
		/>
	</svg>
);

export default AadharIcon2;
