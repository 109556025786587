import React from "react";

const ManageCoinsIcon = ({ fill = "#5E6C84" }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_771_8786)">
				<path
					d="M16.6665 3.33333H3.33317C2.40817 3.33333 1.67484 4.07499 1.67484 4.99999L1.6665 15C1.6665 15.925 2.40817 16.6667 3.33317 16.6667H16.6665C17.5915 16.6667 18.3332 15.925 18.3332 15V4.99999C18.3332 4.07499 17.5915 3.33333 16.6665 3.33333ZM15.8332 15H4.1665C3.70817 15 3.33317 14.625 3.33317 14.1667V9.99999H16.6665V14.1667C16.6665 14.625 16.2915 15 15.8332 15ZM16.6665 6.66666H3.33317V4.99999H16.6665V6.66666Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_771_8786">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ManageCoinsIcon;
