import React from "react";

const WhatsAppBoostIcon = () => {
	return (
		<svg
			width="284"
			height="110"
			viewBox="0 0 284 110"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.553086"
				y="10.5531"
				width="274.894"
				height="26.8938"
				rx="4.97778"
				fill="white"
				stroke="#DFE1E6"
				strokeWidth="1.10617"
			/>
			<rect
				x="0.553086"
				y="45.5531"
				width="274.894"
				height="63.8938"
				rx="4.97778"
				fill="white"
				stroke="#DFE1E6"
				strokeWidth="1.10617"
			/>
			<circle cx="21.7686" cy="23.9934" r="9" fill="#D9D9D9" />
			<path d="M47.8872 23.2897H181.037" stroke="#DFE1E6" strokeWidth="4.42469" />
			<path d="M13 98H211" stroke="#DFE1E6" strokeWidth="4.42469" />
			<path d="M13 88H265" stroke="#DFE1E6" strokeWidth="4.42469" />
			<rect x="13" y="53" width="252" height="23" fill="#DFE1E6" />
			<g filter="url(#filter0_d_637_43394)">
				<circle cx="265.028" cy="17.0278" r="15.0278" fill="white" />
			</g>
			<g clipPath="url(#clip0_637_43394)">
				<path
					d="M270.88 11.1054C269.317 9.54161 267.239 8.67991 265.025 8.67896C260.463 8.67896 256.751 12.3905 256.749 16.9525C256.748 18.4108 257.129 19.8343 257.853 21.089L256.679 25.3765L261.067 24.226C262.276 24.8852 263.637 25.2326 265.022 25.2332H265.025H265.025C269.587 25.2332 273.3 21.521 273.302 16.9592C273.303 14.7482 272.443 12.6694 270.88 11.1054ZM265.025 23.8358H265.023C263.788 23.8351 262.577 23.5037 261.521 22.877L261.27 22.728L258.666 23.4107L259.362 20.873L259.198 20.6129C258.509 19.5179 258.146 18.2524 258.146 16.953C258.148 13.1612 261.233 10.0764 265.028 10.0764C266.866 10.0772 268.593 10.7935 269.892 12.0933C271.19 13.3931 271.905 15.121 271.905 16.9586C271.903 20.7505 268.817 23.8358 265.025 23.8358Z"
					fill="#E0E0E0"
				/>
				<path
					d="M257.059 24.9008L258.18 20.8093C257.488 19.6118 257.125 18.2532 257.125 16.8619C257.127 12.5083 260.67 8.96643 265.024 8.96643C267.137 8.96738 269.12 9.78968 270.611 11.2821C272.102 12.7745 272.923 14.7583 272.922 16.868C272.92 21.2217 269.377 24.7639 265.024 24.7639C265.024 24.7639 265.024 24.7639 265.024 24.7639H265.02C263.699 24.7635 262.4 24.4316 261.246 23.8029L257.059 24.9008Z"
					fill="url(#paint0_linear_637_43394)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M262.981 13.4402C262.828 13.0997 262.666 13.0929 262.521 13.087C262.401 13.0818 262.265 13.0822 262.129 13.0822C261.993 13.0822 261.771 13.1334 261.584 13.3379C261.396 13.5425 260.868 14.037 260.868 15.0428C260.868 16.0487 261.601 17.0206 261.703 17.1571C261.805 17.2934 263.117 19.4234 265.195 20.2428C266.922 20.9238 267.273 20.7884 267.648 20.7543C268.023 20.7202 268.858 20.2599 269.028 19.7825C269.198 19.3052 269.198 18.8961 269.147 18.8106C269.096 18.7254 268.96 18.6742 268.755 18.572C268.551 18.4698 267.546 17.9752 267.359 17.907C267.171 17.8389 267.035 17.8048 266.899 18.0095C266.762 18.2139 266.371 18.6742 266.251 18.8106C266.132 18.9472 266.013 18.9642 265.808 18.862C265.604 18.7594 264.946 18.5438 264.164 17.8474C263.557 17.3055 263.146 16.6364 263.027 16.4317C262.908 16.2273 263.014 16.1165 263.117 16.0146C263.209 15.923 263.321 15.776 263.424 15.6566C263.526 15.5372 263.56 15.452 263.628 15.3157C263.696 15.1792 263.662 15.0598 263.611 14.9576C263.56 14.8554 263.162 13.8444 262.981 13.4402Z"
					fill="white"
				/>
				<path
					d="M270.812 11.0792C269.268 9.5333 267.213 8.68151 265.025 8.68054C260.515 8.68054 256.845 12.3495 256.843 16.8591C256.843 18.3007 257.219 19.7078 257.935 20.9482L256.774 25.1864L261.112 24.0491C262.307 24.7008 263.652 25.0442 265.021 25.0447H265.025H265.025C269.534 25.0447 273.205 21.3752 273.207 16.8657C273.207 14.6802 272.357 12.6252 270.812 11.0792ZM265.025 23.6634H265.022C263.802 23.6627 262.605 23.3351 261.561 22.7156L261.313 22.5684L258.739 23.2432L259.426 20.7346L259.264 20.4775C258.583 19.395 258.224 18.1441 258.224 16.8597C258.226 13.1114 261.276 10.062 265.027 10.062C266.844 10.0628 268.551 10.7708 269.835 12.0557C271.119 13.3406 271.826 15.0486 271.825 16.8652C271.823 20.6136 268.773 23.6634 265.025 23.6634Z"
					fill="white"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_637_43394"
					x="246.243"
					y="0.121531"
					width="37.5695"
					height="37.5694"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="1.87847" />
					<feGaussianBlur stdDeviation="1.87847" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_637_43394"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_637_43394"
						result="shape"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_637_43394"
					x1="264.991"
					y1="24.9008"
					x2="264.991"
					y2="8.96646"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#20B038" />
					<stop offset="1" stopColor="#60D66A" />
				</linearGradient>
				<clipPath id="clip0_637_43394">
					<rect
						width="16.624"
						height="16.6975"
						fill="white"
						transform="translate(257.514 9.51392)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default WhatsAppBoostIcon;
