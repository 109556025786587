import React from "react";

const AadharIcon3 = () => (
	<svg
		width="24"
		height="13"
		viewBox="0 0 24 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.8616 0.950079C10.9947 1.21295 11.2754 1.41364 11.4313 1.67477C11.5325 1.84437 11.5814 2.11427 11.7352 2.24104C11.9206 2.39392 12.2231 2.34291 12.3393 2.13279C12.4501 1.93237 12.3423 1.69433 12.2574 1.50567C12.0431 1.02975 11.7273 0.632496 11.3386 0.28554C11.1491 0.116446 10.9155 -0.0945596 10.6439 0.0459575C10.0615 0.347186 9.58495 1.05644 9.35239 1.65061C9.21882 1.99187 9.39291 2.48864 9.84545 2.29732C10.0628 2.20543 10.113 1.91256 10.2159 1.72308C10.3806 1.41997 10.6153 1.1856 10.8616 0.950079Z"
			fill="#BD5B00"
		/>
		<path
			d="M4.76563 1.57419C4.55648 1.6546 4.50505 1.89522 4.44304 2.08526C4.27542 2.59887 4.20497 3.16645 4.27486 3.70373C4.31533 4.01487 4.46525 4.38762 4.86227 4.2316C4.99136 4.18087 5.07417 4.05521 5.09616 3.92114C5.1183 3.78632 5.06723 3.64499 5.05801 3.51048C5.04188 3.27542 5.07267 3.03994 5.11874 2.80995C5.13705 2.71859 5.14087 2.55261 5.218 2.4886C5.301 2.41968 5.52567 2.53684 5.61226 2.56996C5.95564 2.70122 6.64242 3.45724 6.93178 2.8824C7.08165 2.58467 6.81408 2.35852 6.60414 2.1958C6.2146 1.89387 5.73605 1.68287 5.24937 1.59612C5.09887 1.56928 4.91523 1.51667 4.76563 1.57419Z"
			fill="#BD5B00"
		/>
		<path
			d="M17.37 1.56456C16.762 1.65918 16.1655 1.90205 15.7008 2.30971C15.4692 2.51289 15.3266 2.94823 15.725 3.08298C16.0996 3.2097 16.5203 2.68556 16.862 2.56115C16.9448 2.53101 17.129 2.43322 17.2145 2.47702C17.3086 2.52521 17.3274 2.76513 17.3438 2.8584C17.3815 3.07271 17.407 3.29278 17.3921 3.51062C17.3824 3.65157 17.3294 3.80501 17.3585 3.94543C17.405 4.17001 17.6081 4.28884 17.8297 4.25429C18.1534 4.20383 18.1858 3.79552 18.1922 3.53477C18.2043 3.04194 18.1518 2.58347 18.013 2.10955C17.912 1.76501 17.7757 1.50142 17.37 1.56456Z"
			fill="#BD5B00"
		/>
		<path
			d="M9.4588 4.6564C8.07125 4.83832 6.77561 5.2166 5.63641 6.06831C5.33278 6.29533 4.85394 6.59622 4.67975 6.941C4.563 7.17213 4.69296 7.48114 4.95903 7.51621C5.30285 7.56153 5.50406 7.22602 5.73536 7.02796C6.20266 6.62784 6.72679 6.29888 7.28149 6.0348C9.02261 5.2059 11.0512 5.27477 12.7973 6.0333C14.5811 6.80821 15.9031 8.47256 16.4122 10.3229C16.5188 10.7102 16.5776 11.1075 16.6156 11.5065C16.6353 11.7128 16.5954 11.9332 16.6597 12.1346C16.7217 12.3288 16.9166 12.3965 17.1036 12.3716C17.55 12.3122 17.4293 11.6505 17.398 11.3374C17.2913 10.272 16.9876 9.25065 16.4534 8.31791C15.1097 5.97125 12.2121 4.29543 9.4588 4.6564Z"
			fill="#BD5B00"
		/>
		<path
			d="M14.9517 4.66297C14.7237 4.74034 14.6381 5.05174 14.7617 5.24953C14.8551 5.39887 15.0373 5.50204 15.1694 5.61538C15.4302 5.83916 15.6761 6.06988 15.9194 6.31277C16.9311 7.32299 17.6578 8.62194 17.9968 10.0087C18.112 10.4801 18.1954 10.9731 18.2167 11.4581C18.2268 11.6876 18.1633 12.0635 18.3246 12.2507C18.4673 12.4165 18.6995 12.376 18.895 12.376H20.0805C20.2598 12.376 20.4896 12.4136 20.661 12.3554C20.9742 12.2491 20.9031 11.8612 20.903 11.603C20.9027 10.8748 20.7369 10.126 20.5367 9.42893C19.9882 7.51865 18.682 5.55175 16.7177 4.8956C16.2878 4.75201 15.8182 4.64616 15.363 4.64599C15.2345 4.64594 15.0748 4.6212 14.9517 4.66297Z"
			fill="#BD5B00"
		/>
		<path
			d="M21.5793 6.86814C21.4827 7.21068 21.396 7.53838 21.2346 7.85855C21.1456 8.03523 20.9554 8.1978 20.9115 8.38999C20.8291 8.75045 21.2099 9.0496 21.53 8.82833C22.0278 8.48415 22.253 7.63952 22.3805 7.08555C22.4262 6.88737 22.5145 6.60206 22.4122 6.40927C22.3062 6.20964 22.0407 6.16297 21.8454 6.09057C21.3184 5.89515 20.541 5.64646 19.9826 5.84969C19.681 5.95948 19.6514 6.41736 19.9343 6.56759C20.1299 6.67143 20.3751 6.58599 20.5874 6.60653C20.9296 6.6396 21.255 6.76277 21.5793 6.86814Z"
			fill="#BD5B00"
		/>
		<path
			d="M2.03132 7.25478C2.35448 7.14977 2.68248 7.02721 3.02321 6.99319C3.23264 6.97227 3.48434 7.05735 3.67604 6.95423C3.97684 6.79243 3.91143 6.3374 3.6038 6.2308C3.04239 6.03622 2.29376 6.29145 1.76521 6.48057C1.5703 6.55031 1.30364 6.59773 1.19844 6.79591C1.09997 6.98143 1.18393 7.2556 1.22333 7.44803C1.34307 8.03295 1.58876 8.80098 2.05963 9.20192C2.29797 9.40488 2.71045 9.24171 2.7187 8.92157C2.72499 8.67742 2.51118 8.49506 2.40168 8.2935C2.22399 7.96643 2.12439 7.61302 2.03132 7.25478Z"
			fill="#BD5B00"
		/>
		<path
			d="M9.58007 6.20247C8.01314 6.40801 6.54909 7.04675 5.54506 8.31798C5.19897 8.75617 4.88592 9.24254 4.68875 9.76735C4.59389 10.0198 4.66869 10.3432 4.98353 10.369C5.3605 10.3998 5.44074 10.0418 5.57138 9.76735C5.8261 9.23222 6.21332 8.71897 6.6528 8.32208C7.9775 7.12574 9.7986 6.69156 11.5155 7.19287C13.0636 7.64494 14.3175 8.90389 14.8297 10.4196C14.9533 10.7853 15.031 11.1708 15.0676 11.5549C15.0859 11.7464 15.043 11.9778 15.1223 12.1587C15.2007 12.3378 15.3976 12.3997 15.5798 12.3711C15.9524 12.3125 15.8958 11.7824 15.8632 11.5066C15.763 10.6592 15.5522 9.8604 15.1237 9.11513C14.0445 7.23778 11.7916 5.9124 9.58007 6.20247Z"
			fill="#BD5B00"
		/>
		<path
			d="M11.9034 9.98438C10.8498 9.01827 9.17716 9.05938 8.15767 10.0499C7.72468 10.4706 7.46212 11.0094 7.36948 11.6028C7.33678 11.8125 7.30124 12.1386 7.48065 12.2899C7.66165 12.4425 7.9846 12.3981 8.09412 12.1825C8.22814 11.9187 8.16911 11.5515 8.28548 11.2647C8.61248 10.4586 9.4568 10.0002 10.3067 10.0851C10.9869 10.1531 11.6338 10.6744 11.8623 11.313C11.9622 11.5922 11.8985 12.1559 12.1704 12.322C12.3858 12.4536 12.7953 12.3758 13.0404 12.3758C13.3333 12.3758 13.9338 12.489 14.1752 12.3081C14.3661 12.165 14.323 11.931 14.3226 11.7236C14.3215 11.1695 14.1404 10.5712 13.8884 10.081C12.9529 8.26128 10.784 7.2822 8.80675 7.91162C7.43996 8.34672 6.35643 9.42349 5.9591 10.8057C5.85021 11.1845 5.71717 11.7179 5.8204 12.1101C5.90829 12.4441 6.42424 12.475 6.55674 12.1584C6.62303 12 6.59349 11.8171 6.60936 11.6512C6.63293 11.4048 6.68845 11.1627 6.76093 10.9265C7.07511 9.90256 7.92651 9.07265 8.92771 8.71716C9.80918 8.40419 10.8046 8.48763 11.6372 8.90333C11.8361 9.00262 12.0171 9.12859 12.1937 9.26283C12.2505 9.30609 12.3617 9.37081 12.3617 9.45303C12.3617 9.61858 11.9987 9.84929 11.9034 9.98438Z"
			fill="#BD5B00"
		/>
		<path
			d="M1.52408 11.4579C1.52411 11.6754 1.49083 11.9535 1.57799 12.1583C1.67377 12.3833 2.0116 12.4536 2.19692 12.2932C2.40417 12.1138 2.32243 11.7028 2.32243 11.4579C2.32243 11.0538 2.40642 10.2532 1.74181 10.4731C1.61839 10.5139 1.49687 10.5654 1.37893 10.6199C0.891844 10.8449 0.339664 11.2824 0.0631896 11.7478C-0.131805 12.076 0.152624 12.484 0.532177 12.3552C0.693496 12.3005 0.786692 12.132 0.897246 12.0135C1.09143 11.8053 1.29946 11.6307 1.52408 11.4579Z"
			fill="#BD5B00"
		/>
		<path
			d="M22.4749 11.458C22.7044 11.6226 22.9093 11.8066 23.1017 12.0136C23.2205 12.1415 23.3125 12.309 23.491 12.3606C23.8626 12.4679 24.1284 12.0649 23.9359 11.7489C23.6487 11.2773 23.0973 10.8329 22.5959 10.6092C22.4778 10.5566 22.357 10.5022 22.233 10.4652C21.5845 10.2721 21.6766 11.0693 21.6766 11.458C21.6766 11.7045 21.5933 12.1143 21.802 12.2934C21.9894 12.4543 22.326 12.384 22.4244 12.1586C22.5122 11.9572 22.4749 11.6723 22.4749 11.458Z"
			fill="#BD5B00"
		/>
		<path
			d="M4.52304 10.8428C4.03267 10.962 4.26462 11.7174 4.76497 11.5928C5.25452 11.4709 5.02148 10.7216 4.52304 10.8428Z"
			fill="#BD5B00"
		/>
		<path
			d="M9.84538 10.8406C9.38822 10.9139 8.99526 11.2922 8.91313 11.7479C8.87873 11.9387 8.87604 12.1868 9.04808 12.3119C9.19057 12.4155 9.41287 12.376 9.57927 12.376C10.0134 12.376 10.5332 12.4547 10.9582 12.3677C11.3558 12.2862 11.2398 11.7088 11.1086 11.458C10.8624 10.9876 10.3666 10.757 9.84538 10.8406Z"
			fill="#BD5B00"
		/>
	</svg>
);

export default AadharIcon3;
